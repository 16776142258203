import React from "react";
import { color_scheme, getTypeIcon, message_crm_url, replaceCreationUser } from "../../../../utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { config } from "../../../../utils/config";
import moment from 'moment-timezone';
import { INTERACTION_MODES } from "../../../../utils/constants";
moment.tz.setDefault('Australia/Brisbane');

const COLORS = color_scheme(config.notes, config.scheme.interactions);

/**
 * A table row that displays an interaction sent.
 * @param {Object[]} interactionsSent - An array of interactions sent.
 */
const InteractionTableRow = ({ interactionSent }) => (
    <tr>
        <td>
            <div>
                <FontAwesomeIcon
                    icon={getTypeIcon(interactionSent.interaction.interaction_type)}
                    style={{ color: COLORS[interactionSent.interaction.interaction_type] }}
                /> {interactionSent.interaction.interaction_type}
            </div>
            <div>
                {interactionSent.interaction.num_messages > 1 && <small>{interactionSent.interaction.num_messages} recipients</small>}
            </div>
            {interactionSent.interaction.mode !== INTERACTION_MODES.EMAIL_PER_ENROLLMENT && <div>
                <small>Email(s) sent per <b>{interactionSent.interaction.mode}</b>. For this interaction the email was {interactionSent.send_email === false && 'not'} send.</small>
            </div>}
        </td>
        <td>
            <div>{moment(interactionSent.interaction.time_created).format("DD MMM YYYY")}</div>
            <div><small>{moment(interactionSent.interaction.time_created).format("ddd HH:mm")}</small></div>
        </td>
        <td>{replaceCreationUser(interactionSent.interaction.username)}</td>
        <td>
            <div>{interactionSent.interaction.user_role}</div>
            <div><small>{interactionSent.unit_code}</small></div>
        </td>
        { interactionSent.interaction.interaction_type === 'Crm' ?
        <td>
                <b>{interactionSent.case_title}</b>
                <br />
                CRM Case: <a target="_blank" rel="noopener noreferrer" href={message_crm_url(interactionSent.case_url)}>{interactionSent.case_number}</a>
                <br />
                <small>More details available to users with SugarCRM access</small>
        </td>
        :
        <td>
            <b>{interactionSent.interaction.subject}</b>
            <p className="hardWrap" dangerouslySetInnerHTML={{__html: interactionSent.interaction.body_text}} />
        </td>
        }
        <td>{interactionSent.interaction.strategy_name}</td>
    </tr>
);

export default InteractionTableRow;
