import { useState, useEffect } from 'react';
import { config } from '../utils/config';
import useQuery from './useQuery';
import { readQueryString } from '../utils/uriParams';

/**
 * Custom hook returns the parameters.
 *
 * @returns {Object} An object with the parameters.
 */
export default function useCustomParams() {
    const query = useQuery();
    const [params, setParams] = useState(config.defaultParams);

    /**
     * When the query (search params) changes.
     */
    useEffect(() => {
        setParams(readQueryString(config.defaultParams, window.location.search));
    }, [query])

    return params;
}
