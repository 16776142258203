import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment-timezone';
moment.tz.setDefault('Australia/Brisbane');

const MyExperienceListItem = ({ item }) => (
    <div className="feedbackListItem">
        <div className="feedbackListItemIcon">
            <FontAwesomeIcon icon={faComment} />
        </div>
        <div className="feedbackListItemInfo">
            <p>
                <span style={{ leftPadding: '1rem' }}>
                    {moment(item.recorded_date, 'YYYY-MM-DD hh:ii:ss').format('D MMM YYYY')}
                </span>
            </p>
        </div>
        <div className="feedbackListItemContent">
            <p>Satisfaction score: {item.rating}</p>
            <p>{item.response ? "Response:" : ""} {item.response}</p>
        </div>
    </div>
);

export default MyExperienceListItem;