import React from 'react';
import Spinner from '../Spinner/Spinner';

/**
 * This is a function component which is like a wrapper for the spinner. Displaying text depending which property is True.
 */
const Loader = ({isLoadingAssets = false, isLoadingHome = false, isLoadingEnrollments = false, isLoadingAssessments = false}) => {
    if (isLoadingAssets) return <Spinner text='Loading assets ...'/>;
    if (isLoadingHome) return <Spinner text='Loading profile ...'/>;
    if (isLoadingEnrollments) return <Spinner text='Loading enrollments ...'/>;
    if (isLoadingAssessments) return <Spinner text='Loading assessments ...'/>;
    return <Spinner />
}

export default Loader;