import React from 'react';
import { filterIntegers, getWeeklyTotals, renderNumberBar, renderYLabel, withWidget } from '../../../../utils/graph';
import { config } from '../../../../utils/config';
import { Hint, HorizontalGridLines, VerticalBarSeries, XAxis, XYPlot, YAxis } from 'react-vis';
import { getWeekFromDate, mapBeginDateToWeeks, mapBeginDateToShortDates } from '../../../../utils/calendar';

const WeeklyUnenrolmentsGraph = (props) => {
    const {
        data,
        hover,
        //handleClick,
        handleMouseOver,
        handleMouseOut,
        //isClickable,
        calendar,
        disabled,
        axisDates, // display dates instead of week numbers
    } = props;
    const title = props.title ? props.title : "Weekly Unenrolments Summary";

    const getUnenrolGraph = (unenrolByWeek, subCode, color) => {
        const points = Object.entries(unenrolByWeek).map((item, index) => {
            const [begin_date, total] = item;
            const week = getWeekFromDate(begin_date, calendar);
            const selected = hover;
            return {
                x: begin_date,
                y: total,
                subCode,
                color: (selected.x === begin_date && selected.subCode === subCode) ? config.colors.cquBlue50 : color,
                description: week && week.long_name,
            }
        });
        //  console.log('UNENROL GRAPH', points);
        return points;
    }
    /*
    const getUnenrolTotal = (unenrolByWeek) => {
      const data = unenrolByWeek.reduce((acc, _) => {
        acc += (_ === null) ? 0 : _.total;
        return acc;
      }, 0);
      return data;
    }
    */

    const getUnenrolWeek = (unenrolByWeek, thisWeek) => {
        if (thisWeek === null) return null;
        for (const week of unenrolByWeek) {
            if (week.begin_date === thisWeek.begin_date) {
                return week;
            }
        }
        return null;
    }

    const hasData = (data.length > 0);
    const thisWeek = calendar.this_week;
    const unenrolPreCensusGraph = getUnenrolGraph(getWeeklyTotals(data, 'pre_census', calendar), 'Pre Census', config.colors.high);
    const unenrolPostCensusGraph = getUnenrolGraph(getWeeklyTotals(data, 'post_census', calendar), 'Post Census', config.colors.low);
    // obj, or null if no data for this week
    const unenrolThisWeek = getUnenrolWeek(data, thisWeek);
    const unenrolThisWeekTotal = unenrolThisWeek ? unenrolThisWeek.total : 0;
    const disabledClass = disabled ? "faded" : "";

    const plot = {
        width: 480,
        height: 230,
        left: 60,
        bottom: 50,
    };

    return (
        <div className={`UnitWidget UnitWidgetWide ${disabledClass}`}>
            <h4>{title}</h4>
            {/* hasUnenrolData && (
        this.renderLegendBar([
        [`Pre-Census (${this.getUnenrolTotal(unenrolPreCensus)})`, config.colors.high],
        [`Post-Census (${this.getUnenrolTotal(unenrolPostCensus)})`, config.colors.low],
          ])
        )*/}
            <div style={{
            }}>
                {hasData ? renderNumberBar(
                    unenrolThisWeekTotal,
                    unenrolThisWeekTotal === 1
                        ? 'unenrolment'
                        : 'unenrolments',
                    'this week'
                ) : renderNumberBar()}
            </div>
            <XYPlot
                height={plot.height}
                width={plot.width}
                xType="ordinal"
                //  xDomain={[0, termWeekTotal]}
                stackBy='y'
                className={hasData ? "" : "empty"}
                margin={{
                    left: plot.left,
                    bottom: plot.bottom,
                }}
            >
                {hover && (
                    <Hint
                        value={hover}
                        className='plotTooltip'
                    >
                        <div>
                            <h3>{hover.description}</h3>
                            <p>{hover.subCode} {hover.y - (hover.y0 || 0)}</p>
                        </div>
                    </Hint>
                )}
                {axisDates ? (
                    <XAxis
                        height={plot.bottom}
                        tickFormat={begin_date => mapBeginDateToShortDates(begin_date, calendar)}
                        tickLabelAngle={-90}
                    />
                ) : (
                    <XAxis
                        height={plot.bottom}
                        tickFormat={begin_date => mapBeginDateToWeeks(begin_date, calendar)}
                    />
                )}
                {hasData && (
                    <YAxis
                        width={plot.left}
                        tickFormat={filterIntegers}
                    //  tickTotal={3}
                    />
                )}
                {/** renderXLabel("Week of Term", plot) **/}
                {renderYLabel("Unenrolments", plot)}
                <HorizontalGridLines />
                <VerticalBarSeries
                    colorType={"literal"}
                    //fill={config.colors.high}
                    stroke="#ffffff"
                    data={unenrolPreCensusGraph}
                    onValueMouseOver={handleMouseOver}
                    onValueMouseOut={handleMouseOut}
                />
                <VerticalBarSeries
                    colorType={"literal"}
                    //fill={config.colors.low}
                    stroke="#ffffff"
                    data={unenrolPostCensusGraph}
                    onValueMouseOver={handleMouseOver}
                    onValueMouseOut={handleMouseOut}
                />
            </XYPlot>
            {!hasData && (
                <div style={{
                    position: 'relative',
                    bottom: '160px',
                }}>
                    No data
                </div>
            )}
        </div>
    );
}

export default withWidget(WeeklyUnenrolmentsGraph);