// [NC 2019-11-05] Some functions to standardize graph elements

import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import {
  ChartLabel,
} from "react-vis";
import { make_object } from "./utils";

// hide non-integer values in axes
const filterIntegers = n => n === Math.floor(n) ? parseInt(n) : null;

const renderSiNumber = (val) => {
  let num = filterIntegers(val);
  if (num === null) {
    return num;
  }
  if (val >= 1000) {
      return (val / 1000) + 'k';
  }
  return num;
}

function renderNumberBar(number, line1, line2)
{
  return (
    <table className="numberBar">
      <tbody>
        <tr>
          <td className="numberBarNumber">{number}</td>
          <td>{line1}<br/>{line2}</td>
        </tr>
      </tbody>
    </table>
  );
}

/**
 * @param {string} text label
 * @param {object} plot size and margins - deprecated
 * @returns <ChartLabel>
 */
function renderXLabel(text, plot = {})
{
  return (
    <ChartLabel
      text={text}
      includeMargin={true}
      xPercent={0.5}
      yPercent={0.95}
      style={{
        textAnchor: 'middle',
        fontWeight: 'bold'
      }}
    />
  );
}

/**
 * @param {string} text label
 * @param {object} plot size and margins
 * @returns <ChartLabel>
 */
function renderYLabel(text, plot = {})
{
  const {
    width=300,
    height=280,
    left=60,
    bottom=60,
  } = plot;
  let xPercent = 1 - Math.log(width)/Math.log(width+left);
  return (
    <ChartLabel
      text={text}
      includeMargin={true}
      xPercent={xPercent}
      yPercent={0.4}
      style={{
        transform: 'rotate(-90)',
        textAnchor: 'middle',
        fontWeight: 'bold'
      }}
    />
  );
}

const renderPercentageBar = (parts, props = {}) => {
  const { onMouseOver = ()=>{}, onMouseOut = ()=>{}, onClick = ()=>{} } = props;
  const clickable = props.onClick ? "clickable" : "";
  return (
    <div className="percentageBar" style={{display: 'flex', flexDirection: 'row', height: '2rem', width: '100%'}}>
      {parts.map((part, index) => {
        const [percentage, hexade, hover] = part;
        return (
          <div
            key={index}
            className={`percentageBarSegment ${clickable}`}
            style={{width: `${percentage}%`, backgroundColor: hexade}}
            onClick={() => onClick(hover)}
            onMouseOver={() => onMouseOver(hover)}
            onMouseOut={onMouseOut}
          />
        );
      })}
    </div>
  );
}

const renderLegendBar = (parts, props = {}) => {
  //  console.log(parts, props);
  const { onMouseOver = ()=>{}, onMouseOut = ()=>{}, onClick = ()=>{} } = props;
  const clickable = props.onClick ? "clickable" : "";
  return (
    <p className={"widgetLegend"} style={{textAlign: 'center'}}>
      {parts.map((part, index) => {
        const [label, hexade, hover] = part;
        return (
          <span
            key={index}
            className={`horizontal-padding noWrap ${clickable}`}
            onClick={() => onClick(hover)}
            onMouseOver={() => onMouseOver(hover)}
            onMouseOut={onMouseOut}
            >
            <FontAwesomeIcon
              icon={faCircle}
              style={{ color: hexade }}
            /> {label}
          </span>
        );
      })}
    </p>
  );
}

// LegendBar, but vertical
const renderLegend = (parts, props = {}) => {
  const { onMouseOver = ()=>{}, onMouseOut = ()=>{}, onClick = ()=>{} } = props;
  const clickable = props.onClick ? "clickable" : "";
  return (
    <div className={"widgetLegend"}>
      {parts.map((part, index) => {
        const [label, hexade, hover] = part;
        return (
          <div
            className={`row ${clickable}`}
            key={index}
            //title={title}
            onClick={() => onClick(hover)}
            onMouseOver={() => onMouseOver(hover)}
            onMouseOut={onMouseOut}
          >
            <FontAwesomeIcon
              icon={faCircle}
              style={{ color: hexade }}
            /> {label}
          </div>
        );
      })}
    </div>
  );
}

const getPercentageGraph = (data, colors, selectedColor, selectedCode) => {
  return data.map((_, i) => {
    const color = (selectedCode !== undefined && selectedCode === _.code) ? selectedColor : colors[i];
    return (
      [_.total, color, _]
    );
  });
}

const getRadialGraph = (data, labelProp, colorCodes, selectedColor, selectedCode) => {
  return data.map((_) => {
    const code = _.code;
    const color = (selectedCode !== undefined && code === selectedCode) ? selectedColor : colorCodes[code];
    const label = labelProp ? String(_[labelProp]) : undefined;
    return ({
      ..._,
      angle: _.total,
      color,
      label,
    });
  });
}

const getWeeklyTotals = (array, propName, calendar) => {
  let validWeekDates = calendar.term_weeks.map(week => week.begin_date);
  const data = array.reduce((acc, _) => {
    if ('begin_date' in _) {
      if (_.begin_date in acc) {
        acc[_.begin_date] += _[propName];
      } else {
        console.log("Key not found", _.begin_date, 'in', _);
      }
    } else {
      console.log("Key not found: begin_date", _);
    }
    return acc;
  }, make_object(validWeekDates, 0));
  return data;
}

// this wraps a Graph function and provides state
// for handling click and mouse events
function withWidget(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.handleClick = this.handleClick.bind(this);
      this.handleMouseOver = this.handleMouseOver.bind(this);
      this.handleMouseOut = this.handleMouseOut.bind(this);
      this.state = {
        hover: false,
        isClickable: ('onClick' in props),
        ...props,
      };
    }

    handleClick(value)
    {
      this.state.onClick && this.state.onClick({
        ...this.state,
        //propName: this.state.propName,
        //paramName: this.state.paramName,
        propValue: value.code,
        propDescription: value.description,
      });
    }

    handleMouseOver(value)
    {
      this.setState({hover: value});
    }

    handleMouseOut()
    {
      this.setState({hover: false});
    }

    render() {
      // Notice that we pass through any additional props
      return <WrappedComponent
        hover={this.state.hover}
        isClickable={this.state.isClickable}
        handleClick={this.handleClick}
        handleMouseOver={this.handleMouseOver}
        handleMouseOut={this.handleMouseOut}
        {...this.props}
      />;
    }
  };
}

export {
  filterIntegers,
  renderSiNumber,
  renderNumberBar,
  renderXLabel,
  renderYLabel,
  renderPercentageBar,
  renderLegendBar,
  renderLegend,
  getPercentageGraph,
  getRadialGraph,
  getWeeklyTotals,
  withWidget
}
