import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const InteractionValidationErrorListItem = ({text}) => (
    <div className='errorMessage'>
        <FontAwesomeIcon icon={faTimesCircle} /> {text}
    </div>
)

export default InteractionValidationErrorListItem;