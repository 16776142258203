//  [NC 2019-01-18]
//  Shared config

const config = {
  system: {
    appEnv: process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : 'production',
    name: 'CQU Success',
    byline: 'For timely and informed support',
    baseUrl: 'https://success.cqu.edu.au',
    home: '/',
    query_limit: 5000,
    baseApiUrl: process.env.REACT_APP_API_BASE_URL ? process.env.REACT_APP_API_BASE_URL : '/api',
    spinnerTimeout: 30000, // milliseconds
  },
  links: {
    aimsCourseUrl: 'https://aims.cqu.edu.au/student/admissions/course/-/-/$courseCode',
    aimsStudentUrl: 'https://aims.cqu.edu.au/student/students/detail/$studentCode',
    aimsUrl: 'https://aims.cqu.edu.au/',
    handbookUrl: 'https://handbook.cqu.edu.au/',
    moodleCourseUrl: 'https://moodle.cqu.edu.au/course/view.php?name=$shortname&enablesam=1',
    moodleStudentUrl: 'https://moodle.cqu.edu.au/user/profile.php?id=$studentId',
    moodleUrl: 'https://moodle.cqu.edu.au/my/',
    myTasksUrl: 'https://cqu365.sharepoint.com/sites/eqi/SitePages/CQU-Success.aspx',
    vetMeUrl: 'https://sqlreports.cqu.edu.au/PBIReports/powerbi/My%20Experience/ME%20Educational%20VET%20Comments',
  },
  successStrategy: {
    fieldNames: [
      'AdminName',
      'AdminFirstName',
      'AdminLastName',
      'AdminEmail',
      'AdminUsername',
      'AdminSignature',
      'StaffName',
      'StaffFirstName',
      'StaffLastName',
      'StaffEmail',
      'StaffUsername',
      'TimeGenerated',
      'NumMatches',
    ],
  },
  interactions: {
    fieldNames: {
      HE: [
        'StudentFirstName',
        'StudentLastName',
        'StudentName',
        'StudentCode',
        'StudentS1ID',
        'Unit',
        'UnitCode',
        'UnitName',
        'StaffName',
        'StaffFirstName',
        'StaffLastName',
        'StaffEmail',
        'Signature',
      ],
      VET: [
        'StudentFirstName',
        'StudentLastName',
        'StudentName',
        'StudentCode',
        'StudentS1ID',
        'Unit',
        'UnitCode',
        'UnitName',
        'StaffName',
        'StaffFirstName',
        'StaffLastName',
        'StaffEmail',
        'Signature',
        'CourseCode',
        'CourseName',
        'IntakeMonth',
        'IntakeYear',
      ],
    },
  },
  genders: [
    { code: 'M', name: 'Male' },
    { code: 'F', name: 'Female' },
    { code: 'X', name: 'Other' },
    { code: 'N', name: 'Not Entered' },
  ],
  colors: {
    cquGreen: "#C1D600",
    cquBlue: "#003057",
    cquLightBlue: "#0070B4",
    cquCharcoal: "#58595B",
    cquLightCharcoal: "#D2D8E0",
    cquBlue50: "#8096A6",
    cquBlue80: "#6280A7",
    cquRed: "#EB1E23",
    cquYellow: "#FFE900",
    cquOrange: "#FFB100",
    low: "#eb1e23",
    medium: "#ffb100",
    high: "#c2d601",
    lowContrast: "#ffb100",
    mediumContrast: "#003057",
    highContrast: "#003057",
    mailout: "#a38fd2",
    email: "#a24d1c",
    note: "#2b93f0",
    meeting: "#e2952c",
    crm: "#3d9186",
  },
  scheme: {

    /* scheme names */
    interactions: ["#a38fd2", "#a24d1c", "#2b93f0", "#e2952c", "#3d9186"],
    alert: ["#ffb100", "#eb1e23"],

    /* https://visme.co/blog/website-color-schemes/ */
    antique: ["#155765", "#57652A", "#AB9353", "#4D2C3D"],
    aqua: ["#05386B", "#379683", "#5CDB95", "#8EE4AF"],
    bold: ["#000000", "#062F4F", "#813773", "#B82601"],
    blood: ["#565656", "#76323F", "#C09F80"],
    blues: ["#003057", "#0070B4"],
    bright: ["#F78888", "#F3D250", "#90CCF4", "#5DA2D5"],
    calm: ["#5092c1", "#6BBAA7", "#FBA100", "#E17F93", "#6C648B"],  // <-- GOOD
    dark: ["#8096A6", "#58595B"],
    energy: ["#8860D0", "#5680E9", "#5AB9EA", "#688c6e"],
    fresh: ["#003057", "#79678c", "#77C9D4", "#57BC90", "#015249"],  // <-- GOOD
    fruity: ["#6B7A8F", "#F7882F", "#F7C331"],
    fun: ["#4ADBAC", "#F7B733", "#FC4A1A"],
    green: ["#003057", "#58595B", "#688c6e", "#7c94a5"],
    gold: ["#636B46", "#CDA34F", "#373F27"],
    grays: ["#A0A5Ad", "#58595B"],
    light: ["#A9B7C0", "#C7D8C6", "#EFD9C1", "#CCCBC6"],
    lime: ["#1F6521", "#53900F", "#A4A71E", "#D6CE15"],
    lively: ["#E7717D", "#C2B9B0", "#7E685A", "#AFD275"],
    maroons: ["#5A5560", "#46344E", "#9B686F", "#9d8D8F"],
    spectrum: ["#FF0000", "#FF7F00", "#FFFF00", "#00FF00", "#0000FF", "#4B0082", "#9400D3"],
    natural2: ["#844D36", "#8E8268", "#474853", "#86B3D1"],
    natural: ["#DAAD85", "#BD986A", "#8D8741", "#659DBD"],  // <-- GOOD
    orange: ["#003057", "#58595B", "#D4AF37", "#7c94a5"],
    pastel2: ["#EDB5BF", "#5F6366", "#4D6D9A", "#99CED3"],
    pastel: ["#C38D9E", "#E27D60", "#E8A87C", "#41B3A3", "#85DCB0"],
    primary: ["#EFE2BA", "#D79922", "#F13C20", "#4056A1", "#C5CBE3"],
    purple: ["#003057", "#58595B", "#79678c", "#7c94a5"],
    punchy: ["#E24E42", "#E9B000", "#EB6E80", "#008F95"],
    rasta: ["#272727", "#747474", "#FF652F", "#FFE400", "#14A76C"],
    rust: ["#233237", "#984B43", "#EAC67A"],
    tropic: ["#1B7B34", "#1FB58F", "#EAB126", "#D24C4E"],
    warm: ["#111111", "#33266E", "#806543", "#542F34", "#A6607C"]
  },
  scrolling: {
    showHowMany: 50,
    loadHowMany: 100
  },
  orderable: {
    always: [
      "age",
      "campusCode",
      "code",
      "collegeCode",
      "courseCode",
      "courseCampusCode",
      "credit",
      "enrolDate",
      "flagScore",
      "gender",
      "GPA",
      "lastLogin",
      "latestActivity",
      "map",
      "moodleId",
      "name",
      "noteScore",
      "passRate",
      "priorFails",
      "progress",
      "previousAttempts",
      "schoolCode",
      "totalActivity",
      "totalInteractions",
      "entryPathway",
      "trendAbs",
      "unitCode",
      "unitLoad",
      "unitScore",
      "unitsPassed",
      "unitsFailed",
      "weeklyActivity"
    ],
  },
  params: [
    "path",
    "filters",
    "bands",
    "trend",
    "flags",
    "maps",
    "notes",
    "order",
    "show",
    "campuses",
    "GPA",
    "colleges",
    "courses",
    "units",
    "entryPathway",
    "schools",
    "groups",
    "students",
    "lastLogin",
    "grade",
    "intakeMonth",
    "participation",
    "unitStatus",
    'passRates',
    'activityBands',
    'load',
    'engaged',
    'priorAttempts',
    'intakeYear',
    'extended',
  ],
  defaultParams: {
    path: [],
    filters: [],
    bands: [],
    trend: [],
    flags: [],
    notes: [],
    maps: [],
    order: ["name"],
    show: [],
    campuses: [],
    GPA: [],
    colleges: [],
    courses: [],
    units: [],
    schools: [],
    entryPathway: [],
    groups: [],
    students: [],
    lastLogin: [],
    grade: [],
    intakeMonth: [],
    participation: [],
    unitStatus: [],
    passRates: [],
    activityBands: [],
    load: [],
    engaged: [],
    gender: [],
    priorAttempts: [],
    intakeYear: [],
    extended: [],
  },
  unitDashboardParams: {
    HE: [
      "minAge",
      "maxAge",
      "bands",
      "campuses",
      "colleges",
      "courses",
      "gender",
      "groups",
      "lastLogin",
      "entryPathway",
      "maps",
      "residency",
      "studyMode",
      "priorAttempts",
    ],
    VET: [
      "minAge",
      "maxAge",
      //"bands",
      "campuses",
      "colleges",
      "courses",
      "gender",
      //"groups",
      "lastLogin",
      "entryPathway",
      //"maps",
      //"residency",
      "studyMode",
      "priorAttempts",
      "intakeMonth",
    ],
  },
  defaultUnitDashboardParams: {
    HE: {
      minAge: [],
      maxAge: [],
      bands: [],
      campuses: [],
      colleges: [],
      courses: [],
      gender: [],
      groups: [],
      lastLogin: [],
      entryPathway: [],
      maps: [],
      residency: [],
      studyMode: [],
      priorAttempts: [],
    },
    VET: {
      minAge: [],
      maxAge: [],
      //bands: [],
      campuses: [],
      colleges: [],
      courses: [],
      gender: [],
      //groups: [],
      lastLogin: [],
      entryPathway: [],
      //maps: [],
      //residency: [],
      studyMode: [],
      priorAttempts: [],
      intakeMonth: [],
    },
  },
  courseDashboardParams: {
    HE: [
      "minAge",
      "maxAge",
      "bands",
      "courseCampuses",
      "gender",
      "lastLogin",
      "maps",
      "residency",
      "entryPathway",
      "sesStatus",
      "sort",
      "studyMode",
      "units",
    ],
    VET: [
      "minAge",
      "maxAge",
      //"bands",
      "courseCampuses",
      "gender",
      "lastLogin",
      //"maps",
      //"residency",
      //"entryPathway",
      //"sesStatus",
      "sort",
      "studyMode",
      "units",
      "intakeMonth",
    ],
  },
  defaultCourseDashboardParams: {
    HE: {
      minAge: [],
      maxAge: [],
      bands: [],
      courseCampuses: [],
      gender: [],
      lastLogin: [],
      entryPathway: [],
      maps: [],
      residency: [],
      sesStatus: [],
      sort: ['unitCode'],
      studyMode: [],
      units: [],
    },
    VET: {
      minAge: [],
      maxAge: [],
      //bands: [],
      courseCampuses: [],
      gender: [],
      lastLogin: [],
      //entryPathway: [],
      //maps: [],
      //residency: [],
      //sesStatus: [],
      sort: ['unitCode'],
      studyMode: [],
      units: [],
      intakeMonth: [],
    },
  },
  defaultPrefs: {
    current: { // Current category.
      HE: { // HE subcategory.
        default: { // The default columns and filters for current.HE.
          columns: ['Name', 'ID', 'Age (Gender)', 'Course (Campus)', 'GPA', 'Pass Rate (Prior Fails)', 'Course Progress (Credit)', 'Unit (Prior Attempts)', 'Interactions', 'Unit Status (Days Enrolled)', 'Activity', 'Weekly Activity'],
          filters: ['Pass Rate', 'Unit Status', 'Load', 'Student Flag', 'GPA', 'Moodle Group', 'Moodle Logins', 'Activity This Week', 'Prior Attempts']
        },
        unavailable: { // The columns and filters which cannot be selected/enabled by the user for current.HE.
          columns: ['VET Weekly Activity YTD', 'VET Participation', 'Intake Month', 'Liability', 'Activity Intake', 'Activity Start Date', 'Activity End Date', 'Extended', 'Participation Date'],
          filters: ['VET Participation', 'Intake Month', 'Intake Year', 'VET Weekly Activity YTD', 'Extended']
        },
      },
      VET: {
        default: { // The default columns and filters for current.VET.
          columns: ['Name', 'ID', 'Age (Gender)', 'Course (Campus)', 'Pass Rate (Prior Fails)', 'Unit (Prior Attempts)', 'Interactions', 'Intake Month', 'Unit Status (Days Enrolled)', 'Activity', 'Weekly Activity'],
          filters: ['Grade', 'Unit Status', 'Unit Campus', 'Load', 'Student Flag', 'VET Participation', 'Moodle Logins', 'Intake Month', 'Intake Year']
        },
        unavailable: { // The columns and filters which cannot be selected/enabled by the user for current.VET.
          columns: ['MAP', 'GPA', 'Course Progress (Credit)'],
          filters: ['GPA', 'MAP', 'Activity This Term']
        },
      }
    },
    historic: { // Historic category.
      HE: { // HE subcategory.
        default: { // The default columns and filters for historic.HE.
          columns: ['Name', 'ID', 'Age (Gender)', 'Course (Campus)', 'GPA', 'Pass Rate (Prior Fails)', 'Course Progress (Credit)', 'Unit (Prior Attempts)', 'Interactions', 'Grade', 'Activity', 'Weekly Activity'],
          filters: ['Grade', 'Pass Rate', 'Unit Status', 'Student Flag', 'GPA', 'Moodle Group', 'Moodle Logins']
        },
        unavailable: { // The columns and filters which cannot be selected/enabled by the user for historic.HE.
          columns: ['VET Weekly Activity YTD', 'VET Participation', 'Intake Month', 'Liability', 'Activity Intake', 'Activity Start Date', 'Activity End Date', 'Extended', 'Participation Date'],
          filters: ['VET Participation', 'Intake Month', 'Intake Year', 'VET Weekly Activity YTD', 'Extended']
        },
      },
      VET: {
        default: { // The default columns and filters for historic.VET.
          columns: ['Name', 'ID', 'Age (Gender)', 'Course (Campus)', 'Pass Rate (Prior Fails)', 'Unit (Prior Attempts)', 'Interactions', 'Intake Month', 'Unit Status (Days Enrolled)', 'Activity', 'Weekly Activity', 'VET Weekly Activity YTD'],
          filters: ['Grade', 'Unit Status', 'Unit Campus', 'Student Flag', 'VET Participation', 'Moodle Logins', 'Intake Month']
        },
        unavailable: { // The columns and filters which cannot be selected/enabled by the user for historic.VET.
          columns: ['MAP', 'GPA', 'Course Progress (Credit)'],
          filters: ['GPA', 'MAP', 'Activity This Term', 'Intake Year']
        },
      }
    }
  },
  filterOptions: [
    'Pass Rate',
    'Unit Status',
    'Grade',
    'Unit Campus',
    'School',
    'College',
    'Load',
    'Course',
    'Unit',
    'Student Flag',
    'GPA',
    'Moodle Group',
    'VET Participation',
    'Entry Pathway',
    'Moodle Logins',
    'Activity This Week',
    'MAP',
    'Activity This Term',
    'Intake Month',
    'Intake Year',
    'Prior Attempts',
    'Extended',
  ],
  columnOptions: [
    'Name',
    'ID',
    'Age (Gender)',
    'Load',
    'MAP',
    'Student Flag',
    'College',
    'Course (Campus)',
    'GPA',
    'Pass Rate (Prior Fails)',
    'Course Progress (Credit)',
    'Entry Pathway',
    'Unit Campus (School)',
    'Unit (Prior Attempts)',
    'Interactions',
    'VET Participation',
    'Moodle Logins',
    'Intake Month',
    'Activity Intake',
    'Activity Start Date',
    'Activity End Date',
    'Extended',
    'Participation Date',
    'Moodle',
    'Unit Status (Days Enrolled)',
    'Grade',
    'Liability',
    'Activity',
    'Weekly Activity',
    'VET Weekly Activity YTD',
  ],
  defaultFilters: ["Campuses", "Courses"], /* REQUIRED */
  passRates: ["Low", "Medium", "High", "-"],
  activityBands: ["Low", "Medium", "High"],
  grades: ["NA", "AF/WF", "F", "P/PN", "C", "D", "HD"],
  maps: [
    "Good Standing",
    "Clear 1",
    "Clear 2",
    "MAP 1",
    "MAP 2",
    "MAP 3",
    "MAP X",
    "Successful Show Cause",
    "Successful Appeal",
    "Pending External Appeal",
    "Ignore for MAP Purposes",
  ],
  factorWeights: new Map([
    ["Fails", 5],
    ["NoLogin", 4],
    ["Logins", 3],
    ["Activity", 2],
    ["Attendance", 1]
  ]),
  flags: [
    'Indigenous',
    'International',
    'LowSES',
    'Disability',
    '1stInFamily',
    '1stTerm',
    //  '1stYear',
    'Language',
    'Remote',
    'Regional',
  ],
  vetFlags: [
    'Indigenous',
    'International',
    'LowSES',
    'Disability',
    '1stInFamily',
    '1stTerm',
    //  '1stYear',
    'Language',
    'Remote',
    'Regional',
    'HE Unit',
  ],
  flagWeights: new Map([
    ["Disability", 3],
    ["LowSES", 4],
    ["1stInFamily", 3],
    ["1stYear", 2],
    ["1stTerm", 4],
    ["Language", 3],
    ["Indigenous", 4],
    ["International", 1],
    ["Remote", 5],
    ["Regional", 2],
    ["HE", 2],
    ["VET", 2],
  ]),
  notes: ["Mailout", "Email", "Note", "Crm"],
  notesExceptCrm: ["Mailout", "Email", "Note"],
  noteShorthand: new Map([
    ["Meeting", 'mtg'],
    ["Call", 'call'],
    ["Email", 'email'],
    ["Note", 'note'],
    ["Mailout", 'mailout']
  ]),
  noteWeights: new Map([
    ["Meeting", 7],
    ["Call", 5],
    ["Email", 3],
    ["Note", 2],
    ["Mailout", 1]
  ]),
  campuses: {
    //  Probably no longer used; check in cleanup.
    FLEX: "Distance",
    ADL: "Adelaide Campus",
    BIL: "Biloela Study Centre",
    BNE: "Brisbane Campus",
    BDG: "Bundaberg Campus",
    CNS: "Cairns Campus",
    CSH: "Cairns Study Hub",
    EMD: "Emerald Campus",
    GLC: "Gladstone, City Campus",
    GLD: "Gladstone, Marina Campus",
    MKC: "Mackay, City Campus",
    MKY: "Mackay, Ooralea Campus",
    MEL: "Melbourne Campus",
    NSA: "Noosa Campus",
    PER: "Perth Campus",
    ROC: "Rockhampton, City Campus",
    ROK: "Rockhampton, North Campus",
    SYD: "Sydney Campus",
    TVL: "Townsville Campus",
    TNS: "Townsville Study Hub",
    YPN: "Yeppoon Study Centre"
  },
  campusWeights: new Map([
    ["FLEX", 7],
    ["ADL", 5],
    ["BIL", 3],
    ["BNE", 2],
    ["BDG", 1]
  ]),
  GPA: ['< 1', '< 2', '< 3', '< 4', '< 5', '< 6', '≤ 7'],
  GPAWeights: [
    ['F', 0],
    ['P', 1],
    ['CR', 2],
    ['D', 3],
    ['HD', 4],
    ['HD+', 5],
  ],
  errorCodes: {
    default: "Error",
    400: "Bad Request",
    403: "Permission Denied",
    404: "Not Found",
    500: "Error",
    503: "Service Unavailable",
    504: "Timeout",
  },
};

export { config };
