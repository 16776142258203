import { PASS_RATE_NULL } from "./constants";
import moment from 'moment-timezone';
moment.tz.setDefault('Australia/Brisbane');

const gpaBand = gpa => {
  const intGpa = Math.floor(gpa);
  if (intGpa < 0) return '-';
  if (intGpa < 1) return '< 1';
  if (intGpa < 2) return '< 2';
  if (intGpa < 3) return '< 3';
  if (intGpa < 4) return '< 4';
  if (intGpa < 5) return '< 5';
  if (intGpa < 6) return '< 6';
  return '≤ 7';
}

const priorAttemptBand = (attempts) => {
  const intAttempts = parseInt(attempts);

  if (intAttempts >= 4) return '4+';
  if (intAttempts >= 3) return '3';
  if (intAttempts >= 2) return '2';
  if (intAttempts >= 1) return '1';

  return '-';
}

const lastLoginBand = (lastLogin) => {
  if (!lastLogin) {
    return 'Never';
  }

  const lastLoginMoment = moment(lastLogin);
  const currentMoment = moment();

  if (lastLoginMoment.isSame(currentMoment, 'isoWeek')) {
    return 'This week';
  }

  return 'Not this week';
}

const activityBand = (activities, week, config) => {
  if ( week === undefined) return 'Low';
  const activity = activities.find(activity => activity.begin_date === week.start);
  const counter = activity?.activity ?? 0;
  const {low, high} = config.activity_bands;
  if (counter < low) return 'Low';
  if (counter > high) return 'High';
  return 'Medium';
}

const passRateBand = (passRate, config) => {
  const parsed = parseInt(passRate, 10);
  const {low, high} = config.pass_rate_limits;

  if (isNaN(parsed) || parsed === PASS_RATE_NULL) return '-';
  if (parsed < low ) return 'Low';
  if (parsed > high ) return 'High';

  return 'Medium';
}

/**
 * Determines whether an assessment is 'in term' or 'not due in term', based on its due date and the start and end dates of a term.
 *
 * @param {object} assessment - An object representing an assessment, with a `date_due` property.
 * @param {object} term - An object representing a term, with `start` and `end` properties.
 * @returns {string} - A string indicating whether the assessment is 'none', 'in term', or 'not due in term'.
 */
function assessmentDueDateBand(assessment, term) {
  // 1. None - Where the date due equal 1970-01-01 10:00:00
  if (moment(assessment.date_due).year() === 1970) {
      return 'None';
  }

  // 2. In Term - Where the date due falls within the start and end dates of the term.
  if (moment(assessment.date_due).isBetween(moment(term.start), moment(term.end), null, '[]')) {
      return 'In Term';
  }

  // 3. Not Due In Term - Where the date due falls outside the start and end dates of the term.
  return 'Not Due In Term';
}

const residency = flags => flags.includes("International") ? "International" : "Domestic";

const sesStatus = flags => flags.includes("SES") ? "Low" : "Non-SES";

const studyMode = campusCode => (campusCode === "DST" || campusCode === "MIX") ? "Online" : "F2F";

export {
    gpaBand,
    lastLoginBand,
    passRateBand,
    activityBand,
    residency,
    sesStatus,
    studyMode,
    priorAttemptBand,
    assessmentDueDateBand,
}