import React, { useContext } from 'react';
import { ActivityGraph, DaysAgoCell, GPACell, PassRateCell, InteractionCell, WeeklyCell } from './index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare, faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { config } from '../../../utils/config';
import SelectedEnrollmentsContext from '../../../context/SelectedEnrollmentsContext';
import { Link } from 'react-router-dom';
import { AimsStudentLink, StudentDashboardLink } from '../../../components';
import moment from 'moment-timezone';
import useRouteParams from '../../../hooks/useRouteParams';
moment.tz.setDefault('Australia/Brisbane');

const checkedStyle = { color: config.colors.cquBlue };

const EnrollmentListItem = ({ item, weeks, incognito, columns }) => {
    const { selectedEnrollments, setPrevSelectedEnrollment, addSelectedEnrollment, removeSelectedEnrollment } = useContext(SelectedEnrollmentsContext);
    const { raw } = useRouteParams();

    const handleClick = (event) => {

        // Shift + Click bubbled to handleShiftClick on tbody.
        if (event.shiftKey) {
            return;
        }

        setPrevSelectedEnrollment(event.target.parentNode.parentNode);
        if (selectedEnrollments.has(item.id)) {
            removeSelectedEnrollment(item.id);
        } else {
            addSelectedEnrollment(item);
        }
    }

    const graphData = weeks.map(element => {
        const activity = item.activities.find(activity => activity.begin_date === element.start);
        return activity ? activity.activity : 0;
    });
    const checked = selectedEnrollments?.has(item.id);
    return (
        <tr id={item.id} key={item.id} className={checked ? "predict-row-selected" : "predict-row-unselected"}>
            <td className="columnSelection columnCentered" style={{ fontSize: '1.5rem' }}>
                <FontAwesomeIcon
                    icon={checked ? faCheckCircle : faSquare}
                    style={checked ? checkedStyle : undefined}
                    onClick={handleClick}
                    className="enrolSelect" />
            </td>
            {columns.includes('Name') && <td><StudentDashboardLink studentId={item.student_id} unitCode={item.unit.code} term={{year: raw.year, term: raw.term}}>{incognito ? "XXXXX, XXXXX" : `${item.student.last_name}, ${item.student.first_name}`}</StudentDashboardLink></td> }
            {columns.includes('ID') && <td><AimsStudentLink studentCode={item.student.code}>{incognito ? "XXXXXXXX" : item.student.id}</AimsStudentLink></td> }
            {columns.includes('Age (Gender)') && <td align='right'>{incognito ? "XX" : moment().diff(item.student.date_birth, 'years', false)}</td> }
            {columns.includes('Age (Gender)') && <td align='left'><small>{incognito ? 'X' : item.student.gender}</small></td> }
            {columns.includes('Load') && <td align='right'>{item.load}</td>}
            {columns.includes('MAP') && <td>{item.student_course.map}</td> }
            {columns.includes('Student Flag') && <td>{item.flags.join(', ')}</td> }
            {columns.includes('College') && <td>{item.college.code}</td> }
            {columns.includes('Course (Campus)') && <td align='right'>{item.course.code}</td> }
            {columns.includes('Course (Campus)') && <td align='left'><small>{item.student_course.campus_code}</small></td> }
            {columns.includes('GPA') && <GPACell value={item.student_course.gpa} /> }
            {columns.includes('Pass Rate (Prior Fails)') && <PassRateCell value={item.passRate} /> }
            {columns.includes('Pass Rate (Prior Fails)') && <td align='left'>
                <small>{item.student_course.units_passed}P, {item.student_course.units_failed}F</small>
            </td>}
            {columns.includes('Course Progress (Credit)') && <td className="rightAlign">
                <span title={`${Number(item.student_course.credit_points_passed / item.student_course.credit_points_required * 100).toFixed()}%`}>
                    {item.student_course.credit_points_passed} / {item.student_course.credit_points_required}
                </span>
            </td>}
            {columns.includes('Course Progress (Credit)') && <td className="leftAlign">
                <small>{item.student_course.credit_points_credited} CP</small>
            </td>}
            {columns.includes('Entry Pathway') && <td>{item.student_course.basis_of_admission}</td> }
            {columns.includes('Unit Campus (School)') && <td align='right'>{item.campus_code}</td> }
            {columns.includes('Unit Campus (School)') && <td align='left'><small>{item.school_code}</small></td> }
            {columns.includes('Unit (Prior Attempts)') && <td align='right'><Link to={`/unit/${raw.year}/${raw.term}/${item.unit.code}`}>{item.unit.code}</Link></td> }
            {columns.includes('Unit (Prior Attempts)') && <td align='left'><small>{item.previous_attempts > 0 && item.previous_attempts}</small></td> }
            {columns.includes('Interactions') && <InteractionCell studentId={item.student.id} unitCode={item.unit.code} interactionsSent={item.interactionsSent} interactionCases={item.interactionCases} myExperiences={item.myExperiences} /> }
            {columns.includes('VET Participation') && <td align='center'>{item.participation}</td> }
            {columns.includes('Moodle Logins') && <DaysAgoCell value={item.last_login} /> }
            {columns.includes('Intake Month') && <td align='right'>{item.study_period_code}</td> }
            {columns.includes('Intake Month') && <td align='left'><small>{item.term.year}</small></td> }
            {columns.includes('Activity Intake') && <td align='right'>{item.activity_study_period_code}</td> }
            {columns.includes('Activity Intake') && <td align='left'><small>{item.activity_year}</small></td> }
            {columns.includes('Activity Start Date') && <td align='left'>{item.activity_intake_start_date && moment(item.activity_intake_start_date).format('YYYY-MM-DD')}</td>}
            {columns.includes('Activity End Date') && <td align='left'>{item.activity_intake_end_date && moment(item.activity_intake_end_date).format('YYYY-MM-DD')}</td>}
            {columns.includes('Extended') && <td>{item.isExtended}</td>}
            {columns.includes('Participation Date') && <td>{item.participation_date}</td>}
            {columns.includes('Moodle') && <td>{item.visibleToStudent}</td>}
            {columns.includes('Unit Status (Days Enrolled)') && <td align='right'>{item.status_code}</td> }
            {columns.includes('Unit Status (Days Enrolled)') && <DaysAgoCell value={item.enrol_date} small /> }
            {columns.includes('Grade') && <td align='center'>{item.grade_code}</td> }
            {columns.includes('Liability') && <td align='center'>{item.liability}</td> }
            {columns.includes('Activity') && <td align='center'>{item.totalActivity}</td> }
            {columns.includes('Activity') && <td><ActivityGraph data={graphData} /></td> }
            {columns.includes('Weekly Activity') && weeks.map((week, index) => <WeeklyCell key={index} week={week} enrolment={item} />)}
        </tr>
    );

}

export default EnrollmentListItem;