import { sortBy } from 'lodash';
import React, { useContext } from 'react';
import Select from 'react-select';
import SelectedEnrollmentsContext from '../../../../context/SelectedEnrollmentsContext';

/**
 * Allows the user to select enrollments.
 *
 * @param {Object} props - The component props.
 * @param {Array} props.enrollments - An array of enrollment objects to display.
 * @param {Object} props.defaultEnrollment - The default enrollment object.
 * @param {Array<number>} [props.value] - The IDs of the selected enrollments.
 * @param {Function} props.onChange - The function to call with the updated selection.
 * @returns {JSX.Element} - The rendered component.
 */
const VetEnrollmentSelection = ({ enrollments, selectedClassName }) => {
    const {selectedEnrollments, addMultipleSelectedEnrollments, clearSelectedEnrollments} = useContext(SelectedEnrollmentsContext);

    // Map the enrollments to options for the Select component
    const options = sortBy(enrollments.map((enrollment) => ({
        label: enrollment.unit.code,
        value: enrollment.id,
        enrollment: enrollment
    })), 'label');

    const numSelected = selectedEnrollments.size;
    const numOptions = options.length - 1; // exclude "Select All" option

    // Add a "Select All" option if not all options are selected.
    if (numSelected < numOptions) {
        options.unshift({
            label: "Select All",
            value: "all"
        });
    }

    const handleOnChange = (selected) => {
        if (!selected) {
            clearSelectedEnrollments();
            return;
        }

        // Check if "Select All" option is selected
        if (selected.some((option) => option.value === "all")) {
            addMultipleSelectedEnrollments(options.filter((option) => option.value !== 'all').map((option) => option.enrollment));
        } else {
            const selectedIds = selected.map((option) => option.enrollment);
            addMultipleSelectedEnrollments(selectedIds);
        }
    }

    // Find the selected enrollment options
    const selectedOptions = options.filter((option) => Array.from(selectedEnrollments.keys()).includes(option.value));

    return (
        <Select
            isMulti
            options={options}
            value={selectedOptions}
            onChange={handleOnChange}
            overrideStrings={{ selectSomeItems: "Select Units", allItemsAreSelected: "All units" }}
            className={selectedClassName}
        />
    );
};

export default VetEnrollmentSelection;
