import React from "react";
import {
    Hint,
    HorizontalGridLines,
    VerticalBarSeries,
    XYPlot,
    XAxis,
    YAxis,
} from "react-vis";
import { color_scheme } from "../..//utils/utils";
import { config } from "../../utils/config";
import { filterIntegers, renderXLabel, renderYLabel, withWidget } from "../../utils/graph";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCircle,
    faCube,
    faEnvelope,
    faMailBulk,
    faStickyNote,
} from "@fortawesome/free-solid-svg-icons";

const UnitsInteractionsGraph = (props) => {
    const {
        //data,
        hover,
        handleClick,
        handleMouseOver,
        handleMouseOut,
        isClickable,
    } = props;
    const title = props.title ? props.title : "Interactions";

    const getTypeIcon = (type) => {
        if (type === 'Mailout') return faMailBulk;
        if (type === 'Email') return faEnvelope;
        if (type === 'Note') return faStickyNote;
        if (type === 'Crm' || type === 'CRM') return faCube;
        return faCircle;
    }

    const interactionsLegend = color_scheme(config.notesExceptCrm, config.scheme.interactions);
    // override Email colour
    interactionsLegend.Email = interactionsLegend.Mailout;
    // filter and sort by interactionsTotal, descending
    const data = props.data.filter((a) => {
        return (a.interactionsTotal > 0);
    })
        .sort((a, b) => {
            const total = 'interactionsTotal';
            if (a[total] < b[total]) return 1;
            if (a[total] > b[total]) return -1;
            return 0;
        }).slice(0, 5);
    const graphEmails = Object.entries(data).map((parts) => {
        const [, item] = parts;
        const key = item.code;
        const subCode = 'Emails';
        const value = item.emailsTotal;
        const color = (hover && hover.code === key && hover.subCode === subCode) ? config.colors.cquBlue50 : interactionsLegend.Email;
        return {
            x: key,
            y: value,
            color,
            code: key,
            subCode,
            description: key,
        };
    });
    const graphNotes = Object.entries(data).map((parts) => {
        const [, item] = parts;
        const key = item.code;
        const subCode = 'Notes';
        const value = item.notesTotal;
        const color = (hover && hover.code === key && hover.subCode === subCode) ? config.colors.cquBlue50 : interactionsLegend.Note;
        return {
            x: key,
            y: value,
            color,
            code: key,
            subCode,
            description: key,
        };
    });
    const hasData = data.length > 0;

    if (graphEmails.length > 0) {
        // pad columns with empty values
        while (graphEmails.length < 5) {
            graphEmails.push({ x: -graphEmails.length, y: 0 });
        }
    }

    const plot = {
        width: 300,
        height: 240,
        left: 60,
        bottom: 100,
    };

    return (
        <div className="UnitWidget">
            <div style={{ float: 'right' }}>Top 5 Units</div>
            <h4>{title}</h4>
            <div className="text-right">
                <p>
                    {["Email", "Note"].map((label, index) => {
                        const hexade = interactionsLegend[label];
                        const icon = getTypeIcon(label);
                        return (
                            <span key={index} className="horizontal-padding">
                                <FontAwesomeIcon
                                    icon={icon}
                                    style={{ color: hexade }}
                                />&nbsp;{label}
                            </span>
                        )
                    })}
                </p>

            </div>
            <XYPlot
                height={plot.height}
                width={plot.width}
                xType="ordinal"
                stackBy='y'
                className={hasData ? "" : "empty"}
                margin={{
                    left: plot.left,
                    bottom: plot.bottom,
                }}
            >
                {hover && (
                    <Hint
                        value={hover}
                        className='plotTooltip'
                    >
                        <div>
                            <h3>{hover.description}</h3>
                            <p>{hover.y - (hover.y0 || 0)} {hover.subCode}</p>
                        </div>
                    </Hint>
                )}
                <XAxis
                    height={plot.bottom}
                    tickLabelAngle={-45}
                    tickFormat={val => (val < 0 ? '' : val)}
                />
                {hasData && (
                    <YAxis
                        width={plot.left}
                        tickFormat={filterIntegers}
                    />
                )}
                {renderXLabel("Unit Code", plot)}
                {renderYLabel("Count of interactions", plot)}
                <HorizontalGridLines />
                <VerticalBarSeries
                    colorType={"literal"}
                    //color={config.colors.cquGreen}
                    className={isClickable ? "clickable" : ""}
                    data={graphEmails}
                    onValueClick={handleClick}
                    onValueMouseOver={handleMouseOver}
                    onValueMouseOut={handleMouseOut}
                />
                <VerticalBarSeries
                    colorType={"literal"}
                    //color={config.colors.cquGreen}
                    className={isClickable ? "clickable" : ""}
                    data={graphNotes}
                    onValueClick={handleClick}
                    onValueMouseOver={handleMouseOver}
                    onValueMouseOut={handleMouseOut}
                />
            </XYPlot>
            {!hasData && (
                <div style={{
                    position: 'relative',
                    bottom: '160px',
                }}>
                    No data
                </div>
            )}
        </div>
    );
}

export default withWidget(UnitsInteractionsGraph);