import React, { useContext } from 'react';
import { renderLegendBar, withWidget } from '../../../../utils/graph';
import { config } from '../../../../utils/config';
import { color_scheme, sendLog } from '../../../../utils/utils';
import { RadialChart } from 'react-vis';
import { ModalContext } from '../../../../context/ModalContext';
import { LOG } from '../../../../utils/constants';
import useRouteParams from '../../../../hooks/useRouteParams';
import UnitWidgetModal from '../UnitWidgetModal/UnitWidgetModal';
import SelectedEnrollmentsContext from '../../../../context/SelectedEnrollmentsContext';

// There can only ever be 4 groups.
const COLORS = color_scheme(['1', '2', '3', '4+'], config.scheme.blues);

const PriorAttemptsGraph = ({ enrollments, hover, handleMouseOver, handleMouseOut }) => {
    const { handleModal } = useContext(ModalContext);
    const { year, term, unit } = useRouteParams();
    const { clearAndAddMultipleSelectedEnrollments } = useContext(SelectedEnrollmentsContext);

    // Group the enrollments by previous_attempts and calculate the total for each group
    const groupedData = enrollments.reduce((acc, curr) => {
        const { previous_attempts } = curr;

        // Skip previous_attempts == 0
        if (previous_attempts === 0) {
            return acc;
        }

        // Create a key for the group based on previous_attempts
        const key = previous_attempts >= 4 ? "4+" : previous_attempts.toString();

        // If the group doesn't exist create it.
        if (!acc[key]) {
            acc[key] = { previous_attempts: key, total: 0 };
        }

        acc[key].total++;
        return acc;
    }, {});

    // Convert the groupedData object to an array of objects that can be used as data for a RadialChart
    const radialChartData = Object.values(groupedData).map(({ previous_attempts, total }) => ({
        angle: total,
        label: total.toString(),
        code: previous_attempts, // Set the code to the previous_attempts value (e.g. "1", "2", "3", "4+")
        color: hover.code === previous_attempts ? config.colors.cquBlue50 : COLORS[previous_attempts],
    }));

    // Create a legend based on the radialChartData
    const legend = radialChartData.map((val) => ([val.code, val.color, val]));

    if (radialChartData.length === 0) {
        return (
            <div className="UnitWidget">
                <h4>Prior Attempts</h4>
                <div style={{marginTop: '40%',textAlign: 'center'}}>
                    No data
                </div>
            </div>
        );
    }

    /**
     * Open the Modal displaying the cohort of the clicked course.
     */
    const handleClick = (data) => {
        const aggregated = enrollments.filter(({ previous_attempts }) => {
            if (data.code === "4+") {
                return previous_attempts >= 4;
            }
            return parseInt(data.code) === previous_attempts;
        });

        clearAndAddMultipleSelectedEnrollments(aggregated);
        handleModal(<UnitWidgetModal title={`Prior Attempts -${data.code}`} handleModal={handleModal} cohortFilter={`priorAttempts=${data.code}`} />);
        sendLog("App\\Events\\Widget\\Aggregated", 'r', LOG.ACTION.AGGREGATED, LOG.TARGET.ENROLLMENTS, LOG.DASHBOARD.UNIT, {
            term: {year,term},
            code: unit,
            aggregation: 'priorAttempts',
            value: data.code,
            aggregated: aggregated.length,
        });
    };


    return (
        <div className="UnitWidget">
            <h4>Prior Attempts</h4>
            <div style={{ padding: '1rem 0', width: '200px', margin: '0.5rem auto' }}>
                <RadialChart
                    radius={100}
                    innerRadius={60}
                    data={radialChartData}
                    colorType={"literal"}
                    width={200}
                    height={200}
                    showLabels={true}
                    className={"clickable"}
                    labelsStyle={{ fontWeight: 'bold', fill: '#fff' }}
                    labelsRadiusMultiplier={0.90}
                    onValueClick={handleClick}
                    onValueMouseOver={handleMouseOver}
                    onMouseLeave={handleMouseOut}
                />
            </div>
            {renderLegendBar(legend, { onClick: handleClick, onMouseOver: handleMouseOver, onMouseOut: handleMouseOut })}
        </div>
    );
}

export default withWidget(PriorAttemptsGraph);
