import React from "react";
import { Link } from "react-router-dom";
import { config } from "../../utils/config";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle, faHome, } from "@fortawesome/free-solid-svg-icons";

library.add(faExclamationCircle, faHome,);

/**
 * Error function component.
 *
 * @param {*} props
 * @returns
 */
const Error = (props) => {

    /**
     * Returns true if the environment is not production.
     *
     * @returns {boolean}
     */
    function inDevEnv() {
        return (
            config.system.appEnv &&
            config.system.appEnv !== 'production'
        ) || (
                props.config &&
                props.config.environment &&
                props.config.environment !== 'production'
            );
    }

    const ConditionalWrapper = ({ condition, wrapper, children }) =>
        condition ? wrapper(children) : children;

    const error = props.error;
    const modal = props.modal || false;

    const code = error.response?.status ? error.response.status : error.code;
    const title = error.title ? error.title : "";
    const isDevEnv = inDevEnv();
    const message = (code >= 500 && code <= 599 && !isDevEnv) ? "" : error.message;
    const debugging = error.debugging ? error.debugging : "";
    const heading = title !== "" && code !== 503
        ? title
        : config.errorCodes.hasOwnProperty(code)
            ? config.errorCodes[code]
            : config.errorCodes['default'];

    // Determine whether to display an offline message
    const offlineMessage = code === 503 ? (
        error.response.data.message ?
            error.response.data.message
            : (error.message ? error.message : '')
    ) : "";

    return (
        <ConditionalWrapper
            condition={!modal}
            wrapper={children =>
                <main className="errorMessage">
                    <div className="errorMessageBlock">
                        <div className="errorMessageDetails">
                            {children}
                        </div>
                        <p>
                            <Link to="/">
                                <button className="primaryButton">
                                    <FontAwesomeIcon icon={faHome} /> {config.system.name}
                                </button>
                            </Link>
                        </p>
                    </div>
                </main>
            }
        >
            <>
                <h2>{heading}</h2>
                <p>{message}</p>
                {offlineMessage !== "" && (
                    <p dangerouslySetInnerHTML={{ __html: offlineMessage }} />
                )}
                {isDevEnv && debugging !== "" && (
                    <pre>{debugging}</pre>
                )}
            </>
        </ConditionalWrapper>
    );
}

export default Error;
