import React, { Component } from "react";
import moment from "moment-timezone";
import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faCheckCircle,
  faExclamationCircle,
  faQuestionCircle
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faCheckCircle,
  faExclamationCircle,
  faQuestionCircle
);
moment.tz.setDefault('Australia/Brisbane');

class IncrementalLogs extends Component {

  /**
   * Imports are of the form:
   * {
   *   '2020-09-08': {
   *    'students': {
   *        'records': 16,  // <-- sum
   *        'seconds': 2,   // <-- average
   *        'rows':    16,  // <-- count
   *      }
   *      // ... + endpoints
   *   }
   * }
   */
  constructor(props) {
    super(props);
    this.state = {
      imports: props.import.imports,
      steps: props.import.steps,
      lastUpdates: props.import.lastUpdates,
    };
  }

  render() {

    const columns = this.state.steps;
    const imports = this.state.imports;
    const updates = this.state.lastUpdates;

    return (
      <table className="oddEvenRows">
        <thead>
          <tr>
            <td></td>
            { columns.map((col, index) => (
            <td key={index} colSpan={3}>{col}</td>
            ))}
          </tr>
          <tr>
            <td>{moment(updates['now']).format('YYYY-MM-DD hh:mm:ss')}</td>
            { columns.map((col, index) => (
              <td key={index} colSpan={3}>
              { updates[col] !== undefined && (
                <span title={updates[col]}>{moment(updates[col]).from(updates['now'])}</span>
              )}
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          { Object.keys(imports).map((day, index1) => (
            <tr key={index1}>
              <td>{day}</td>
              { columns.map((col, index2) => (
                <React.Fragment>
                  <td key={index2}>
                  { imports[day][col] === undefined ? '' : imports[day][col].records }
                  </td>
                  <td key={index2}>
                    { imports[day][col] === undefined ? '' : ('/ ' + imports[day][col].runs) }
                  </td>
                  <td key={index2}>
                  { imports[day][col] === undefined ? '' : (imports[day][col].seconds + 's') }
                  </td>
                </React.Fragment>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  renderObj(obj) {
    return Object.keys(obj).map((key, i) => {
      return (
        <div>
          {obj[key]}
        </div>
      )
    })
  }
}

export { IncrementalLogs }
