import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { config } from "./config"
import { StudentDashboardLink } from "../components";
import "../../node_modules/react-vis/dist/style.css";
import {
  alphabetical,
  capitalize,
  deep_equal,
  getFiltersFromParams,
  haveSameFilters,
  hide_string,
  is_function,
  make_object,
  sendLog,
} from "./utils";
import {
  getWeekFromDate,
} from "./calendar";

import {
  readDashboardQueryString,
  setParam,
  uriLink,
} from "./uriParams";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from 'moment-timezone'
import {
  faPollH,
  faTimesCircle as faClose,
  faUserClock as faStudentActivity,
  faSort,
  faSortUp,
  faSortDown
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faClose,
  faPollH,
  faStudentActivity,
);
moment.tz.setDefault('Australia/Brisbane');

const aggregateByProp = (filtered, propName) => {
  return filtered.reduce((acc, _) => {
    const code = _[propName];
    if (code === null || code === undefined) { return acc; }
    if (!(code in acc)) {
      acc[code] = [];
    }
    acc[code].push(_);
    return acc;
  }, {});
};

const aggregateByActivity = (students) => {
  const activity = students.reduce((acc, _) => {
    const code = (_.activity > 0) ? 'Active' : 'Inactive';
    acc[code].push(_);
    return acc;
  }, {'Active': [], 'Inactive': []});
  return activity;
};

const aggregateByActivityThisWeek = (students) => {
  const activityThisWeek = students.reduce((acc, _) => {
    const code = (_.activityThisWeek > 0) ? 'Active' : 'Inactive';
    acc[code].push(_);
    return acc;
  }, {'Active': [], 'Inactive': []});
  return activityThisWeek;
};

const withDashboard = (WrappedComponent, {
  // required functions
  apiUrl,
  loadStudents,
  filterStudents,
  aggregateStudents,
  // required variables
  dashboardParams,
  defaultDashboardParams,
}) => {
  return class extends React.Component {
    constructor(props) {
      super(props);
      // 'params' and 'showFilter' processing postponed
      // until api is loaded, to determine if HE or VET
      // const params = readDashboardQueryString(dashboardParams,window.location.search, defaultDashboardParams);
      const params = null;
      // const showFilter = !deep_equal(params, defaultDashboardParams);
      const showFilter = null;
      this.state = {
        url: apiUrl(props),
        // functions from WrappedComponent
        loadStudents,
        filterStudents,
        aggregateStudents,
        // variables from WrappedComponent
        configDashboardParams: dashboardParams,
        configDefaultDashboardParams: defaultDashboardParams,
        // state variables
        params,
        showSidebar: showFilter ? 'filter' : null,
        myExperience: {
          loaded: false,
          show: false,
          responses: [],
          selected: {},
          currentSortOrder: 'default',
          currentSortElement: 'date',
          currentSortFunction: (a,b) => new Date(b.recorded_date) - new Date(a.recorded_date),
          respSortIcon: faSort,
          dateSortIcon: faSort,
          nameSortIcon: faSort,
        },
        // base variables
        error: null,
        config: null,
        calendar: null,
        imports: null,
        isLoading: null,
        cached: null,
        loaded: [],
        filtered: [],
        aggregated: {},
        allAggregated: {}, // unfiltered
        user: {},
        incognito: props.incognito,
        showStudents: null,
        moodleCode: 'HE',
      };

      this.handleChange = this.handleChange.bind(this);
      this.handleUpdateIncognito = this.handleUpdateIncognito.bind(this);
      this.handleShowFilter = this.handleShowFilter.bind(this);
      this.handleShowInfo = this.handleShowInfo.bind(this);
      this.handleShowMyExperience = this.handleShowMyExperience.bind(this);
      this.handleShowStudents = this.handleShowStudents.bind(this);
      this.loadStudents = this.loadStudents.bind(this);
      this.aggregateStudents = this.aggregateStudents.bind(this);
      this.filterStudents = this.filterStudents.bind(this);
      this.renderCohortLink = this.renderCohortLink.bind(this);
      this.renderStudentList = this.renderStudentList.bind(this);
    }

    static getDerivedStateFromProps(newProps, oldState) {
      // cannot process the params until we've loaded api to know if the dashboard is HE or VET
      if (!oldState || !oldState.dashboardParams) {
        return {};
      }
      const newParams = readDashboardQueryString(oldState.dashboardParams, window.location.search, oldState.defaultDashboardParams);
      return {
        params: newParams,
      };
    }

    componentDidMount() {
      this.handleChange();
    }

    // load api data
    handleChange() {
      const url = this.state.url;
      this.setState({
        isLoading: true,
      });
      axios
        .get(url)
        .then(res => {
          // If there are any errors given from in the request. Throw the error so it gets catched before doing anything else.
          if( res.data.error !== null ) throw(res.data.error);

          // at this point we know if dashboard is HE or VET
          const moodle_db = res.data.result.moodle_db;
          const dashboardParams = this.state.configDashboardParams[moodle_db];
          const defaultDashboardParams = this.state.configDefaultDashboardParams[moodle_db];
          const params = readDashboardQueryString(dashboardParams,window.location.search, defaultDashboardParams);
          const showFilter = !deep_equal(params, defaultDashboardParams);
          const data = this.loadStudents(res, params);
          // console.log("Dashboard loaded", data);
          this.setState({
            moodleCode: moodle_db,
            dashboardParams,
            defaultDashboardParams,
            params,
            showSidebar: showFilter ? 'filter' : null,
            ...data
          });
        })
        .catch(error => {
          this.setState({error});
        })
    }

    componentDidUpdate(prevProps, prevState) {
      if (!deep_equal(this.props.match.params, prevProps.match.params)) {
        // reload if link followed
        this.handleChange();
        return;
      }
      const oldParams = prevState.params;
      const newParams = this.state.params;
      if (!deep_equal(oldParams, newParams)) {
        const dashboard = window.location.pathname.split('/')[1];
        if (!haveSameFilters(oldParams, newParams)) {
          sendLog(`App\\Events\\${capitalize(dashboard)}Dashboard\\Filtered`, 'r', 'filtered', 'enrolments', dashboard,
            {filters: getFiltersFromParams(newParams), ...prevProps.match.params});
        }
        if (oldParams !== null && JSON.stringify(oldParams.sort) !== JSON.stringify(newParams.sort)) {
          sendLog(`App\\Events\\${capitalize(dashboard)}Dashboard\\Sorted`, 'r', 'sorted', 'enrolments', dashboard,
            {order: newParams.sort, ...prevProps.match.params});
        }
        const loaded = this.state.loaded;
        const filtered = this.filterStudents(loaded, newParams);
        //console.log(`before ${loaded.length} - after ${filtered.length}`);
        const aggregated = this.aggregateStudents({students: filtered, calendar: this.state.calendar});
        this.setState({
          params: newParams,
          filtered: filtered,
          aggregated: aggregated,
        })
      }
    }

    // function provided by WrappedComponent
    loadStudents(data, params) {
      return this.state.loadStudents(data, params);
    }

    // function provided by WrappedComponent
    filterStudents(loaded, params) {
      return this.state.filterStudents(loaded, params);
    }

    // function provided by WrappedComponent
    aggregateStudents(props) {
      return this.state.aggregateStudents(props);
    }

    // recent logins using loginBands
    // return array of values: [loginCount, nonLoginCount]
    getStudentLogins(aggregated) {
      const studentLogin = aggregated['This week'] ? aggregated['This week'].length : 0;
      const studentNotLogin = Object.entries(aggregated).reduce((acc, parts) => {
        const [band, items] = parts;
        if (band !== 'This week') {
          acc += items.length;
        }
        return acc;
      }, 0);
      return [studentLogin, studentNotLogin];
    }

    getActivityByWeek(students) {
      const activityTotals = students.reduce((acc, _) => {
        for (const week of _.weekly) {
          const total = acc[week.begin_date] !== undefined ? acc[week.begin_date] : 0;
          acc[week.begin_date] = total + week.activity;
        }
        return acc;
      }, {});
      const activityByWeek = Object.entries(activityTotals).map((parts) =>
      {
        const [key, value] = parts;
        return {
          begin_date: key,
          total: value,
        }
      });
      return activityByWeek;
    }

    getLastActivity(students) {
      const lastActivity = students.reduce((acc, _) => {
        if (acc === null) return _.lastLogin;
        if (acc < _.lastLogin) return _.lastLogin;
        return acc;
      }, null);
      return lastActivity;
    }

    getGpaBandTotals(aggregated) {
      const gpaBands = Object.entries(aggregated).reduce((acc, parts) => {
        const [key, value] = parts;
        acc[key] = value.length;
        return acc;
      }, make_object(config.GPA, 0));
      return gpaBands;
    }

    handleUpdateIncognito(incognito) {
      this.setState({
        incognito: incognito,
      });
    }

    // toggle filters sidebar
    handleShowFilter() {
      const showFilter = !(this.state.showSidebar === 'filter'); // toggle
      this.setState({
        showSidebar: showFilter ? 'filter' : null,
      })
    }

    // toggle info sidebar
    handleShowInfo() {
      const showInfo = !(this.state.showSidebar === 'info'); // toggle
      this.setState({
        showSidebar: showInfo ? 'info' : null,
      })
    }

    // handle click in Widget to select aggregated student data
    // as defined by the clicked value
    handleShowStudents(props) {
      if (!('propName' in props)) {
        this.setState({
          showStudents: null,
        });
        return;
      }
      const aggregated = this.state.aggregated;
      const {
        propName, // for aggregated data eg. 'grade'
        propValue, // eg. 'F' or ['F', 'AF']
        //paramName, // passed through to state for url link
      } = props;

      if(propName === 'assessmentCalculation') {
        this.setState({
          showStudentsProps: props,
          showStudents: props.students,
        });
        sendLog('App\\Events\\Widget\\Aggregated', 'r', 'aggregated', 'enrolments',
          window.location.pathname.split('/')[1],
          {aggregation: props.propName, value: props.propDescription, aggregated: props.students.length, assessment_id: props.id});
        return;
      }


      let ret = [];
      if (Array.isArray(propValue)) {
        // multiple values selected, so concat the result:
        propValue.forEach((_) => {
          if (aggregated[propName][_]) {
            ret = ret.concat(aggregated[propName][_]);
          }
        });
      } else if (is_function(propValue)) {
        // used for LOS Trends with stacked bar-charts
        ret = propValue(aggregated[propName]);
      } else {
        ret = aggregated[propName][propValue];
      }
      this.setState({
        showStudentsProps: props,
        showStudents: ret,
      });
      sendLog('App\\Events\\Widget\\Aggregated', 'r', 'aggregated', 'enrolments',
        window.location.pathname.split('/')[1],
        {aggregation: props.propName, value: props.propDescription, aggregated: ret.length});
    }

    /**
     * When a user clicks a Bar in the My Experience widget this function handles getting the data behind it.
     *
     * @param {*} props
     * @returns
     */
    handleShowMyExperience(props) {
      const data = props?.hover;
      // When props doesn't have hover we will handle as close model event.
      if (data === undefined) {
        this.setState({myExperience: {
          ...this.state.myExperience,
          show: false
        }});

        return; // Function has finished return void to stop.
      }

      // Load all the responses and store them in the state.
      if (!this.state.myExperience.loaded) {
        axios.get(this.state.url + "/myexperience")
          .then(res =>
              this.setState({myExperience: {
                ...this.state.myExperience,
                responses: res.data,
                loaded: true
              }})
            );
      }

      sendLog('App\\Events\\Widget\\Aggregated', 'r', 'aggregated', 'enrolments',
        window.location.pathname.split('/')[1],
        {aggregation: 'myExperience', value: props.propDescription, aggregated: data.students});
      this.setState({myExperience: {
        ...this.state.myExperience,
        selected: data,
        show: true
      }});
    }

    renderIncognito(str, len = null)
    {
      return this.state.incognito ? hide_string(str, len) : str;
    }

    // return link to cohort dash with applied params and filters
    // as populated by handleShowStudents
    renderCohortLink()
    {
      const { propValue, paramName } = this.state.showStudentsProps;
      const path = this.state.cohortPath;

      let params = {...this.state.params};
      // map filter params to cohort hashCriteria
      const filters = [];
      const cohortFilters = [];
      if ('courseCampuses' in params && params.courseCampuses.length > 0) {
        filters.push('course_campus:' + params.courseCampuses.join(','));
      }
      if ('gender' in params && params.gender.length > 0) {
        filters.push('gender:' + params.gender.join(','));
      }
      if ('minAge' in params && params.minAge.length > 0) {
        filters.push('age:gte:' + params.minAge[0]);
      }
      if ('maxAge' in params && params.maxAge.length > 0) {
        filters.push('age:lte:' + params.maxAge[0]);
      }
      if ('residency' in params && params.residency.length > 0) {
        filters.push('residency:' + params.residency.join(','));
      }
      if ('sesStatus' in params && params.sesStatus.length > 0) {
        filters.push('ses_status:' + params.sesStatus.join(','));
      }
      if ('studyMode' in params && params.studyMode.length > 0) {
        filters.push('study_mode:' + params.studyMode.join(','));
      }
      // map graph params (click on legend) to cohort hashCriteria
      switch (paramName) {
        case 'enrolWeek': {
          const enrolWeek = getWeekFromDate(propValue, this.state.calendar);
          if (enrolWeek) {
            filters.push('enrol_week:' + enrolWeek.calendar_week_number);
          }
          break;
        }
        case 'activity': {
          switch (propValue) {
            case 'Active': {
              filters.push('activity');
              break;
            }
            case 'Inactive': {
              filters.push('no_activity');
              break;
            }
            default:
              break;
          }
          break;
        }
        case 'priorAttempts': {
          if (propValue === '4+') {
            filters.push('prior_attempts:gte:4');
          } else {
            filters.push('prior_attempts:eq:' + propValue);
          }
          break;
        }
        case 'courseCreditOrg': {
          filters.push('course_credit_orgs:' + propValue);
          break;
        }
        case 'forumPosts': {
          filters.push('forum_posts:' + propValue);
          break;
        }
        case 'basisOfAdmission': {
          filters.push('basis_of_admission:' + propValue);
          break;
        }
        case 'predictionByWeek': {
          const [band, begin_date] = propValue();
          const week = getWeekFromDate(begin_date, this.state.calendar);
          if (week) {
            filters.push('week_band:' + band + ':' + week.calendar_week_number);
          }
          break;
        }
        case 'vetParticipation': {
          let participation = propValue === 'VET Participation' ? "Y" : "N";
          cohortFilters.push('participation=' + participation);
          break;
        }
        default: {
          // remainder of filter params use ordinary url params
          params = setParam(params, paramName, propValue);
          break;
        }
      }

      params = setParam(params, 'path', path);
      let url = '';
      if (cohortFilters.length > 0) {
        url = '/predictions' + uriLink(params, config.defaultParams) + '&' + cohortFilters.join('&');
      } else {
        url = '/predictions' + uriLink(params, config.defaultParams) + '#' + filters.join('/');
      }

      return (
        <Link to={url}>
          <FontAwesomeIcon icon={faPollH} />
          &nbsp;Cohort Dashboard
        </Link>
      );
    }

    // render student list from state populated by handleShowStudents
    renderStudentList()
    {
      const students = this.state.showStudents;
      if (!students) {
        return null;
      }
      //console.log(this.state.showStudentsProps);
      const title = this.state.showStudentsProps.title;
      const subTitle = this.state.showStudentsProps.propDescription;
      const sortedStudents = students.sort((a, b) => {
        if (a["lastName"] < b["lastName"]) return -1;
        if (a["lastName"] > b["lastName"]) return 1;
        if (a["firstName"] < b["firstName"]) return -1;
        if (a["firstName"] > b["firstName"]) return 1;
        return 0;
      });
      return (
        <Fragment>
          <div>
            <div style={{textAlign: "center"}}>
              <h3>{title} - {subTitle}</h3>
              <h4>{this.renderCohortLink()}</h4>
            </div>
            <table className="dataTable" style={{margin: "0px auto"}}>
              <tbody>
                <tr>
                  <th>Student One ID</th>
                  <th>Name (Last, First)</th>
                </tr>
          { sortedStudents.map((_, index) => (
            <tr key={index}>
            <td>{this.renderIncognito(_.s1Id)}</td>
            <td>{this.renderIncognito(_.lastName)}, {this.renderIncognito(_.firstName)}</td>
            </tr>
          ))}
              </tbody>
            </table>
          </div>
        </Fragment>
      )
    }

    /**
     * This function set the state of element myExperience. The table used in the pop over can be sorted on three elements.
     * The logic of this sorting has been put in this function.
     *
     * The function uses a switch statement because I beleive this is the most maintainable.
     */
    sortMyExperienceList(elementName)
    {
      const currentSortElement = this.state.myExperience.currentSortElement;
      let currentSortOrder = this.state.myExperience.currentSortOrder,
          nextSortOrder,
          nextSortElement = elementName,
          nextSortFunction,
          respSortIcon = this.state.myExperience.respSortIcon,
          dateSortIcon = this.state.myExperience.dateSortIcon,
          nameSortIcon = this.state.myExperience.nameSortIcon;

      // If the previous selected sort element was not the same. The the sort order starts as default.
      if (currentSortElement !== nextSortElement) currentSortOrder = 'default';

      // Example of a case could be "date asc" then it would go to "date desc".
      switch(elementName + " " + currentSortOrder) {
        case 'date default':
          nextSortOrder = 'asc';
          nextSortFunction = (a, b) => new Date(a.recorded_date) - new Date(b.recorded_date);
          respSortIcon = faSort;
          dateSortIcon = faSortDown;
          nameSortIcon = faSort;
          break;
        case 'date asc':
          nextSortOrder = 'desc';
          nextSortFunction = (a, b) => new Date(b.recorded_date) - new Date(a.recorded_date)
          respSortIcon = faSort;
          dateSortIcon = faSortUp;
          nameSortIcon = faSort;
          break;
        case 'resp default':
          nextSortOrder = 'asc';
          // The following sort function might be difficult to understand. See: https://stackoverflow.com/a/29829370
          nextSortFunction = alphabetical(false, 'response');
          respSortIcon = faSortDown;
          dateSortIcon = faSort;
          nameSortIcon = faSort;
          break;
        case 'resp asc':
          nextSortOrder = 'desc';
          nextSortFunction = alphabetical(true, 'response');
          respSortIcon = faSortUp;
          dateSortIcon = faSort;
          nameSortIcon = faSort;
          break;
        case 'name default':
          nextSortOrder = 'asc';
          nextSortFunction = alphabetical(false, 'user_name');
          respSortIcon = faSort;
          dateSortIcon = faSort;
          nameSortIcon = faSortDown;
          break;
        case 'name asc':
          nextSortOrder = 'desc';
          nextSortFunction = alphabetical(true, 'user_name');
          respSortIcon = faSort;
          dateSortIcon = faSort;
          nameSortIcon = faSortUp;
          break;
        default:
          nextSortElement = 'date';
          nextSortOrder = 'default';
          nextSortFunction = (a,b) => new Date(b.recorded_date) - new Date(a.recorded_date);
          respSortIcon = faSort;
          dateSortIcon = faSort;
          nameSortIcon = faSort;
      }

      // Set state with the calculated values.
      this.setState({myExperience: {
        ...this.state.myExperience,
        currentSortOrder: nextSortOrder,
        currentSortElement: nextSortElement,
        currentSortFunction: nextSortFunction,
        respSortIcon,
        dateSortIcon,
        nameSortIcon
      }});
    }

    render() {
      // Notice that we pass through state as props
      return <WrappedComponent
        {...this.state}
        match={this.props.match}
        getStudentLogins={this.getStudentLogins}
        getActivityByWeek={this.getActivityByWeek}
        getLastActivity={this.getLastActivity}
        getGpaBandTotals={this.getGpaBandTotals}
        handleShowFilter={this.handleShowFilter}
        handleShowInfo={this.handleShowInfo}
        handleShowMyExperience={this.handleShowMyExperience}
        handleShowStudents={this.handleShowStudents}
        handleUpdateIncognito={this.handleUpdateIncognito}
        renderStudentList={this.renderStudentList}
      />;
    }
  };
}

export {
  aggregateByProp,
  aggregateByActivity,
  aggregateByActivityThisWeek,
  withDashboard,
}
