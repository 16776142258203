import React from "react";
import {
    Hint,
    HorizontalGridLines,
    VerticalBarSeries,
    XYPlot,
    XAxis,
    YAxis,
} from "react-vis";
import { color_scheme } from "../..//utils/utils";
import { config } from "../../utils/config";
import { mapBeginDateToWeeks } from "../../utils/calendar";
import { filterIntegers, renderYLabel, withWidget } from "../../utils/graph";
import { groupBy, startCase } from "lodash";

const InterventionStrategyGraph = (props) => {
    const {
        hover,
        handleMouseOver,
        handleMouseOut,
        title = props?.title?.toString() ?? 'Intervention Strategy Widget',
        calendar,
        students,
        interactions,
        interactionsSent,
    } = props;

    const hasData = interactionsSent.length > 0;

    const plot = {
        width: 480,
        height: 300,
        left: 60,
        bottom: 50,
        yDomain: [0, 10]
    };

    // First we bind the interaction type and student to each interaction sent.
    interactionsSent.forEach(element => {
        const interaction = interactions.find(interaction => interaction.id === element.interaction_id);
        const student = students.find(student => student.id === element.enrollment_id);
        // Do a bit of cleaning, everything to lowercase and the Capitalise.
        element.interaction_type = startCase(interaction.interaction_type.toLowerCase());
        element.strategy_name = interaction.strategy_name;
        element.student = student;
    });

    // Secondly we create groups by strategy name.
    const interactionGroups = groupBy(interactionsSent, 'strategy_name');

    const legend = color_scheme([...Array(6).keys()], [config.colors.cquBlue, config.colors.cquBlue50]);

    // Thirdly we move all interactions sent to a weekly format. So that every week looks someting like this:
    // {
    //   x: 2, // <-- week.short_name
    //   y: 10, // <-- count of interactions
    //   interactions: [], // <-- should all have the same interaction types and strategy name and should all be in the same week
    //   students: [], // <-- binding students to the interactions, binding on enrollment_id
    //   interaction_type: "Mailout", // <-- Same in all the group
    //   strategy_name: 'Jada' // <-- Same in all the group
    // }
    // We store these in the object array below.
    const interactionGroupsData = {};
    for (const property in interactionGroups) {
        const weeks = calendar.term_weeks.map((week) => {
            const filteredInteractions = interactionGroups[property].filter(element =>
                element.time_created >= week.begin_date &&
                element.time_created <= week.end_date
            );
            const students = filteredInteractions.map((interaction) => interaction.student);

            let countEmail = 0;
            let countNote = 0;
            let countOther = 0;
            filteredInteractions.forEach((interaction) => {
                if (interaction.interaction_type === 'Email' || interaction.interaction_type === 'Mailout') {
                    countEmail++;
                } else if (interaction.interaction_type === 'Note') {
                    countNote++;
                } else {
                    countOther++;
                }
            });

            return {
                x: week.begin_date, //parseInt(short_name),
                y: filteredInteractions.length,
                long_name: week.long_name,
                count: students.length,
                students,
                strategy_name: property,
                countEmail,
                countNote,
                countOther
            };
        });
        interactionGroupsData[property] = weeks;
    }

    // Now map these groups to VerticalBarSeries.
    let series = [];
    let i = 0;
    for (const property in interactionGroupsData) {
        series.push(
            <VerticalBarSeries
                key={property}
                data={interactionGroupsData[property]}
                colorType="literal"
                color={hover.strategy_name === property ? config.colors.cquGreen : legend[i]}
                onValueMouseOut={handleMouseOut}
                onValueMouseOver={handleMouseOver}
            />);
        i++;
    }

    return (
        <div className="UnitWidget UnitWidgetWide">
            <h4>{title}</h4>
            <XYPlot
                xType="ordinal"
                width={plot.width}
                height={plot.height}
                margin={{
                    left: plot.left,
                    bottom: plot.bottom,
                }}
                stackBy='y'
            >
                {hover && (
                    <Hint value={hover} className='plotTooltip'>
                        <div>
                            <h3>{hover.strategy_name} - {hover.long_name}</h3>
                            <p>
                                {hover.count} interactions <br />
                                Email: {hover.countEmail} interactions sent <br />
                                Note: {hover.countNote} interactions sent
                            </p>
                        </div>
                    </Hint>
                )}
                <HorizontalGridLines />
                <XAxis tickFormat={val => mapBeginDateToWeeks(val, calendar)} />
                {renderYLabel("Count of Intervention Strategy", plot)}
                <YAxis tickFormat={filterIntegers} />
                {series}
            </XYPlot>
            {!hasData && (
                <div style={{
                    position: 'relative',
                    bottom: '160px'
                }}>
                    No data
                </div>
            )}
        </div>
    );
}

export default withWidget(InterventionStrategyGraph);