/**
 *  ----------------------------------------------------------------------
 *  A GraphLegend generates a list of clickable filter buttons in the right
 *  colour scheme for a graph rendered elsewhere.
 *
 *  Used in FilterBox.
 *
 *  May be too simple to be necessary; consider integrating into FilterBox.
 *  ----------------------------------------------------------------------
 */

import React, { Component } from "react";
import { FilterLink } from '../FilterLink/FilterLink';

class GraphLegend extends Component {

  render() {

    //  Only render when all properties are found.
    if (
      this.props.paramName === undefined ||
      this.props.params === undefined ||
      this.props.legend === undefined ||
      this.props.scheme === undefined ||
      this.props.totals === undefined
    ) {
      console.log("Error: GraphLegend properties are missing.")
      return <p>GraphLegend: {JSON.stringify(Object.keys(this.props))}</p>;
    }

    const paramName = this.props.paramName;
    const params = this.props.params;
    const legend = this.props.legend;
    const scheme = this.props.scheme;
    const totals = this.props.totals;
    const hash = this.props.hash ? this.props.hash : '';
    const hints = this.props.hints ? this.props.hints : {};

    return legend.map((legendKey, index) => {
      if (params[paramName] !== undefined) {
        const total = totals[legendKey];
        return (
          <div key={index} className="legend-padding no-wrap">
            <FilterLink
              label={legendKey}
              params={params}
              paramName={paramName}
              scheme={scheme}
              total={total}
              hash={hash}
              hint={hints[legendKey] ?? ''}
            />
          </div>
        );
      } else {
        return null;
      }
    });
  }
}

export {
  GraphLegend
}
