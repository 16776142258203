/**
 *  ----------------------------------------------------------------------
 *  An OrderButton displays a Link, but when shift-Clicked will render a
 *  Redirect that sends to a different URL. This allows Excel style sorting.
 *  ----------------------------------------------------------------------
 */

import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faArrowCircleUp,
  faArrowCircleDown,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faArrowCircleUp,
  faArrowCircleDown,
);

class OrderButtonComponent extends Component {

  constructor(props) {
    super(props);

    //  Properties:
    //
    //  [NC 2019-09-10] We could use withRouter to move a lot of the history
    //  handling into OrderButton and FilterLink.
    //
    //  - to        <-- link to click (sets primary sorting)
    //  - shiftTo   <-- link when shift-clicked (sets secondary sorting)
    //  - title
    //  - level     <-- primary|secondary|none
    //  - order     <-- up|down|none
    //  - vertical  <-- true|false

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();
    if (e.shiftKey) {
      this.props.history.push(this.props.shiftTo);
    } else {
      this.props.history.push(this.props.to);
    }
  }

  render() {
    const title = this.props.title;
    const vertical = this.props.vertical;
    const icon = this.props.order === 'up' ? faArrowCircleUp : faArrowCircleDown;
    const className = this.props.level === 'none'
      ? "orderButtonInactive"
      : (
        this.props.level === 'secondary'
        ? "orderButtonSecondary"
        : "orderButtonPrimary"
      );
    return vertical ? (
      <div
        className={"orderButton " + className}
        onClick={this.handleClick}
      >
        <div><FontAwesomeIcon icon={icon} /></div>
        <div>
          <Link to={this.props.to}>
            {title}
          </Link>
        </div>
      </div>
    ) : (
      <div
        className={"orderButton " + className}
        onClick={this.handleClick}
      >
        <Link to={this.props.to}>
          <FontAwesomeIcon icon={icon} />
          {title}
        </Link>
      </div>
    );
  }

}

const OrderButton = withRouter(OrderButtonComponent);  // <-- Inject this.props.history

export {
  OrderButton,
}
