import React from 'react';
import { useLocation } from 'react-router-dom';

/**
 * A custom hook that build on useLocation to parse the query string.
 *
 * @returns {URLSearchParams}
 */
export default function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}