import React, { useContext } from 'react';
import { ModalContext } from '../../../context/ModalContext';
import { InteractionList } from './index';
import moment from 'moment-timezone';
moment.tz.setDefault('Australia/Brisbane');

const WeeklyCell = ({ week, enrolment }) => {
    const { handleModal } = useContext(ModalContext);

    const activity = enrolment.activities.find(activity => activity.begin_date === week.start);
    const interactions = enrolment.interactionsSent.filter(int => moment(int.time_created).isBetween(week.start, week.end));
    return (
        <td
            className={'popoverParent columnWeeklyActivity' + (interactions.length > 0 ? ' interactionsThisWeek' : ' noInteractionsThisWeek')}
            onClick={() => handleModal(<InteractionList items={interactions} />)}
        >
            {activity?.activity ?? 0}
            {interactions.length > 0 && (
                <div className="popoverChild">
                    {interactions.map((int, index) => (
                        <div key={index}>
                            <div className="popoverHeading">
                                {int.interaction.interaction_type}, {moment(int.interaction.time_created, 'YYYY-MM-DD hh:ii:ss').format('D MMM')}, {int.interaction.username}
                            </div>
                            <div className="popoverBody">
                                {int.interaction.subject}
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </td>
    );
}

export default WeeklyCell;