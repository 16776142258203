import React from 'react';
import { Sparklines, SparklinesBars } from 'react-sparklines';

/**
 * This is the little graph in that shows a enrollments activity.
 */
const ActivityGraph = ({ data }) => (
    <Sparklines data={data} width={data.length * 7} svgWidth={data.length * 7} svgHeight={20} min={0} max={100}>
        <SparklinesBars barWidth={6} style={{ fill: "#ffb100" }} />
    </Sparklines>
);

export default ActivityGraph;