import React from 'react';
import InteractionValidationErrorListItem from './InteractionValidationErrorListItem';

const InteractionValidationErrorList = ({errors}) => {
    if (!errors) {
        return <></>
    }

    return errors.map((msg, key)  => <InteractionValidationErrorListItem text={msg} key={key}/> )
}

export default InteractionValidationErrorList;