import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AssetsContext } from '../../../context/AssetsContext';
import { OrderButton, EnrollmentListItem } from './index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare, faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import useCustomParams from '../../../hooks/useCustomParams';
import { getValueByProperty } from '../../../utils/utils';
import _ from 'lodash';
import SelectedEnrollmentsContext from '../../../context/SelectedEnrollmentsContext';
import { config } from '../../../utils/config';
import moment from 'moment-timezone';
import useRouteParams from '../../../hooks/useRouteParams';
import { ActionWhenVisible } from '../../../components';
moment.tz.setDefault('Australia/Brisbane');

const ORDERS = new Map([
    ['name', [(a) => getValueByProperty('student.last_name', a).toLowerCase(), (a) => getValueByProperty('student.first_name', a).toLowerCase()]],
    ['id', ['student_id']],
    ['age', [(a) => moment().diff(getValueByProperty('student.date_birth', a), 'years', false)]],
    ['gender', [(a) => getValueByProperty('student.gender', a)]],
    ['load', ['load']],
    ['map', [(a) => getValueByProperty('student_course.map', a)]],
    ['college', ['college_code']],
    ['course', [(a) => getValueByProperty('course.code', a)]],
    ['campus', [(a) => getValueByProperty('student_course.campus_code', a)]],
    ['gpa', [(a) => getValueByProperty('student_course.gpa', a)]],
    ['passRate', [(a) => Number(a['passRate'])]],
    ['priorFails', [(a) => getValueByProperty('student_course.units_failed', a)]],
    ['courseProgress', [(a) => getValueByProperty('student_course.credit_points_passed', a)]],
    ['credit', [(a) => getValueByProperty('student_course.credit_points_credited', a)]],
    ['entryPathway', [(a) => getValueByProperty('student_course.basis_of_admission', a)]],
    ['interactions', ['totalInteractions']],
    ['participation', ['participation']],
    ['unitCampus', ['campus_code']],
    ['school', ['school_code']],
    ['unit', [(a) => getValueByProperty('unit.code', a)]],
    ['priorAttempts', ['previous_attempts']],
    ['logins', [(a) => a['last_login'] ?? '1970-01-01 10:00:00']],
    ['unitStatus', ['status_code']],
    ['daysEnrolled', ['enrol_date']],
    ['gradeCode', ['grade_code']],
    ['liability', ['liability']],
    ['activity', ['totalActivity']],
    ['study_period_code', [
        'term.year',
        (a)=> ['JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC'].indexOf(a.study_period_code)
    ]],
    ['activityYear', ['activity_year']],
    ['activityTerm', [(a)=> ['JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC'].indexOf(a.activity_study_period_code)]],
    ['activityIntake', [
        'activity_year',
        (a)=> ['JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC'].indexOf(a.activity_study_period_code)]
    ],
    ['extended', ['extended']],
    ['participationDate', ['participation_date']],
    ['moodle', ['visibleToStudent']],
    ['activityIntakeStartDate', ['activity_intake_start_date']],
    ['activityIntakeEndDate', ['activity_intake_end_date']],
]);

/**
 * Method to handle the sorting of enrollments.
 *
 * @param {*} order
 * @param {*} items
 * @returns
 */
function sortByOrder(order, items) {
    let iteratees = [],
        directions = [];

    order.forEach(sort => {
        const direction = sort.slice(0, 1) !== '-';
        const key = direction ? sort : sort.slice(1);
        if (ORDERS.has(key)) {
            iteratees = iteratees.concat(ORDERS.get(key));
            directions = directions.concat(Array(ORDERS.get(key).length).fill(direction ? 'asc' : 'desc'));
        }
    });
    return _.orderBy(items, iteratees, directions);
}

/**
 * The enrollment list function component.
 */
const EnrollmentList = ({ enrollments, incognito, columns }) => {
    const { year, term } = useRouteParams();
    const [limit, setLimit] = useState(50);
    const assets = useContext(AssetsContext);
    const { order } = useCustomParams();
    const { selectedEnrollments, previousSelectedEnrollment, clearPrevSelectedEnrollment, addMultipleSelectedEnrollments, clearSelectedEnrollments } = useContext(SelectedEnrollmentsContext);

    /**
     * Increase the limit.
     */
    const increaseLimit = () => {
        let newLimit = 50;
        if (enrollments.length <= limit + 50) {
            newLimit = enrollments.length;
        } else {
            newLimit = limit + 50;
        }

        if (newLimit < 50) {
            newLimit = 50;
        }

        setLimit(newLimit);
    };

    if (assets.isLoading) {
        return <p>Assets are loading ...</p>
    }

    /**
     * Handles the clicking of the checkbox which is in the header.
     */
    const handleClick = () => {
        if (selectedEnrollments.size === 0) {
            addMultipleSelectedEnrollments(enrollments);
        } else {
            clearSelectedEnrollments();
        }
        clearPrevSelectedEnrollment();
    }

    /**
     * Handles the Shift + Click of the checkbox in each row for multiple selection.
     */
    const handleShiftClick = (event) => {
        if (!event.target.classList.contains('enrolSelect')) {
            return;
        }
        if (!event.shiftKey) {
            return;
        }
        if (previousSelectedEnrollment === null) {
            return;
        }

        window.getSelection().removeAllRanges();

        const selectedEnrollment = event.target.parentElement.parentElement;
        if (selectedEnrollment.rowIndex === previousSelectedEnrollment.rowIndex) {
            return;
        }

        let next, limit;
        if (selectedEnrollment.rowIndex > previousSelectedEnrollment.rowIndex) {
            next = previousSelectedEnrollment.nextElementSibling;
            limit = selectedEnrollment.rowIndex;
        } else {
            next = selectedEnrollment.nextElementSibling;
            limit = previousSelectedEnrollment.rowIndex;
        }

        let selectedRows = [selectedEnrollment.id, previousSelectedEnrollment.id];
        while (next) {
            if (next.rowIndex === limit) {
                break;
            }
            selectedRows.push(next.id);
            next = next.nextElementSibling
    	}

        addMultipleSelectedEnrollments(
            _.filter(enrollments, (enrollment) => {
                return _.includes(selectedRows, enrollment.id.toString())
            })
        );
    }

    const activeTerm = assets.termInfo.displayedTerm;
    const weeks = activeTerm.weeks.data.filter(week => { return moment().isSameOrAfter(week.start, 'day') });

    // VET terms can hold 52 weeks.
    // Restrict the amount of weeks shown when VET Weekly Activity YTD is not in columns.
    if (term === 'VET' && !columns.includes('VET Weekly Activity YTD') && weeks.length > 8) {
        weeks.splice(0, weeks.length - 8);
    }

    // add weekly activity sorting logic for the visible weeks
    columns.includes('Weekly Activity') && weeks.map((week, index) => {
        ORDERS.set(`week${week.short_name}`,
            [ (a) => getValueByProperty('activities', a).find(activity => activity.begin_date === week.start)?.activity ?? 0
            ]
        );
    });

    return (
        <div className='tableBar'>
            <table className='oddEvenRows predictionTable'>
                <thead>
                    <tr className='primarySorting'>
                        <td>
                            <FontAwesomeIcon
                                icon={selectedEnrollments.size > 0 ? faTimesCircle : faSquare}
                                style={{
                                    fontSize: '1.5rem',
                                    opacity: selectedEnrollments.size > 0 ? 1 : 0.3,
                                    cursor: 'pointer',
                                    padding: '0 0.3rem',
                                    color: selectedEnrollments.size > 0 ? config.colors.cquGreen : 'white'
                                }}
                                onClick={handleClick}
                            />
                        </td>
                        {columns.includes('Name') && <td>
                            <OrderButton text='Name' sort='name' />
                        </td>}
                        {columns.includes('ID') && <td>
                            <OrderButton text='ID' sort='id' />
                        </td>}
                        {columns.includes('Age (Gender)') && <td colSpan={2}>
                            <OrderButton text='Age' sort='age' />
                            <OrderButton text='Gender' sort='gender' />
                        </td>}
                        {columns.includes('Load') && <td>
                            <OrderButton text='Load' sort='load'/>
                        </td>}
                        {columns.includes('MAP') && <td>
                            <OrderButton text='MAP' sort='map' />
                        </td>}
                        {columns.includes('Student Flag') && <td>
                            Student Flag
                        </td>}
                        {columns.includes('College') && <td>
                            <OrderButton text='College' sort='college' />
                        </td>}
                        {columns.includes('Course (Campus)') && <td colSpan={2}>
                            <OrderButton text='Course' sort='course' />
                            <OrderButton text='Campus' sort='campus' />
                        </td>}
                        {columns.includes('GPA') && <td>
                            <OrderButton text='GPA' sort='gpa' />
                        </td>}
                        {columns.includes('Pass Rate (Prior Fails)') && <td colSpan={2}>
                            <OrderButton text='Pass Rate' sort='passRate' />
                            <OrderButton text='Prior Fails' sort='priorFails' />
                        </td>}
                        {columns.includes('Course Progress (Credit)') && <td colSpan={2}>
                            <OrderButton text='Course Progress' sort='courseProgress' />
                            <OrderButton text='Credit' sort='credit' />
                        </td>}
                        {columns.includes('Entry Pathway') && <td>
                            <OrderButton text='Entry Pathway' sort='entryPathway' />
                        </td>}
                        {columns.includes('Unit Campus (School)') && <td colSpan={2}>
                            <OrderButton text='Unit Campus' sort='unitCampus' />
                            <OrderButton text='School' sort='school' />
                        </td>}
                        {columns.includes('Unit (Prior Attempts)') && <td colSpan={2}>
                            <OrderButton text='Unit' sort='unit' />
                            <OrderButton text='Prior Attempts' sort='priorAttempts' />
                        </td>}
                        {columns.includes('Interactions') && <td>
                            <OrderButton text='Interactions' sort='interactions' />
                        </td>}
                        {columns.includes('VET Participation') && <td>
                            <OrderButton text='Participation' sort='participation' />
                        </td>}
                        {columns.includes('Moodle Logins') && <td>
                            <OrderButton text='Moodle Logins' sort='logins' />
                        </td>}
                        {columns.includes('Intake Month')&& <td colSpan={2}>
                            <OrderButton text='Intake Month' sort='study_period_code' />
                        </td>}
                        {columns.includes('Activity Intake') && <td colSpan={2}>
                            <OrderButton text='Activity Intake' sort='activityIntake' />
                        </td>}
                        {columns.includes('Activity Start Date') && <td>
                            <OrderButton text='Activity Start Date' sort='activityIntakeStartDate' />
                        </td>}
                         {columns.includes('Activity End Date') && <td>
                            <OrderButton text='Activity End Date' sort='activityIntakeEndDate' />
                        </td>}
                        {columns.includes('Extended') && <td>
                            <OrderButton text='Extended' sort='extended' />
                        </td>}
                        {columns.includes('Participation Date') && <td>
                            <OrderButton text='Participation Date' sort='participationDate' />
                        </td>}
                        {columns.includes('Moodle') && <td>
                            <OrderButton text='Moodle' sort='moodle' />
                        </td>}
                        {columns.includes('Unit Status (Days Enrolled)') && <td colSpan={2}>
                            <OrderButton text='Unit Status' sort='unitStatus' />
                            <OrderButton text='Days Enrolled' sort='daysEnrolled' />
                        </td>}
                        {columns.includes('Grade') && <td>
                            <OrderButton text='Grade' sort='gradeCode' />
                        </td>}
                        {columns.includes('Liability') && <td>
                            <OrderButton text='Liability' sort='liability' />
                        </td>}
                        {columns.includes('Activity') && <td colSpan={2}>
                            <OrderButton text='Activity' sort='activity' />
                        </td>}
                        {columns.includes('Weekly Activity') && weeks.map((week, index) => <td key={index}>
                            <OrderButton text={week.short_name} sort={`week${week.short_name}`} />
                        </td>)}
                    </tr>
                </thead>
                <tbody onClick={handleShiftClick}>{sortByOrder(order, enrollments).slice(0, limit).map((row, index) => <EnrollmentListItem key={index} item={row} weeks={weeks} incognito={incognito} columns={columns}/>)}</tbody>
            </table>
            <ActionWhenVisible action={increaseLimit}>
                <i>Showing {(limit > enrollments.length) ? enrollments.length : limit} of {enrollments.length} enrollments.</i>
            </ActionWhenVisible>
        </div>
    );
}

export default EnrollmentList;