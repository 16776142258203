import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope as ICON_MAIL, faStickyNote as ICON_NOTE, faCube as ICON_CRM } from '@fortawesome/free-solid-svg-icons';
import { message_crm_url, replaceCreationUser } from '../../../utils/utils';
import moment from 'moment-timezone';
import { INTERACTION_MODES } from '../../../utils/constants';
moment.tz.setDefault('Australia/Brisbane');

const InteractionListItem = ({ item }) => {
    let icon = ICON_MAIL;

    if (['CRM'].includes(item.interaction.interaction_type)) {
        icon = ICON_CRM;
    } else if (['Note'].includes(item.interaction.interaction_type)) {
        icon = ICON_NOTE;
    }

    return (
        <div className="interactionsListItem">
            <div className="interactionsListItemIcon">
                <FontAwesomeIcon icon={icon} />
            </div>
            <div className="interactionsListItemInfo">
                <p>
                    <span title={'#' + item.interaction.id} style={{ leftPadding: '1rem' }}>
                        {moment(item.interaction.time_created, 'YYYY-MM-DD hh:ii:ss').format('D MMM YYYY')}
                    </span>
                </p>
                <p>{replaceCreationUser(item.interaction.username)}</p>
                <p>{item.interaction.mode !== INTERACTION_MODES.EMAIL_PER_ENROLLMENT && <small>Email(s) sent per <b>{item.interaction.mode}</b>. For this interaction the email was {item.send_email === false && 'not'} send.</small>}</p>
            </div>
            <div className="interactionsListItemContent">
                <p>
                    <b>{item.interaction.subject}</b>
                </p>
                {icon === ICON_CRM ?
                    <div>
                            CRM Case: <a target="_blank" rel="noopener noreferrer" href={message_crm_url(item.interaction.case_url)}>{item.interaction.case_number}</a>
                            <br/>
                            <small>More details available to users with SugarCRM access</small>
                    </div>
                    :
                    <div dangerouslySetInnerHTML={{ __html: item.interaction.body_text }} />
                }
            </div>
        </div>
    );
};

export default InteractionListItem;