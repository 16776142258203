import React, { useContext } from 'react';
import { AssetsContext } from '../../../../context/AssetsContext';
import { AssessmentContext } from '../../context/AssessmentContext';
import moment from 'moment-timezone';
import useRouteParams from '../../../../hooks/useRouteParams';
moment.tz.setDefault('Australia/Brisbane');

const AssessmentsDue = ({ enrollments }) => {
    const { year, term } = useRouteParams();
    const { terms } = useContext(AssetsContext);
    const { isLoading, isError, assessments } = useContext(AssessmentContext);


    if (!enrollments || enrollments.length === 0 || isError ) {
        return (
            <div className="shadowBox numberBox faded" style={{ width: '250px' }}>
                <div>—</div>
                <div>Assessments Due This Term</div>
            </div>
        );
    }

    if (isLoading) {
        return (
            <div className="shadowBox numberBox faded" style={{ width: '250px' }}>
                <div>Loading ... </div>
                <div>Assessments Due This Term</div>
            </div>
        );
    }

    const unitIds = enrollments.map( enr => enr.unit_id );
    const thisTerm = terms.find(item => item.year === parseInt(year) && item.term === term );

    // Firstly filter all assessments so they they are in the targeted enrollments.
    // Secondly find the assessments which are due during this term.
    const assessmentsDueInTerm = assessments
        .filter( ass => unitIds.includes(ass.unit_id) )
        .filter(
            ass => moment(ass.date_due).year() === 1970 // Non-Timed assessments.
            || ( moment(thisTerm.start).isSameOrBefore(ass.date_due) &&  moment(thisTerm.end).isSameOrAfter(ass.date_due))
        );

    return (
        <div className="shadowBox numberBox" style={{ width: '250px' }}>
            <div>{assessmentsDueInTerm.length}</div>
            <div>Assessments Due This Term</div>
        </div>
    );
}

export default AssessmentsDue;