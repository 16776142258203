import { faPollH, faUserClock } from '@fortawesome/free-solid-svg-icons';
import React, { useContext, useEffect, useState } from 'react';
import { DashboardInfo, Header, Loader, CampusDashboardFilter, GroupDashboardFilter, MapDashboardFilter, CourseDashboardFilter, GenderDashboardFilter, LoginDashboardFilter, FlagDashboardFilter, IntakeMonthDashboardFilter, AgeDashboardFilter, UnitStatusDashboardFilter } from '../../components';
import { AssetsContext, AssetsContextProvider } from '../../context/AssetsContext';
import { ModalProvider } from '../../context/ModalContext';
import { activityBand, gpaBand, lastLoginBand, passRateBand } from '../../utils/bands';
import useEnrollments from '../../hooks/useEnrollments';
import useUnitAssessments from '../../hooks/useUnitAssessments';
import { calcPassRate, filterByProperty, getFiltersFromParams, isCurrent, isCurrentTerm, sendLog } from '../../utils/utils';
import { ActivityThisWeekWidget, AssessmentExtensionWidget, AssessmentSubmissionWidget, CoursesWidget, FiltersActiveCard, GpaBandsWidget, InteractionSummaryWidget, MyExperienceWidget, OverviewWidget, RecentLoginsWidget, PriorAttemptsWidget, VetParticipationWidget, WeeklyActivityWidget, DashboardPreferences } from './components';
import { config as SiteConfig } from '../../utils/config';
import useQuery from '../../hooks/useQuery';
import { readQueryString } from '../../utils/uriParams';
import QueryString from '../../utils/queryString';
import Error from '../Error/Error';
import { CURRENT_VET_YEAR_LABEL, CURRENT_VET_YEAR_VALUE, LOG } from '../../utils/constants';
import { isEqual } from 'lodash';
import moment from 'moment-timezone';
import { HeaderContext, HeaderContextProvider } from '../../context/HeaderContext';
import { getUnitPreferences, filterAssessments } from './utils/preference';
import useRouteParams from '../../hooks/useRouteParams';
import { SelectedEnrollmentsProvider } from '../../context/SelectedEnrollmentsContext';
moment.tz.setDefault('Australia/Brisbane');

/**
 *  The unit dashboard is wrapped. This allows the cohort to use context and other things.
 */
const UnitDashboard = ({ incognito }) => {
    const { year, term } = useRouteParams();

    return (
        <HeaderContextProvider incognito={incognito}>
            <AssetsContextProvider year={year} term={term}>
                <SelectedEnrollmentsProvider dashboard={LOG.DASHBOARD.UNIT}>
                    <ModalProvider>
                        <Main/>
                    </ModalProvider>
                </SelectedEnrollmentsProvider>
            </AssetsContextProvider>
        </HeaderContextProvider>

    );
}

/**
 * The main component contains the JSX and other logic for the unit dashboard.
 */
const Main = () => {
    const query = useQuery();
    const { year: parYear, term: parTerm, unit: parUnit, raw } = useRouteParams();
    const home = useContext(HeaderContext);
    const assets = useContext(AssetsContext);
    const unit = assets.isSuccess ? assets.units.find(({ code }) => code === parUnit) : null;
    const term = assets.isSuccess ? assets.termInfo.displayedTerm : null;
    const isVet = parTerm.toUpperCase() === 'VET' ? true : false;
    const enrollments = useEnrollments(parYear, parTerm, new URLSearchParams(`path=:${parUnit}`));
    const unitIds = assets.isSuccess && enrollments.isSuccess ? assets.units.reduce((acc, unit) => {
        if (unit.code  !== parUnit) {
            return acc;
        }
        if (enrollments.data.find(({unit_id}) => unit_id === unit.id) === undefined) {
            return acc;
        }
        acc.add(unit.id);
        return acc;
    }, new Set()) : new Set();
    const assessments = useUnitAssessments(Array.from(unitIds.values()));
    const [search, setSearch] = useState('');
    const [sidebar, setSidebar] = useState('');
    const [params, setParams] = useState(SiteConfig.defaultParams);
    const isLoading = assets.isLoading || enrollments.isLoading || home.isLoading || assessments.isLoading;
    const isError = assets.isError || enrollments.isError || home.isError || assessments.isError;

    const FILTERS = [
        { enabled: true, title: 'Unit Status', property: 'status_code', parameter: 'unitStatus', component: UnitStatusDashboardFilter },
        { enabled: true, title: 'Campuses', property: 'campus_code', parameter: 'campuses', asset: 'campuses', component: CampusDashboardFilter },
        { enabled: true, title: 'Moodle Groups', property: 'groups.*.name', parameter: 'groups', component: GroupDashboardFilter },
        { enabled: !isVet, title: 'MAP Status', property: 'student.map', parameter: 'maps', component: MapDashboardFilter },
        { enabled: true, title: 'Courses', property: 'course.code', parameter: 'courses', asset: 'courses', component: CourseDashboardFilter },
        { enabled: isVet, title: 'Intake Month', property: 'study_period_code', parameter: 'intakeMonth', component: IntakeMonthDashboardFilter },
        { enabled: true, title: 'Student Flag', property: 'flags', parameter: 'flags', component: FlagDashboardFilter },
        { enabled: true, title: 'Gender', property: 'student.gender', parameter: 'gender', component: GenderDashboardFilter },
        { enabled: true, title: 'Moodle Logins', property: 'lastLoginBand', parameter: 'lastLogin', component: LoginDashboardFilter },
        { enabled: true, title: 'Age', property: 'student.age', parameter: 'age', component: AgeDashboardFilter },];

    const onClickSidebarButton = (menu) => {
        if (sidebar === menu) {
            setSidebar('');
            return;
        }

        setSidebar(menu);
    }

    /**
     * For event logging a page view.
     */
    useEffect(() => {
        if (enrollments.isSuccess) {
            // Finish up.
            sendLog("App\\Events\\UnitDashboard\\Viewed", 'r', LOG.ACTION.VIEW, LOG.TARGET.PAGE, LOG.DASHBOARD.UNIT, {
                term: { year: parYear, term: parTerm },
                code: parUnit,
                total: enrollments.data.length,
            });
        }
    }, [enrollments.isSuccess, parYear, parTerm, parUnit]);

    /**
     * When the query (search params) changes.
     */
    useEffect(() => {
        const newParams = readQueryString(SiteConfig.defaultParams, window.location.search);
        setParams(newParams);

        // Log the new filters.
        const newFilters = getFiltersFromParams(newParams);
        if (isEqual(newFilters, getFiltersFromParams(params)) && isEqual(newFilters, {})) {
            // Only update if they don't match the old params. This prevents double logging.
            return;
        }
        sendLog("App\\Events\\UnitDashboard\\Filtered", 'r', LOG.ACTION.FILTER, LOG.TARGET.ENROLLMENTS, LOG.DASHBOARD.UNIT, {
            filters: newFilters,
            term: { parYear, parTerm },
            code: parUnit,
        });
        // Ignore missing dependencies since this should only ever run when the query changes.
        // eslint-disable-next-line
    }, [query]);

    if (isLoading && !isError) {
        return <Loader
            isLoadingAssets={assets.isLoading}
            isLoadingHome={home.isLoading}
            isLoadingAssessments={assessments.isLoading}
            isLoadingEnrollments={enrollments.isLoading}
        />;
    }

    if (isError || !unit || !term) {
        if (!unit) {
            return <Error error={{ code: 404, title: "Unit not found", message: "The unit provided in the URL is incorrect.", }} />
        }
        if (!term) {
            return <Error error={{ code: 404, title: "Term not found", message: "The term provided in the URL is incorrect.", }} />
        }
        return <Error error={assets.error || enrollments.error || home.error || assessments.error} />
    }

    const ENRfilter = isCurrent(term) && term.term !== 'VET' ? '&unitStatus=ENR' : '';
    const services = [
        [`Cohort Dashboard for ${unit.code}`, `/cohort/${raw.year}/${raw.term}?path=:${parUnit}${ENRfilter}`, faPollH],
    ];

    if (unit.shortname && unit.shortname !== '') {
        services.push(['Student Activity Monitor', SiteConfig.links.moodleCourseUrl.replace('$shortname', unit.shortname), faUserClock, true]);
    }

    const unitType = enrollments?.data?.[0]?.unit_type;
    const isClustered = (unitType === 'cluster' || unitType === 'sub_cluster') ? true : false;

    if (isVet && !isClustered) {
        services.push(['VET My Experience', SiteConfig.links.vetMeUrl, faPollH, true]);
    }

    const week = term.weeks.data.find(week => moment().isSameOrAfter(week.start, 'day') && moment().isSameOrBefore(week.end, 'day'));
    const dataLoaded = enrollments.data.map((enrollment) => {
        enrollment.student.age = moment().diff(enrollment.student.date_birth, 'years');
        enrollment.lastLoginBand = lastLoginBand(enrollment.last_login);
        enrollment.gpaBand = gpaBand(enrollment.student_course.gpa);
        enrollment.passRate = calcPassRate(
            enrollment.student_course.units_passed + enrollment.student_course.units_failed,
            enrollment.student_course.units_passed
        );
        enrollment.passRateBand = passRateBand(enrollment.passRate, home.config);
        enrollment.term = assets.terms.find(({ id }) => id === enrollment.term_id);
        enrollment.unit = assets.units.find(({ id }) => id === enrollment.unit_id);
        enrollment.course = assets.courses.find(({ id }) => id === enrollment.course_id);
        enrollment.college = assets.colleges.find(({ code }) => code === enrollment.college_code);
        enrollment.campus = assets.campuses.find(({ code }) => code === enrollment.campus_code);
        enrollment.school = assets.schools.find(({ code }) => code === enrollment.school_code);
        enrollment.activityThisWeekBand = activityBand(enrollment.activities, week, home.config);
        return enrollment;
    });

    // Filter from params.
    let filtered = dataLoaded;
    const keys = Object.keys(params);
    keys.forEach(key => {
        const filter = FILTERS.find((element) => element.parameter === key);
        const values = params[key] || [];

        if (values.length === 0 || filter === undefined) {
            return;
        }

        filtered = filtered.filter((element) => filterByProperty(filter.property, values, element));
    });

    // Special case to filter ages.
    const qs = new QueryString(window.location.pathname + window.location.search);
    const minAge = qs.query.minAge || undefined;
    const maxAge = qs.query.maxAge || undefined;
    if (!isNaN(minAge) && !isNaN(maxAge)) {
        filtered = filtered.filter((element) => element.student.age >= minAge && element.student.age <= maxAge);
    }

    const cleanSearch = search.trim().toLowerCase();
    if (cleanSearch !== '') {
        filtered = filtered.filter(element => (
            element.student.first_name.slice(0, cleanSearch.length).toLowerCase() === cleanSearch ||
            element.student.last_name.slice(0, cleanSearch.length).toLowerCase() === cleanSearch ||
            element.student.code.slice(0, cleanSearch.length).toLowerCase() === cleanSearch ||
            element.student.id.toString().slice(0, cleanSearch.length).toLowerCase() === cleanSearch
        ));
    }

    const filteredAssessments = filterAssessments(assessments.data, getUnitPreferences(home.user, {year: parYear, term: parTerm}, unit.code), term);

    return (
        <>
            <Header
                config={home.config}
                termInfo={assets.termInfo}
                imports={home.imports}
                user={home.user}
                notices={home.notices}
                services={services}
                incognito={home.incognito}
                handleUpdateIncognito={home.setIncognito}
                handleSearch={setSearch}
                breadcrumbs={[
                    ['Unit Dashboard'],
                    [`${parYear === CURRENT_VET_YEAR_VALUE ? CURRENT_VET_YEAR_LABEL : raw.year} ${raw.term}`],
                    [`${unit.code}: ${unit.name}`]
                ]}
            />
            <main className="UnitDashboard">
                <div className="UnitDashboard">
                    <article className="sidebar">
                        {sidebar === 'filter' && <section className="filterBox">
                            {FILTERS.map((filter, key) => filter.enabled &&
                                <fieldset key={key}>
                                    <legend>{filter.title}</legend>
                                    <filter.component {...filter} enrollments={enrollments.data} value={params[filter.parameter]} />
                                </fieldset>
                            )}
                        </section>}
                        {sidebar === 'info' && <DashboardInfo term={term} user={home.user} />}
                        {sidebar === 'preferences' && <DashboardPreferences assessments={assessments.data} />}
                        <div className="ButtonWrapper">
                            <button className={sidebar === 'filter' ? "primaryButton" : "secondaryButton"} onClick={() => onClickSidebarButton('filter')}><p>Filter</p></button>
                            <button className={sidebar === 'info' ? "primaryButton" : "secondaryButton"} onClick={() => onClickSidebarButton('info')}><p>Info</p></button>
                            <button className={sidebar === 'preferences' ? "primaryButton" : "secondaryButton"} onClick={() => onClickSidebarButton('preferences')}><p>Preference</p></button>
                        </div>
                    </article>
                    <div className='UnitWidgets'
                    /*style={{ marginLeft: sidebar ? '380px' : '50px'}}*/
                    >
                        {filtered.length !== enrollments.data.length && <FiltersActiveCard filtered={filtered.length} total={enrollments.data.length} onClear={() => setSearch('')} />}
                        <OverviewWidget week={week} unit={unit} enrollments={filtered} isCurrentTerm={isCurrentTerm({year: parYear, term: parTerm, start: term.start, end: term.end})} isVetTerm={term.term === 'VET'} />
                        {!isVet && <MyExperienceWidget year={term.year} term={term.term} enrollments={filtered} />}
                        <AssessmentSubmissionWidget unit={unit} assessments={filteredAssessments} enrollments={filtered} />
                        {isCurrentTerm({year: parYear, term: parTerm, start: term.start, end: term.end}) && <>
                            <RecentLoginsWidget week={week} enrollments={filtered} />
                            <ActivityThisWeekWidget enrollments={filtered} />
                        </>}
                        {isVet && <VetParticipationWidget enrollments={filtered} />}
                        <WeeklyActivityWidget year={term.year} term={term.term} enrollments={filtered} />
                        <CoursesWidget enrollments={filtered} />
                        {!isVet && <GpaBandsWidget enrollments={filtered} />}
                        <InteractionSummaryWidget enrollments={filtered} />
                        {!isVet && <AssessmentExtensionWidget unit={unit} assessments={assessments.data} enrollments={filtered} />}
                        <PriorAttemptsWidget enrollments={filtered} />
                    </div>
                </div>
            </main>
        </>
    );
}

export default UnitDashboard;