import React, { useContext } from 'react';
import {CohortLink, InteractionBar, StudentTable} from '../../components';
import SelectedEnrollmentsContext from '../../../../context/SelectedEnrollmentsContext';

/**
 * Renders the context of a modal based upon the SelectedEnrollmentsContext.
 *
 * @param {Object} props - Component props.
 * @param {string} props.title - The text to display in the header.
 * @param {string} props.cohortFilter - The uri which leads to the cohort dashboard, if undefined won't display.
 * @param {function} props.handleModal - The function to open a widget
 * @returns {JSX.Element} Rendered component.
 */
const UnitWidgetModal = ({title, handleModal, cohortFilter}) => {
    const { selectedEnrollments } = useContext(SelectedEnrollmentsContext);
    const students = Array.from(selectedEnrollments.values()).map((enr) => enr.student);

    return <>
        <center>
            <h3>{title}</h3>
            {cohortFilter && <CohortLink filter={cohortFilter} />}
        </center>
        {handleModal && <InteractionBar handleModal={handleModal} /> }
        <StudentTable students={students} />
    </>;
};

export default UnitWidgetModal;
