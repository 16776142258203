import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LZString from "lz-string";
import { FilterBox, Header, Loader, Spinner } from '../../components';
import useQuery from '../../hooks/useQuery';
import useEnrollments from '../../hooks/useEnrollments';
import EnrollmentList from './components/EnrollmentList';
import { fetchHomeAndTermEnrollmentData } from '../../utils/fetch';
import { AssetsContext, AssetsContextProvider } from '../../context/AssetsContext';
import { HeaderContext, HeaderContextProvider } from '../../context/HeaderContext';
import { config as SiteConfig } from '../../utils/config';
import { readQueryString } from '../../utils/uriParams';
import { gpaBand, lastLoginBand, passRateBand, activityBand, priorAttemptBand } from '../../utils/bands';
import { calcPassRate, filterByProperty, getParams, getPreferenceFromUser, isCurrent, isCurrentTerm, sendLog } from '../../utils/utils';
import { ModalProvider } from '../../context/ModalContext';
import { SelectedEnrollmentsProvider } from '../../context/SelectedEnrollmentsContext';
import { FilterPassRate, PreferenceButton, SelectedFrom } from './components';
import { faThumbtack } from '@fortawesome/free-solid-svg-icons';
import { CURRENT_VET_YEAR_LABEL, CURRENT_VET_YEAR_VALUE, LOG } from '../../utils/constants';
import moment from 'moment-timezone';
import Error from '../Error/Error';
import { getUserCourseServices } from '../../utils/utils';
import useRouteParams from '../../hooks/useRouteParams';
moment.tz.setDefault('Australia/Brisbane');

const FILTERS = [
    { title: 'Pass Rate', property: 'passRateBand', parameter: 'passRates', },
    { title: 'Unit Status', property: 'status_code', parameter: 'unitStatus', },
    { title: 'Grade', property: 'grade_code', parameter: 'grade' },
    { title: 'Unit Campus', property: 'campus_code', parameter: 'campuses', asset: 'campuses' },
    { title: 'School', property: 'school_code', parameter: 'schools', asset: 'schools' },
    { title: 'College', property: 'college_code', parameter: 'colleges', asset: 'colleges' },
    { title: 'Load', property: 'load', parameter: 'load', },
    { title: 'Course', property: 'course.code', parameter: 'courses', asset: 'courses' },
    { title: 'Unit', property: 'unit.code', parameter: 'units', asset: 'units' },
    { title: 'Student Flag', property: 'flags', parameter: 'flags', },
    { title: 'GPA', property: 'gpaBand', parameter: 'GPA', },
    { title: 'Moodle Group', property: 'groups.*.name', parameter: 'groups', },
    { title: 'VET Participation', property: 'participation', parameter: 'participation', sort: 'vetParticipationSort' },
    { title: 'Entry Pathway', property: 'student_course.basis_of_admission', parameter: 'entryPathway', },
    { title: 'Moodle Logins', property: 'lastLoginBand', parameter: 'lastLogin', },
    { title: 'Activity This Week', property: 'activityThisWeekBand', parameter: 'activityBands',
      legend: ['Low', 'Medium', 'High']},
    { title: 'MAP', property: 'student_course.map', parameter: 'maps', },
    { title: 'Activity This Term', property: 'engaged', parameter: 'engaged', },
    { title: 'Intake Month', property: 'study_period_code', parameter: 'intakeMonth', sort: 'monthSort' },
    { title: 'Intake Year', property: 'year', parameter: 'intakeYear' },
    { title: 'Prior Attempts', property: 'priorAttemptBand', parameter: 'priorAttempts'},
    { title: 'SAM Filter (not shown)', property: 'student_code', parameter: 'students'},
    { title: 'Extended', property: 'isExtended', parameter: 'extended'},
];

/**
 * The cohort dashboard is wrapped. This allows the cohort to use the assets context.
 */
const CohortDashboard = ({ incognito }) => {
    const { year, term } = useRouteParams();

    return (
        <HeaderContextProvider incognito={incognito}>
            <AssetsContextProvider year={year} term={term}>
                <SelectedEnrollmentsProvider dashboard={LOG.DASHBOARD.COHORT}>
                    <ModalProvider>
                        <Main/>
                    </ModalProvider>
                </SelectedEnrollmentsProvider>
            </AssetsContextProvider>
        </HeaderContextProvider>
    );
}

const Main = () => {
    const query = useQuery();
    const home = useContext(HeaderContext);
    const assets = useContext(AssetsContext);
    const { year: parYear, term: parTerm } = useRouteParams();
    const enrollments = useEnrollments(parYear, parTerm, query);
    const [params, setParams] = useState(SiteConfig.defaultParams);
    const [search, setSearch] = useState('');
    const isLoading = assets.isLoading || enrollments.isLoading || home.isLoading;
    const isError = assets.isError || enrollments.isError || home.isError;

    /**
     *  On page load.
     */
    useEffect(() => {
        if (enrollments.isSuccess) {
            sendLog("App\\Events\\Cohort\\Viewed", 'r', LOG.ACTION.VIEW, LOG.TARGET.PAGE, LOG.DASHBOARD.COHORT, {
                term: {year: parYear, term: parTerm},
                path: query.get('path').split(','),
                total: enrollments.data.length,
            });
        }
    }, [enrollments.isSuccess, parYear, parTerm]);

    /**
     * When the query (search params) changes.
     */
    useEffect(() => {
        setParams(readQueryString(SiteConfig.defaultParams, window.location.search));
    }, [query])

    // Stop here when loading.
    if (isLoading && !isError) {
        return <Loader
            isLoadingAssets={assets.isLoading}
            isLoadingHome={home.isLoading}
            isLoadingEnrollments={enrollments.isLoading}
        />;
    }

    // Stop here if the data is still loading or has errored.
    if (isError) {
        return <Error error={assets.error || enrollments.error || home.error} />
    }

    const term = assets.termInfo.displayedTerm;
    const weeks = term.weeks.data.filter(week => { return moment().isSameOrAfter(week.start, 'day') });
    const thisWeek = weeks[weeks.length -1];
    const {columns, filters} = getPreferenceFromUser( home.user, isCurrentTerm({term: parTerm, year: parYear, start: term.start, end: term.end}), parTerm === 'VET' );

    const services = getUserCourseServices(home.user, assets.terms);

    const dataLoaded = enrollments.data.map((enrolment) => {
        // Create the bands which are used for filtering.
        enrolment.lastLoginBand = lastLoginBand(enrolment.last_login);
        enrolment.priorAttemptBand = priorAttemptBand(enrolment.previous_attempts);
        enrolment.gpaBand = gpaBand(enrolment.student_course.gpa);
        enrolment.passRate = calcPassRate(
            enrolment.student_course.units_passed + enrolment.student_course.units_failed,
            enrolment.student_course.units_passed
        );
        enrolment.passRateBand = passRateBand(enrolment.passRate, home.config);
        enrolment.totalActivity = enrolment.activities.reduce((acc, obj) => acc + obj.activity, 0);
        enrolment.totalInteractions = enrolment.interactionsSent.length + enrolment.myExperiences.length + enrolment.interactionCases.length;
        // Fix empty value for grade code filter.
        enrolment.grade_code = enrolment.grade_code === '' ? '-' : enrolment.grade_code;
        enrolment.engaged = enrolment.totalActivity === 0 ? 'No' : 'Yes';
        enrolment.participation = enrolment.vet_participation;
        enrolment.isExtended = enrolment.extended ? 'Yes' : 'No';
        enrolment.term = assets.terms.find(({ id }) => id === enrolment.term_id);
        enrolment.unit = assets.units.find(({ id }) => id === enrolment.unit_id);
        enrolment.course = assets.courses.find(({ id }) => id === enrolment.course_id);
        enrolment.college = assets.colleges.find(({ code }) => code === enrolment.college_code);
        enrolment.campus = assets.campuses.find(({ code }) => code === enrolment.campus_code);
        enrolment.school = assets.schools.find(({ code }) => code === enrolment.school_code);
        enrolment.activityThisWeekBand = activityBand(enrolment.activities, thisWeek, home.config);
        enrolment.visibleToStudent = enrolment.unit.hidden ? 'No': 'Yes';
        enrolment.student_course.basis_of_admission = enrolment.student_course.basis_of_admission === '' ? '-' : enrolment.student_course.basis_of_admission;
        enrolment.flags = enrolment.flags.length === 0 ? ['-'] : enrolment.flags;
        return enrolment;
    });

    // Filter from params.
    let filtered = dataLoaded;
    const keys = Object.keys(params);
    keys.forEach(key => {
        const filter = FILTERS.find((element) => element.parameter === key);
        var values = params[key] || [];
        if (values.length === 0 || filter === undefined) {
            return;
        }

        // SAM (Moodle local_activity_monitor): Has a special case here.
        // The students parameter contains a compressed list of student codes which should act as a filter.
        if (key === 'students') {
            const decoded = LZString.decompressFromEncodedURIComponent(values[0]);
            const codes = decoded === "" ? new Set() : new Set(decoded.split(','));
            values = Array.from(codes);
        }

        filtered = filtered.filter((element) => filterByProperty(filter.property, values, element));
    });

    const cleanSearch = search.trim().toLowerCase();
    if (cleanSearch !== '') {
        filtered = filtered.filter(element => (
            element.student.first_name.slice(0, cleanSearch.length).toLowerCase() === cleanSearch ||
            element.student.last_name.slice(0, cleanSearch.length).toLowerCase() === cleanSearch ||
            element.student.code.slice(0, cleanSearch.length).toLowerCase() === cleanSearch ||
            element.student.id.toString().slice(0, cleanSearch.length).toLowerCase() === cleanSearch
        ));
    }

    return (
        <>
            <Header
                config={home.config}
                termInfo={assets.termInfo}
                imports={home.imports}
                user={home.user}
                notices={home.notices}
                services={services.slice(0, 10)}
                handleUpdateUser={home.setUser}
                incognito={home.incognito}
                handleUpdateIncognito={home.setIncognito}
                columnOptions={SiteConfig.columnOptions}
                filterOptions={FILTERS.map(filter => filter.title)}
                handleSearch={setSearch}
                breadcrumbs={[
                    ['Cohort Dashboard'],
                    [`${parseInt(parYear) === CURRENT_VET_YEAR_VALUE ? CURRENT_VET_YEAR_LABEL : parYear} ${parTerm}`],
                    [params.path.join(',')],
                ]}
            />
            <main>
                <article className="filterBar">
                    <SelectedFrom loaded={dataLoaded} strategies={home.config.strategies} />
                    {FILTERS.map((filter, index) => {
                        // Don't show filter when not in preference, and filter is not in use.
                        if (!filters.includes(filter.title) && params[filter.parameter].length === 0) {
                            return undefined;
                        }

                        // Dont show SAM filter.
                        if (filter.parameter === 'students') {
                            return undefined;
                        }

                        // Edge case.
                        if (filter.property === 'passRateBand') {
                            return <FilterPassRate
                                key={index}
                                title={filter.title}
                                propName={filter.property}
                                paramName={filter.parameter}
                                sort={filter.sort}
                                asset={filter.asset}
                                loaded={dataLoaded}
                                filtered={filtered}
                            />
                        }
                        return <FilterBox
                            key={index}
                            title={filter.title}
                            propName={filter.property}
                            paramName={filter.parameter}
                            legend={filter.legend}
                            sort={filter.sort}
                            asset={filter.asset}
                            loaded={dataLoaded}
                            filtered={filtered}
                        />
                    })}
                    <PreferenceButton />
                </article>
                <EnrollmentList enrollments={filtered} incognito={home.incognito} columns={columns} />
            </main>
        </>
    );
};

export default CohortDashboard;
