import React, { useState, useEffect } from 'react';
import { fetchAssessmentsInTermForStudent } from '../../../utils/fetch';

/*
 * AssessmentContext contains all assessments for a given year, term and student id.
 */
const AssessmentContext = React.createContext();

/**
 * This FunctionComponent is the Provider that gives access to the context to its children.
 *
 * @param {JSX.Element} children Child components, which will have access to assets.
 * @param {number} year The year of the term to fetch
 * @param {string} term The term of the term to fetch
 * @param {number} student The id of the student for which to fetch the assessments
 */
const AssessmentProvider = ({ year, term, student, children }) => {
    const [error, setError] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const [assessments, setAssessments] = useState([]);

    useEffect(() => {
        fetchAssessmentsInTermForStudent(parseInt(year), term, parseInt(student)).then(result => {
            setAssessments(result);
            setLoading(false);
        }).catch(error => setError(error));
    }, [year, term, student]);

    const isLoading = loading;
    const isError = error !== undefined;
    const isSuccess = !isError && !isLoading;
    const value = {
        isLoading,
        isSuccess,
        isError,
        error,
        assessments
    };

    return (
        <AssessmentContext.Provider value={value}>
            {children}
        </AssessmentContext.Provider>
    );
};

export { AssessmentContext, AssessmentProvider };
