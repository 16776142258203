/**
 * [NC 2019-02-27] URI parameter handling; allows state params to be stored in URI and linked.
 *
 * - Manage parameters: toggle, set, delete
 * - Construct links.
 *
 * - TODO: Inject config so functions are deterministic.
 * - TODO: Ensure tests are up-to-date.
 *
 */

import { config } from "./config";
import { make_object } from "./utils";

//  ----------------------------------------
//  URI assembly
//  ----------------------------------------

function uriChars(str) {
  //  Minimal safe charset for URI fields.
  return str.replace(/[^A-Za-z0-9\-,~!:@^_*]/g, "");
}

function uriLink(params, defaultParams, allowedParams = config.params) {
  // console.log(params, allowedParams, defaultParams);
  const keys = allowedParams.filter(_ => params.hasOwnProperty(_));
  const parts = keys.reduce((list, key) => {
    const paramsOrDefaults =
      params[key].length === 0 && defaultParams[key].length > 0
        ? defaultParams[key]
        : params[key];
    const terms = paramsOrDefaults.map(_ => encodeURIComponent(_)).join(",");
    if (terms.length > 0) {
      list.push(key + "=" + terms);
    }
    return list;
  }, []);
    //  console.log(config.params);
  return "?" + parts.join("&");
}

//  ----------------------------------------
//  Reading/Writing query strings
//  ----------------------------------------

function readQueryString(defaultParams, search) {
  let params = {...defaultParams};
  if (search === undefined || search === "") {
    return params;
  }
  let query = search[0] === "?" ? search.slice(1) : search;
  query.split("&").forEach(term => {
    let parts = term.split("=");
    if (parts.length === 2) {
      let [key, val] = parts;
      if (params.hasOwnProperty(key)) {
        params[key] = decodeURIComponent(val).split(",");
      }
    }
  });
  //  Don't sort on hidden fields.
  //  if (params.hasOwnProperty("show")) {
  //  params["order"] = onlyShownFields(params["order"], params["show"]);
  //  }
  return params;
}

function readDashboardQueryString(paramsList, search, defaultParams) {
  let params = make_object(paramsList, []);
  params = Object.assign(params, defaultParams);
  if (search === undefined || search === "") {
    return params;
  }
  let query = search[0] === "?" ? search.slice(1) : search;
  query.split("&").forEach(term => {
    let parts = term.split("=");
    if (parts.length === 2) {
      let [key, val] = parts;
      if (params.hasOwnProperty(key)) {
        params[key] = decodeURIComponent(val).split(",");
      }
    }
  });
  return params;
}

function writeQueryString(paramsList, params) {
  let parts = [];
  paramsList.forEach(key => {
    if (params[key] !== undefined && params[key].length > 0) {
      parts.push(key + "=" + params[key]);
    }
  });
  if (parts.length > 0) {
    return "?" + parts.join("&");
  } else {
    return "";
  }
}

//  ----------------------------------------
//  Operations
//  ----------------------------------------

function onlyFilteredFields(params) {
  return params;

  /**
   *  -----------------------------------------------------------------------
   *  [NC 2019-05-10] Probably not needed; filters have been removed. Keep in
   *  case of reversion.
   *  -----------------------------------------------------------------------
   *

  //  [NC 2019-05-06] Filter selections removed; made side-scrolling.
  let newParams = {
    'path': params['path'],
    'filters': params['filters'],
    'students': params['students'],
  };
  const filters = params['filters'];
  if (filters.includes('Prediction')) {
    newParams['bands'] = params['bands'];
    newParams['trend'] = params['trend'];
  }
  if (filters.includes('Flags')) {
    newParams['flags'] = params['flags'];
  }
  if (filters.includes('MAPS')) {
    newParams['maps'] = params['maps'];
  }
  if (filters.includes('Interactions')) {
    newParams['notes'] = params['notes'];
  }
  if (filters.includes('Campuses')) {
    newParams['campuses'] = params['campuses'];
  }
  if (filters.includes('GPA')) {
    newParams['GPA'] = params['GPA'];
  }
  if (filters.includes('Courses')) {
    newParams['courses'] = params['courses'];
  }
  if (filters.includes('Units')) {
    newParams['courses'] = params['courses'];
  }
  if (filters.includes('Schools')) {
    newParams['schools'] = params['schools'];
  }
  return newParams;

   *  -----------------------------------------------------------------------
   */
}

function onlyShownFields(order, show) {
  let fields = config.orderable.always.concat(
    config.orderable.always.map(key => "-" + key)
  );
  if (show.includes("student")) {
    fields = fields
      .concat(config.orderable.student)
      .concat(config.orderable.student.map(key => "-" + key));
  }
  if (show.includes("passRate")) {
    fields = fields
      .concat(config.orderable.passRate)
      .concat(config.orderable.passRate.map(key => "-" + key));
  }
  let result = order.filter(
    key =>
      fields.includes(key) ||
      key.startsWith("week-") ||
      key.startsWith("-week-")
  );
  //  [NC 2019-07-12] 'show' is replaced by preferences; this is obsolete
  //  if (!show.includes("weekly")) {
  //  result = result.filter(
  //  key => !key.startsWith("week-") && !key.startsWith("-week-")
  //  );
  //  }
  return result;
}

function differentParams(newParams, oldParams) {
  if (typeof newParams !== "object" || typeof oldParams !== "object") {
    console.log("params must be objects");
    return true;
  }
  if (
    differentLists(
      Object.getOwnPropertyNames(newParams),
      Object.getOwnPropertyNames(oldParams)
    )
  ) {
    return true;
  }
  const keys = Object.getOwnPropertyNames(newParams);
  for (const i in keys) {
    const key = keys[i];
    if (!Array.isArray(newParams[key]) || !Array.isArray(oldParams[key])) {
      console.log(
        "params must be objects of lists: " +
          JSON.stringify(newParams) +
          " " +
          JSON.stringify(oldParams) +
          " " +
          key
      );
      return true;
    }
    if (differentLists(newParams[key], oldParams[key])) {
      return true;
    }
  }
  return false;
}

function differentLists(list1, list2) {
  return JSON.stringify(list1) !== JSON.stringify(list2);
}

//  ----------------------------------------
//  Query parameters
//  ----------------------------------------

function toggleParams(params, key, value) {
  let newParams = JSON.parse(JSON.stringify(params));
  if (typeof params !== "object") {
    console.error("Params must be an object");
    return {};
  }
  if (newParams.hasOwnProperty(key)) {
    if (newParams[key].includes(value)) {
      newParams[key] = newParams[key].filter(_ => _ !== value);
    } else {
      newParams[key].push(value);
    }
  } else {
    newParams[key] = [value];
  }
  return newParams;
}

/**
 * TODO: Obsolete, check.
 */
function toggleSortParams(params, key, value) {
  let newParams = JSON.parse(JSON.stringify(params));
  if (typeof params !== "object") {
    console.error("Params must be an object");
    return {};
  }
  if (newParams.hasOwnProperty(key)) {
    if (newParams[key].includes(value)) {
      newParams[key] = newParams[key].filter(_ => _ !== value);
      newParams[key].push("-" + value);
    } else if (newParams[key].includes("-" + value)) {
      newParams[key] = newParams[key].filter(_ => _ !== "-" + value);
      newParams[key].push(value);
    } else {
      newParams[key].push(value);
    }
  } else {
    newParams[key] = [value];
  }
  return newParams;
}

function setParam(params, key, value) {
  let newParams = JSON.parse(JSON.stringify(params));
  if (Array.isArray(value)) {
    newParams[key] = value;
  } else {
    newParams[key] = [value];
  }
  return newParams;
}

function unsetParam(params, key) {
  let newParams = JSON.parse(JSON.stringify(params));
  delete newParams[key];
  return newParams;
}

function deleteParam(params, key, value) {
  let newParams = JSON.parse(JSON.stringify(params));
  if (newParams.hasOwnProperty(key)) {
    if (newParams[key].includes(value)) {
      newParams[key] = newParams[key].filter(_ => _ !== value);
    }
    if (newParams[key].includes("-" + value)) {
      newParams[key] = newParams[key].filter(_ => _ !== "-" + value);
    }
  }
  return newParams;
}

export {
  deleteParam,
  differentLists,
  differentParams,
  onlyShownFields,
  onlyFilteredFields,
  readQueryString,
  readDashboardQueryString,
  setParam,
  toggleParams,
  toggleSortParams,
  unsetParam,
  uriChars,
  uriLink,
  writeQueryString
};
