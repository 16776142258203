import React, { useContext } from 'react';
import { renderPercentageBar, renderLegendBar, getPercentageGraph, withWidget } from "../../../../utils/graph";
import { config } from '../../../../utils/config';
import { ModalContext } from '../../../../context/ModalContext';
import { LABEL_NOT_PARTICIPATED, LABEL_PARTICIPATED, LOG, VET_PARTICIPATED, VET_NOT_PARTICIPATED } from '../../../../utils/constants';
import { sendLog } from '../../../../utils/utils';
import useRouteParams from '../../../../hooks/useRouteParams';
import SelectedEnrollmentsContext from '../../../../context/SelectedEnrollmentsContext';
import { UnitWidgetModal } from '..';

const VetParticipationWidgetGraph = ({ enrollments, hover, handleMouseOver, handleMouseOut }) => {
    const { handleModal } = useContext(ModalContext);
    const { year, term, unit } = useRouteParams();
    const { clearAndAddMultipleSelectedEnrollments } = useContext(SelectedEnrollmentsContext);

    const total = enrollments.length;

    if (total === 0) {
        return (
            <div className="UnitWidget">
                <h4>VET Participation</h4>
                <div style={{marginTop: '40%',textAlign: 'center'}}>No data</div>
            </div>
        );
    }

    const participated = enrollments.reduce((acc, enrollment) => {
        if (VET_PARTICIPATED.includes(enrollment.vet_participation)) {
            acc++;
        }
        return acc;
    }, 0);

    const percent = Math.floor(100 * (participated/total));

    const data = [
        {
            label: LABEL_PARTICIPATED,
            total: percent,
            description: LABEL_PARTICIPATED,
            code: LABEL_PARTICIPATED,
        },
        {
            label: LABEL_NOT_PARTICIPATED,
            total: 100 - percent,
            description: LABEL_NOT_PARTICIPATED,
            code: LABEL_NOT_PARTICIPATED,
        }
    ];

    const graph = getPercentageGraph(
        data,
        [config.colors.cquGreen, config.colors.cquBlue],
        config.colors.cquBlue50,
        hover.code
    );

    const legend = graph.map((_) => {
        const [, color, obj] = _;
        return [
            obj.label,
            color,
            obj,
        ]
    });

    /**
     * Open the Modal on click.
     */
    const onClick = (data) => {
        const participation = data.label === LABEL_PARTICIPATED ? VET_PARTICIPATED.join(',') : VET_NOT_PARTICIPATED.join(',');
        const aggregated = enrollments.filter( ({vet_participation}) => {
            if (data.label === LABEL_PARTICIPATED) {
                return VET_PARTICIPATED.includes(vet_participation);
            }

            if (data.label === LABEL_NOT_PARTICIPATED) {
                return VET_NOT_PARTICIPATED.includes(vet_participation);
            }

            return false;
        });

        clearAndAddMultipleSelectedEnrollments(aggregated);
        handleModal( <UnitWidgetModal title={`VET Participation - ${participation}`} handleModal={handleModal} cohortFilter={`participation=${participation}`} /> );
        sendLog("App\\Events\\Widget\\Aggregated", 'r', LOG.ACTION.AGGREGATED, LOG.TARGET.ENROLLMENTS, LOG.DASHBOARD.UNIT, {
            term: {year,term},
            code: unit,
            aggregation: 'participation',
            value: data.label,
            aggregated: aggregated.length,
        });
    }

    return (
        <div className="UnitWidget">
            <h4>VET Participation</h4>
            <h1>{percent}%</h1>
            {renderPercentageBar(graph, { onClick, onMouseOver: handleMouseOver, onMouseOut: handleMouseOut, })}
            {renderLegendBar(legend, { onClick, onMouseOver: handleMouseOver, onMouseOut: handleMouseOut, })}
            <p><small>{participated} of {total} students have<br /><b>participation</b> in this term.</small></p>
        </div>
    )


}

export default withWidget(VetParticipationWidgetGraph);
