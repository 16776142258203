export const ASSESSMENT_ATTEMPT_STATUS = {
    MANUAL_SUBMISSION_GRADED: ['Manual Submission (Graded)'],
    MANUAL_SUBMISSION_NOT_GRADED: ['Manual Submission (Not graded)'],
    NO_DUE_DATE: ['No Due Date'],
    OVERDUE: ['Overdue'],
    SUBMITTED: ['Submitted'],
    SUBMITTED_NO_DUE: ['Submitted (no Due Date)'],
    SUBMITTED_LATE: ['Submitted Late'],
    UPCOMING: ['Upcoming'],
};
export const ASSESSMENT_DUE_IN_TERM = {
    NO_DUE_DATE: 2,
    DUE_IN_TERM: 1,
    NOT_DUE_IN_TERM: 0,
};
export const COUNT_SHOW_ERROR = -500;
export const COUNT_SHOW_DEFAULT = -1;
export const COUNT_SHOW_LOADING = -2;
export const COUNT_SHOW_DELAY = 600;
export const ONE_SECOND = 1000;
export const ONE_MINUTE = 60000;
export const ONE_HOUR = 3600000;
export const ONE_DAY = 86400000;
export const PAGINATOR_TYPE_OFFSET = 'offset';
export const PAGINATOR_TYPE_CURSOR = 'cursor';
export const PASS_RATE_NULL = -1;
export const LABEL_LOGIN = 'Log In';
export const LABEL_NO_LOGIN = 'No Log In';
export const LABEL_ENGAGED = 'Activity This Term';
export const LABEL_NOT_ENGAGED = 'No Activity This Term';
export const VET_PARTICIPATED = ['Y', 'Y*'];
export const VET_NOT_PARTICIPATED = ['N', 'N*', '-'];
export const LABEL_PARTICIPATED = 'Yes';
export const LABEL_NOT_PARTICIPATED = 'No';
export const CRM_CREATION_USERS_TO_REPLACE = ['mulesoft production', 'boomi production']; // Use lowercase only.
export const CURRENT_VET_YEAR_VALUE = 10000;
export const CURRENT_VET_YEAR_VALUE_STRING = 'current';
export const CURRENT_VET_YEAR_LABEL = 'Current';
export const LOG = {
    ACTION: {
        AGGREGATED: 'aggregated',
        DISCARD: 'discarded',
        FILTER: 'filtered',
        SELECT: 'selected',
        SORTED: 'sorted',
        VIEW: 'viewed',
        OPEN: 'opened',
    },
    TARGET: {
        ENROLLMENTS: 'enrolments',
        PAGE: 'page',
        TAB: 'tab',
        INTERACTION: 'interaction',
        MY_EXPERIENCE: 'my experience',
        CRM_CASES: 'crm cases',
    },
    DASHBOARD: {
        HOME: 'home',
        COHORT: 'cohort',
        STUDENT: 'student',
        UNIT: 'unit',
    },
};
export const TINYMCE_INIT_SETTINGS = {
    menubar: false,
    plugins: [
        'autolink',
        'link',
    ],
    toolbar: 'bold italic | link | alignleft aligncenter alignright alignjustify | outdent indent',
    paste_data_images: false,
}
export const INTERACTION_MODES = {
    EMAIL_PER_COURSE: 'course',
    EMAIL_PER_ENROLLMENT: 'enrollment',
    EMAIL_PER_SCHOOL: 'school',
    EMAIL_PER_UNIT: 'unit',
}
