import React, { Component } from "react";
import axios from "axios";
//  import debounce from "lodash/debounce";

import { Header, Spinner } from '../../components';
import {
  ClearCacheForm,
  ImportConfigForm,
  ImportJobsForm,
  ImportLogs,
  IncrementalLogs,
  PassRateForm,
  ActivityBandForm,
  MaintenanceForm,
  StrategiesForm,
} from './components';

import Error from "../Error/Error";

import { config as siteConfig } from "../../utils/config";

import { library } from "@fortawesome/fontawesome-svg-core";

//  Unused in this page
//  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faBell,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faBell,
);

class SiteConfig extends Component {

  constructor(props) {
    super(props);
    this.state = {
      config: null,
      calendar: null,
      imports: null,
      error: null,
      user: {},
      usernames: [],  // <-- for impersonate
      maintenance: {},
      notice: {},
      strategies: [],
      strategyStats: [],
      importLogs: [],
      roles: [],
      tests: {
        cdr: undefined,
        aims: undefined,
        crm: undefined,
        message: '',
      },
      loaded: false,
      incognito: props.incognito,
    };
  }

  componentDidMount() {
      axios.all([
        axios.get(siteConfig.system.baseApiUrl + "/config/strategies"),
        axios.get(siteConfig.system.baseApiUrl + "/success-strategies/stats"),
        axios.get(siteConfig.system.baseApiUrl + "/config/maintenance"),
        axios.get(siteConfig.system.baseApiUrl + "/config/notice"),
        axios.get(siteConfig.system.baseApiUrl + "/config/incremental-logs"),
        axios.get(siteConfig.system.baseApiUrl + "/config/import-logs"),
        axios.get(siteConfig.system.baseApiUrl + "/config/import-config"),
        axios.get(siteConfig.system.baseApiUrl + "/config/import-jobs"),
        axios.get(siteConfig.system.baseApiUrl + "/config/usernames"),
        axios.get(siteConfig.system.baseApiUrl + "/config/roles"),
        axios.get(siteConfig.system.baseApiUrl + "/config/pass-rates"),
        axios.get(siteConfig.system.baseApiUrl + "/config/activity-bands"),
      ])
      .then(axios.spread((
        resStrategies, resStrategyStats, resMaintenance, resNotice,
        resIncrementalLogs, resImportLogs, resImportConfig, resImportJobs, resUsernames, resRoles, resPassRates, resActivityBands
      ) => {
        this.setState({
          config: resStrategies.data.config,
          calendar: resStrategies.data.calendar,
          imports: resStrategies.data.imports,
          importConfig: resImportConfig.data.result,
          importJobs: resImportJobs.data.result,
          importLogs: resImportLogs.data.result,
          incrementalLogs: resIncrementalLogs.data.result,
          maintenance: resMaintenance.data.result,
          notice: resNotice.data.result,
          strategies: resStrategies.data.result,
          strategyStats: resStrategyStats.data.result,
          user: resStrategies.data.user,
          usernames: resUsernames.data.result,
          roles: resRoles.data.result,
          tests: {
            message: '',
          },
          loaded: true,
          passRates: resPassRates.data.result,
          activityBands: resActivityBands.data.result,
        });
      }))
      .catch(error => {
        console.log(error);
        this.setState({error});
      });
  }

  formatUserOptions(items) {
    const options = items.map(item => {
      const tags = [
        item.lastname + ', ' + item.firstname,
        item.level,
        item.username,
        item.moodle_db,
      ];
      return {
        value: item.username,
        label: tags.join(' : ')
      };
    });
    return options;
  }

  render() {

    if (this.state.error !== null) {
      return (
        <Error
          error={this.state.error}
          config={this.state.config}
        />
      );
    }

    if (!this.state.loaded) {
      return <Spinner />;
    }

    const {
      user,
      config,
      calendar,
      imports,
      importConfig,
      importJobs,
      importLogs,
      incrementalLogs,
      maintenance,
      strategies,
      strategyStats,
      incognito,
      usernames
    } = this.state;

    const usernameOptions = this.formatUserOptions(usernames);

    return (
      <React.Fragment>
        <Header
          config={config}
          calendar={calendar}
          imports={imports}
          user={user}
          breadcrumbs={[
            ["Admin"],
            ["Site Configuration"],
          ]}
          services={[
            ["Success Strategies", "/success-strategies", faBell],
          ]}
          incognito={incognito}
        />
        <main>
          <article className="siteConfig">
            <section>
              <h4>
                Dashboard config
              </h4>
              <PassRateForm initialValues={this.state.passRates}/>
              <ActivityBandForm initialValues={this.state.activityBands}/>
            </section>
            <section>
              <h4>
                Strategy Names
              </h4>
              <StrategiesForm initialValues={strategies} />
            </section>
            <section>
              <h4>
                Import Config
              </h4>
              <ImportConfigForm initialValues={importConfig} />
            </section>
            <section>
              <h4>
                Import Jobs
              </h4>
              <ImportJobsForm initialValues={importJobs} />
            </section>
            <section>
              <h4>
                Success Strategies
              </h4>
              <table className="oddEvenRows">
                <tbody>
                  <tr>
                    <th colSpan={2}>Stats</th>
                  </tr>
                  <tr>
                    <td>Entered</td>
                    <td>{strategyStats.entered}</td>
                  </tr>
                  <tr>
                    <td>Enabled</td>
                    <td>{strategyStats.enabled}</td>
                  </tr>
                  <tr>
                    <td colSpan={2} style={{
                      textAlign: 'center',
                      padding: '1rem',
                    }}>
                      <button
                        className="primaryButton"
                        onClick={(event) => {
                          window.location.href = '/success-strategies';
                        }}
                      >
                        List Strategies
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </section>
            <section>
              <h4>
                Impersonate User
              </h4>
              <table style={{width: '18rem'}} className="oddEvenRows">
                <tbody>
                  <tr>
                    <td>
                      <select
                        id="impersonate_username"
                        className="form-element"
                      >
                        { usernameOptions.map(_ => {
                          if (_.level === 'admin') return undefined;
                          return <option value={_.value}>{_.label}</option>
                        })}
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>This will log you in as a given user;<br/>when finished, logout and log back in<br/>as admin again.</td>
                  </tr>
                  <tr>
                    <td>
                      <button
                        onClick={() => {
                          let username = document.getElementById('impersonate_username').value;
                          const url = siteConfig.system.baseApiUrl + '/config/impersonate/' + username;
                          axios.get(url)
                            .then(res => {
                              window.location = '/';
                            })
                            .catch(err => {
                              console.log(err);
                              alert('Could not log you in.');
                            });
                        }}
                      >
                        Impersonate
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </section>
            <section>
              <h4>
                Maintenance Mode
              </h4>
              <MaintenanceForm initialValues={maintenance} />
            </section>
            <section>
              <h4>
                Manage user roles
              </h4>
              <table style={{width: '18rem'}} className="oddEvenRows">
                <tbody>
                  <tr>
                    <td>
                      <select id="manage_role_username" className="form-element">
                        { usernameOptions.map(_ => (
                        <option value={_.value}>{_.label}</option>
                        ))}
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <select id="manage_role_role" className="form-element">
                        {this.state.roles.map( role => (<option value={role.value}>{role.label}</option>))}
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>Apply the selected role to user. The following roles are persistent: "admin", "trainer" and "inactive". This means that user with this role will maintain it's level after new imports. If the user doesn't exist in the new import, they will be created if they have a persistent role.</td>
                  </tr>
                  <tr>
                    <td>
                      <button
                        onClick={() => {
                          const username = document.getElementById('manage_role_username').value;
                          const role = document.getElementById('manage_role_role').value;
                          const url = siteConfig.system.baseApiUrl + '/config/roles';

                          axios.post(url, {
                            role,
                            username
                          }).then(res => {
                            if (res.data.error) {
                              console.log(res.data.error);
                            }
                            const newUsernames = this.state.usernames.map( user => {
                              if (user.username === username) {
                                user.level = role;
                              }
                              return user;
                            })
                            this.setState({usernames: newUsernames});
                          })
                          .catch(err => {
                            console.log(err);
                          });
                        }}>
                        Apply
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </section>
            <section>
              <h4>
                Imports Log
              </h4>
              <ImportLogs import={importLogs} />
            </section>
            <section>
              <h4>
                Incremental Log
              </h4>
              <IncrementalLogs import={incrementalLogs} />
            </section>
            <section>
              <h4>
                Clear application cache
              </h4>
              <ClearCacheForm />
            </section>
          </article>
        </main>
      </React.Fragment>
    );
  }
}

export default SiteConfig;
