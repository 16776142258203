import React, { createContext, useEffect, useState } from "react";
import { fetchTermCampuses, fetchTermColleges, fetchTermSchools, fetchTermCourses, fetchTermUnits, fetchTerms } from "../utils/fetch";
import { isCurrent } from '../utils/utils';
import { CURRENT_VET_YEAR_VALUE } from "../utils/constants";
/**
 * AssetsContext contains items used amongst many components shown on multiple pages. The assets contains objects such as but not limited to units, student or colleges.
 */
const AssetsContext = createContext(null);

/**
 * This FunctionComponent is the Provider that gives access to the context to its children.
 *
 * @param {JSX.Element} children Child components, which will have access to assets.
 * @param {number} year The year of the term to fetch
 * @param {string} term The term of the term to fetch
 */
const AssetsContextProvider = ({ children, year, term }) => {
    const [error, setError] = useState(undefined);

    const [campuses, setCampuses] = useState([]);
    const [colleges, setColleges] = useState([]);
    const [schools,  setSchools]  = useState([]);
    const [courses,  setCourses]  = useState([]);
    const [units,    setUnits]    = useState([]);
    const [terms,    setTerms]    = useState([]);
    const [termInfo, setTermInfo] = useState([]);

    const [campusesLoaded, setCampusesLoaded] = useState(false);
    const [collegesLoaded, setCollegesLoaded] = useState(false);
    const [schoolsLoaded,  setSchoolsLoaded]  = useState(false);
    const [coursesLoaded,  setCoursesLoaded]  = useState(false);
    const [unitsLoaded,    setUnitsLoaded]    = useState(false);
    const [termsLoaded,    setTermsLoaded]    = useState(false);

    // Load the assets.
    useEffect(() => {
        fetchTermCampuses(year, term).then( campuses => {
            setCampuses(campuses);
            setCampusesLoaded(true);
        }).catch( error => setError(error) );

        fetchTermColleges(year, term).then( colleges => {
            setColleges(colleges);
            setCollegesLoaded(true);
        }).catch( error => setError(error) );

        fetchTermSchools(year, term).then( schools => {
            setSchools(schools);
            setSchoolsLoaded(true);
        }).catch( error => setError(error) );

        fetchTermCourses(year, term).then( courses => {
            setCourses(courses);
            setCoursesLoaded(true);
        }).catch( error => setError(error) );

        fetchTermUnits(year, term).then( units => {
            setUnits(units);
            setUnitsLoaded(true);
        }).catch( error => setError(error) );

        fetchTerms().then( terms => {
            setTerms(terms);
            // Here is some handy information that consumers of the assets may be interested in.
            // 'current' means that the term/week dates include today.
            // 'displayed' means the term that has been selected to show on the page.
            const parsedYear = year == CURRENT_VET_YEAR_VALUE ? new Date().getFullYear() : parseInt(year);

            const displayedTerm =  terms.find(t => t.year === parsedYear && t.term === term);
            const displayedIsCurrent =  isCurrent(displayedTerm);
            const displayedIsHE = term !== 'VET';
            const displayedIsVET = term === 'VET';
            const currentVETTerm = terms.find(t => t.term === 'VET' && isCurrent(t));
            const currentHETerm = terms.find(t => t.term !== 'VET' && isCurrent(t)) || currentVETTerm;
            const currentHEWeek =  currentHETerm.weeks.data.find( w => isCurrent(w));
            const currentVETWeek = currentVETTerm.weeks.data.find( w => isCurrent(w));
            setTermInfo({
                displayedTerm: displayedTerm,
                displayedIsCurrent: displayedIsCurrent,
                displayedIsHE: displayedIsHE,
                displayedIsVET: displayedIsVET,
                currentHETerm: currentHETerm,
                currentVETTerm: currentVETTerm,
                currentHEWeek: currentHEWeek,
                currentVETWeek: currentVETWeek,
            });
            setTermsLoaded(true);
        }).catch( error => setError(error) );
    }, [year, term])

    // Create the assets object that will be passed through.
    const isLoading = !(campusesLoaded && collegesLoaded && schoolsLoaded && coursesLoaded && unitsLoaded && termsLoaded);
    const isError   = error !== undefined;
    const isSuccess = !isError && !isLoading;
    const assets = {
        isLoading,
        isSuccess,
        isError,
        error,
        campuses,
        colleges,
        schools,
        courses,
        units,
        terms,
        termInfo,
    };

    return (
        <AssetsContext.Provider value={assets}>
            {children}
        </AssetsContext.Provider>
    );
};

export { AssetsContext, AssetsContextProvider };
