import React from 'react';
import { InteractionListItem } from './index';

const InteractionList = ({ items }) => (
    <div className="feedbackList">
        <h4>Interactions ({items.length})</h4>
        {items.map((item, index) => <InteractionListItem item={item} key={index} />)}
    </div>
)

export default InteractionList;