import React from 'react';
import { config } from '../../utils/config';
import { filterIntegers, renderXLabel, renderYLabel, withWidget } from '../../utils/graph';
import { Hint, HorizontalGridLines, VerticalBarSeries, XYPlot, XAxis, YAxis } from 'react-vis';

const GpaBandsGraph = (props) => {
    const {
        data,
        hover,
        handleClick,
        handleMouseOver,
        handleMouseOut,
        isClickable,
    } = props;
    const title = props.title ? props.title : "GPA Bands";
    const cohort = props.cohort ? props.cohort : "students";
    const graph = Object.entries(data).map((parts) => {
        const [key, value] = parts;
        const color = (hover && hover.code === key) ? config.colors.cquBlue50 : config.colors.cquGreen;
        return {
            x: key,
            y: value,
            color,
            code: key,
            description: key,
        };
    });
    const hasData = graph.filter((_) => {
        return (_.y > 0);
    }).length > 0;

    const plot = {
        width: 300,
        height: 280,
        left: 60,
        bottom: 60,
    };

    return (
        <div className="UnitWidget">
            <h4>{title}</h4>
            <XYPlot
                height={plot.height}
                width={plot.width}
                xType="ordinal"
                className={hasData ? "" : "empty"}
                margin={{
                    left: plot.left,
                    bottom: plot.bottom,
                }}
            >
                {hover && (
                    <Hint
                        value={hover}
                        className='plotTooltip'
                    >
                        <div>
                            <h3>{hover.x}</h3>
                            <p>{hover.y}</p>
                        </div>
                    </Hint>
                )}
                <XAxis
                    height={plot.bottom}
                />
                {hasData && (
                    <YAxis
                        width={plot.left}
                        tickFormat={filterIntegers}
                    />
                )}
                {renderXLabel("GPA Band", plot)}
                {renderYLabel("Count of " + cohort, plot)}
                <HorizontalGridLines />
                <VerticalBarSeries
                    colorType={"literal"}
                    //color={config.colors.cquGreen}
                    className={isClickable ? "clickable" : ""}
                    data={graph}
                    onValueClick={handleClick}
                    onValueMouseOver={handleMouseOver}
                    onValueMouseOut={handleMouseOut}
                />
            </XYPlot>
            {!hasData && (
                <div style={{
                    position: 'relative',
                    bottom: '160px',
                }}>
                    No data
                </div>
            )}
        </div>
    );
}

export default withWidget(GpaBandsGraph);