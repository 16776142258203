import React, { useEffect, useState} from 'react';
import axios from 'axios';
import { config } from '../../../../utils/config';

const StudentAvatar = ({incognito, studentId}) => {
    const [backgroundImage, setBackgroundImage] = useState('url(/anonymous.jpg)');

    /**
     * Only execute when studentId changes.
     */
    useEffect( () => {
        if (studentId === null) {
            return;
        }
        axios.get(`${config.system.baseApiUrl}/studentImage/${studentId.toLowerCase()}`)
            .then( res => {
                if (res.status !== 200) {
                    return;
                }
                setBackgroundImage(`url(${res.data}), url(/anonymous.jpg)`);
            })
            .catch(err => {
                console.log(err);
            });
    }, [studentId]);

    if (incognito) {
        return <section style={{ padding: '1rem'}}>
            <div className='avatar-circle' style={{backgroundImage: 'url(/anonymous.jpg)', backgroundSize: 'cover,cover'}} />
        </section>;
    }

    return <section style={{ padding: '1rem'}}>
        <div className='avatar-circle' style={{backgroundImage: backgroundImage, backgroundSize: 'cover,cover'}} />
    </section>;
}

export default StudentAvatar;