import React from 'react';
import { config } from '../../../utils/config';
import { unsetParam, uriLink, toggleParams } from '../../../utils/uriParams';
import { getValueByProperty, make_object } from '../../../utils/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { faSquare as faSquareOutline, } from "@fortawesome/free-regular-svg-icons";
import { RadialChart } from 'react-vis';
import { FilterLink } from '../../../components';
import { Link } from 'react-router-dom';
import useCustomParams from '../../../hooks/useCustomParams';

const SCHEMA = {
    'Low': config.colors.low,
    'Medium': config.colors.medium,
    'High': config.colors.high,
    '-': config.colors.cquLightCharcoal
};

const FilterPassRate = ({ title, propName, paramName, sort, asset, loaded, filtered, hash }) => {
    const params = useCustomParams();

    if (params[paramName] === undefined) {
        return (
            <React.Fragment>
                <h4>FilterBox</h4>
                <p>Missing param: {paramName}</p>
                <div>{JSON.stringify(Object.keys(params))}</div>
            </React.Fragment>
        );
    }

    const passRateSummary = filtered.reduce((acc, prediction) => {
        const value = getValueByProperty(propName, prediction);
        if (value !== undefined && value !== null) {
            if (Array.isArray(value)) {
                value.forEach(legendKey => (acc[legendKey] += 1));
            } else {
                acc[value] += 1;
            }
        }
        return acc;
    }, make_object(config.passRates, 0));

    const chartData = config.passRates.reduce((acc, key) => {
        if (passRateSummary[key] > 0) {
            acc.push({
                angle: passRateSummary[key],
                label: key,
                color: SCHEMA[key]
            });
        }
        return acc;
    }, []);


    const linkToUnsetFilter = uriLink(unsetParam(params, paramName), config.defaultParams);
    const allParams = Object.keys(passRateSummary).reduce((carry, key) => {
        const params = toggleParams(carry, paramName, key);
        return params;
    }, params);


    const linkToSetAllFilter = uriLink(allParams, config.defaultParams);
    return (
        <section className="filterBox">
            <h4>
                <span style={{ cursor: 'pointer', }} title="Select All">
                    {params[paramName].length > 0 ? (
                        <Link to={linkToUnsetFilter}>
                            <FontAwesomeIcon
                                style={{
                                    maxWidth: '1rem',
                                    paddingRight: '.265rem',
                                    color: config.colors.cquGreen,
                                }}
                                icon={faTimesCircle}
                            />
                            {title}
                        </Link>
                    ) : (
                        <Link to={linkToSetAllFilter}>
                            <FontAwesomeIcon
                                style={{
                                    maxWidth: '1rem',
                                    paddingRight: '.39rem',
                                    color: config.colors.cquBlue50,
                                }}
                                icon={faSquareOutline}
                            />
                            {title}
                        </Link>
                    )}
                </span>
            </h4>
            <table>
                <tbody>
                    <tr>
                        <td>
                            <div style={{ maxHeight: 180 }} >
                                <div style={{ position: 'relative', zIndex: 10, top: 0, left: 0 }}>
                                    <RadialChart
                                        radius={70}
                                        innerRadius={62}
                                        colorType={"literal"}
                                        data={chartData}
                                        width={180}
                                        height={180}
                                    />
                                </div>
                                <div style={{ position: 'relative', zIndex: 10, top: -160, left: 0 }}>
                                    <div>
                                        <div className="featuredFraction">
                                            <div className="featuredFractionNumerator">{filtered.length}</div>
                                            <div className="featuredFractionDenominator">{loaded.length}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div className="verticalScroll" style={{ maxHeight: '10rem' }}>
                                <div className="Ctable dataTable">
                                    {Object.keys(passRateSummary).map((key, index) => (
                                        <div className="row" key={index}>
                                            <div className="col">
                                                <FilterLink
                                                    label={key}
                                                    paramName={paramName}
                                                    params={params}
                                                    scheme={SCHEMA}
                                                    total={passRateSummary}
                                                    legend={config.passRates}
                                                />
                                            </div>
                                            <div className="col">{passRateSummary[key]}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </section>
    );
}

export default FilterPassRate;