import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import { config } from '../../../../utils/config';

const FiltersActiveCard = ({ filtered, total, onClear }) => (
    <div className="UnitFilterCard">
        <div style={{
            backgroundColor: '#67712e',
            color: 'white',
            padding: '1rem',
            borderTop: 'solid 1px white',
            borderBottom: 'solid 1px white',
        }}>
            <div style={{
                float: 'right'
            }}>
                <Link to={window.location.pathname} style={{color: 'white'}} onClick={onClear}>
                    <FontAwesomeIcon
                        icon={faTimesCircle}
                        style={{
                            color: config.colors.high,
                            paddingRight: '5px',
                        }}
                    /> Clear
                </Link>
            </div>
            <div>
                <b>Filters active</b> &mdash; {filtered} / {total} Students
            </div>
        </div>
    </div>
)

export default FiltersActiveCard;