import React, { Component } from 'react';
import { config } from '../../utils/config';
import { faSpinner, faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const containerStyle = {
  width: '100vw',
  height: '100vh',
  display: 'grid',
  placeItems: 'center',
  fontSize: '1rem',
  color: config.colors.cquLightCharcoal,
  background: config.colors.cquCharcoal,
  position: 'fixed',
  left: 0,
  top: 0,
}

class Spinner extends Component {

  constructor(props) {
    super(props);
    this.state = {
      timerID: null,
      timeout: false,
    };
  }

  render() {
    if (this.state.timeout) {
      return (
        <div style={containerStyle}>
          <center>
            <div style={{fontSize: '2rem'}}>
            <FontAwesomeIcon icon={faClock} style={{ paddingRight: '1rem' }} /> No response
            </div>
            <div style={{ paddingTop: '1em', maxWidth: '74ch' }}>
              This is taking longer than expected,<br />
              Please try a more specific search
            </div>
          </center>
        </div>
      );
    } else {
      return (
        <div style={containerStyle}>
          <center>
            <FontAwesomeIcon icon={faSpinner} className={'fa-spin'} style={{ fontSize: '5rem' }} />
            <div style={{ paddingTop: '1em', maxWidth: '74ch' }}>
              {this.props.text}
            </div>
          </center>
        </div>
      );
    }
  }
}

export default Spinner;