import moment from 'moment-timezone';
moment.tz.setDefault('Australia/Brisbane');

// calendar functions used by graphs or filters

const getWeekFromDate = (date, calendar) => {
  /*
  for (const week of calendar.term_weeks) {
    if (date >= week.begin_date && date <= week.end_date) {
      return week;
    }
  }
  return null;
  */
  return calendar.term_weeks.find((week) => {
    return (date >= week.begin_date && date <= week.end_date);
  })
}

const getWeekFromNumber = (num, calendar) => {
  return calendar.term_weeks.find((week) => {
    return (week.calendar_week_number === parseInt(num, 10));
  })
}

function mapBeginDateToWeeks(begin_date, weeks, elseShow='·')
{
  if (moment(begin_date).year() === 1970) {
    return 'No Due Date';
  }
  //  Mark only weeks in the integer range 1..12 and breaks
  for (const index in weeks) {
    const term_week = weeks[index];
    if (term_week.start === begin_date) {
      if (['BR', 'B2', 'B3', 'B4'].includes(term_week.short_name)) {
        return term_week.short_name;
      }
      const i = parseInt(term_week.short_name, 10);
      if (!isNaN(i) && i > 0 && i < 13) {
        return term_week.short_name;
      }
    }
    if (begin_date === term_week.end){
      return 'Not Due In Term';
    }
  }
  return elseShow;
}

function mapBeginDateToShortDates(begin_date, weeks, elseShow='·')
{
  if (moment(begin_date).year() === 1970) {
    return 'No Due Date';
  }
  //  Mark only weeks in the integer range 1..12 and breaks
  for (const index in weeks) {
    const term_week = weeks[index];
    if (term_week.start === begin_date) {
      if (['BR', 'B2', 'B3', 'B4'].includes(term_week.short_name)) {
        return term_week.short_name;
      }
      return moment(begin_date).format('D MMM');
    }
    if (begin_date === term_week.end){
      return 'Not Due In Term';
    }
  }
  return elseShow;
}

function trimCalendarToTerm(calendar)
{
  const newCalendar = {
    ...calendar,
    'term_weeks': calendar.term_weeks.filter(term_week => {
      if (term_week.short_name.startsWith('B')) {
        return true; // BR, B2, etc
      }
      const int = parseInt(term_week.short_name);
      if (int >= 0 && int <= 12) {
        return true;
      }
      return false;
    }),
  };
  return newCalendar;
}

export {
  getWeekFromDate,
  getWeekFromNumber,
  mapBeginDateToWeeks,
  mapBeginDateToShortDates,
  trimCalendarToTerm,
}
