import React from 'react'
import Select from 'react-select'
import useStudentCourses from "../../../hooks/useStudentCourses";

const MAPSelect = ({ enabled, value, year, term, onChange }) => {
    const {data, isLoading, isError, isSuccess} = useStudentCourses(year, term);

    // a set for unique map values
    const uniqueMapValues = new Set();

    if (isSuccess) {
        data.forEach((element) => {
            uniqueMapValues.add(element.map);
        });
    }

    // sort the map values alphabetically
    const uniqueMapValuesAsArray = Array.from(uniqueMapValues);
    uniqueMapValuesAsArray.sort();

    // create options array from unique map values
    const options = Array.from(uniqueMapValuesAsArray, (mapValue) => ({ value: mapValue, label: mapValue }));

    return (
        <div className='leftThirdPadded'>
            <Select
                isMulti
                isDisabled={!enabled || isLoading || isError || data.length === 0}
                isLoading={isLoading}
                options={options}
                value={value}
                placeholder="MAPs ..."
                onChange={onChange}
                classNamePrefix="react-select"
            />
        </div>
    );
}

export default MAPSelect;
