import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { CURRENT_VET_YEAR_VALUE, CURRENT_VET_YEAR_VALUE_STRING } from '../../../utils/constants';

const DashboardButton = ({ selTerm, user, units, courses, colleges }) => {
    const [count, setCount] = useState(0);

    useEffect( () => {
        setCount(0 + units?.length + courses?.length + colleges?.length);
    }, [units, courses, colleges]);

    // Return as early as possible.
    if (count === 0 || count > 1) {
        return <></>;
    }

    const { term } = selTerm;
    const year = selTerm.year === CURRENT_VET_YEAR_VALUE ? CURRENT_VET_YEAR_VALUE_STRING : selTerm.year;

    if (units?.length === 1) {
        const {value: code} = units[0]; // Get the code from the selection.

        return (
            <Link to={`unit/${year}/${term}/${code}`}>
                <button id="showMatchesButton" className="primaryButton">
                    Show Unit Dashboard <FontAwesomeIcon icon={faChevronRight} />
                </button>
            </Link>
        );
    }

    if (courses?.length === 1) {
        const {value: code} = courses[0]; // Get the code from the selection.

        return (
            <Link to={`course/${year}/${term}/${code}`}>
                <button id="showMatchesButton" className="primaryButton">
                    Show Course Dashboard <FontAwesomeIcon icon={faChevronRight} />
                </button>
            </Link>
        );
    }

    if (colleges?.length === 1) {
        const {value: code} = colleges[0]; // Get the code from the selection.

        return (
            <Link to={`college/${year}/${term}/${code}`}>
                <button id="showMatchesButton" className="primaryButton">
                    Show College Dashboard <FontAwesomeIcon icon={faChevronRight} />
                </button>
            </Link>
        );
    }

    // This shouldn't be reached.
    return <></>;

}

export default DashboardButton;