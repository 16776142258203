import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { config } from "../../utils/config"
import "../../../node_modules/react-vis/dist/style.css";
import {
  make_object,
} from "../../utils/utils";
import {
  getWeekFromDate,
} from "../../utils/calendar";
import {
  gpaBand,
  lastLoginBand,
  residency,
  sesStatus,
  studyMode,
} from "../../utils/bands";

import { CourseCreditOrgsWidget, EngagedWhileLearningWidget, EntryPathwaysWidget, WeeklyLoginsWidget, UnitsInteractionsWidget, RecentLoginsWidget, GpaBandsWidget, InterventionStrategyWidget, Header, DashboardFilter, DashboardInfo, Spinner, ModalBackground } from '../../components';
import Error from '../Error/Error';
import {
  uriLink,
  writeQueryString,
} from "../../utils/uriParams";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faArrowDown,
  faArrowUp,
  faPollH,
  faTimesCircle as faClose,
  faUserClock as faStudentActivity,
} from "@fortawesome/free-solid-svg-icons";
import {
  aggregateByActivity,
  aggregateByActivityThisWeek,
  aggregateByProp,
  withDashboard,
} from "../../utils/Dashboard";

library.add(
  faArrowDown,
  faArrowUp,
  faClose,
  faPollH,
  faStudentActivity,
);

const apiUrl = (props) => {
  const url = [
    config.system.baseApiUrl,
    "college",
    props.match.params.moodleCode,
    props.match.params.college,
  ].join('/');
  console.log(url, props.match.params);
  return url;

}

const filterStudents = (list, params) => {
  if (list === null) {
    return [];
  } else {
    const minAge = params.minAge.length === 0 ? null : parseInt(params.minAge[0]);
    const maxAge = params.maxAge.length === 0 ? null : parseInt(params.maxAge[0]);
    const campuses = new Set(params.campuses);
    const courseCampuses = new Set(params.courseCampuses);
    //const courses = new Set(params.courses);
    const units = new Set(params.units);
    const gender = new Set(params.gender);
    const lastLogin = new Set(params.lastLogin);
    const maps = new Set(params.maps);
    const groups = new Set(params.groups);
    const residency = new Set(params.residency);
    const sesStatus = new Set(params.sesStatus);
    const studyMode = new Set(params.studyMode);
    const studyPeriod = new Set(params.intakeMonth);
    return list
      .filter(_ => {
        return minAge === null || _.age >= minAge;
      })
      .filter(_ => {
        return maxAge === null || _.age <= maxAge;
      })
      .filter(_ => {
        return campuses.size === 0 || campuses.has(_.campusCode);
      })
      .filter(_ => {
        return courseCampuses.size === 0 || courseCampuses.has(_.courseCampusCode);
      })
      //.filter(_ => {
      //  return courses.size === 0 || courses.has(_.courseCode);
      //})
      .filter(_ => {
        return units.size === 0 || units.has(_.unitCode);
      })
      .filter(_ => {
        return gender.size === 0 || gender.has(_.gender);
      })
      .filter(_ => {
        return lastLogin.size === 0 || lastLogin.has(_.lastLoginBand);
      })
      .filter(_ => {
        return maps.size === 0 || maps.has(_.map);
      })
      .filter(_ => {
        return residency.size === 0 || residency.has(_.residency);
      })
      .filter(_ => {
        return sesStatus.size === 0 || sesStatus.has(_.sesStatus);
      })
      .filter(_ => {
        return studyMode.size === 0 || studyMode.has(_.studyMode);
      })
      .filter(_ => {
        return studyPeriod.size === 0 || studyPeriod.has(_.studyPeriodCode);
      })
      .filter(_ => {
        return (
          groups.size === 0 || _.groupNames.some(group => groups.has(group))
        );
      })
  }
};

const aggregateByCourseCreditOrgs = (students) => {
  const courseCreditOrgs = students.reduce((acc, enrol) => {
    Object.keys(enrol.courseCreditOrgs).forEach((_) => {
      if (_ in acc) {
        acc[_].push(enrol);
      } else {
        acc[_] = [enrol];
      }
    });
    return acc;
  }, {});
  return courseCreditOrgs;
};

const aggregateStudents = ({students}) => {
  const distinctStudents = Object.values(students.reduce((acc, _) => {
    // get distinct students from multiple enrollments.
    // don't care which enrollment we pick for each student because this is
    // used for overall course props that are not unit-specific
    if (_.code in acc) {
      // ignore
    } else {
      acc[_.code] = _;
    }
    return acc;
  }, {}));

  return {
    // NOTE: some aggregate are course based and use DISTINCT student lists
    //courses: aggregateByProp(students, 'courseCode'),
    activity: aggregateByActivity(students),
    activityThisWeek: aggregateByActivityThisWeek(students),
    basisOfAdmission: aggregateByProp(distinctStudents, 'basisOfAdmissionCode'),
    enrolWeeks: aggregateByProp(students, 'enrolWeek'),
    gpaBands: aggregateByProp(distinctStudents, 'gpaBand'),
    loginBands: aggregateByProp(distinctStudents, 'lastLoginBand'),
    units: aggregateByProp(students, 'unitCode'),
    courseCreditOrgs: aggregateByCourseCreditOrgs(distinctStudents),
  };
}

const getDistinctStudentCodes = (students) => {
  const distinctStudents = students.reduce((set, _, index) => {
    set.add(_.code);
    return set;
  }, new Set());
  return distinctStudents;
}

const getDistinctUnitCodes = (students) => {
  const distinctUnits = students.reduce((set, _, index) => {
    set.add(_.unitCode);
    return set;
  }, new Set());
  return distinctUnits;
}

const loadStudents = (res, params) => {
  const data = res.data;
  let cached,
    loaded,
    filtered,
    aggregated,
    allAggregated,
    college,
    distinctStudentCount,
    distinctUnitCount,
    units,
    logins,
    cohortPath;
  if (data.result.students) {
    cached = data.result.cached;
    loaded = data.result.students;
    loaded.forEach(_ => {
      _.lastLoginBand = lastLoginBand(_.lastLogin, data.calendar.this_week);
      if (_.weekly.length === 0) {
        _.activityThisWeek = null;
      } else {
        const week = _.weekly[ _.weekly.length - 1 ];
        _.activityThisWeek = week.activity;
      }
      _.gpaBand = gpaBand(_.GPA);
      const enrolWeek = getWeekFromDate(_.enrolDate, data.calendar);
      _.enrolWeek = enrolWeek ? enrolWeek.begin_date : null;
      _.residency = residency(_.flags);
      _.studyMode = studyMode(_.campusCode);
      _.sesStatus = sesStatus(_.flags);
    });
    filtered = filterStudents(loaded, params);
    aggregated = aggregateStudents({students: filtered});
    allAggregated = aggregateStudents({students: loaded});
    college = data.result.college;
    cohortPath = `_${data.result.moodle_db},!${data.result.college.code}`;
    distinctStudentCount = getDistinctStudentCodes(loaded).size;
    distinctUnitCount = getDistinctUnitCodes(loaded).size;
    units = data.result.units;
    logins = data.result.logins;
  }
  return({
    params,
    config: data.config,
    calendar: data.calendar,
    imports: data.imports,
    error: data.error,
    college,
    cohortPath,
    units,
    logins,
    loaded,
    filtered,
    aggregated,
    allAggregated,
    distinctStudentCount,
    distinctUnitCount,
    user: data.user,
    interactions: data.result.interactions,
    interactions_sent: data.result.interactions_sent,
    isLoading: false,
    cached,
  });
};

const CollegeDashboard = (props) => {
  const getAcademicLoginsByWeek = (logins, calendar) => {
    const validWeekDates = calendar.term_weeks.map(week => week.begin_date);
    const academicLogins = logins.reduce((acc, _) => {
      if (_.begin_date in acc) {
        acc[ _.begin_date ] += _.total;
      }
      return acc;
    }, make_object(validWeekDates, 0));
    const academicLoginsByWeek = Object.entries(academicLogins).map((parts) => {
      const [begin_date, total] = parts;
      return {
        begin_date,
        total
      }
    })
    return academicLoginsByWeek;
  }

  const getEntryPathways = (aggregated) => {
    const agg = Object.entries(aggregated).map((parts) => {
      const [key, enrols] = parts;
      return {
        total: enrols.length,
        code: key,
        name: enrols[0].basisOfAdmission,
      }
    });
    return agg;
  }

  const getUnitsSummary = (aggregated) => {
    // create Units summary as object indexed by unitCode
    // this aggregates data for:
    // - passRate average
    // - overall band
    // - interactions totals
    // requires these variables in scope:
    // - siteConfig, units, thisWeek

    const unitsMap = Object.entries(aggregated).reduce((acc, parts) => {
      const [unitCode, enrols] = parts;
      const unitSum = enrols.reduce((accSum, enrol) =>{
        //accSum.passRate += enrol.passRate;
        // interaction sums
        accSum.meetingsTotal += enrol.meetingsTotal;
        accSum.emailsTotal += enrol.emailsTotal;
        accSum.notesTotal += enrol.notesTotal;
        // count International students
        accSum.international += enrol.flags.includes("International") ? 1 : 0;
        // count First Term students
        accSum.firstTerm += enrol.flags.includes("1stTerm") ? 1 : 0;
        // count Previous Attempt students
        accSum.previousAttempt += enrol.previousAttempts > 0 ? 1 : 0;
        accSum.loginWeek += enrol.lastLoginBand === "This week" ? 1 : 0;
        return accSum;
      }, make_object([
        'meetingsTotal',
        'emailsTotal',
        'notesTotal',
        'international',
        'firstTerm',
        'previousAttempt',
        'loginWeek'
      ], 0));

      acc[unitCode] = {
        code: unitCode,
        //passRatePC: Math.floor(unitSum.passRate/enrols.length),
        enrolCount: enrols.length,
        interactionsTotal: unitSum.meetingsTotal + unitSum.emailsTotal + unitSum.notesTotal,
        meetingsTotal: unitSum.meetingsTotal,
        emailsTotal: unitSum.emailsTotal,
        notesTotal: unitSum.notesTotal,
        internationalPC: Math.floor(100*unitSum.international/enrols.length),
        firstTermPC: Math.floor(100*unitSum.firstTerm/enrols.length),
        previousAttemptPC: Math.floor(100*unitSum.previousAttempt/enrols.length),
        loginWeekPC: Math.floor(100*unitSum.loginWeek/enrols.length),
      };
      return acc;
    }, {});
    // add logins to unit and return array of Unit summary objects
    const unitsData = units.map((unit) => {
      const loginsThisWeek = unit.logins.find((el) => el.begin_date === thisWeek.begin_date);
      const loginsTotal = unit.logins.reduce((acc, week) => {
        acc += week.total;
        return acc;
      }, 0)
      const ret = {
        code: unit.code,
        unitCode: unit.code,
        unitName: unit.name,
        academicLoginsTotal: loginsTotal,
        academicLoginsThisWeek: loginsThisWeek ? loginsThisWeek.total : 0,
      };
      if (unit.code in unitsMap) {
        return { ...unitsMap[unit.code], ...ret};
      }
      return ret;
    });
    return unitsData;
  }

  const sortUnitsTable = (data, key) => {
    const order = key.slice(0, 1) === '-' ? -1 : 1;
    const sortKey = key.slice(0,1) === '-' ? key.slice(1) : key;
    return data.sort((a, b) => {
      const keyA = a[sortKey] ?? null;
      const keyB = b[sortKey] ?? null;
      return keyA === keyB ? 0 : (keyA > keyB ? order : 0 - order);
    });
  }

  const getCourseCreditTotals = (aggregated) => {
    const courseCredits = Object.entries(aggregated).reduce((acc, parts) => {
      const [key, enrols] = parts;
      acc[key] = {
        code: key,
        unitCode: key,
        total: enrols.length,
        name: enrols[0].courseCreditOrgs[key], // pick Org name from first student's course credits
      }
      return acc;
    }, {});
    return courseCredits;
  }

  const unitTableSortingButton = (key) => {

    const paramsList = dashboardParams;
    const parameters = params;
    const sortParam = parameters.sort ?? ['unitCode'];
    const sort = sortParam[0] ?? 'unitCode';
    const keyToSort = sort.startsWith('-') ? sort.slice(1) : sort;

    const isSelected = keyToSort === key;
    const isDescending = sort.startsWith('-')

    const newKey = isSelected && !isDescending ? '-' + key : key;
    const color = isSelected ? config.colors.cquBlue : config.colors.cquBlue50;
    const icon = !isDescending || !isSelected ? faArrowDown : faArrowUp;

    const newParams = {...params, sort: [newKey]}; // ... hack due to list handling
    const link = writeQueryString(paramsList, newParams);

    //  In case people try multi-sorting; don't open a new window.
    const preventShiftClicks = (event) => {
      if (event.shiftKey) {
        event.preventDefault();
      }
    };

    return (
      <Link to={link} onClick={preventShiftClicks}>
        <FontAwesomeIcon icon={icon} style={{color: color}} /><br/>
      </Link>
    );
  }

  const renderUnitsTable = (units, course) => {
    return (
    <React.Fragment>
      <table className="dataTable" style={{minWidth: '10rem'}}>
        <thead>
        <tr>
          <th style={{verticalAlign: 'bottom'}} rowSpan="2">Unit</th>
          <th style={{verticalAlign: 'bottom'}} rowSpan="2" title="Percentage of Students having a Prior Attempt">Prior Attempts</th>
          {!isVet && (
            <Fragment>
            <th style={{verticalAlign: 'bottom'}} rowSpan="2" title="Percentage of International Students">Intl</th>
            <th style={{verticalAlign: 'bottom'}} rowSpan="2" title="Percentage of 1st Term Students">1st Term</th>
            </Fragment>
          )}
          <th style={{verticalAlign: 'bottom'}} rowSpan="2" title="Percentage of Students not logged in this week">No Login</th>
          <th style={{verticalAlign: 'bottom'}} rowSpan="2">Students</th>
          <th style={{verticalAlign: 'bottom'}} colSpan="2">Staff Logins</th>
        </tr>
        <tr>
          <td style={{verticalAlign: 'bottom'}}>Total</td>
          <td style={{verticalAlign: 'bottom'}}>This week</td>
        </tr>
        <tr>
          <td>{ unitTableSortingButton('unitCode') }</td>
          <td>{ unitTableSortingButton('previousAttemptPC') }</td>
          {!isVet && (
            <Fragment>
            <td>{ unitTableSortingButton('internationalPC') }</td>
            <td>{ unitTableSortingButton('firstTermPC') }</td>
            </Fragment>
          )}
          <td>{ unitTableSortingButton('loginWeekPC') }</td>
          <td>{ unitTableSortingButton('enrolCount') }</td>
          <td>{ unitTableSortingButton('academicLoginsTotal') }</td>
          <td>{ unitTableSortingButton('academicLoginsThisWeek') }</td>
        </tr>
        <tr><td className="horizontalBar" colSpan="9"></td></tr>
        </thead>
        <tbody>
        {units.map((_, index) => (
          <tr key={index}>
            <td title={_.unitName}>
              <Link to={"/unit/" + _.unitCode}>
                {_.code}
              </Link>
            </td>
            <td>{_.previousAttemptPC !== undefined ? _.previousAttemptPC + '%' : '—'}</td>
            {!isVet && (
              <Fragment>
              <td>{_.internationalPC !== undefined ? _.internationalPC + '%' : '—'}</td>
              <td>{_.firstTermPC !== undefined ? _.firstTermPC + '%' : '—'}</td>
              </Fragment>
            )}
            <td>{_.loginWeekPC !== undefined ? (100 - _.loginWeekPC) + '%' : '—'}</td>
            <td>{_.enrolCount !== undefined ? _.enrolCount : '—'}</td>
            <td>{_.academicLoginsTotal}</td>
            <td>{_.academicLoginsThisWeek}</td>
          </tr>
        ))}
        </tbody>
      </table>
      <p><small>This is a unit breakdown of students admitted in {college.code} only.<br/>Accessing the unit overview dashboard will show the breakdown for all enrolments in that unit.</small></p>
    </React.Fragment>
    );
  }

  //const siteConfig = props.config;
  const {
    // functions common to all dashboards
    getStudentLogins,
    getGpaBandTotals,
    handleUpdateIncognito,
    handleShowFilter,
    handleShowInfo,
    handleShowStudents,
    renderStudentList,
    // data common to all dashboards
    config: siteConfig,
    error,
    isLoading,
    cached,
    params,
    dashboardParams,
    defaultDashboardParams,
    calendar,
    imports,
    user,
    loaded,
    filtered,
    aggregated,
    allAggregated,
    incognito,
    showSidebar,
    showStudents,
    moodleCode,
    // course-specific
    units,
    logins, // academic logins
    college,
    distinctStudentCount,
    distinctUnitCount,
  } = props;

  const isVet = (moodleCode === (calendar && calendar.vet_code))

  if (error !== null) {
    return (
      <Error
        error={error}
        config={siteConfig}
      />
    );
  }

  if (isLoading === null || isLoading === true) {
    return <Spinner />
  }

  const thisWeek = calendar.this_week;

  const isFiltered = (filtered.length !== loaded.length);

  const studentTotal = filtered.length; // technically this is a count of filtered enrolments
  const [studentLogin, studentNotLogin] = getStudentLogins(aggregated.loginBands);

  // overview
  const allStudentTotal = loaded.length; // technically this is the count of all enrolments
  const [
    allStudentLogin,
    //allStudentNotLogin
  ] = getStudentLogins(allAggregated.loginBands);

  const engagedWhileLearning = aggregated.activity['Active'].length;

  // overview - use current week data
  const allEngagedWhileLearning = allAggregated.activityThisWeek['Active'].length;

  const gpaBands = isVet ? null : getGpaBandTotals(aggregated.gpaBands);

  const academicLoginsByWeek = getAcademicLoginsByWeek(logins, calendar);

  const unitsData = getUnitsSummary(aggregated.units);

  const entryPathways = isVet ? null : getEntryPathways(aggregated.basisOfAdmission);

  //  Get sort parameter
  const sortParam = params.sort ?? ['unit_name'];
  const sort = sortParam[0] ?? 'unit_name';
  const sortedUnitsData = sortUnitsTable(unitsData, sort);

  const courseCreditsData = isVet ? null : getCourseCreditTotals(aggregated.courseCreditOrgs);

  return (
    <Fragment>
      <Header
        cached={cached}
        config={siteConfig}
        calendar={calendar}
        imports={imports}
        user={user}
        services={[
          ['Cohort Dashboard for ' + college.code, '/predictions?path=_' + moodleCode + ',.' + college.code, faPollH],
        ]}
        breadcrumbs={[
          ["College Dashboard"],
          [college.code + ' ' + college.name],
        ]}
        incognito={incognito}
        handleUpdateIncognito={handleUpdateIncognito}
      />
      <main className="UnitDashboard">
        <article className="sidebar">
          { showSidebar === 'filter' && (
            <DashboardFilter
              dashboardParams={dashboardParams}
              defaultDashboardParams={defaultDashboardParams}
              loaded={loaded}
              params={params}
            />
          )}
          { showSidebar === 'info' && (
            <DashboardInfo
              calendar={calendar}
              user={user}
            />
          )}
          <div className="Buttons">
            <div className="ButtonWrapper">
              <button
                className={showSidebar === 'filter' ? "primaryButton" : "secondaryButton"}
                onClick={handleShowFilter}
              >
                Filters
              </button>
            </div>
            <div className="ButtonWrapper">
              <button
                className={showSidebar === 'info' ? "primaryButton" : "secondaryButton"}
                onClick={handleShowInfo}
              >
                Info
              </button>
            </div>
          </div>
        </article>
        { showStudents && showStudents.length > 0 && (
          <Fragment>
            <ModalBackground
              handleClose={handleShowStudents}
            />
            <aside id="popoverForCourseDashboardStudentList" className="middleCenter">
              <div style={{
                float: 'right',
                marginRight: '2rem',
              }}>
                <div className="closeButton" style={{
                  position: 'fixed',
                }}>
                  <FontAwesomeIcon icon={faClose} onClick={handleShowStudents} />
                </div>
              </div>
              {renderStudentList()}
            </aside>
          </Fragment>
        )}
        <div className="UnitWidgets">
          <div className="UnitOverview">
            <h1>{college.code} {thisWeek.long_name} Overview</h1>
            <div className="UnitInfoBar">
              <div className="UnitInfo">
                <h1>{Math.floor(100*allStudentLogin/distinctStudentCount)}%</h1>
                <h2>Logins</h2>
              </div>
              <div className="UnitInfo">
                <h1>{Math.floor(100*allEngagedWhileLearning/allStudentTotal)}%</h1>
                <h2>Engagement</h2>
              </div>
              <div className="UnitInfo">
                <h1>{distinctUnitCount}</h1>
                <h2>Units</h2>
              </div>
              <div className="UnitInfo">
                <h1>{distinctStudentCount}</h1>
                <h2>Students</h2>
              </div>
              <div className="UnitInfo">
                <h1>{allStudentTotal}</h1>
                <h2>Enrolments</h2>
              </div>
            </div>
          </div>
          {isFiltered && (
          <div className="UnitFilterCard">
            <div style={{
              backgroundColor: '#67712e',
              color: 'white',
              padding: '1rem',
              borderTop: 'solid 1px white',
              borderBottom: 'solid 1px white',
            }}>
              <div style={{
                float: 'right'
              }}>
                <Link
                  to={uriLink(defaultDashboardParams, defaultDashboardParams)}
                  style={{
                    color: 'white',
                  }}
                >
                  <FontAwesomeIcon
                    icon={faClose}
                    style={{
                      color: config.colors.high,
                      paddingRight: '5px',
                    }}
                  /> Clear
                </Link>
              </div>
              <div>
                <b>Filters active</b> &mdash; {studentTotal} / {allStudentTotal} Enrolments
              </div>
            </div>
          </div>
          )}
          <div className="CourseWidgets">
            <div className="UnitWidgets">
              {!isVet && (
                <EntryPathwaysWidget
                  title="Entry Pathways"
                  data={entryPathways}
                  propName={'basisOfAdmission'}
                  paramName={'basisOfAdmission'}
                  onClick={handleShowStudents}
                />
              )}
              <WeeklyLoginsWidget
                calendar={calendar}
                data={academicLoginsByWeek}
                disabled={isFiltered}
                axisDates={isVet}
              />
              {!isVet && (
                <CourseCreditOrgsWidget
                  title="Transfer Credits"
                  data={courseCreditsData}
                  propName={'courseCreditOrgs'}
                  paramName={'courseCreditOrg'}
                  onClick={handleShowStudents}
                  cohort={'students'}
                />
              )}
              {!isVet && (
                <GpaBandsWidget
                  title="GPA Bands"
                  data={gpaBands}
                  propName={'gpaBands'}
                  paramName={'GPA'}
                  onClick={handleShowStudents}
                  cohort={'students'}
                />
              )}
              <EngagedWhileLearningWidget
                title="Engagement In Learning"
                data={{
                  engaged: engagedWhileLearning,
                  total: studentTotal
                }}
                propCodes={['Active', 'Inactive']}
                propName={'activity'}
                paramName={'activity'}
                onClick={handleShowStudents}
                cohort={'enrolments'}
              />
              <RecentLoginsWidget
                title="Recent Logins"
                week={thisWeek}
                data={{
                  logins: studentLogin,
                  total: studentLogin + studentNotLogin, // studentTotal
                }}
                propCodes={['This week', ['Not this week', 'Never']]}
                propName={'loginBands'}
                paramName={'lastLogin'}
                onClick={handleShowStudents}
                cohort={'students'}
              />
              <UnitsInteractionsWidget
                title="Interactions"
                data={unitsData}
              />
              <InterventionStrategyWidget
                calendar={calendar}
                students={loaded}
                interactionsSent={props.interactions_sent}
                interactions={props.interactions}
              />
            </div>
            <div className="UnitSummary">
              {renderUnitsTable(sortedUnitsData, college)}
            </div>
          </div>
        </div>
      </main>
    </Fragment>
  );
}
export default withDashboard(
  CollegeDashboard,
  {
  apiUrl,
  loadStudents,
  filterStudents,
  aggregateStudents,
  dashboardParams: config.courseDashboardParams,
  defaultDashboardParams: config.defaultCourseDashboardParams,
  }
);
