import React, { Component } from "react";
import axios from "axios";
import Cookie from "js-cookie";
import { Route, Redirect, withRouter } from "react-router-dom";

class PrivateRouteWithoutRouter extends Component {
  constructor(props) {
    super(props);

    let handle401 = this.handle401;
    axios.interceptors.response.use(function (response) {
      // Do something with response data
      return response;
    }, function (error) {
      if (error.response.status === 401) {
        Cookie.remove('auth-token');
        handle401();
      }
      return Promise.reject(error);
    });
  }

  handle401 = () => {
    this.props.history.push('/login/401');
  }

  /**
   * After logging via external login api
   *  retrieve saved {location} from cookie and do local redirect
   * 
   * @param {*} Component 
   * @param {*} props 
   */
  renderGetRedirect(Component, props) {
    const redirect = Cookie.getJSON('redirect');
    if (redirect) {
      Cookie.remove('redirect');
      return (
        <Redirect
          to={{...redirect}}
        />
      );
    } else {
      let incognito = false;
      if (Cookie.get('incognito')) {
        incognito = true;
      }
      return (
        <Component {...props} incognito={incognito} />
      );
    }
  }

  /**
   * Save {location} in cookie and redirect to external login api
   * 
   * @param {*} location 
   */
  renderSetRedirect(location) {
    Cookie.set('redirect', location);
    return (
      <Redirect
        to={{
          //pathname: "/login/401",
          pathname: "/login-redirect",
          state: { from: location }
          // in destination component: state = this.props.location.state
        }}
      />
    );
  }

  render() {
    let authenticated = false;
    if (Cookie.get('auth-token')) {
      axios.defaults.headers.common['auth-token'] = Cookie.get('auth-token');
      authenticated = true;
    }

    const { component: Component, ...rest } = this.props;

    return (
      <Route
        {...rest}
        render={props =>
          authenticated ? this.renderGetRedirect(Component, props) : this.renderSetRedirect(props.location)
        }
      />
    )
  }
}

const PrivateRoute = withRouter(PrivateRouteWithoutRouter);

export default PrivateRoute;
