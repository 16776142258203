import React, { Component, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Cookie from "js-cookie";
import axios from "axios";
import { library } from "@fortawesome/fontawesome-svg-core";
import moment from 'moment-timezone';
import {
  faArrowCircleLeft,
  faBell,
  faCog,
  faCheckCircle,
  faChevronRight,
  faClock,
  faCommentDots as faFeedback,
  faDownload,
  faExclamationCircle,
  faExclamationTriangle,
  faHistory as faCached,
  faLock,
  faPollH,
  faQuestionCircle,
  faSearch,
  faShieldAlt,
  faSignOutAlt,
  faSpinner,
  faTimesCircle,
  faWrench
} from "@fortawesome/free-solid-svg-icons";

import Error from "../../pages/Error/Error";
import { config } from "../../utils/config";
import { read_snooze_cookie, sendLog, submitUserPreferences, test_snooze_cookie } from "../../utils/utils";
import { SuccessStrategyNotices } from "./SuccessStrategyNotices";
import { ModalBackground } from "../../components";
import PreferenceForm from "./PreferenceForm";

library.add(
  faArrowCircleLeft,
  faBell,
  faCached,
  faCog,
  faCheckCircle,
  faChevronRight,
  faClock,
  faDownload,
  faExclamationCircle,
  faExclamationTriangle,
  faFeedback,
  faLock,
  faPollH,
  faQuestionCircle,
  faSearch,
  faShieldAlt,
  faSignOutAlt,
  faSpinner,
  faTimesCircle,
  faWrench
);
moment.tz.setDefault('Australia/Brisbane');

class Header extends Component {

    //  * Preferences popup
    //  * Login and user info
    //  * Universal nav
    //  * Breadcrumbs

    constructor(props) {
      super(props);
      this.state = {
        cached: this.props.cached,
        config: this.props.config,
        user: this.props.user,
        notices: this.props.notices,
        error: null,
        feedback: {},
        showFeedbackForm: false,
        // show new notice until user has dismissed it
        showNotice: (this.props.user.notice !== null) ?
          (localStorage.getItem('notice') === this.props.user.notice ?
            !localStorage.getItem('noticeDismissed') :
            true
          ) : false,
        showImports: false,
        showPreferences: false,
        showStrategyNotifications: false,
        incognito: props.incognito,
        feedbackValidationErrors: {},
      };

      localStorage.setItem('notice', this.props.user.notice);

      this.handleShowImports = this.handleShowImports.bind(this);
      this.handleShowPreferences = this.handleShowPreferences.bind(this);
      this.handleShowStrategyNotifications = this.handleShowStrategyNotifications.bind(this);
      this.handleCloseStrategyNotifications = this.handleCloseStrategyNotifications.bind(this);
      this.handleShowFeedbackForm = this.handleShowFeedbackForm.bind(this);
      this.handleShowUserNotice = this.handleShowUserNotice.bind(this);
      this.handleUpdatePreferences = this.handleUpdatePreferences.bind(this);
      this.handleSendFeedback = this.handleSendFeedback.bind(this);
      this.handleUpdateFeedback = this.handleUpdateFeedback.bind(this);
      this.handleSavePreferences = this.handleSavePreferences.bind(this);
      this.handleToggleIncognito = this.handleToggleIncognito.bind(this);

      //  Make this component callable from anywhere; useful for enabling menus.
      window.cquSuccessHeader = this;
    }

    renderBreadcrumbs(links) {
      return links.map(([title, href], index) => (
        <React.Fragment key={index}>
          { index > 0 && (
            <FontAwesomeIcon icon={faChevronRight} />
          )}
          { typeof href === 'string' ? (
            <Link to={href}>{title}</Link>
          ) : (
            <React.Fragment>{title}</React.Fragment>
          )}
        </React.Fragment>
      ));
    }

    renderAppEnv() {
      const environment = (this.state.config && this.state.config.environment) ?
        this.state.config.environment : 'production';
      const app_date = (this.state.config && this.state.config.app_date) ? this.state.config.app_date : null;
      const cached = this.state.cached;
      if (environment !== 'production') {
        return (
          <div style={{
            color: '#ad0000',
            fontWeight: 'bold',
            float: 'left'
          }}>
            {cached !== undefined && (
              <Fragment>
                {cached ? (
                  <span style={{color: "#0000ad"}}>
                    <FontAwesomeIcon
                      icon={faCached}
                    />
                  CACHED
                  </span>
                ) : (
                  <span style={{color: "#00ad00"}}>
                    <FontAwesomeIcon
                      icon={faDownload}
                    />
                  FRESH
                  </span>
                )}
              </Fragment>
            )}
            {app_date && (
              <Fragment>
                <FontAwesomeIcon
                  icon={faExclamationCircle}
                />
                APP_DATE is {this.state.config.app_date}
              </Fragment>
            )}
            <FontAwesomeIcon
              icon={faExclamationCircle}
            />
            APP_ENV is {environment.toUpperCase()}
          </div>
        );
      }
    }

    renderReactAppEnv() {
      const appEnv = (config.system && config.system.appEnv) ?
        config.system.appEnv : 'production';
      if (appEnv !== 'production') {
        return (
          <div style={{
            color: '#ad0000',
            fontWeight: 'bold',
            float: 'left'
          }}>
            <FontAwesomeIcon
              icon={faExclamationCircle}
            />
            REACT_APP_ENV is {appEnv.toUpperCase()}
          </div>
        );
      }
    }

    handleShowImports(event) {
      event.stopPropagation();
      if (this.state.showImports === false) {
        sendLog('App\\Events\\Imports\\ViewedImports', 'r', 'viewed', 'imports');
      }
      this.setState({
        showFeedbackForm: false,
        showNotice: false,
        showPreferences: false,
        showImports: this.state.showImports === true ? false : true,
        showStrategyNotifications: false,
      });
    }

    handleShowPreferences(event) {
      event.stopPropagation();
      if (this.state.showPreferences === false) {
        sendLog('App\\Events\\UserPreference\\ViewedPreferences', 'r', 'viewed', 'preferences');
      }
      this.setState({
        showFeedbackForm: false,
        showNotice: false,
        showPreferences: this.state.showPreferences === true ? false : true,
        showImports: false,
        showStrategyNotifications: false,
      });
    }

    handleShowStrategyNotifications(event) {
      event.stopPropagation();
      this.setState({
        showStrategyNotifications: true,
      });
    }

    handleCloseStrategyNotifications(event) {
      event.stopPropagation();
      this.setState({
        showStrategyNotifications: false,
      });
    }

    handleShowFeedbackForm(event) {
      event.stopPropagation();
      if (this.state.showFeedbackForm === false) {
        sendLog('App\\Events\\Header\\ViewedFeedbackForm', 'r', 'viewed', 'feedback');
      }
      this.setState({
        showFeedbackForm: this.state.showFeedbackForm === true ? false : true,
        showNotice: false,
        showPreferences: false,
        showImports: false,
        showStrategyNotifications: false,
        error: null, // clear previous errors
      });
    }

    handleShowUserNotice(event) {
      event.stopPropagation();
      this.setState({
        showFeedbackForm: false,
        showNotice: this.state.showNotice === true ? false : true,
        showPreferences: false,
        showImports: false,
        showStrategyNotifications: false,
      });
      localStorage.setItem('noticeDismissed', true);
    }

    handleUpdateFeedback(event) {
      event.stopPropagation();
      this.setState({
        feedback: {
          body: document.getElementById('feedback_body').value,
        }
      });
    }

    handleSendFeedback(event) {
      event.stopPropagation();
      this.setState({isSending: true});
      const url = config.system.baseApiUrl + "/feedback";
      axios
        .post(url, {
          body: this.state.feedback.body,
          metadata: {
            url: window.location.href,
            user: this.state.user,
          }
        })
        .then(res => {
          this.setState({
            ...this.state,
            isSending: false
          });
          if (res.data.error) {
            // likely 500 error
            this.setState({
              ...this.state,
              error: res.data.error,
            });
            return;
          }
          if (res.data.result && res.data.result.error) {
            const result = res.data.result;
            let validationErrors = {};
            if (result.validationErrors) {
              Object.keys(result.validationErrors).forEach(key => {
                validationErrors[key] = result.validationErrors[key];
              });
              this.setState({
                ...this.state,
                error: {message: result.error, code: result.code || 400},
                feedbackValidationErrors: validationErrors
              });
            }
          } else {
            this.setState({
              isSaving: false,
              showFeedbackForm: false,
              error: null,
              feedback: {},
            });
          }
        })
        .catch(error => {
          //  console.log(error);
          this.setState({
            isSending: false,
            error,
            //showFeedbackForm: false,
          })
        });
    }

    handleUpdatePreferences(preferences) {
      const user = {...this.state.user};
      user.preference = preferences;
        this.setState({
          user: user,
        });
        if (this.props.handleUpdateUser !== undefined) {
          this.props.handleUpdateUser(user);
        }
      }

    handleSavePreferences(event) {
      event.stopPropagation();
      this.setState({isSaving: true});
      const url = config.system.baseApiUrl + "/preferences";

      const newPreferences = {};

      if (this.state.user.preference.current) {
        newPreferences.current = this.state.user.preference.current;
      }
      if (this.state.user.preference.historic) {
        newPreferences.historic = this.state.user.preference.historic;
      }

      if (!newPreferences.current && !newPreferences.historic) {
        this.setState({
          isSaving: false,
          showPreferences: false,
        });
        return;
      }

      submitUserPreferences(this.state.user);
      this.setState({
        isSaving: false,
        showPreferences: false,
      });
    }

    handleToggleIncognito(event)
    {
      const incognito = this.state.incognito ? false : true; // toggle
      if (incognito) {
        Cookie.set('incognito', "1"); // <-- Strings!
      } else {
        Cookie.remove('incognito');
      }
      //  Prompt redraw in header
      this.setState({ incognito });
      //  Prompt redraw in surrounding page
      if (this.props.handleUpdateIncognito !== undefined) {
        this.props.handleUpdateIncognito(incognito);
      }
    }

    countVisibleStrategyNotifications(notices)
    {
      return notices.size > 0;
    }

    render() {

      const user = this.props.user;
      const notices = this.state.notices === undefined ? [] : this.state.notices;

      const suppressedBySnooze = test_snooze_cookie(read_snooze_cookie());
      const hasCurrentNotifications = notices.some(_ => _.proceed_time === null && _.dismiss_time === null);

      const description = this.props.description ?? <React.Fragment>Click the <FontAwesomeIcon icon={faFeedback} /> icon above to leave feedback</React.Fragment>;

      const hasStrategyNotifications = this.countVisibleStrategyNotifications(notices) > -1;
      const showStrategyNotifications = this.state.showStrategyNotifications;

      const breadcrumbs = (this.props.breadcrumbs !== undefined)
        ? [['Home', config.system.home], ...this.props.breadcrumbs]
        : [['Home', config.system.home]];

      const feedbackValidationErrors = this.state.feedbackValidationErrors;

      // The week number to be displayed is taken from this.props.termInfo if we are on a dashboard page,
      // or from this.props.calendar if we are on the home page or config page.
      // For future re-factoring we want to avoid using props, and get the info directly from useContext()
      // The logic we want for the week display is:
      // Case home page or config page - no term is being displayed:  show current HE week (from props.calendar)
      // showing an HE term (current or historical): show the current HE week
      // showing a VET term (current or historical): VET term: show the current VET week
      const week = this.props.termInfo === undefined
        ? (this.props.calendar === undefined
          ? ' '
          : this.props.calendar.this_week.long_name)
        : this.props.termInfo.displayedIsVET
          ? this.props.termInfo.currentVETWeek.long_name
          : this.props.termInfo.currentHEWeek.long_name;

      const imports = this.props.imports;
      var dataUpToDate = false;
      if (
        moment().diff(moment.unix(this.props.imports.last_crm), 'days') < 1
        && moment().diff(moment.unix(this.props.imports.last_materialisation), 'days') < 1
      ) {
        dataUpToDate = true;
      }

      const weekIcon = dataUpToDate
        ? faCheckCircle
        : faExclamationTriangle;

      const weekColor = dataUpToDate
        ? config.colors.cquBlue50
        : config.colors.medium;

      const incognito = this.state.incognito;

      return (
        <header>

          {notices !== undefined && (
          <SuccessStrategyNotices
            user={user}
            notices={notices}
            openedByUser={showStrategyNotifications}
            handleClose={this.handleCloseStrategyNotifications}
          />
          )}

          <div className="primaryHeader">
            <div className="menuBar">
              <div id="buttonToHideStudentDetails" className="menuDivision" title="Show/Hide student details">
                <FontAwesomeIcon
                  icon={faShieldAlt}
                  onClick={this.handleToggleIncognito}
                  style={{
                    color: incognito ? config.colors.cquGreen : config.colors.cquLightCharcoal,
                    cursor: 'pointer'
                  }}
                />
              </div>
              { false && user !== undefined && user.notice !== null && (
              <div className="menuDivision">
                <FontAwesomeIcon
                  icon={faExclamationCircle}
                  onClick={this.handleShowUserNotice}
                  style={{
                    color: this.state.showNotice ? config.colors.cquGreen : config.colors.cquLightCharcoal,
                    cursor: 'pointer'
                  }}
                />
              </div>
              )}
              { this.props.handleUpdateUser !== undefined && (
              <div id="buttonToShowUserPreferences" className="menuDivision" title="Show user preferences">
                <FontAwesomeIcon
                  icon={faCog}
                  onClick={this.handleShowPreferences}
                  style={{
                    color: this.state.showPreferences ? config.colors.cquGreen : config.colors.cquLightCharcoal,
                    cursor: 'pointer'
                  }}
                />
              </div>
              )}
              <div id="buttonToShowFeedbackForm" className="menuDivision" title="Show feedback form">
                <FontAwesomeIcon
                  icon={faFeedback}
                  onClick={this.handleShowFeedbackForm}
                  style={{
                    color: this.state.showFeedbackForm ? config.colors.cquGreen : config.colors.cquLightCharcoal,
                    cursor: 'pointer'
                  }}
                />
              </div>
              { hasStrategyNotifications && (
              <div
                id="buttonToShowUserNotifications"
                className="menuDivision"
                onClick={this.handleShowStrategyNotifications}
                style={{cursor: 'pointer'}}
                title={hasCurrentNotifications
                    ? "Show snoozed notifications"
                    : "No current notifications"}
              >
                <FontAwesomeIcon
                  icon={faBell}
                  style={{
                    color: !hasCurrentNotifications ? config.colors.cquBlue50 : (
                      suppressedBySnooze ? config.colors.cquGreen : config.colors.cquLightCharcoal
                    ),
                  }}
                />
              </div>
              )}
              { imports !== undefined && week !== null && (
              <div
                id="buttonToShowImportStatus"
                className="menuDivision"
                title="Show import status"
                onClick={this.handleShowImports}
                style={{cursor: 'pointer'}}
              >
                <FontAwesomeIcon
                  icon={weekIcon}
                  style={{
                    color: weekColor
                  }}
                /> <b style={{
                  color: config.colors.cquBlue50
                }}>{week}</b>
              </div>
              )}
              { user !== undefined && (
              <React.Fragment>
                <div id="userIdentification" className="menuDivision">
                  {user.first_name} {user.last_name} ({user.level})
                </div>
                { user.level === 'admin' && (
                <div className="menuDivision">
                  <a href="/config">
                    <FontAwesomeIcon icon={faWrench} />
                  </a>
                </div>
                )}
                <div className="menuDivision">
                  <a href={config.system.baseApiUrl + '/saml2/adfs/logout'}>
                    <FontAwesomeIcon icon={faSignOutAlt} />
                  </a>
                </div>
              </React.Fragment>
              )}
            </div>
            <div className="titleBar">
              <Link to="/">{config.system.name}</Link>
            </div>
          </div>
          <div className="secondaryHeader">
            { description !== undefined && (
              <div className="querySummary">{description}</div>
            )}
            <div className="spaceChildren">
              {this.props.handleSearch === undefined ? (
                <Link to="/"><FontAwesomeIcon icon={faSearch} /> Search</Link>
              ) : (
                <React.Fragment>
                  <FontAwesomeIcon icon={faSearch} />
                  <input
                    /**
                     * Uncontrolled component; no state; sends changes to handleSearch()
                     **/
                    className="findStudent"
                    placeholder="Find in cohort..."
                    onChange={(e) => this.props.handleSearch(e.target.value)}
                    onFocus={(event) => {
                      event.target.select();
                    }}
                  />
                </React.Fragment>
              )}
              {this.props.services !== undefined && this.props.services.map((tuple, index) => {
                let [title, href, icon, external = false] = tuple;
                if (external) {
                  // open link in new window
                  return <a key={index} href={href} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={icon} /> {title}</a>
                }
                return <a href={href}><FontAwesomeIcon icon={icon} replace/> {title}</a>
              })}
            </div>
          </div>
          <div className="tertiaryHeader">
            { breadcrumbs !== undefined && this.renderBreadcrumbs(breadcrumbs) }
            <div style={{float: 'right'}}>
              { this.renderReactAppEnv() }
              { this.renderAppEnv() }
            </div>
          </div>
          <div id="feedbackForm">
          { this.state.showFeedbackForm && (
            <React.Fragment>
              <ModalBackground
                handleClose={this.handleShowFeedbackForm}
              />
              <aside id="popoverForUserFeedbackForm" className="middleCenter">
                <div className="closeButton">
                  <FontAwesomeIcon icon={faTimesCircle} onClick={this.handleShowFeedbackForm} />
                </div>
                <div>
                  <h4><FontAwesomeIcon icon={faFeedback} /> Send Feedback</h4>
                  {feedbackValidationErrors.body && (
                    <div className="errorMessage">
                      <FontAwesomeIcon icon={faTimesCircle} /> {feedbackValidationErrors.body}
                    </div>
                  )}
                  <p>Feedback on <em>CQU Success</em> &mdash;</p>
                  <p>
                  <textarea
                    id="feedback_body"
                    className={feedbackValidationErrors.body ? 'error' : ''}
                    onChange={this.handleUpdateFeedback}
                  >
                  {this.state.feedback.body}
                  </textarea>
                  </p>
                  <p>
                    <em>Feedback will include your user account and the current URL.</em>
                  </p>
                </div>
                  <div className="vertical-space">
                    <div style={{float: 'right'}}><button
                        type="button"
                        onClick={this.handleShowFeedbackForm}
                      >
                        Cancel
                      </button>
                    </div>
                    {this.state.error && (
                      <div className="errorMessage" style={{float: 'left'}}>
                        <Error
                          error={this.state.error}
                          config={this.state.config}
                          modal={true}
                        />
                      </div>
                    )}
                    <div>
                      <button
                        type="button"
                        className="primaryButton"
                        onClick={this.handleSendFeedback}
                        disabled={this.state.isSending}
                      >
                        { this.state.isSending ? 'Sending...' : 'Send Feedback' }
                      </button>
                    </div>
                  </div>
               </aside>
            </React.Fragment>
          )}
          </div>
          <div id="Preferences">
          { this.props.imports !== null && this.state.showImports && (
            <React.Fragment>
              <ModalBackground
                handleClose={this.handleShowImports}
              />
              <aside id="popoverForImportSuccessSummary" className="topLeft">
                <div className="closeButton">
                  <FontAwesomeIcon icon={faTimesCircle} onClick={this.handleShowImports} />
                </div>
                <h4>Daily data summary</h4>
                <table>
                  <tbody>
                    <tr>
                      <td>{moment().diff(moment.unix(this.props.imports.last_materialisation), 'days') < 1 ?
                        <FontAwesomeIcon icon={faCheckCircle} style={{color: config.colors.cquGreen}}/> :
                        <FontAwesomeIcon icon={faExclamationTriangle} style={{color: config.colors.medium}}/>
                      }</td>
                      <td>Enrolment & Activity Data last updated {moment.unix(this.props.imports.last_materialisation).calendar()}</td>
                    </tr>
                    <tr>
                      <td>{moment().diff(moment.unix(this.props.imports.last_crm), 'days') < 1 ?
                        <FontAwesomeIcon icon={faCheckCircle} style={{color: config.colors.cquGreen}}/> :
                        <FontAwesomeIcon icon={faExclamationTriangle} style={{color: config.colors.medium}}/>
                      }</td>
                      <td>Interactions (CRM Data) is current as of {moment.unix(this.props.imports.last_crm).calendar()}</td>
                    </tr>
                  </tbody>
                </table>
              </aside>
            </React.Fragment>
          )}
          { this.state.showNotice && (
            <React.Fragment>
              <ModalBackground
                handleClose={this.handleShowUserNotice}
              />
              <aside id="popoverForSystemMaintenanceNotice" className="topLeft">
                <div className="closeButton">
                  <FontAwesomeIcon icon={faTimesCircle} onClick={this.handleShowUserNotice} />
                </div>
                <div className="column">
                  <p>{user.notice}</p>
                </div>
              </aside>
            </React.Fragment>
          )}
          { this.state.showPreferences && (
            <PreferenceForm user={this.state.user} handleClose={this.handleShowPreferences} saving={this.state.isSaving} onChange={this.handleUpdatePreferences} onSave={this.handleSavePreferences}/>
          )}
          </div>
        </header>
      );
    }
}

export default Header;