import React from 'react';
import { faCheckCircle, } from "@fortawesome/free-solid-svg-icons";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import QueryString from '../../utils/queryString';

const GenderDashboardFilter = ({ enrollments, value, parameter }) => {
    const genders = enrollments.reduce((acc, obj) => acc.add(obj.student.gender), new Set());

    const options = Array.from(genders).map((gender, index) => {
        const qs = new QueryString(window.location.pathname + window.location.search);

        // Ensure that our parameter exists.
        if (qs.query[parameter] === undefined) {
            qs.query[parameter] = [];
        }

        // Ensure that our parameter is a array.
        if (typeof qs.query[parameter] === 'string' && qs.query[parameter] !== "") {
            qs.query[parameter] = [qs.query[parameter]];
        }

        // Add or remove from parameter.
        if (value.includes(gender)) {
            qs.query[parameter] = qs.query[parameter].filter( value => value !== gender );
        } else {
            qs.query[parameter].push(gender);
        }

        if (qs.query[parameter].length === 0) {
            delete qs.query[parameter];
        }

        return (<Link to={qs.encode()} style={{ marginRight: '.155rem' }} key={index}>
            <FontAwesomeIcon icon={value.includes(gender) ? faCheckCircle : faSquare} />&nbsp;{gender}
        </Link>);
    });

    return options;
}

export default GenderDashboardFilter;