import { useParams } from 'react-router-dom';
import { CURRENT_VET_YEAR_VALUE } from '../utils/constants';

/**
 * A custom hook that gets parameters in a correct format.
 *
 * @returns {object}
 */
export default function useRouteParams() {
    const params = useParams();

    return {
        year: params.year === 'current' ? CURRENT_VET_YEAR_VALUE : parseInt(params.year),
        term: params.term,
        raw: params,
        unit: params.unit,
        student: params.student,
    }
}