/**
 *  ----------------------------------------------------------------------
 *  A FilterLink generates a single clickable Link, in the right colour scheme,
 *  that applies a filter for a graph rendered elsewhere.
 *
 *  Used in FilterBox, GraphLegend
 *  ----------------------------------------------------------------------
 */

import React from "react";
import { Link, useParams } from "react-router-dom";
import { config } from '../../utils/config';

import {
  onlyFilteredFields,
  readQueryString,
  toggleParams,
  uriLink,
} from "../../utils/uriParams";
import { getFiltersFromParams, sendLog, truncate } from "../../utils/utils";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faCircle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";

import {
  faSquare as faSquareOutline,
} from "@fortawesome/free-regular-svg-icons";
import { LOG } from "../../utils/constants";
import useRouteParams from "../../hooks/useRouteParams";

library.add(
  faCircle,
  faCheckCircle,
  faSquareOutline,
);

const FilterLink = (props) => {
  const { year, term } = useRouteParams();
  if (
    props.label === undefined ||
    props.params === undefined ||
    props.paramName === undefined ||
    props.scheme === undefined
  ) {
    console.log("Error: FilterLink properties are missing.");
    return <p>FilterLink: {JSON.stringify(Object.keys(props))}</p>;
  }

  const label = props.label;
  const paramName = props.paramName;
  const params = props.params;
  const scheme = props.scheme;
  const hash = props.hash ? props.hash : '';
  const hint = props.hint ? props.hint : '';

  const defaultParams =
    props.defaultParams === undefined
      ? config.defaultParams
      : props.defaultParams;
  const allowedParams =
    props.allowedParams === undefined
      ? config.params
      : props.allowedParams;

  if (params[paramName] === undefined) {
    return null;
  }

  const unselectedIcon = props.icon === undefined ? faCircle : props.icon;
  const unselectedColor = props.color === undefined ? scheme[label] : props.color;
  const icon = params[paramName].includes(label) ? faCheckCircle : unselectedIcon;
  const color = params[paramName].includes(label) ? scheme[label] : unselectedColor;
  const space = params[paramName].includes(label) ? '.0425rem' : '.155rem';
  const link = uriLink(
    onlyFilteredFields(toggleParams(params, paramName, label), params.filters),
    defaultParams,
    allowedParams
  ) + hash;

  const handleOnClick = () => {
    const params = readQueryString(config.defaultParams, link);
    sendLog('App\\Events\\Cohort\\Filtered', 'r', LOG.ACTION.FILTER, LOG.TARGET.ENROLLMENTS, LOG.DASHBOARD.COHORT, {
      term: { year, term },
      path: params.path,
      filters: getFiltersFromParams(params)
    });
  }

  return (
    <Link to={link} title={hint} onClick={handleOnClick}>
      <FontAwesomeIcon
        icon={icon}
        style={{
          color: color,
          paddingLeft: '.05rem',
          paddingRight: space
        }}
      />&nbsp;{truncate(label.toString(), { pos: "center" })}
    </Link>
  );

};

export {
  FilterLink
}
