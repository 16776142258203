import React, { Component } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import Select from "react-select";
import moment from 'moment-timezone';

import { config } from "../../utils/config";
import { FilterLink } from '../FilterLink/FilterLink';
import {
  setParam,
  unsetParam,
  uriLink,
} from "../../utils/uriParams";
import {
  deep_equal,
  make_object,
} from "../../utils/utils";
import {
  Link,
  withRouter
} from 'react-router-dom';

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faCheckCircle as faCheckOn,
  faTimesCircle as faCancel,
} from "@fortawesome/free-solid-svg-icons";

import {
  faSquare as faCheckOff,
} from "@fortawesome/free-regular-svg-icons";


library.add(
  faCancel,
  faCheckOn,
  faCheckOff
  );

moment.tz.setDefault('Australia/Brisbane');
const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);

const getPropRange = (list, propName) => {
  const map = getUniqueProp({loaded: list, propName});
  const arr = [...Object.keys(map).sort()];
  return [ arr[0], arr[arr.length - 1] ];
}

/*
// get unique propName from list
// return as [name, ...]
const getList = ({loaded, propName, ...props}) => {
  if (loaded === undefined) {
    return;
  }
  const list = loaded.reduce((acc, item) => {
    if (Array.isArray(item[propName])) {
      item[propName].forEach(_ => acc.add(_));
    } else {
      acc.add(item[propName]);
    }
    return acc;
  }, new Set());
  //console.log('codelist', propName, list);
  //return [...list];
}
*/

// get unique propName/propValue from list
// if propName is array:
// return as {propName[0-n]: propName[0-m], ...}
// else
// return as {propName: propCode, ...}
const getUniqueProp = ({loaded, propName, propCode, ...props}) => {
  if (loaded === undefined) {
    return;
  }
  const map = loaded.reduce((acc, item) => {
    const value = item[propName];
    if (Array.isArray(value)) {
      value.forEach(_ => acc[_] = _);
    } else {
      const code = (propCode !== undefined) ? item[propCode] : value;
      acc[code] = value;
    }
    return acc;
  }, {});
  return map;
}

const getCodeList = (map) => {
  const list = Object.keys(map).reduce((acc, key) => {
    acc.push({
      name: map[key],
      code: key,
    });
    return acc;
  }, []);
  return list;
}

const getLegend = ({loaded, propName, ...props}) => {
    if (loaded === undefined) {
      return;
    }
    const list = loaded;

    //  ------------------------------------------------------------
    //  Legend property is optional; if missing then collate the
    //  values in the result set.

    let legend = [];
    if (props.legend !== undefined) {
      legend = props.legend;
    } else {
      const legendSet = list.reduce((acc, item) => {
        if (item[propName] !== undefined) {
          const value = item[propName];
          if (Array.isArray(value)) {
            value.forEach(_ => acc.add(_));
          } else {
            acc.add(value);
          }
        }
        return acc;
      }, new Set());
      legend = [...legendSet].sort();
    }

    //console.log('legend', legend);
    //  ------------------------------------------------------------
    //  If there's nothing to filter, then don't show a filter.
    //  Actually, use a test of what's in the path?

    if (legend.length < 2) {
      //return null;
    }

    let totals = list.reduce((acc, item) => {
      if (item[propName] !== undefined) {
        const value = item[propName];

        //  ------------------------------------------------------------
        //  The property in the prediction line may be an scalar like unitCode
        //  or Array like flags.

        if (Array.isArray(value)) {
          value.forEach(legendKey => (acc.hasOwnProperty(legendKey) ? acc[legendKey] += 1 : null));
        } else {
          if (acc.hasOwnProperty(value)) {
            acc[value] += 1;
          }
        }
      }
      return acc;
    }, make_object(legend, 0));
    return totals;
}

// Based off FilterBox.js
class FilterButtons extends Component {

  render() {
    const {
      paramName,
      params,
      legend,
      dashboardParams,
      defaultDashboardParams
    } = this.props;
    return (
      <div className="filterButtons">
        { Object.keys(legend).map((_, index) => (
          <FilterLink
            key={index}
            label={_}
            paramName={paramName}
            params={params}
            allowedParams={dashboardParams}
            defaultParams={defaultDashboardParams}
            scheme={config.colors.highContrast}
            total={1} // non-zero?
            icon={faCheckOff}
            color={config.colors.cquLightCharcoal}
          />
        ))}
      </div>
    );
  }
}

class DashboardFilter extends Component {

  constructor(props) {
    super(props);
    const {
      loaded, dashboardParams, defaultDashboardParams
    } = props;
    const ageRange = getPropRange(loaded, 'age').map((value) => parseInt(value));
    const campusCodeOptions = getCodeList(getUniqueProp({
      loaded,
      propCode: 'campusCode',
      propName: 'campusName',
    }));
    const courseCampusCodeOptions = getCodeList(getUniqueProp({
      loaded,
      propCode: 'courseCampusCode',
      propName: 'courseCampusName',
    }));
    const groupCodeOptions = getCodeList(getUniqueProp({
      loaded,
      propName: 'groupNames',
    }));
    const mapCodeOptions = getCodeList(getUniqueProp({
      loaded,
      propName: 'map',
    }));
    const courseCodeOptions = getCodeList(getUniqueProp({
      loaded,
      propName: 'courseName',
      propCode: 'courseCode',
    })).map((_) => {
      return {
        code: _.code,
        name: _.code + ": " + _.name,
      }
    });
    const unitCodeOptions = getCodeList(getUniqueProp({
      loaded,
      propName: 'unitName',
      propCode: 'unitCode',
    })).map((_) => {
      return {
        code: _.code,
        name: _.code + ": " + _.name,
      }
    });
    const studyPeriodOptions = getCodeList(getUniqueProp({
      loaded,
      propName: 'studyPeriodCode',
    }));

    this.state = {
      dashboardParams,
      defaultDashboardParams,
      loaded, // student list
      params: props.params,
      ageRange,
      minAge: null,
      maxAge: null,
      campusCode: [],
      campusCodeOptions,
      courseCampusCode: [],
      courseCampusCodeOptions,
      courseCode: [],
      courseCodeOptions,
      groupCode: [],
      groupCodeOptions,
      mapCode: [],
      mapCodeOptions,
      unitCode: [],
      unitCodeOptions,
      studyPeriod: [],
      studyPeriodOptions,
    };
    this.handleAgeRange = this.handleAgeRange.bind(this);
    this.handleAgeRangeUpdated = this.handleAgeRangeUpdated.bind(this);
    this.updateParams = this.updateParams.bind(this);
  }

  componentDidMount() {
    this.setState(this.getDerivedStateFromParams(this.state.params));
  }

  getDerivedStateFromParams(params) {
    const minAge = params.minAge.length > 0 ? params.minAge[0] : null;
    const maxAge = params.maxAge.length > 0 ? params.maxAge[0] : null;
    return {
      params: params,
      minAge: minAge,
      maxAge: maxAge,
      campusCode: params.campuses,
      courseCampusCode: params.courseCampuses,
      courseCode: params.courses,
      groupCode: params.groups,
      mapCode: params.maps,
      unitCode: params.units,
      studyPeriod: params.intakeMonth,
    }

  }

  componentDidUpdate(prevProps, prevState) {
    const oldParams = prevProps.params;
    const newParams = this.props.params;
    if (!deep_equal(oldParams, newParams)) {
      this.setState(this.getDerivedStateFromParams(newParams));
      return;
    }
    let params = Object.assign({}, this.state.params);
    if (this.state.dashboardParams.includes("campuses")) {
      params = setParam(params, "campuses", this.state.campusCode);
    }
    if (this.state.dashboardParams.includes("courseCampuses")) {
      params = setParam(params, "courseCampuses", this.state.courseCampusCode);
    }
    if (this.state.dashboardParams.includes("groups")) {
      params = setParam(params, "groups", this.state.groupCode);
    }
    if (this.state.dashboardParams.includes("maps")) {
      params = setParam(params, "maps", this.state.mapCode);
    }
    if (this.state.dashboardParams.includes("courses")) {
      params = setParam(params, "courses", this.state.courseCode);
    }
    if (this.state.dashboardParams.includes("units")) {
      params = setParam(params, "units", this.state.unitCode);
    }
    if (this.state.dashboardParams.includes("intakeMonth")) {
      params = setParam(params, "intakeMonth", this.state.studyPeriod);
    }
    if (!deep_equal(params, this.props.params)) {
      this.updateParams(params);
    }
  }

  updateParams(params) {
    const link = uriLink(params, this.state.defaultDashboardParams, this.state.dashboardParams);
    this.props.history.push(link); // update the url
  }

  handleAgeRange(values) {
    let range = this.state.ageRange;
    const min = (range[0] && range[0] === values[0]) ? null : values[0];
    const max = (range[1] && range[1] === values[1]) ? null : values[1];
    this.setState({
      minAge: min,
      maxAge: max
    });
  }

  handleAgeRangeUpdated() {
    let params = this.state.params;
      if (this.state.minAge === null) {
        params = unsetParam(params, "minAge");
      } else {
        params = setParam(params, "minAge", this.state.minAge);
      }
      if (this.state.maxAge === null) {
        params = unsetParam(params, "maxAge");
      } else {
        params = setParam(params, "maxAge", this.state.maxAge);
      }
      this.updateParams(params);

  }

  // from Home.js
  getCodes(selection) {
    if (selection === null) {
      return [];
    }
    return selection.map(item => {
      return item.value;
    });
  }
  // from Home.js
  sortLabels(unsorted) {
    const sorted = unsorted.sort((a, b) => {
      if (a.label < b.label) return -1;
      if (a.label > b.label) return 1;
      return 0;
    });
    return sorted;
  }
  sortLabelMonths(unsorted) {
    const sorted = unsorted.sort((a, b) => {
      const aMon = parseInt(moment().month(a.label).format("M"));
      const bMon = parseInt(moment().month(b.label).format("M"));
      if (aMon < bMon) return -1;
      if (aMon > bMon) return 1;
      return 0;
    });
    return sorted;
  }
  // from Home.js
  getSelectOptionsFromCodeNames(list, prefix=false) {
    //  FROM: [{'code', 'name'}, ...]
    //  TO: [{'value', 'label'}, ...]
    if (prefix) {
      return list.reduce((options, item) => {
        options.push({value: item.code, label: item.code + ': ' + item.name});
        return options;
      }, []);
    } else {
      return list.reduce((options, item) => {
        options.push({value: item.code, label: item.name});
        return options;
      }, []);
    }
  }

  filterSelectOptions(list, keep) {
    const match = new Set(keep);
    return list.filter((value) => match.has(value.value));
  }

  render() {
    const loaded = this.state.loaded;
    if (loaded.length === 0) {
      return;
    }
    const dashboardParams = this.state.dashboardParams; // config
    const defaultDashboardParams = this.state.defaultDashboardParams; // config
    const params = this.state.params;
    const ageRange = this.state.ageRange;
    const minAge = this.state.minAge !== null ? this.state.minAge : ageRange[0];
    const maxAge = this.state.maxAge !== null ? this.state.maxAge : ageRange[1];
    const linkToUnsetFilter = uriLink(defaultDashboardParams, defaultDashboardParams);

    const reactSelectStyles = {
      container: styles => ({
        ...styles,
        backgroundColor: 'white',
        minHeight: '30px',
      }),
      control: styles => ({
        ...styles,
      }),
      valueContainer: styles => ({
        ...styles,
      }),
      option: styles => ({
        ...styles,
      }),
      input: styles => ({
        ...styles,
      }),
      singleValue: styles => ({
        ...styles,
      }),
    }
    const campusCodeOptions = this.sortLabels(
      this.getSelectOptionsFromCodeNames(this.state.campusCodeOptions)
    );
    const campusCode = this.filterSelectOptions(campusCodeOptions, this.state.campusCode);

    const courseCampusCodeOptions = this.sortLabels(
      this.getSelectOptionsFromCodeNames(this.state.courseCampusCodeOptions)
    );
    const courseCampusCode = this.filterSelectOptions(courseCampusCodeOptions, this.state.courseCampusCode);

    const courseCodeOptions = this.sortLabels(
      this.getSelectOptionsFromCodeNames(this.state.courseCodeOptions)
    );
    const courseCode = this.filterSelectOptions(courseCodeOptions, this.state.courseCode);

    const groupCodeOptions = this.sortLabels(
      this.getSelectOptionsFromCodeNames(this.state.groupCodeOptions)
    );
    const groupCode = this.filterSelectOptions(groupCodeOptions, this.state.groupCode);

    const mapCodeOptions = this.sortLabels(
      this.getSelectOptionsFromCodeNames(this.state.mapCodeOptions)
    );
    const mapCode = this.filterSelectOptions(mapCodeOptions, this.state.mapCode);

    const unitCodeOptions = this.sortLabels(
      this.getSelectOptionsFromCodeNames(this.state.unitCodeOptions)
    );
    const unitCode = this.filterSelectOptions(unitCodeOptions, this.state.unitCode);

    const studyPeriodOptions = this.sortLabelMonths(
      this.getSelectOptionsFromCodeNames(this.state.studyPeriodOptions)
    )
    const studyPeriod = this.filterSelectOptions(studyPeriodOptions, this.state.studyPeriod);


    const genderLegend = getLegend({
      loaded: loaded,
      propName: 'gender',
    });

    const loginLegend = getLegend({
      loaded: loaded,
      propName: 'lastLoginBand',
      legend: ['This week'],
    });

    const residencyLegend = getLegend({
      loaded: loaded,
      propName: 'residency',
    });

    const sesStatusLegend = getLegend({
      loaded: loaded,
      propName: 'sesStatus',
    });

    const studyModeLegend = getLegend({
      loaded: loaded,
      propName: 'studyMode',
    });

    const isFiltered = !deep_equal(params, defaultDashboardParams);

    return (
      <section className="filterBox">
        <div className="header">
        { isFiltered && (
            <Link to={linkToUnsetFilter}>
              <FontAwesomeIcon
                style={{
                  maxWidth: '1rem',
                  paddingRight: '.265rem',
                  color: config.colors.cquGreen,
                }}
                icon={faCancel}
              />
              Clear All
            </Link>
        )}
        </div>
        { dashboardParams.includes("units") && (
        <fieldset>
          <legend>Units</legend>
          <Select
            value={unitCode}
            isMulti
            styles={reactSelectStyles}
            classNamePrefix="react-select"
            options={unitCodeOptions}
            placeholder="Units"
            onChange={selected => {
              this.setState({unitCode: this.getCodes(selected)})
            }}
          />
        </fieldset>
        )}
        { dashboardParams.includes("campuses") && (
        <fieldset>
          <legend>Campuses</legend>
          <Select
            value={campusCode}
            isMulti
            styles={reactSelectStyles}
            classNamePrefix="react-select"
            options={campusCodeOptions}
            placeholder="Campuses"
            onChange={selected => {
              this.setState({campusCode: this.getCodes(selected)})
            }}
          />
        </fieldset>
        )}
        { dashboardParams.includes("courseCampuses") && (
        <fieldset>
          <legend>Course Campuses</legend>
          <Select
            value={courseCampusCode}
            isMulti
            styles={reactSelectStyles}
            classNamePrefix="react-select"
            options={courseCampusCodeOptions}
            placeholder="Campuses"
            onChange={selected => {
              this.setState({courseCampusCode: this.getCodes(selected)})
            }}
          />
        </fieldset>
        )}
        { dashboardParams.includes("groups") && (
        <fieldset>
          <legend>Moodle Groups</legend>
          <Select
            value={groupCode}
            isMulti
            styles={reactSelectStyles}
            classNamePrefix="react-select"
            options={groupCodeOptions}
            placeholder="Moodle Groups"
            onChange={selected => {
              this.setState({groupCode: this.getCodes(selected)})
            }}
          />
        </fieldset>
        )}
        { dashboardParams.includes("maps") && (
        <fieldset>
          <legend>MAP Status</legend>
          <Select
            value={mapCode}
            isMulti
            styles={reactSelectStyles}
            classNamePrefix="react-select"
            options={mapCodeOptions}
            placeholder="MAP Status"
            onChange={selected => {
              this.setState({mapCode: this.getCodes(selected)})
            }}
          />
        </fieldset>
        )}
        { dashboardParams.includes("courses") && (
        <fieldset>
          <legend>Courses</legend>
          <Select
            value={courseCode}
            isMulti
            styles={reactSelectStyles}
            classNamePrefix="react-select"
            options={courseCodeOptions}
            placeholder="Courses"
            onChange={selected => {
              this.setState({courseCode: this.getCodes(selected)})
            }}
          />
        </fieldset>
        )}
        { dashboardParams.includes("intakeMonth") && (
        <fieldset>
          <legend>Intake Month</legend>
          <Select
            value={studyPeriod}
            isMulti
            styles={reactSelectStyles}
            classNamePrefix="react-select"
            options={studyPeriodOptions}
            placeholder="Month"
            onChange={selected => {
              this.setState({studyPeriod: this.getCodes(selected)})
            }}
          />
        </fieldset>
        )}
        { dashboardParams.includes("minAge") && (
        <fieldset>
          <legend>Age</legend>
          <div style={{
            margin: '20px',
            width: '200px',
            paddingTop: '20px',
          }}
          >
          <Range
            min={ageRange[0]} // integer!
            max={ageRange[1]} // integer!
            marks={{
              [ageRange[0]]: ageRange[0],
              [ageRange[1]]: ageRange[1]
            }}
            allowCross={true} // must set for pushable to work
            pushable={true}
            value={[parseInt(minAge),parseInt(maxAge)]}
            tipFormatter={value => `${value}`}
            tipProps={{
              placement: "top",
              visible: true,
              prefixCls: "filterBoxTooltip rc-slider-tooltip"
            }}
            onChange={this.handleAgeRange}
            onAfterChange={this.handleAgeRangeUpdated}
          />
          </div>
        </fieldset>
        )}
        { dashboardParams.includes("gender") && (
        <fieldset>
          <legend>Gender</legend>
          <FilterButtons
            dashboardParams={dashboardParams}
            defaultDashboardParams={defaultDashboardParams}
            loaded={loaded}
            params={params}
            paramName={'gender'}
            legend={genderLegend}
          />
        </fieldset>
        )}
        { dashboardParams.includes("lastLogin") && (
        <fieldset>
          <legend>Login</legend>
          <FilterButtons
            dashboardParams={dashboardParams}
            defaultDashboardParams={defaultDashboardParams}
            loaded={loaded}
            params={params}
            paramName={'lastLogin'}
            legend={loginLegend}
            />
        </fieldset>
        )}
        { dashboardParams.includes("residency") && (
        <fieldset>
          <legend>Residency</legend>
          <FilterButtons
            dashboardParams={dashboardParams}
            defaultDashboardParams={defaultDashboardParams}
            loaded={loaded}
            params={params}
            paramName={'residency'}
            legend={residencyLegend}
            />
        </fieldset>
        )}
        { dashboardParams.includes("studyMode") && (
        <fieldset>
          <legend>Study Mode</legend>
          <FilterButtons
            dashboardParams={dashboardParams}
            defaultDashboardParams={defaultDashboardParams}
            loaded={loaded}
            params={params}
            paramName={'studyMode'}
            legend={studyModeLegend}
            />
        </fieldset>
        )}
        { dashboardParams.includes("sesStatus") && (
        <fieldset>
          <legend>SES Status</legend>
          <FilterButtons
            dashboardParams={dashboardParams}
            defaultDashboardParams={defaultDashboardParams}
            loaded={loaded}
            params={params}
            paramName={'sesStatus'}
            legend={sesStatusLegend}
          />
        </fieldset>
        )}
      </section>
    );
  }
}

export default withRouter(DashboardFilter)