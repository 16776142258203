import React  from "react";
import { config } from "../../../../utils/config"
import { Formik, Field, ErrorMessage } from 'formik';
import axios from "axios";
import * as yup from "yup";

const ActivityBandForm = (props) => {

  const bandSchema = yup.object().shape({
    low: yup.number().integer().required().positive().lessThan(101),
    high: yup.number().integer().required().positive().lessThan(101),
  });

  return (
    <Formik
      initialValues={{
        low: props.initialValues.low,
        high: props.initialValues.high
      }}
      onSubmit={(values, { setSubmitting }) => {
        axios
          .post(config.system.baseApiUrl + "/config/activity-bands", values)
          .then(() => setSubmitting(false));
      }}
      validationSchema={bandSchema}
      render={({
        values,
        errors,
        status,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
      }) => (
          <form onSubmit={handleSubmit}>
            <table className="oddEvenRows">
              <tbody>
                <tr>
                    <th colSpan={2}>Activity per Week</th>
                </tr>
                <tr>
                  <td>
                      <div><span className="fg-low">⬤</span> Low activity is less than:</div>
                  </td>
                  <td>
                    <Field name="low" type="integer" className="config config-percentage" />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                      <div><span className="fg-medium">⬤</span> Medium Activity is between low and high.</div>
                  </td>
                </tr>
                <tr>
                  <td>
                      <div><span className="fg-high">⬤</span> High Activity is greater than:</div>
                  </td>
                  <td>
                    <Field name="high" type="integer" className="config config-percentage" />
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <button type="submit" disabled={isSubmitting}>
                      { isSubmitting ? "Save..." : "Submit" }
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>

            <div><ErrorMessage name="low" /></div>
            <div><ErrorMessage name="high" /></div>
        </form>
      )}
    />
  );
}

export { ActivityBandForm }
