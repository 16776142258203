import React, { Component } from "react";

import axios from "axios";
import moment from 'moment-timezone';
import Cookies from "js-cookie";

import { config } from '../../utils/config';
import { ModalBackground } from '..';
import { read_snooze_cookie, test_snooze_cookie } from '../../utils/utils';

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faBell,
  faTimesCircle,
  faCalendar,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faBell,
  faTimesCircle,
  faCalendar,
);
moment.tz.setDefault('Australia/Brisbane');

/**
 * ----------------------------------------------------------------------
 * SuccessStrategyNotices display the 'notices' info summarised by the
 * ResponseWrapper in the API. This can be dropped into any page.
 * ----------------------------------------------------------------------
 */

class SuccessStrategyNotices extends Component {

  constructor(props) {
    super(props);
    this.state = {
      notices: props.notices,
      snooze: read_snooze_cookie(), // moment|null
      showPrevious: false,
    }
    this.handleSnooze = this.handleSnooze.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleStrategyCallback = this.handleStrategyCallback.bind(this);
    this.handleShowPrevious = this.handleShowPrevious.bind(this);
  };

  hasMissingProps() {
    return [
      this.props.user === undefined,
      this.props.notices === undefined,
      this.props.openedByUser === undefined,
      this.props.handleClose === undefined,
    ].some(_ => _ === true);
  }

  renderPathQuery(path_query, triggers, suffix="") {
    return "/predictions?path=" + path_query + suffix + "#" + triggers.join('/');
  }

  handleShowPrevious(event) {
    event.stopPropagation();
    this.setState({
      showPrevious: this.state.showPrevious === true ? false : true,
    });
  }

  handleSnooze(event) {
    event.stopPropagation();
    const expires = moment().add(15, 'minutes');
    Cookies.set(
      'snooze-notifications',
      expires.format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS),
      {
        expires: expires.toDate(),
      }
    );
    this.setState({
      snooze: expires,
      showPrevious: false, // <-- always reset on close.
    });
    //  console.log('SET SNOOZE', expires.format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS));
  }

  handleClose(event) {
    event.stopPropagation();
    const expires = moment().endOf('day');
    Cookies.set(
      'snooze-notifications',
      expires.format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS),
      {
        expires: expires.toDate(),
      }
    );
    this.setState({
      snooze: expires,
      showPrevious: false, // <-- always reset on close.
    });
    this.props.handleClose(event);
  }

  handleStrategyCallback(method, id, path_query, triggers) {
    return event => {
      const url = [
        config.system.baseApiUrl,
        "success-strategies",
        method,
        id,
      ].join('/');
      axios
        .get(url)
        .then(response => {
          if (method !== 'suspend') {
            this.setState({
              notices: this.state.notices.filter(_ => _.id !== id),
            });
          }
          if (method === 'proceed') {
            const suffix = "&successStrategyNotices=NO";
            window.location = this.renderPathQuery(path_query, triggers, suffix);
          }
          if (method === 'suspend') {
            var notices = this.state.notices.map(_ => {
              if (_.id === id) {
                //  Make the page think this is not due for a reminder yet:
                _.suspend_time = 1;  // <-- any not-null value.
                _.suspend_diff_days = 0;  // <-- any non-positive value
              }
              return _;
            });
            this.setState({
              notices: notices
            })
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  render() {

    if (this.hasMissingProps()) {
      return (
        <React.Fragment>
          <h4>SuccessStrategyNotices</h4>
          <p>Missing properties</p>
          <div>{JSON.stringify(this.props)}</div>
        </React.Fragment>
      );
    }

    const user = this.props.user;
    const notices = this.state.notices;
    const snooze = this.state.snooze;
    const showPrevious = this.state.showPrevious;

    var priorNotices = [];
    var todayNotices = [];
    var laterNotices = [];
    notices.forEach(_ => {
      if (_.dismiss_time !== null || _.proceed_time !== null) {
        priorNotices.push(_);
      } else if (_.suspend_time === null || _.suspend_diff_days > 0) {
        todayNotices.push(_);
      } else {
        laterNotices.push(_);
      }
    });

    const openedByUser = this.props.openedByUser;
    const suppressedByUri = window.location.search.includes("&successStrategyNotices=NO");
    const suppressedBySnooze = test_snooze_cookie(snooze);

    if (openedByUser && notices.length === 0) {
      return null;
    }
    if (!openedByUser && (suppressedByUri || suppressedBySnooze)) {
      return null;
    }
    if (todayNotices.length === 0) {
      return null;
    }

    return (

      <React.Fragment>
        <ModalBackground
          handleClose = {openedByUser ? this.handleClose : event => {return true;}}
        />
        <aside id="popoverForSuccessStrategyNotices" className="middleCenter noPadding" style={{
          height: '38rem',
        }}>
          <h2 className="popverTitle">
            <button
              className="popverTitleRightButton"
              onClick={this.handleClose}
            >
              { openedByUser ? (
                <React.Fragment>Close</React.Fragment>
              ) : (
                <React.Fragment>Snooze Today</React.Fragment>
              ) }
            </button>
            { !openedByUser && (
            <button
              className="popverTitleRightButton"
              onClick={this.handleSnooze}
            >
                Snooze 15m
            </button>
            ) }
            <FontAwesomeIcon icon={faBell} /> Success Strategy notices for @{user.username}
          </h2>
          <div className="verticalScroll horizontalScroll" style={{
            padding: '0rem 0.5rem',
          }}>
            <div style={{
              padding: '0rem 0.7rem 1rem 0.7rem',
              color: '#888',
            }}>
              <div style={{ float: 'right' }}>
                <input
                  id="showPrevious"
                  type="checkbox"
                  onChange={this.handleShowPrevious}
                  checked={showPrevious}
                /> <label
                  htmlFor="showPrevious"
                >
                  Show previous
                </label>
              </div>
              { showPrevious ? (
                <div>You have previously reviewed or dismissed these notifications.</div>
              ) : (
                <div>These notifications currently require your attention.</div>
              )}
            </div>
            <table className="dataTable oddEvenRows cellPadding cellAlignTop">
              <tbody>
                {!showPrevious && todayNotices.map((_, index) => (
                  <tr key={index}>
                    <td style={{width: '10%'}}>
                      <button
                        className="primaryButton"
                        onClick={this.handleStrategyCallback('proceed', _.id, _.path_query, _.triggers)}
                        disabled={_.path_query === null}
                      >
                        Review
                      </button>
                    </td>
                    <td style={{width: '10%'}}>
                      <button
                        className="secondaryButton"
                        onClick={this.handleStrategyCallback('suspend', _.id, _.path_query, _.triggers)}
                     >
                        Remind&nbsp;me<br/>tomorrow
                      </button>
                    </td>
                    <td style={{width: "70%"}}>
                      <div style={{
                          color: config.colors.cquBlue,
                      }}>
                        <b>{_.display_name}</b>
                      </div>
                      <div style={{
                        padding: '0.3rem 0',
                      }}>
                        <small>
                          <b>{_.path_query}</b> &mdash; {_.created_diff} now
                        </small>
                      </div>
                      <div>{_.display_text}</div>
                    </td>
                    <td>
                      <button
                        style={{
                          color: 'white',
                          backgroundColor: 'darkred',
                        }}
                        onClick={this.handleStrategyCallback('dismiss', _.id, _.path_query, _.triggers)}
                      >
                        Dismiss
                      </button>
                    </td>
                  </tr>
                ))}
                {!showPrevious && laterNotices.length > 0 && (
                  <tr>
                    <td colSpan={3}>
                      <div><small>To be reminded later...</small></div>
                    </td>
                    <td></td>
                  </tr>
                )}
                {!showPrevious && laterNotices.length > 0 && laterNotices.map((_, index) => (
                  <tr key={index}>
                    <td style={{width: '10%'}}>
                      <button
                        className="primaryButton"
                        onClick={this.handleStrategyCallback('proceed', _.id, _.path_query, _.triggers)}
                      >
                        Review
                      </button>
                    </td>
                    <td style={{width: '10%'}}>
                      <button
                        className="secondaryButton"
                        disabled={true}
                        style={{
                          color: '#ddd',
                        }}
                      >
                        Remind&nbsp;me<br/>tomorrow
                      </button>
                    </td>
                    <td style={{width: "70%"}}>
                      <div style={{
                          color: config.colors.cquBlue,
                      }}>
                        <b>{_.display_name}</b>
                      </div>
                      <div style={{
                        padding: '0.3rem 0',
                      }}>
                        <small>
                          <b>{_.path_query}</b> &mdash; {_.created_diff} now
                        </small>
                      </div>
                      <div>{_.display_text}</div>
                    </td>
                    <td style={{width: '10%'}}>
                      <button
                        style={{
                          color: 'white',
                          backgroundColor: 'darkred',
                        }}
                        onClick={this.handleStrategyCallback('dismiss', _.id, _.path_query, _.triggers)}
                      >
                        Dismiss
                      </button>
                    </td>
                  </tr>
                ))}
                {showPrevious && priorNotices.length > 0 && priorNotices.map((_, index) => (
                  <tr key={index}>
                    <td style={{width: '10%'}}>
                      <button
                        className="primaryButton"
                        onClick={this.handleStrategyCallback('proceed', _.id, _.path_query, _.triggers)}
                      >
                        Review
                      </button>
                    </td>
                    <td style={{width: '10%'}}>
                      <button
                        className="secondaryButton"
                        disabled={true}
                        style={{
                          color: '#ddd',
                        }}
                      >
                        Remind&nbsp;me<br/>tomorrow
                      </button>
                    </td>
                    <td style={{width: "70%"}}>
                      <div style={{
                          color: config.colors.cquBlue,
                      }}>
                        <b>{_.display_name}</b>
                      </div>
                      <div style={{
                        padding: '0.3rem 0',
                      }}>
                        <small>
                          <b>{_.path_query}</b> &mdash; <b>Created</b> {_.created_diff} now
                          { _.proceed_diff !== null && (
                            <span> &middot; <b>Reviewed</b> {_.proceed_diff} created</span>
                          )}
                          { _.dismiss_diff !== null && (
                            <span> &middot; <b>Dismissed</b> {_.dismiss_diff} created</span>
                          )}
                        </small>
                      </div>
                      <div>{_.display_text}</div>
                    </td>
                    <td style={{width: '10%'}}>
                      <button
                        className="secondaryButton"
                        disabled={true}
                        style={{
                          color: '#ddd',
                        }}
                      >
                        Dismiss
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {showPrevious && notices.length === 50 && (
              <div style={{
                textAlign: 'center',
                paddingTop: '0.5rem',
                paddingBottom: '1rem',
              }}>
                <small>(the maximum of 50 most recent notifications are being displayed)</small>
              </div>
            )}
          </div>
        </aside>
      </React.Fragment>
    );
  }
}

export {
  SuccessStrategyNotices,
}
