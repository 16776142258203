import React, { createContext } from "react";
import { Modal } from "../components";
import useModal from "../hooks/useModal";

let ModalContext;
const { Provider } = (ModalContext = createContext());

/**
 * This function components allows any component to set the contents of a global modal.
 *
 * @param {*} children
 * @returns
 */
const ModalProvider = ({ children }) => {
  let { modal, handleModal, modalContent } = useModal();
  return (
    <Provider value={{ modal, handleModal, modalContent }}>
      <Modal />
      {children}
    </Provider>
  );
};

export { ModalContext, ModalProvider };
