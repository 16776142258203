import React from 'react';
import { PASS_RATE_NULL } from '../../../utils/constants';

/**
 * Return a table cell element with the a formatted Pass Rate value.
 */
const PassRateCell = ({ enabled = true, value }) => {
    if (!enabled) {
        return <></>;
    }

    return <td align='right'>{value === PASS_RATE_NULL ? '-' : `${Number(value).toFixed()}%`}</td>
};

export default PassRateCell;