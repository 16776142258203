import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { AimsStudentLink, IncognitoText, StudentDashboardLink } from '../../../../components';
import { HeaderContext } from '../../../../context/HeaderContext';

const StudentTable = ({ students }) => {
    const { year, term, unit } = useParams();
    const { incognito } = useContext(HeaderContext);

    return (
        <table className="dataTable" style={{ margin: "0px auto" }}>
            <tbody>
                <tr>
                    <th>Student One ID</th>
                    <th>Name (Last, First)</th>
                </tr>
                {students.sort((a, b) => a.last_name.localeCompare(b.last_name)).map((student, index) => (
                    <tr key={index}>
                        <td>
                            <AimsStudentLink studentCode={student.code}>
                                <IncognitoText text={student.id} incognito={incognito} />
                            </AimsStudentLink>
                        </td>
                        <td>
                            <StudentDashboardLink studentId={student.id} unitCode={unit} term={{year, term}}>
                                <IncognitoText text={`${student.last_name}, ${student.first_name}`} incognito={incognito} />
                            </StudentDashboardLink>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default StudentTable;
