import React, { useContext, useState } from 'react';
import { HeaderContext, HeaderContextProvider } from '../../context/HeaderContext';
import { ModalProvider } from '../../context/ModalContext';
import { Header, Loader } from '../../components';
import Error from '../Error/Error';
import { TermSelect } from '../Home/components';
import ResourceDiagnostics from './components/ResourceDiagnostics/ResourceDiagnostics';
import { fetchTermCampuses, fetchTermColleges, fetchTermCourses, fetchTermSchools, fetchTermUnits } from '../../utils/fetch';
import useTerms from '../../hooks/useTerms';
import TermDiagnostics from './components/TermDiagnostics/TermDiagnostics';

/**
 * The site diagnostics is wrapped. This allows the cohort to use context.
 */
const SiteDiagnostics = ({ incognito }) => (
    <HeaderContextProvider incognito={incognito}>
        <ModalProvider>
            <Main />
        </ModalProvider>
    </HeaderContextProvider>
);


const Main = () => {
    const home = useContext(HeaderContext);
    const terms = useTerms();
    const [selTerm, setSelTerm] = useState();
    const isLoading = home.isLoading || terms.isLoading;
    const isError = home.isError || terms.isError;

    if (isLoading && !isError) {
        return <Loader isLoadingHome={home.isLoading} isLoadingAssets={terms.isLoading} />;
    }

    if (isError) {
        return <Error error={home.error || terms.isError} />
    }

    return <>
        {/* <Header config={home.config}
            calendar={home.calendar}
            imports={home.imports}
            user={home.user}
            notices={home.notices}
            incognito={home.incognito}
            handleUpdateIncognito={home.setIncognito}
            services={[]}
            breadcrumbs={[
                ['Diagnostics'],
            ]}
        /> */}

        <TermSelect terms={terms.data} value={selTerm?.id} onChange={({value}) => setSelTerm(terms.data.find(element => element.id === value))} />
        <TermDiagnostics enabled={!!selTerm} year={selTerm?.year} term={selTerm?.term}/>
        <ResourceDiagnostics header={'Campuses'} method={fetchTermCampuses} year={selTerm?.year} term={selTerm?.term}/>
        <ResourceDiagnostics header={'Colleges'} method={fetchTermColleges} year={selTerm?.year} term={selTerm?.term}/>
        <ResourceDiagnostics header={'Courses'} method={fetchTermCourses} year={selTerm?.year} term={selTerm?.term}/>
        <ResourceDiagnostics header={'Schools'} method={fetchTermSchools} year={selTerm?.year} term={selTerm?.term}/>
        <ResourceDiagnostics header={'Units'} method={fetchTermUnits} year={selTerm?.year} term={selTerm?.term}/>
    </>
}

export default SiteDiagnostics;
