import React, { createContext, useEffect, useState } from 'react';
import { LOG } from '../utils/constants';
import useRouteParams from '../hooks/useRouteParams';
import useCustomParams from '../hooks/useCustomParams';
import { flattenEnrollment, sendLog } from '../utils/utils';

const SelectedEnrollmentsContext = createContext(new Map());

export const SelectedEnrollmentsProvider = ({ children, dashboard }) => {
  const [selectedEnrollments, setSelectedEnrollments] = useState(new Map());
  const [previousSelectedEnrollment, setPreviousSelectedEnrollment] = useState(null);
  const [previousCount, setPreviousCount] = useState(0);
  const {year, term} = useRouteParams();
  const { path } = useCustomParams();

  /**
   * Every time the selection changes we'll send a event log.
   */
  useEffect(() => {
    const currentCount = selectedEnrollments.size;
    const hasSelected = currentCount >= previousCount;
    const action = hasSelected ? LOG.ACTION.SELECT : LOG.ACTION.DISCARD;
    let eventname = "App\\Events\\";
    eventname += dashboard.charAt(0).toUpperCase() + dashboard.slice(1) + '\\';
    eventname += hasSelected ? 'Selected' : 'Discarded';

    sendLog(eventname, 'r', action, LOG.TARGET.ENROLLMENTS, dashboard, {
      term: { year, term },
      path,
      enrollments: Array.from(selectedEnrollments.values()).map( flattenEnrollment )
    });
    setPreviousCount(selectedEnrollments.size);
  }, [selectedEnrollments]);

  const addSelectedEnrollment = (enrollment) => {
    const newSelection = new Map(selectedEnrollments).set(enrollment.id, enrollment)
    setSelectedEnrollments(newSelection);
  }

  const addMultipleSelectedEnrollments = (enrollments) => {
    const newSelection = new Map(selectedEnrollments);
    enrollments.forEach(enrollment => newSelection.set(enrollment.id, enrollment));
    setSelectedEnrollments(newSelection);
  }

  const clearAndAddMultipleSelectedEnrollments = (enrollments) => {
    const newSelection = new Map();
    enrollments.forEach(enrollment => newSelection.set(enrollment.id, enrollment));
    setSelectedEnrollments(newSelection);
  }

  const removeSelectedEnrollment = (enrollmentId) => {
    const newSelection = new Map(selectedEnrollments);
    newSelection.delete(enrollmentId);
    setSelectedEnrollments(newSelection);
  }

  const clearSelectedEnrollments = () => {
    setSelectedEnrollments(new Map());
  }

  const setPrevSelectedEnrollment = (row) => {
    setPreviousSelectedEnrollment(row);
  }

  const clearPrevSelectedEnrollment = () => {
    setPreviousSelectedEnrollment(null);
  }

  return (
    <SelectedEnrollmentsContext.Provider value={{
      selectedEnrollments,
      previousSelectedEnrollment,
      setPrevSelectedEnrollment,
      clearPrevSelectedEnrollment,
      addSelectedEnrollment,
      addMultipleSelectedEnrollments,
      removeSelectedEnrollment,
      clearSelectedEnrollments,
      clearAndAddMultipleSelectedEnrollments
    }}>
      {children}
    </SelectedEnrollmentsContext.Provider>
  );
}

export default SelectedEnrollmentsContext;
