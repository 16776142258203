import { config } from "./config";
import axios from 'axios';
import { PAGINATOR_TYPE_CURSOR, PAGINATOR_TYPE_OFFSET } from "./constants";

/**
 * Combines all pages from API and returns them.
 *
 * @param {string} uri
 * @param {object} params
 */
async function fetchPaginator(uri, params = {}, type = PAGINATOR_TYPE_OFFSET) {
    if (type === PAGINATOR_TYPE_CURSOR) {
        return fetchCursorPaginator(uri, params);
    }

    return fetchOffsetPaginator(uri, params);
}

/**
 * Fetches all data from paginated api, using pages.
 *
 * @param {string} uri
 * @param {object} params
 */
async function fetchOffsetPaginator(uri, params = {}) {
    let records = [];
    let page = 0;
    let totalPages = 0;

    do {
        let { data: response } = await axios.get(uri, { params: { ...params, page: ++page} });
        totalPages = response.meta.pagination.total_pages;
        records = records.concat(response.data);
    } while (page < totalPages);

    return records;
}

/**
 * Fetches all data from paginated api, using a cursor.
 *
 * @param {string} uri
 * @param {object} params
 */
async function fetchCursorPaginator(uri, params = {}) {
    let records = [];
    let nextCursor = undefined;

    do {
        let { data: response } = await axios.get(uri, { params: { ...params, cursor: nextCursor} });
        nextCursor = response.next_cursor;
        records = records.concat(response.data);
    } while (nextCursor !== null);

    return records;
}


/**
 * Fetch the enrollments in a term from laravel api.
 *
 * @param {number} year The year of the term to fetch
 * @param {string} term The term of the term to fetch
 * @param {URLSearchParams} query The search params to target
 *
 * @returns {Array}
 */
export async function fetchTermEnrollments(year, term, query) {
    const uri = `${config.system.baseApiUrl}/terms/${year}/${term}/enrollments`;
    const params = {
                limit: 10000,
                path: query.get('path'),
    };

    return await fetchPaginator(uri, params, PAGINATOR_TYPE_CURSOR);
}

/**
 * Fetch the campuses in a term from laravel api.
 *
 * @param {number} year The year of the term to fetch
 * @param {string} term The term of the term to fetch
 * @param {bool} statistics If true the api will fetch from statistics endpoint.
 *
 * @returns {Array}
 */
export async function fetchTermCampuses(year, term, statistics = false) {
    const uri = `${config.system.baseApiUrl}/terms/${year}/${term}/campuses`;
    const params = { limit: 5000, statistics };

    return await fetchPaginator(uri, params, PAGINATOR_TYPE_CURSOR);
}

/**
 * Fetch the colleges in a term from laravel api.
 *
 * @param {number} year The year of the term to fetch
 * @param {string} term The term of the term to fetch
 * @param {bool} statistics If true the api will fetch from statistics endpoint.
 *
 * @returns {Array}
 */
export async function fetchTermColleges(year, term, statistics = false) {
    const uri = `${config.system.baseApiUrl}/terms/${year}/${term}/colleges`;
    const params = { limit: 5000, statistics };

    return await fetchPaginator(uri, params, PAGINATOR_TYPE_CURSOR);
}

/**
 * Fetch the student courses in a term from laravel api.
 *
 * @param {number} year The year of the term to fetch
 * @param {string} term The term of the term to fetch
 *
 * @returns {Array}
 */
export async function fetchStudentCourses(year, term) {
    const uri = `${config.system.baseApiUrl}/terms/${year}/${term}/student-courses`;
    const params = { limit: 5000 };

    return await fetchPaginator(uri, params, PAGINATOR_TYPE_CURSOR);
}

/**
 * Fetch the schools in a term from laravel api.
 *
 * @param {number} year The year of the term to fetch
 * @param {string} term The term of the term to fetch
 * @param {bool} statistics If true the api will fetch from statistics endpoint.
 *
 * @returns {Array}
 */
export async function fetchTermSchools(year, term, statistics = false) {
    const uri = `${config.system.baseApiUrl}/terms/${year}/${term}/schools`;
    const params = { limit: 5000, statistics };

    return await fetchPaginator(uri, params, PAGINATOR_TYPE_CURSOR);
}

/**
 * Fetch the courses in a term from laravel api.
 *
 * @param {number} year The year of the term to fetch
 * @param {string} term The term of the term to fetch
 * @param {bool} statistics If true the api will fetch from statistics endpoint.
 *
 * @returns {Array}
 */
export async function fetchTermCourses(year, term, statistics = false) {
    const uri = `${config.system.baseApiUrl}/terms/${year}/${term}/courses`;
    const params = { limit: 5000, statistics };

    return await fetchPaginator(uri, params, PAGINATOR_TYPE_CURSOR);
}

/**
 * Fetch the units in a term from laravel api.
 *
 * @param {number} year The year of the term to fetch
 * @param {string} term The term of the term to fetch
 * @param {bool} statistics If true the api will fetch from statistics endpoint.
 *
 * @returns {Array}
 */
export async function fetchTermUnits(year, term, statistics = false) {
    const uri = `${config.system.baseApiUrl}/terms/${year}/${term}/units`;
    const params = { limit: 5000, statistics };

    return await fetchPaginator(uri, params, PAGINATOR_TYPE_CURSOR);
}

/**
 * Fetch the terms in a term from laravel api.
 *
 * @returns {Array}
 */
export async function fetchTerms() {
    const uri = `${config.system.baseApiUrl}/terms?include=weeks,dates`;
    const params = { limit: 5000 };

    return await fetchPaginator(uri, params);
}

/**
 * Fetch the students in a term from laravel api.
 *
 * @param {number} year The year of the term to fetch
 * @param {string} term The term of the term to fetch
 *
 * @returns {Array}
 */
export async function fetchTermStudents(year, term) {
    const uri = `${config.system.baseApiUrl}/terms/${year}/${term}/students`;
    const params = { limit: 5000 };

    return await fetchPaginator(uri, params);
}

/**
 * Fetch the assessments of a unit from laravel api.
 *
 * @param {number} unitId The id of the unit to fetch the assessments from.
 *
 * @returns {Array}
 */
export async function fetchAssessments(unitId) {
    const uri = `${config.system.baseApiUrl}/units/${unitId}/assessments`;
    const params = { limit: 5000 };

    return await fetchPaginator(uri, params, PAGINATOR_TYPE_CURSOR);
}

/**
 * Fetch the assessment extensions of a unit from laravel api.
 *
 * @param {number} unitId The id of the unit to fetch the assessment extensions from.
 *
 * @returns {Array}
 */
export async function fetchAssessmentExtensions(unitId) {
    const uri = `${config.system.baseApiUrl}/units/${unitId}/assessment-extensions`;
    const params = { limit: 5000 };

    return await fetchPaginator(uri, params, PAGINATOR_TYPE_CURSOR);
}

/**
 * Fetch the assessments of a term for a student from laravel api.
 *
 * @param {integer} year
 * @param {string} term
 * @param {number} studentId The id of the student to fetch the assessments for.
 *
 * @returns {Array}
 */
export async function fetchAssessmentsInTermForStudent(year, term, studentId) {
    const uri = `${config.system.baseApiUrl}/terms/${year}/${term}/students/${studentId}/assessments`;
    const params = { limit: 5000 };

    return await fetchPaginator(uri, params, PAGINATOR_TYPE_CURSOR);
}

/**
 * This function pulls data required to load the header and also pulls term enrollment data.
 *
 * @param {integer} year
 * @param {string} term
 * @param {URLSearchParams} query
 * @returns {Object}
 */
export async function fetchHomeAndTermEnrollmentData(year, term, query) {
    const response = await axios.get(config.system.baseApiUrl + '/home');
    const data1 = response.data;

    const data2 = await fetchTermEnrollments(year, term, query);

    return { home: data1, enrollments: data2 };
}