import React from 'react';
import Slider from 'rc-slider';
import QueryString from '../../utils/queryString';
import { useHistory } from 'react-router-dom';
const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);

const AgeDashboardFilter = ({ enrollments }) => {
    const history = useHistory();
    const ageRange = enrollments.reduce((acc, obj) => {
        return {
            min: Math.min(acc.min, obj.student.age),
            max: Math.max(acc.max, obj.student.age)
        }
    }, { min: Infinity, max: -Infinity });

    const qs = new QueryString(window.location.pathname +  window.location.search);
    const minAge = qs.query.minAge || ageRange.min;
    const maxAge = qs.query.maxAge || ageRange.max;

    const handleChange = (data) => {
        const [min, max] = data;

        qs.query.minAge = min;
        qs.query.maxAge = max;

        history.replace(qs.encode());
    }

    return (
        <Range
            min={ageRange.min}
            max={ageRange.max}
            marks={{
                [ageRange.min]: ageRange.min,
                [ageRange.max]: ageRange.max
            }}
            allowCross={true}
            pushable={true}
            value={[parseInt(minAge), parseInt(maxAge)]}
            tipFormatter={value => `${value}`}
            tipProps={{
                placement: "bottom",
                visible: true,
                prefixCls: "filterBoxTooltip rc-slider-tooltip",
                getTooltipContainer: () => document.querySelector("article.sidebar")
            }}
            onChange={handleChange}
        />
    );
}

export default AgeDashboardFilter;