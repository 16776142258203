import React from 'react';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import QueryString from '../../utils/queryString';

const CampusDashboardFilter = ({enrollments, value, parameter}) => {
    const history = useHistory();
    const campuses = enrollments.reduce( (acc, obj) => acc.add(obj.campus), new Set());

    const options = Array.from(campuses).map(({code, name}) => {
        return { value: code, label: `${code}: ${name}` };
    });

    const onChange = (data) => {
        const qs = new QueryString(window.location.pathname + window.location.search);

        if (!data) {
            delete qs.query[parameter];
            history.replace(qs.encode());
            return;
        }

        qs.query[parameter] = data.map( data => data.value).join();
        history.replace(qs.encode());
    }

    return (
        <Select
            isMulti
            options={options}
            value={options.filter( obj => value.includes(obj.value))}
            placeholder="Campuses ..."
            onChange={onChange}
            classNamePrefix="react-select"
        />
    );
};

export default CampusDashboardFilter;