import React, { useEffect, useRef } from 'react';

/**
 * Component that triggers an action when its children become visible in the viewport.
 *
 * @param {Object} props - Component props.
 * @param {Function} props.action - The function to execute when the element is visible.
 * @param {ReactNode} props.children - The child components or content.
 * @returns {ReactNode} The component rendering its children.
 */
const ActionWhenVisible = ({ action, children }) => {
    const ref = useRef(null);

    useEffect(() => {
        const observerOptions = {
            root: null, // Use the viewport as the root
            threshold: 0, // Trigger the callback when any part of the element is visible
        };

        const handleIntersection = (entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    action();
                    // Stop observing once the element is visible
                    observer.unobserve(entry.target);
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersection, observerOptions);

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, [action]);

    return <center ref={ref}>{children}</center>;
};

export default ActionWhenVisible;
