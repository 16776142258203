import React, { useEffect, useRef } from 'react';

const InteractionPreviewListItem = ({ preview }) => {
    const ref = useRef();

    const handleLinkClick = (event) => {
        const target = event.target;

        if (target.tagName === 'A' && target.getAttribute('target') !== '_blank') {
            const confirmLeave = window.confirm(`Are you sure you want to leave this page? \nAbout to open ${target.getAttribute('href')}`);
            if (!confirmLeave) {
                event.preventDefault();
            }
        }
    };

    useEffect(() => {
        const element = ref.current;
        element.addEventListener('click', handleLinkClick);

        return () => {
            element.removeEventListener('click', handleLinkClick);
        };
    }, []);

    return (
        <div className="emailPreview" style={preview.send_email === true ? {} : {opacity: 0.4}}>
            <div className="emailPreviewSubject">{preview.subject}</div>
            <div className="emailPreviewBodyText" ref={ref} dangerouslySetInnerHTML={{ __html: preview.body_text }} />
            <div>
                <img src="/cqu-mailout-footer.png" alt="CQU Email Footer" />
            </div>
        </div>
    );
}

export default InteractionPreviewListItem;
