import React  from "react";
import { config } from "../../../../utils/config"
import { Formik, Field, ErrorMessage } from 'formik';
import axios from "axios";
//import * as yup from "yup";

const MaintenanceForm = (props) => {

  return (
    <Formik
      initialValues={props.initialValues}
      onSubmit={(values, { setSubmitting }) => {
        axios
          .post(config.system.baseApiUrl + "/config/maintenance", values)
          .then(() => setSubmitting(false));
      }}
      //validationSchema={schema}
      render={({
        values,
        errors,
        status,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
      }) => (
          <form onSubmit={handleSubmit}>
            <table className="oddEvenRows">
              <tbody>
                <tr>
                  <td>
                      <div>Enabled:</div>
                  </td>
                  <td>
                    <Field name="maintenance_enabled" type="checkbox" className="config" checked={values.maintenance_enabled} />
                  </td>
                </tr>
                <tr>
                  <td>
                      <div>Message:</div>
                  </td>
                  <td>
                    <Field
                      name="maintenance_message"
                      component="textarea"
                      className="config"
                      style={{
                        width: '20rem',
                        height: '5rem',
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <button type="submit" disabled={isSubmitting}>
                      { isSubmitting ? "Save..." : "Submit" }
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
           <div><ErrorMessage name="maintenance_enabled" /></div>
           <div><ErrorMessage name="maintenance_message" /></div>
        </form>
      )}
    />
  );
}

export { MaintenanceForm }
