import React, { Component, Fragment } from "react";
import AsyncSelect from "react-select/async";
import Select from "react-select";

//  import {
//  XYPlot,
//  HorizontalGridLines,
//  XAxis,
//  YAxis,
//  VerticalBarSeries,
//  LineSeries
//  } from "react-vis";

import axios from "axios";
import debounce from "lodash/debounce";
import moment from 'moment-timezone'
import FileDownload from 'js-file-download';

import { Header, Spinner } from '../../components';
import Error from '../Error/Error';

import { config } from "../../utils/config"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBell,
  faAngleDoubleRight,
  faTimesCircle,
  faPlusCircle,
  faWrench,
} from "@fortawesome/free-solid-svg-icons";
library.add(
  faBell,
  faAngleDoubleRight,
  faTimesCircle,
  faPlusCircle,
  faWrench,
);
moment.tz.setDefault('Australia/Brisbane');

export default class SuccessStrategies extends Component {

  constructor(props) {

    super(props);

    this.state = {

      loading: true,  // <-- display spinner

      //  ResponseWrapper values
      error: null,
      config: null,
      calendar: null,
      imports: null,
      user: null,

      //  Whether we're listing, editing, etc...
      //
      pageMode: 'list',  //  Edit / add.
      pageRecordId: 0,   //  If 'add'.

      //  Records list
      successStrategies: null,

      //  Form fields; we'll treat them individually rather than integrate the
      //  multi-selects into Formik.
      formName: "",
      formDates: [],
      formQuery: [],
      formTriggers: [['none', {}]],
      formMessage: "",
      formNotify: "Unit Coordinator",
      formIsEnabled: "1",
      formAdminNotes: "",

      formSubmitted: false,
      moodleModeOptions: [{value: 'HE', label: "HE"}, {value: 'VET', label: "VET"}],
      selectedMoodleMode: {value: 'HE', label: "HE"},
      selectedFormQuery: [],
      heTriggerOptions: {
        none: '(none)',
        no_login: 'No Login yet (for each unit)',
        days_since_login: 'Days since Last Login',
        no_activity: 'No Moodle Actvity yet (for each unit)',
        days_since_activity: 'Days since Last Moodle Activity',
        activity_this_week: 'Moodle Activity THIS week',
        activity_last_week: 'Moodle Activity LAST week',
        days_since_enrolled: 'Days since enrolled',
        flags: 'Student Flag',
        flags_exclude: 'Student Flag EXCLUDE',
        map: 'MAP Status',
        map_exclude: 'MAP Status EXCLUDE',
        pass_rate: 'Pass Rate (%)',
        prior_attempts: 'Prior Attempts',
      },
      vetTriggerOptions: {
        none: '(none)',
        no_login: 'No Login yet (for each unit)',
        days_since_login: 'Days since Last Login',
        no_activity: 'No Moodle Actvity yet (for each unit)',
        days_since_activity: 'Days since Last Moodle Activity',
        activity_this_week: 'Moodle Activity THIS week',
        activity_last_week: 'Moodle Activity LAST week',
        days_since_enrolled: 'Days since enrolled',
        flags: 'Student Flag',
        flags_exclude: 'Student Flag EXCLUDE',
        map: 'MAP Status',
        map_exclude: 'MAP Status EXCLUDE',
        pass_rate: 'Pass Rate (%)',
        prior_attempts: 'Prior Attempts',
        no_vet_participation: 'No Participation',
      },
    };

    this.handleDatesOptions = this.handleDatesOptions.bind(this);
    this.handleDatesOptionsSlowly = debounce(this.handleDatesOptionsSlowly.bind(this), 200);
    this.handleQueryOptions = this.handleQueryOptions.bind(this);
    this.showAddNextWeek = this.showAddNextWeek.bind(this);
    this.handleAddNextWeek = this.handleAddNextWeek.bind(this);
    this.handleQueryOptionsSlowly = debounce(this.handleQueryOptionsSlowly.bind(this), 200);
    this.handleMoodleModeChange = this.handleMoodleModeChange.bind(this);
  }

  componentDidMount() {
    this.loadSuccessStrategies();
  }

  loadSuccessStrategies() {
    this.setState({
      loading: true,
    });
    const url = [
      config.system.baseApiUrl,
      "success-strategies",
    ].join('/');
    axios
      .get(url)
      .then(res => {
        //  console.log(res);
        this.setState({
          config: res.data.config,
          calendar: res.data.calendar,
          imports: res.data.imports,
          error: res.data.error,
          user: res.data.user,
          successStrategies: res.data.result,
          loading: false,
        });
      })
      .catch(error => {
        this.setState({error});
      });
  }

  loadSuccessStrategy(id, copyRecord=false) {
    this.setState({
      loading: true,
    });
    const url = [
      config.system.baseApiUrl,
      "success-strategies",
      id
    ].join('/');
    console.log('load');
    axios
      .get(url)
      .then(res => {
        const newPageMode = copyRecord ? 'add' : 'edit';
        const newPageRecordId = copyRecord ? null : id;
        const form = res.data.result;
        this.setState({
          formName: form.name,
          formDates: form.dates,
          formQuery: form.query,
          //  formTriggers: this.setFormTriggers(form.triggers),
          formMessage: form.message,
          formNotify: form.notify,
          formIsEnabled: form.is_enabled,
          formAdminNotes: form.admin_notes,
          pageMode: newPageMode,
          pageRecordId: newPageRecordId,
          loading: false,
          selectedMoodleMode: {value: form.moodle_db, label: form.moodle_db},
          selectedFormQuery: form.query.map(value => { return {'label': value, 'value': value}; }),
        });
        this.setFormTriggers(form.triggerJson);
      })
      .catch(error => {
        this.setState({error});
      });
  }

  deleteSuccessStrategy(id, name) {
    if (window.confirm('Really delete "' + name + '"?')) {
      this.setState({
        loading: true,
      });
      const url = [
        config.system.baseApiUrl,
        "success-strategies",
        id
      ].join('/');
      axios
        .delete(url)
        .then(res => {
          console.log(res);
          this.loadSuccessStrategies();
        })
        .catch(error => {
          this.setState({error});
        });
    }
  }

  getValues(selection) {
    if (selection === null) {
      return [];
    }
    return selection.map(item => {
      return item.value;
    });
  }

  handleQueryOptions(inputValue, callback) {
    // do input range checking before debouncing
    if (inputValue.length < 3) {
      return callback([]);
    }
    return this.handleQueryOptionsSlowly(inputValue, callback);
  }

  handleQueryOptionsSlowly(inputValue, callback) {
    axios
      .post(config.system.baseApiUrl + "/autocomplete", {
        field: 'combined',
        query: inputValue.toUpperCase(),
        moodleDb: this.state.selectedMoodleMode.value
      })
      .then(response => {
        const results = response.data.map(item => {
          return {
            value: item.code,
            label: item.code === item.name ? item.code : item.code + ': ' + item.name
          };
        });
        return callback(results);
      })
      .catch(error => {
        console.log(error);
      });
  }

  showAddNextWeek() {
    const formDates = this.state.formDates;
    if (formDates.length > 0) {
      const lastDate = formDates[formDates.length - 1];
      if (/\w\w\w Wk \S+/.test(lastDate)) {
        if (lastDate.slice(-3) === " 14") {
          return false;  // <-- end of term
        } else {
          return true;
        }
      } else if (/\d\d\d\d-\d\d-\d\d/.test(lastDate)) {
        return true;
      }
    }
    return false;
  }

  handleAddNextWeek(event) {
    const formDates = this.state.formDates;
    if (formDates.length > 0) {
      const lastDate = formDates[formDates.length - 1];
      //  Options: [
      //    "Every Day",
      //    "Mon Wk 6" (short_name, so could be "Mon Wk -1", "Mon Wk BR"),
      //    "2020-01-01"
      //  ]
      if (/\d\d\d\d-\d\d-\d\d/.test(lastDate)) {
        const date = moment(lastDate);
        const nextWeek = date.add(7, 'days');
        const newFormDates = [...formDates, nextWeek.format('YYYY-MM-DD')];
        this.setState({
          formDates: newFormDates,
        });
      }
    }
  }

  handleDatesOptions(inputValue, callback) {
    // do input range checking before debouncing
    if (inputValue.length < 3) {
      return callback([]);
    }
    return this.handleDatesOptionsSlowly(inputValue, callback);
  }

  handleDatesOptionsSlowly(inputValue, callback) {
    axios
      .post(config.system.baseApiUrl + "/term-dates", {
        query: inputValue.toUpperCase(),
        moodleDb: this.state.selectedMoodleMode.value,
      })
      .then(response => {
        const results = response.data.map(item => {
          return {
            value: item.code,
            label: item.name
          };
        });
        return callback(results);
      })
      .catch(error => {
        console.log(error);
      });
  }

  handleTextInsert(textToInsert) {
    return event => {
      let target = document.querySelector('textarea[name="formMessage"]');
      const cursorPosition = target.selectionStart;
      const textBeforeCursorPosition = target.value.substring(0, cursorPosition)
      const textAfterCursorPosition = target.value.substring(cursorPosition, target.value.length)
      target.value = textBeforeCursorPosition + textToInsert + textAfterCursorPosition
      target.focus();
      target.selectionStart = textBeforeCursorPosition.length + textToInsert.length;
      target.selectionEnd = textBeforeCursorPosition.length + textToInsert.length;
      this.setState({
        formMessage: target.value,
      });
    }
  }

  handleMoodleModeChange(selectedOption) {
    if (this.state.selectedMoodleMode === selectedOption) {
      return;
    }

    this.setState({
      selectedMoodleMode: selectedOption,
      formName: "",
      formDates: [],
      selectedFormQuery: [],
      formTriggers: [['none', {}]],
      formMessage: "",
      formNotify: "Unit Coordinator",
      formIsEnabled: "1",
      formAdminNotes: "",
    });
  }

  getFormData() {
    return {
      id: this.state.pageRecordId,  // <-- null to add
      moodle_db: this.state.selectedMoodleMode.value,
      name: this.state.formName,
      dates: this.state.formDates,
      query: this.state.formQuery,
      triggers: this.state.formTriggers,
      message: this.state.formMessage,
      notify: this.state.formNotify,
      is_enabled: this.state.formIsEnabled,
      admin_notes: this.state.formAdminNotes,
    };
  }

  createRecord() {
    const url = [config.system.baseApiUrl, "success-strategies"].join('/');
    axios
      .post(url, this.getFormData())
      .then(response => {
        this.setState({
          pageMode: 'list',
          pageRecordId: null,
        });
        this.loadSuccessStrategies();
      })
      .catch(error => {
        alert(JSON.stringify(error));
        console.log(error);
      });
  }

  updateRecord(id) {
    const url = [config.system.baseApiUrl, "success-strategies", id].join('/');
    axios
      .post(url, this.getFormData())
      .then(response => {
        this.setState({
          pageMode: 'list',
          pageRecordId: null,
        });
        this.loadSuccessStrategies();
      })
      .catch(error => {
        alert(JSON.stringify(error));
        console.log(error);
      });
  }

  renderDateSummary(list) {
    //  list is ["yyyy-mm-dd", ...]
    const now = moment();
    const remaining = list.filter(_ => moment(_).isAfter(now));
    if (remaining.length > 0) {
      const next = remaining.shift();
      const howManyMore = remaining.length > 0
        ? ' (+' + remaining.length + ')'
        : '';
      return moment(next).format('DD MMM') + howManyMore;
    } else {
      return '--';
    }
  }

  //  UNUSED
  renderNoticeSummary(list) {
    //  Show totals {username: total, ...} by username.
    const totals = list.reduce((acc, _) => {
      const value = _.username;
      if (acc[value] === undefined) {
        acc[value] = 1;
      } else {
        acc[value] += 1;
      }
      return acc;
    }, {});
    const labels = Object.entries(totals).map((entry) => {
      const [key, value] = entry;
      return key + ' (' + value + ')';
    });
    return labels.join(', ');
  }

  //  UNUSED
  renderNoticeResponses(list) {
    //  Show totals {username: total, ...} by username.
    const totals = list.reduce((acc, _) => {

      var value = 'none';
      if (_.dismiss_time !== null) {
        value = 'dismiss';
      } else if (_.proceed_time !== null) {
        value = 'review';
      }

      if (acc[value] === undefined) {
        acc[value] = 1;
      } else {
        acc[value] += 1;
      }
      return acc;
    }, {});
    const labels = Object.entries(totals).map((entry) => {
      const [key, value] = entry;
      return key + ' (' + value + ')';
    });
    return labels.join(', ');
  }

  renderNoticeTotal(list, key) {
    var n = 0;
    list.forEach(item => {
      if (key === 'dismiss' && item.dismiss_time !== null) {
        n += 1;
      }
      if (key === 'review' && item.proceed_time !== null) {
        n += 1;
      }
      if (key === 'display' && item.display_time !== null) {
        n += 1;
      }
    });
    return n;
  }

  getEmptyDefaultObjects() {
      return {
        none: {},
        no_login: {},
        days_since_login: {operator: 'lt', value: ''},
        no_activity: {},
        days_since_activity: {operator: 'lt', value: ''},
        activity_this_week: {operator: 'lt', value: ''},
        activity_last_week: {operator: 'lt', value: ''},
        days_since_enrolled: {operator: 'lt', value: ''},
        flags: {values: []},
        flags_exclude: {values: []},
        map: {values: []},
        map_exclude: {values: []},
        pass_rate: {operator: 'lt', value: ''},
        prior_attempts: {operator: 'lt', value: ''},
        no_vet_participation: {},
      };
  }

  setFormTriggers(formTriggers) {
    //  Enforce correct formTriggers structuring: Remove all 'none' records,
    //  then append one.
    const nonEmptyFormTriggers = formTriggers.filter(item => {
      const key = item[0];
      return key !== 'none';
    });
    const validFormTriggers = nonEmptyFormTriggers.map(item => {
      const [key, value] = item;
      const emptyDefaultObjects = this.getEmptyDefaultObjects();
      if (JSON.stringify(Object.keys(value)) !== JSON.stringify(Object.keys(emptyDefaultObjects[key]))) {
        return [key, emptyDefaultObjects[key]];
      } else {
        return item;
      }
    });
    const emptyItem = ['none', {}];
    this.setState({formTriggers: [...validFormTriggers, emptyItem]});
  }

  newFormTriggersKey(index, newKey) {
    return this.state.formTriggers.map((item, thisIndex) => {
      //  console.log('KEY INDEX', index, thisIndex);
      if (index === thisIndex) {
        const key = item[0];
        const emptyDefaultObjects = this.getEmptyDefaultObjects();
        return [newKey, emptyDefaultObjects[key]];
      } else {
        return item;
      }
    });
  }

  newFormTriggersValue(index, property, newValue) {
    return this.state.formTriggers.map((item, thisIndex) => {
      if (index === thisIndex) {
        const [key, value] = item;
        var newItemValue = value;
        newItemValue[property] = newValue;
        return [key, newItemValue];
      } else {
        return item;
      }
    });
  }

  render() {

    if (this.state.error !== null) {
      return (
        <Error
          error={this.state.error}
          config={this.state.config}
        />
      );
    }

    if (this.state.loading) {
        return <Spinner />
    }

    const siteConfig = this.state.config;
    const calendar = this.state.calendar;
    const user = this.state.user;
    const imports = this.state.imports;
    const successStrategies = this.state.successStrategies;

    const pageMode = this.state.pageMode;
    const pageRecordId = this.state.pageRecordId;

    const selectedStrategy = successStrategies.find(_ => _.id === pageRecordId);  // <-- else undefined

    //  ----
    //  Form
    //  ----

    const formDebug = false;  // <-- Show an extra column of JSON values

    const formName          = this.state.formName;
    const formDates         = this.state.formDates;
    const formQuery         = this.state.formQuery;
    const formTriggers      = this.state.formTriggers;
    const formMessage       = this.state.formMessage;
    const formNotify        = this.state.formNotify;
    const formIsEnabled     = this.state.formIsEnabled;
    const formAdminNotes    = this.state.formAdminNotes;
    const moodleModeOptions = this.state.moodleModeOptions;
    const selectedMoodleMode = this.state.selectedMoodleMode;
    const selectedFormQuery = this.state.selectedFormQuery;

    const formSubmitted  = this.state.formSubmitted;  // <-- Only check once submitted

    //  An empty string means valid; anything else is an error message.

    const testName = (formName.length < 1 || formName.length > 50)
      ? "Required field (1-50 chars)"
      : '';
    const testDates = (formDates.length < 1 || formDates.length > 120)
      ? "Required field (1-120 dates)"
      : '';
    const testQuery = (formQuery.length < 1 || formQuery.length > 12)
      ? "Required field (1-12 values)"
      : '';
    const testTrigger = (formTriggers.length < 1)
      ? "Required fields"
      : '';
    const testMessage = (formMessage.length < 1 || formMessage.length > 300)
      ? "Required field (1-300 chars)"
      : '';
    const testNotify = (formNotify.length < 1)
      ? "Required field"
      : "";
    const testIsEnabled = formIsEnabled !== "0" && formIsEnabled !== "1"
      ? "Required field"
      : "";
    const testAdminNotes = "";  // No validation

    const formInvalid = (
      testName + testDates + testQuery + testTrigger + testMessage +
      testNotify + testIsEnabled + testAdminNotes
    ) !== "";


    //  -----------------------
    //  Styles for AsyncSelects
    //  -----------------------
    //
    const reactSelectStyles = {
      container: styles => ({
        ...styles,
        backgroundColor: 'white',
        minHeight: '30px',
        minWidth: '18rem',
      }),
      control: styles => ({
        ...styles,
      }),
      valueContainer: styles => ({
        ...styles,
      }),
      option: styles => ({
        ...styles,
      }),
      input: styles => ({
        ...styles,
      }),
      singleValue: styles => ({
        ...styles,
      }),
    }

    //  TRIGGERS

    const operatorOptions = {
      lt: 'is less than',
      gt: 'is greater than',
      lte: 'is at most',
      gte: 'is at least',
      eq: 'equals',
      neq: 'does not equal',
    };

    const triggerOptions = this.state.selectedMoodleMode.value === "VET" ? this.state.vetTriggerOptions : this.state.heTriggerOptions;

    const triggerOptionSelectors = {
      none: 'none',
      no_login: 'none',
      days_since_login: 'integer',
      no_activity: 'none',
      days_since_activity: 'integer',
      activity_this_week: 'integer',
      activity_last_week: 'integer',
      days_since_enrolled: 'integer',
      flags: config.flags,
      flags_exclude: config.flags,
      map: config.maps,
      map_exclude: config.maps,
      pass_rate: 'integer',
      prior_attempts: 'integer',
      no_vet_participation: 'none',
    };

    const userOptions = [
      //  '',
      'Unit Coordinator',
      'Course Coordinator',
    ];

    return (
      <Fragment>
        <Header
          config={siteConfig}
          calendar={calendar}
          imports={imports}
          user={user}
          services={[
            ["Site Configuration", '/config', faWrench],
          ]}
          breadcrumbs={[
            ["Admin"],
            ["Success Strategies"],
          ]}
        />
        <main>
          <article>
            <section style={{
              width: '15%',
            }}>
              <div className="padding">
                <p><FontAwesomeIcon icon={faBell} style={{
                  fontSize: '6rem',
                  color: config.colors.cquLightCharcoal,
                }}/></p>
                <p>A <i>success strategy</i> is a scheduled search that runs a
                  nightly check for students who match specific search queries
                  and sends an entered notification message to a specified
                  staff user when they do. This page manages your notifications
                  and tells you what action staff took in response to them.</p>
              </div>
            </section>

            {pageMode === 'notices' && (
              <section style={{
                width: '85%',
              }}>
              <h4>Success Strategy responses for #{pageRecordId} ({selectedStrategy.notices.length})</h4>

              <p style={{padding: '1rem 0.5rem'}}>
                <div style={{
                  float: 'right',
                  color: config.colors.cquCharcoal,
                }}>
                  <div>* how long after created time</div>
                  <div>** how long after display time</div>
                </div>
                <button
                  onClick={event => {
                    this.setState({
                      pageMode: 'list',
                      pageRecordId: null,
                    });
                  }}
                >
                  Back to Success Strategies List
                </button>
              </p>

              <table className="oddEvenRows interactionTable">
                <thead>
                  <tr className="secondarySorting">
                    <td>Created</td>
                    <td>User</td>
                    <td>Displayed*</td>
                    <td>Reviewed**</td>
                    <td>Dismissed**</td>
                    <td>Reminder Set**</td>
                  </tr>
                </thead>
                <tbody>
                  {selectedStrategy.notices.map((row, index) => (
                  <tr key={index}>
                    <td>{row.created_time}</td>
                    <td>{row.username}</td>
                    <td>{row.display_diff}</td>
                    <td>{row.proceed_diff}</td>
                    <td>{row.dismiss_diff}</td>
                    <td>{row.suspend_diff}</td>
                  </tr>
                  ))}
                </tbody>
              </table>

            </section>

            )}

            {pageMode === 'list' && (

            <section>
              <h4>Success Strategies ({successStrategies.length})</h4>

              <div style={{padding: '1rem 0.5rem', float: 'right'}}>
                <button
                  onClick={(event) => {
                    return window.location.href = "/config";
                  }}
                >
                  Back to Config
                </button>
              </div>
              <div style={{padding: '1rem 0.5rem'}}>
                <button
                  className="primaryButton"
                  type="button"
                  onClick={event => {
                    this.setState({
                      //  Change mode
                      pageMode: 'add',
                      pageRecordId: null,
                      //  Clear the form
                      formName: "",
                      formDates: [],
                      formQuery: [],
                      formTriggers: [['none', {}]],
                      formMessage: "",
                      formNotify: "Unit Coordinator",
                      formIsEnabled: "1",
                      formAdminNotes: "",
                      selectedMoodleMode: {value: 'HE', label: "HE"},
		                  selectedFormQuery: [],
                    });
                  }}
                >
                  Add a Success Strategy
                </button>
                <button
                  onClick={(event) => {
                    const url = [
                      config.system.baseApiUrl,
                      "export-csv",
                      "success-strategy-responses"
                    ].join('/');
                    axios.get(url, {responseType: 'blob'})
                      .then((response) => {
                        FileDownload(response.data, 'success-strategies.csv');
                      });
                  }}
                >
                  Export all responses (CSV)
                </button>
              </div>

              <table className="oddEvenRows interactionTable">
                <thead>
                  <tr className="secondarySorting">
                    <td style={{background: 'none'}} colSpan={4}></td>
                    <td colSpan={2}>Notices</td>
                    <td colSpan={2}>Responses</td>
                    <td style={{background: 'none'}} colSpan={2}></td>
                  </tr>
                  <tr className="secondarySorting">
                    <td></td>
                    <td></td>
                    <td>User</td>
                    <td>Moodle Mode</td>
                    <td>Name</td>
                    <td>Generated</td>
                    <td>Displayed</td>
                    <td>Reviewed</td>
                    <td>Dismissed</td>
                    <td></td>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  {successStrategies.map((row, index) => (
                  <tr key={index}>
                    <td>
                      <button
                        className="primaryButton"
                        onClick={event => {
                          this.loadSuccessStrategy(row.id);
                        }}
                      >
                        Edit #{row.id}
                      </button>
                    </td>
                    <td>
                      <button
                        onClick={event => {
                          this.loadSuccessStrategy(row.id, true);  // <-- copyRecord=true
                        }}
                      >
                        Copy
                      </button>
                    </td>
                    <td>
                      <div>{row.username}</div>
                      <div><small>{row.updated_time}</small></div>
                    </td>
                    <td>
                      <div>{row.moodle_db}</div>
                    </td>
                    <td>
                      {row.is_enabled === '1' ? (
                        <div>{row.name}</div>
                      ) : (
                        <div>
                          <strike>{row.name}</strike> -- <FontAwesomeIcon
                            icon={faTimesCircle}
                            color={config.colors.low}
                          /> Disabled
                        </div>
                      )}
                      <div><small>{JSON.stringify(row.query)} &middot;  {JSON.stringify(row.triggers)} &middot; {JSON.stringify(row.dates)}</small></div>
                    </td>
                    <td>{row.notices.length}</td>
                    <td>{this.renderNoticeTotal(row.notices, 'display')}</td>
                    <td>{this.renderNoticeTotal(row.notices, 'review')}</td>
                    <td>{this.renderNoticeTotal(row.notices, 'dismiss')}</td>
                    <td>
                      <button
                        onClick={event => {
                          this.setState({
                            pageMode: 'notices',
                            pageRecordId: row.id,
                          });
                        }}
                      >
                        Responses
                      </button>
                    </td>
                    <td>
                      <button
                        onClick={event => {
                          this.deleteSuccessStrategy(row.id, row.name);
                        }}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                  ))}
                </tbody>
              </table>
            </section>

            )}

            {['add', 'edit'].includes(pageMode) && (

            <section id="genericForm">

              { pageMode === 'add' && (
                <h4>Create a new Success Strategy</h4>
              )}
              { pageMode === 'edit' && (
                <h4>Editing Success Strategy #{pageRecordId} - {this.state.selectedMoodleMode.value}</h4>
              )}

              <table className="oddEvenRows">
                <tbody>
                  <tr>
                    <td>Go Back?</td>
                    <td style={{
                      textAlign: 'right',
                    }}>
                      <button
                        onClick={event => {
                          this.setState({
                            pageMode: 'list',
                            pageRecordId: null,
                          });
                        }}
                      >
                        Cancel {pageMode}
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>Moodle Mode</td>
                    <td>
                      <Select
                        options={moodleModeOptions}
                        value={selectedMoodleMode}
                        onChange={this.handleMoodleModeChange}
                        isDisabled={pageMode === 'edit' ? true : false}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Name</td>
                    <td>
                      <input
                        type="text"
                        name="fromName"
                        value={formName}
                        size="20"
                        onChange={event => {
                          this.setState({formName: event.target.value})
                        }}
                      />
                      { formSubmitted && testName !== "" && (
                        <div className="formError">{testName}</div>
                      )}
                    </td>
                    { formDebug && (
                      <td>{JSON.stringify(formName)}</td>
                    )}
                  </tr>
                  <tr>
                    <td>Dates</td>
                    <td>
                      <div>
                        <AsyncSelect
                          value={formDates.map(value => { return {
                            'label': value,
                            'value': value
                          };})}
                          defaultOptions={true}
                          isMulti
                          name="formDates"
                          styles={reactSelectStyles}
                          classNamePrefix="react-select"
                          placeholder="Term Dates"
                          noOptionsMessage={inputValue => "Enter term dates"}
                          loadOptions={this.handleDatesOptions}
                          onChange={selected => {
                            this.setState({formDates: this.getValues(selected) });
                          }}
                        />
                        { formSubmitted && testDates !== "" && (
                          <div className="formError">{testDates}</div>
                        )}
                      </div>
                      { this.showAddNextWeek() && (
                        <div
                          style={{
                            paddingTop: 5,
                            fontSize: '0.85rem',
                          }}
                          className="clickable"
                        >
                          <div onClick={this.handleAddNextWeek}>
                            <FontAwesomeIcon icon={faPlusCircle} /> Add next week
                          </div>
                        </div>
                      ) }
                    </td>
                    { formDebug && (
                      <td>{JSON.stringify(formDates)}</td>
                    )}
                  </tr>
                  <tr>
                    <td>Path Query (ANY)</td>
                    <td>
                      <div>
                        <AsyncSelect
                          defaultValue={formQuery.map(value => { return {'label': value, 'value': value}; })}
                          isMulti
                          name="formQuery"
                          value={selectedFormQuery}
                          styles={reactSelectStyles}
                          classNamePrefix="react-select"
                          placeholder="Flag, Campus, School, Course, Unit"
                          noOptionsMessage={inputValue => "Enter unit codes"}
                          loadOptions={this.handleQueryOptions}
                          onChange={selected => {
                            this.setState({
                              formQuery: this.getValues(selected),
                              selectedFormQuery: selected,
                            });
                          }}
                        />
                      </div>
                      { formSubmitted && testQuery !== "" && (
                        <div className="formError">{testQuery}</div>
                      )}
                    </td>
                    { formDebug && (
                      <td>{JSON.stringify(formQuery)}</td>
                    )}
                  </tr>
                  <tr>
                    <td>Triggers (ALL)</td>
                    <td>
                      {formTriggers.map((item, index) => {
                        //  console.log(item);
                        const [key, value] = item;
                        return (
                          <div className="flexable">
                            <select
                              value={key}
                              onChange={event => {
                                this.setFormTriggers(this.newFormTriggersKey(index, event.target.value))
                              }}
                            >
                              { Object.entries(triggerOptions).map((option, index) => {
                                const [value, label] = option;
                                return (
                                  <option
                                    key={index}
                                    value={value}
                                    disabled={label.includes(' -- TODO')}
                                  >
                                    {label}
                                  </option>
                                );
                              })}
                            </select>
                            {triggerOptionSelectors[key] === 'integer' && (
                              <Fragment>
                                <select
                                  value={value.operator}
                                  onChange={event => {
                                    this.setFormTriggers(this.newFormTriggersValue(index, 'operator', event.target.value))
                                  }}
                                >
                                  { Object.entries(operatorOptions).map((option, index) => {
                                    const [value, label] = option;
                                    return <option key={index} value={value}>{label}</option>;
                                  })}
                                </select>
                                <input
                                  type="text"
                                  size="4"
                                  placeholder="integer"
                                  value={value.value}
                                  onChange={event => {
                                    this.setFormTriggers(this.newFormTriggersValue(index, 'value', event.target.value))
                                  }}
                                />
                              </Fragment>
                            )}
                            {Array.isArray(value.values) && Array.isArray(triggerOptionSelectors[key]) && (
                              <Fragment>
                              { true ? (
                                <Select
                                  value={value.values.map(value => { return {'label': value, 'value': value}; })}
                                  isMulti
                                  styles={{...reactSelectStyles, width: '100%'}}
                                  classNamePrefix="react-select"
                                  placeholder="Select..."
                                  noOptionsMessage={inputValue => "Select..."}
                                  options={triggerOptionSelectors[key].map(value => { return {'label': value, 'value': value}})}
                                  onChange={selected => {
                                    this.setFormTriggers(this.newFormTriggersValue(index, 'values', this.getValues(selected)));
                                  }}
                                />
                              ) : (
                                <select
                                  value={triggerOptionSelectors[key].value}
                                  onChange={event => {
                                    this.setFormTriggers(this.newFormTriggersValue(index, 'values', event.target.value))
                                  }}
                                >
                                  { triggerOptionSelectors[key].map((option, index) => {
                                    return <option key={index} value={option}>{option}</option>;
                                  })}
                                </select>
                              )}
                            </Fragment>
                          )}
                          </div>
                        )
                      })}
                      { formSubmitted && testTrigger !== "" && (
                        <div className="formError">
                          <div>{testTrigger}</div>
                        </div>
                      )}
                    </td>
                    { formDebug && (
                      <td><pre>{JSON.stringify(formTriggers, null, 2)}</pre></td>
                    )}
                  </tr>
                  <tr>
                    <td>
                      <p>Notice</p>
                      <div className="vertical-space">
                        {config.successStrategy.fieldNames.map(name => (
                          <div className="textInsert" onClick={this.handleTextInsert('{' + name + '}')}>
                            { name } <FontAwesomeIcon icon={faAngleDoubleRight} />
                          </div>
                        ))}
                      </div>
                    </td>
                    <td>
                      <textarea
                        name="formMessage"
                        cols="80"
                        rows="10"
                        value={formMessage}
                        onChange={ event => {
                          this.setState({formMessage: event.target.value});
                        }}
                      />
                      { formSubmitted && testMessage !== "" && (
                        <div className="formError">{testMessage}</div>
                      )}
                    </td>
                    { formDebug && (
                      <td>{JSON.stringify(formMessage)}</td>
                    )}
                  </tr>
                  <tr>
                    <td>Notify whom?</td>
                    <td>
                      <select value={formNotify} onChange={ event => {
                        this.setState({formNotify: event.target.value})
                      }}>
                      { userOptions.map(option => (
                        <option value={option}>{option}</option>
                      ))}
                    </select>
                    { formSubmitted && testNotify !== "" && (
                      <div className="formError">{testNotify}</div>
                    )}
                  </td>
                  { formDebug && (
                    <td>{JSON.stringify(formNotify)}</td>
                  )}
                </tr>
                <tr>
                  <td>Enabled?</td>
                  <td>
                    <select value={formIsEnabled} onChange={ event => {
                      this.setState({formIsEnabled: event.target.value})
                    }}>
                    <option value="0">No</option>
                    <option value="1">Yes</option>
                  </select>
                  { formSubmitted && testIsEnabled !== "" && (
                    <div className="formError">{testIsEnabled}</div>
                  )}
                </td>
                { formDebug && (
                  <td>{JSON.stringify(formIsEnabled)}</td>
                )}
              </tr>
              <tr>
                <td>Admin Notes</td>
                <td>
                  <textarea
                    name="formAdminNotes"
                    cols="80"
                    rows="5"
                    value={formAdminNotes}
                    onChange={ event => {
                      this.setState({formAdminNotes: event.target.value});
                    }}
                  />
                  { formSubmitted && testAdminNotes !== "" && (
                    <div className="formError">{testAdminNotes}</div>
                  )}
                </td>
                { formDebug && (
                  <td>{JSON.stringify(formAdminNotes)}</td>
                )}
              </tr>
              <tr>
                <td></td>
                <td>
                  { pageMode === 'add' && (
                    <button
                      className="primaryButton"
                      onClick={event => {
                        if (formInvalid) {
                          this.setState({
                            formSubmitted: true,
                          });
                        } else {
                          this.createRecord();
                        }
                      }}
                    >
                      Create
                    </button>
                  )}
                  { pageMode === 'edit' && (
                    <button
                      className="primaryButton"
                      onClick={event => {
                        if (formInvalid) {
                          this.setState({
                            formSubmitted: true,
                          });
                        } else {
                          this.updateRecord(pageRecordId);
                        }
                      }}
                    >
                      Update
                    </button>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </section>

            )}

          </article>
        </main>
      </Fragment>
    );
  }
}
