import React from "react";
import ReactDOM from "react-dom";
import { ModalContext } from "../../context/ModalContext";
import ModalBackground from "../ModalBackground/ModalBackground";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

const Modal = () => {
    let { modalContent, handleModal, modal } = React.useContext(ModalContext);
    if (modal) {
        return ReactDOM.createPortal(
            <>
                <ModalBackground handleClose={() => handleModal()} />
                <aside id="popoverForCohortDashboardInteractionsList" className="middleCenter">
                    <div className="closeButton">
                        <FontAwesomeIcon icon={faTimesCircle} onClick={() => handleModal()} />
                    </div>
                    {modalContent}
                </aside>
            </>,
            document.querySelector("#modal-root")
        );
    } else return null;
};

export default Modal;
