import React  from "react";
import { config } from "../../../../utils/config"
import { Formik, Field, ErrorMessage } from 'formik';
import axios from "axios";
import moment from 'moment-timezone';
moment.tz.setDefault('Australia/Brisbane');

const ImportConfigForm = (props) => {

  const hintEnabled = "Enabled";
  const hintTimes = "eg. '23:45' or '01:45,3:45'";
  const hintYear = "eg. '2020'";
  const hintTerm = "eg. 'HT1'";
  const hintVetYears = "e.g. 2020";

  /**
   * validate one (1) or more times separated by comma
   * time is in format 'H:m'
   */
  const validateTimes = (value) => {
    const arr = value.split(/\s*,\s*/);
    const res = arr.reduce((carry, item) => {
      const t = moment(item, 'H:m');
      return t.isValid();
    }, false);
    return res;
  };

  const validate = (values) => {
    let errors = {};
    const timeFields = [
      'import_crm_cases_times',
    ];
    errors = timeFields.reduce((carry, item) => {
      if (!validateTimes(values[item])) {
        errors[item] = "Invalid";
      }
      return errors;
    }, errors);
    return errors;
  };

  return (
    <Formik
      initialValues={props.initialValues}
      onSubmit={(values, { setSubmitting }) => {
        axios
          .post(config.system.baseApiUrl + "/config/import-config", values)
          .then((response) => {
            values['current_term_id'] = response.data.result.current_term_id;
            setSubmitting(false)
          });
      }}
      //validationSchema={schema}
      validate={validate}
      //validateOnChange={false}
      //validateOnBlur={false}
      render={({
        values,
        errors,
        status,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
      }) => (
          <form className="siteConfigForm" onSubmit={handleSubmit}>
            <table className="oddEvenRows">
              <tbody>
                <tr>
                  <th colSpan="2">
                    Year and term config
                  </th>
                </tr>
                <tr>
                  <td>
                    HE Year:
                  </td>
                  <td>
                    <Field
                      name="current_year"
                      type="input"
                      className={errors.current_year ? "config error" : "config"}
                      title={hintYear}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    HE Term:
                  </td>
                  <td>
                    <Field
                      name="current_term"
                      type="input"
                      className={errors.current_term ? "config error" : "config"}
                      title={hintTerm}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    HE S1 Term:
                  </td>
                  <td>
                    {values['current_term_id']}
                  </td>
                </tr>
                <tr>
                  <td>
                    VET Year(s):
                  </td>
                  <td>
                    <Field
                      name="vet_years"
                      type="input"
                      className={errors.vet_years ? "config error" : "config"}
                      title={hintVetYears}
                    />
                  </td>
                </tr>
                <tr>
                  <th colSpan="2">
                    Schedule
                  </th>
                </tr>
                <tr>
                  <td>
                      <div>Import New Term:</div>
                  </td>
                  <td>
                    <Field
                      name="import_new_term_enabled"
                      type="checkbox"
                      checked={values.import_new_term_enabled}
                      className="config"
                      title={hintEnabled}
                    />
                    <Field
                      name="import_new_term_times"
                      type="input"
                      className={errors.import_new_term_times ? "config error" : "config"}
                      title={hintTimes}
                    />
                    <div className="errorMessage">
                      <ErrorMessage name="import_new_term_times" />
                    </div>
                 </td>
                </tr>
                <tr>
                  <td>
                      <div>Import CRM Cases:</div>
                  </td>
                  <td>
                    <Field
                      name="import_crm_cases_enabled"
                      type="checkbox"
                      checked={values.import_crm_cases_enabled}
                      className="config"
                      title={hintEnabled}
                    />
                    <Field
                      name="import_crm_cases_times"
                      type="input"
                      className={errors.import_crm_cases_times ? "config error" : "config"}
                      title={hintTimes}
                    />
                    <div className="errorMessage">
                      <ErrorMessage name="import_crm_cases_times" />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <button type="submit" disabled={isSubmitting}>
                      { isSubmitting ? "Save..." : "Submit" }
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
         </form>
      )}
    />
  );
}

export { ImportConfigForm }
