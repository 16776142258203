import React from 'react';
import { hide_string } from '../../utils/utils';

/**
 * Renders text in incognito mode by hiding characters if incognito is enabled.
 * @param {Object} props - Component props.
 * @param {boolean} props.incognito - Indicates whether incognito mode is enabled.
 * @param {string} props.text - The original text to be displayed.
 * @param {number} [props.length] - Optional: Length to which the text should be hidden.
 *                                 If not provided, the original text length is used.
 * @returns {JSX.Element} Rendered component.
 */
const IncognitoText = ({incognito, text, length}) => {
    if ( !incognito ) {
        return <>{text}</>
    }

    if (length === undefined) {
        length = text.length;
    }

    return <>{hide_string(text, length)}</>
}

export default IncognitoText;