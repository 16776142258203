import React, { createContext, useEffect, useState } from "react";
import { config as SiteConfig } from "../utils/config";
import axios from 'axios';

/**
 * HeaderContext contains items used for the header, it also contains the user object which components user for preferences and other items.
 * This allows the user to update the user, and other header items easier.
 */
const HeaderContext = createContext(null);

/**
 * This FunctionComponent is the Provider that gives access to the context to its children.
 *
 * @param {JSX.Element} children Child components, which will have access to assets.
 * @param {boolean} incognito Whether incognito mode is enabled
 */
const HeaderContextProvider = ({children, incognito: parIncognito}) => {
    const [error, setError] = useState(undefined);
    const [isLoading, setLoading] = useState(true);
    const [config, setConfig] = useState();
    const [user, setUser] = useState({ preference: { filters: [], columns: [] } });
    const [incognito, setIncognito] = useState(parIncognito);
    const [imports, setImports] = useState();
    const [notices, setNotices] = useState([]);
    const [services, setServices] = useState([]);
    const [calendar, setCalendar] = useState();
    const [selTerm, setSelTerm] = useState();
    const [terms, setTerms] = useState([]);

    // Load the assets.
    useEffect(() => {
        setLoading(true);
        axios.get(SiteConfig.system.baseApiUrl + '/home')
            .then( response => {
                setCalendar(response.data.calendar);
                setConfig(response.data.config);
                setImports(response.data.imports);
                setNotices(response.data.notices);
                setUser(response.data.user);
                setSelTerm(response.data.result.terms[0]);
                setTerms(response.data.result.terms);
            }).catch( error => {
                console.error(error);
                setError(error);
            }).finally( () => {
                setLoading(false);
            });
    }, []);

    // Create the assets object that will be passed through.
    const isError   = error !== undefined;
    const isSuccess = !isError && !isLoading;
    const value = {
        config,
        setConfig,
        user,
        setUser,
        incognito,
        setIncognito,
        imports,
        setImports,
        notices,
        setNotices,
        services,
        setServices,
        calendar,
        setCalendar,
        selTerm,
        setSelTerm,
        terms,
        setTerms,
        error,
        isLoading,
        isError,
        isSuccess,
    }

    return (
        <HeaderContext.Provider value={value}>
            {children}
        </HeaderContext.Provider>
    );
};

export { HeaderContext, HeaderContextProvider };
