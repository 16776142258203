import React, { useContext } from 'react';
import { sortBy } from 'lodash';
import { Hint, HorizontalGridLines, VerticalBarSeries, XAxis, XYPlot, YAxis } from 'react-vis';
import { ModalContext } from '../../../../context/ModalContext';
import { config } from '../../../../utils/config';
import { renderXLabel, renderYLabel, withWidget } from '../../../../utils/graph';
import { sendLog } from '../../../../utils/utils';
import { LOG } from '../../../../utils/constants';
import useRouteParams from '../../../../hooks/useRouteParams';
import UnitWidgetModal from '../UnitWidgetModal/UnitWidgetModal';
import SelectedEnrollmentsContext from '../../../../context/SelectedEnrollmentsContext';

const PLOT = {
    width: 300,
    height: 280,
    left: 60,
    bottom: 60,
};

const GpaBandsGraph = ({ enrollments, hover, handleMouseOver, handleMouseOut }) => {
    const { handleModal } = useContext(ModalContext);
    const { year, term, unit } = useRouteParams();
    const { clearAndAddMultipleSelectedEnrollments } = useContext(SelectedEnrollmentsContext);

    const result = enrollments.reduce((acc, enrollment) => {
        const gpaBand = enrollment.gpaBand;
        acc[gpaBand] = acc[gpaBand] || { y: 0 };
        acc[gpaBand].y++;
        return acc;
    }, {});

    const data = Object.keys(result).map((key) => ({
        x: key,
        y: result[key].y,
        color: hover.code === key ? config.colors.cquBlue50 : config.colors.cquGreen,
        code: key,
        description: key,
    }));

    /**
     *  Open the Modal displaying the cohort of the clicked course.
     */
    const handleClick = (data) => {
        const aggregated = enrollments.filter(({ gpaBand }) => data.code === gpaBand);

        clearAndAddMultipleSelectedEnrollments(aggregated);
        handleModal(<UnitWidgetModal title={`GPA Bands - ${data.description}`} handleModal={handleModal} cohortFilter={`GPA=${data.code}`}/>);
        sendLog("App\\Events\\Widget\\Aggregated", 'r', LOG.ACTION.AGGREGATED, LOG.TARGET.ENROLLMENTS, LOG.DASHBOARD.UNIT, {
            term: {year,term},
            code: unit,
            aggregation: 'gpaBands',
            value: data.description,
            aggregated: aggregated.length,
        });
    };

    return (
        <div className='UnitWidget'>
            <h4>GPA Bands</h4>
            <XYPlot
                xType="ordinal"
                width={PLOT.width}
                height={PLOT.height}
                margin={{
                    left: PLOT.left,
                    bottom: PLOT.bottom,
                }}
                xDomain={['-', '< 1', '< 2', '< 3', '< 4', '< 5', '< 6', '≤ 7']}
            >
                {hover && (
                    <Hint value={hover} className='plotTooltip'>
                        <div>
                            <h3>{hover.x}</h3>
                            <p>{hover.y}</p>
                        </div>
                    </Hint>
                )}
                {renderXLabel("GPA Band", PLOT)}
                <XAxis height={PLOT.bottom} />
                {renderYLabel("Count of students", PLOT)}
                <YAxis width={PLOT.left} />
                <HorizontalGridLines />
                <VerticalBarSeries
                    colorType={"literal"}
                    data={sortBy(data, ['code'])}
                    onValueClick={handleClick}
                    onValueMouseOver={handleMouseOver}
                    onValueMouseOut={handleMouseOut}
                />
            </XYPlot>
        </div>
    )
};

export default withWidget(GpaBandsGraph);