import React from 'react';
import { filterIntegers, renderYLabel, withWidget } from '../../../../utils/graph';
import { getWeekFromDate, mapBeginDateToShortDates, mapBeginDateToWeeks } from "../../../../utils/calendar";
import { config } from '../../../../utils/config';
import { color_scheme, make_object } from '../../../../utils/utils';
import { Hint, HorizontalGridLines, VerticalBarSeries, XAxis, XYPlot, YAxis } from 'react-vis';

const WeeklyActivityPercentByUnitsGraph = (props) => {
    // data is grouped by week with arrays of units
    // [
    //   {
    //      begin_date: "2019-10-28 00:00:00",
    //      units: [
    //        {
    //           unitCode: "COIT40206",
    //           total: 123,
    //        }
    //      ]
    //   }
    // ]
    const {
        data,
        hover,
        //handleClick,
        handleMouseOver,
        handleMouseOut,
        //isClickable,
        calendar,
        axisDates, // display dates instead of week numbers
    } = props;
    const title = props.title ? props.title : "Activity per week of term";
    const plot = {
        width: 480,
        height: 300,
        left: 60,
        bottom: 50,
    };

    const getActivityGraph = (weeklyData, extraData) => {
        const points = weeklyData.map((item, index) => {
            const { begin_date, total, unitCode } = item;
            const week = getWeekFromDate(begin_date, calendar);
            const pc = (total === undefined || total === 0) ? 0 : 100 * total / extraData.weeklyTotals[begin_date];

            return {
                x: begin_date,
                y: pc,
                total: total,
                unitCode,
                description: week && week.long_name,
                color: (unitCode === hover.unitCode) ? config.colors.cquGreen : extraData.color,
            }
        });
        return points;
    }

    const validWeekDates = calendar.term_weeks.map(week => week.begin_date);
    const weeklyTotals = make_object(validWeekDates, 0);
    // sort each week by totals
    data.forEach((el) => {
        // tally up totals for each week to use later
        el.units.forEach((unit) => {
            weeklyTotals[el.begin_date] += unit.total;
        });
        // sort units by activity total
        el.units.sort((a, b) => {
            if (a.total < b.total) return -1;
            if (a.total > b.total) return 1;
            return 0;
        });
    });

    // top units each week
    const dataAg = [];
    for (let i = 0; i <= 5; i++) {
        const top = data.reduce((acc, week) => {
            let ret = {
                begin_date: week.begin_date,
                total: 0,
            };
            if (week.units.length > i) {
                if (i <= 4) {
                    // top 5
                    const unit = week.units[week.units.length - (i + 1)];
                    if (unit) {
                        ret.total = unit.total;
                        ret.unitCode = unit.unitCode;
                    }
                } else if (week.units.length > 5) {
                    // sum all other units
                    const otherUnits = week.units.slice(0, week.units.length - i);
                    const total = otherUnits.reduce((acc, _) => {
                        acc += _.total;
                        return acc;
                    }, 0);
                    ret.unitCode = "Other Units";
                    ret.total = total;
                }
            }
            acc.push(ret);
            return acc;
        }, [])
        dataAg.push(top);
    }

    const hasData = (Object.values(weeklyTotals).reduce((acc, _) => (acc += _)) > 0);

    const legend = color_scheme([...Array(6).keys()], [config.colors.cquBlue, config.colors.cquBlue50]);

    const graphs = dataAg.map((weeks, index) => {
        return getActivityGraph(weeks, { weeklyTotals, color: legend[index] });
    })

    return (
        <div className="UnitWidget UnitWidgetWide">
            <div style={{ float: 'right' }}>Weekly Top 5 Units</div>
            <h4>{title}</h4>
            <div>
                <XYPlot
                    height={plot.height}
                    width={plot.width}
                    xType="ordinal"
                    stackBy="y"
                    className={hasData ? "" : "empty"}
                    margin={{
                        left: plot.left,
                        bottom: plot.bottom,
                    }}
                >
                    {hover && (
                        <Hint
                            value={hover}
                            className='plotTooltip'
                        >
                            <div>
                                <h3>{hover.unitCode} - {hover.description}</h3>
                                <p>{hover.total} ({Math.round(hover.y - (hover.y0 || 0))}%)</p>
                            </div>
                        </Hint>
                    )}
                    {hasData && (
                        <YAxis
                            width={plot.left}
                            tickFormat={filterIntegers}
                        />
                    )}
                    {axisDates ? (
                        <XAxis
                            height={plot.bottom}
                            tickFormat={begin_date => mapBeginDateToShortDates(begin_date, calendar)}
                            tickLabelAngle={-90}
                        />
                    ) : (
                        <XAxis
                            height={plot.bottom}
                            tickFormat={begin_date => mapBeginDateToWeeks(begin_date, calendar)}
                        />
                    )}
                    {/** renderXLabel("Week of Term", plot) **/}
                    {renderYLabel("Percent of weekly activity", plot)}
                    <HorizontalGridLines />
                    {graphs.map((graph, index) => (
                        <VerticalBarSeries
                            key={index}
                            colorType={"literal"}
                            //color={legend[index]}
                            data={graph}
                            onValueMouseOver={handleMouseOver}
                            onValueMouseOut={handleMouseOut}
                        />
                    ))}
                </XYPlot>
                {!hasData && (
                    <div style={{
                        position: 'relative',
                        bottom: '160px',
                    }}>
                        No data
                    </div>
                )}
            </div>
        </div>
    );
}

export default withWidget(WeeklyActivityPercentByUnitsGraph);