import React from 'react';
import moment from 'moment-timezone';
moment.tz.setDefault('Australia/Brisbane');

const DaysSinceLogin = ({ enrollments }) => {
    if (!enrollments || enrollments.length === 0) {
        return (
            <div className="shadowBox numberBox faded" style={{ width: '250px' }}>
                <div>—</div>
                <div>Days Since Login</div>
            </div>
        );
    }

    const lastLoginDaysAgo = enrollments.reduce(function (prev, current) {
        return (moment(prev.last_login) > moment(current.last_login)) ? prev : current
    }).last_login;

    return (
        <div className="shadowBox numberBox" style={{ width: '250px' }}>
            <div>{lastLoginDaysAgo ? moment().diff(moment(lastLoginDaysAgo), 'days') : '—'}</div>
            <div>Days Since Login</div>
        </div>
    );
}

export default DaysSinceLogin;