import React, { Fragment } from 'react';
import { config } from '../../utils/config';
import { getPercentageGraph, renderPercentageBar, renderLegendBar, withWidget } from '../../utils/graph';

const EngagedWhileLearningGraph = (props) => {
    const {
        data,
        hover,
        handleClick,
        handleMouseOver,
        handleMouseOut,
        isClickable,
    } = props;
    const title = props.title ? props.title : "Engagement In Learning";
    const cohort = props.cohort ? props.cohort : "students";
    const codes = props.propCodes ? props.propCodes : [null, null];
    const { engaged, total } = data;
    const percent = Math.floor(100 * (engaged / total));
    const graphData = [
        {
            label: 'Activity This Term',
            code: codes[0],
            total: percent,
            description: 'Activity This Term',
        },
        {
            label: 'No Activity This Term',
            code: codes[1],
            total: 100 - percent,
            description: 'No Activity This Term',
        },
    ];
    const graph = getPercentageGraph(
        graphData,
        [config.colors.cquGreen, config.colors.cquBlue],
        config.colors.cquBlue50,
        hover.code
    );
    const hasData = total > 0;
    const legend = graph.map((_) => {
        const [, color, obj] = _;
        return [
            obj.label,
            color,
            obj,
        ]
    });

    return (
        <div className="UnitWidget">
            <h4>{title}</h4>
            {hasData && (
                <Fragment>
                    <h1>{percent}%</h1>
                    {renderPercentageBar(graph, isClickable && {
                        onClick: handleClick,
                        onMouseOver: handleMouseOver,
                        onMouseOut: handleMouseOut,
                    })}
                    {renderLegendBar(legend, isClickable && {
                        onClick: handleClick,
                        onMouseOver: handleMouseOver,
                        onMouseOut: handleMouseOut,
                    })}
                    <p><small>{engaged} of {total} {cohort} have<br /><b>engaged</b> while learning this term.</small></p>
                </Fragment>
            )}
            {!hasData && (
                <div style={{
                    marginTop: '40%',
                    textAlign: 'center',
                }}>
                    No data
                </div>
            )}
        </div>
    );
}

export default withWidget(EngagedWhileLearningGraph);