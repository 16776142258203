import moment from "moment-timezone";
import { getValueByProperty } from "../../../utils/utils";
import { assessmentDueDateBand } from "../../../utils/bands";

/**
 * Creates a preference key using the current year, term, unit code and the given key.
 *
 * @param {string} key - The key to prepend to the preference key.
 * @param {Object} term - The term object to which to set preference key for.
 * @param {string} unitCode - The code of unit to which to set preference key for.
 * @returns {string} - The preference key in format: "unit.<year>.<term>.<unitCode>.<key>".
 */
export function createPreferenceKey(key, term, unitCode) {
    return `unit.${term.year}.${term.term}.${unitCode}.${key}`;
}

/**
 * Get the preferences of a given unit.
 *
 * @param {Object} user - The user object which contains the preferences.
 * @param {Object} term - The term object to which to target.
 * @param {string} unitCode - The code of unit to target.
 * @returns {Object} - The unit preferences, or an empty object if no preferences are found.
 */
export function getUnitPreferences(user, term, unitCode) {
    return user.preference?.unit?.[term.year]?.[term.term]?.[unitCode] ?? {}
}

/**
 * Filters a list of assessments based on the unit preferences.
 *
 * @param {Array<Object>} assessments - The list of assessments to filter.
 * @param {Object} unitPreferences - The unit preferences.
 * @param {Object} term - The term object, containing "term.start" and "term.end".
 * @returns {Array<Object>} - The filtered list of assessments.
 */
export function filterAssessments(assessments, unitPreferences, term) {
    // If there are no assessment preferences set, we do not filter anything.
    if (unitPreferences.assessment === undefined) {
        return assessments;
    }

    const allowedHiddenValues = [];

    // Append the allowedHidden with value, if not set default to true.
    if (getValueByProperty('assessment.hidden.yes', unitPreferences) ?? true)
        allowedHiddenValues.push(true, 1);
    if (getValueByProperty('assessment.hidden.no', unitPreferences) ?? true)
        allowedHiddenValues.push(false, 0);

    // Return filtered set.
    return assessments.filter( assessment  => {
        const typeKey = `assessment.type.${assessment.type}`;
        const dueDateKey =  `assessment.due_date.${assessment.due_in_term}`;

        return allowedHiddenValues.includes(assessment.hidden)
            && (getValueByProperty(dueDateKey, unitPreferences) ?? true) // If due_in_term value is not defined, default to true
            && (getValueByProperty(typeKey, unitPreferences) ?? true);
    });
}
