import React, { Component } from 'react';

class ModalBackground extends Component {

    constructor(props) {
      super(props);
      this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    handleKeyDown(event) {
      if (event.keyCode === 27) {
        if (this.props.handleClose !== undefined) {
          //  Any event will do; there'll be a stopPropagation call.
          this.props.handleClose(event);
        }
      }
    }

    componentDidMount() {

      //  Listen for 'Esc'
      document.addEventListener("keydown", this.handleKeyDown, false);

      //  Stop the page below from scrolling
      const body = document.getElementsByTagName("body")[0];
      body.classList.add('noScrolling');

    }

    componentWillUnmount() {

      //  Stop listening for 'Esc'
      document.removeEventListener("keydown", this.handleKeyDown, false);

      //  Allow the page below to scroll again
      const body = document.getElementsByTagName("body")[0];
      body.classList.remove('noScrolling');

    }

    render() {
      return (
        <div
          title="Click to close"
          onClick={this.props.handleClose}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            zIndex: 11,
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
          }}
        >
          &nbsp;
        </div>
      );
    }
}

export default ModalBackground;