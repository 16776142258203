import React, { useContext, useState } from 'react';
import { HeaderContext } from '../../context/HeaderContext';
import IncognitoText from '../IncognitoText/IncognitoText';
import InteractionPreviewListItem from './InteractionPreviewListItem';
import SelectedEnrollmentsContext from '../../context/SelectedEnrollmentsContext';


const InteractionPreviewList = ({ previews, handleSubmit, handleGoBackButton }) => {
    const [submitting, setSubmitting] = useState(false);
    const { incognito } = useContext(HeaderContext);
    const { selectedEnrollments } = useContext(SelectedEnrollmentsContext);

    const onSubmit = () => {
        setSubmitting(true);
        handleSubmit();
    }

    const recipientList = [];
    selectedEnrollments.forEach((value, key) => {
        recipientList.push(
            <tr key={key}>
                <td>{value.unit.code}</td>
                <td><IncognitoText text={`${value.student.last_name}, ${value.student.first_name}`} incognito={incognito} length={10} /></td>
            </tr>);
    });

    return (
        <div>
            <table style={{ width: '100%' }}>
                <tbody>
                    <tr>
                        <td width="70%">
                            <div className="emailPreviewList">
                                {previews.map((email, index) => (
                                    <InteractionPreviewListItem key={index} preview={email}/>
                                ))}
                            </div>
                        </td>
                        <td width="30%">
                            <div className="emailRecipientList">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Unit</th>
                                            <th>Student</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {recipientList}
                                    </tbody>
                                </table>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div style={{ paddingTop: '1rem', }}>
                                <small>Previewing {previews.length} / {selectedEnrollments.size} (a grayed out preview won't be send as a email).</small>
                            </div>
                        </td>
                        <td align={'right'}>
                            <button type="button" className="primaryButton" onClick={onSubmit} disabled={submitting}>
                                {submitting ? 'Submitting ...' : 'Submit'}
                            </button>
                            <button type="button" className="secondaryButton" onClick={handleGoBackButton} disabled={submitting}>
                                Go Back
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default InteractionPreviewList;