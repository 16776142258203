import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCube as ICON_CRM } from '@fortawesome/free-solid-svg-icons';
import { message_crm_url, replaceCreationUser } from '../../../utils/utils';
import moment from 'moment-timezone';
moment.tz.setDefault('Australia/Brisbane');

const CrmCasesListItem = ({ item }) => {

    return (
        <div className="interactionsListItem">
            <div className="interactionsListItemIcon">
                <FontAwesomeIcon icon={ICON_CRM} />
            </div>
            <div className="interactionsListItemInfo">
                <p>
                    <span title={'#' + item.case_number} style={{ leftPadding: '1rem' }}>
                        {moment(item.creation_date, 'YYYY-MM-DD hh:ii:ss').format('D MMM YYYY')}
                    </span>
                </p>
                <p>{replaceCreationUser(item.creation_user)}</p>
            </div>
            <div className="interactionsListItemContent">
                <p>
                    <b>{item.case_title}</b>
                </p>
                <p>
                    CRM Case: <a target="_blank" rel="noopener noreferrer" href={message_crm_url(item.case_url)}>{item.case_number}</a>
                    <br/>
                    <small>More details available to users with SugarCRM access</small>
                </p>
            </div>
        </div>
    );
};

export default CrmCasesListItem;