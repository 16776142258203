import React, { useContext } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faStickyNote } from "@fortawesome/free-solid-svg-icons";
import { HeaderContext } from "../../../../context/HeaderContext";
import { InteractionForm } from '../../../../components';
import SelectedEnrollmentsContext from '../../../../context/SelectedEnrollmentsContext';

const InteractionBar = ({handleModal}) => {
    const { config } = useContext(HeaderContext);
    const { selectedEnrollments } = useContext(SelectedEnrollmentsContext);
    const disabled = selectedEnrollments.size === 0;
    const allowEmail = selectedEnrollments.size === 1;

    return (
        <div style={{ textAlign: "center", marginBottom: "1rem" }}>
            {allowEmail && <button
                disabled={disabled}
                className="primaryButton"
                onClick={() => handleModal(<InteractionForm type='Email' strategies={config.strategies} fullCohort={false} />)}
            >
                <FontAwesomeIcon icon={faEnvelope} /> Email
            </button>}
            <button
                disabled={disabled}
                className={allowEmail ? "secondaryButton" : "primaryButton"}
                onClick={() => handleModal(<InteractionForm type='Mailout' strategies={config.strategies} fullCohort={false} />)}
            >
                <FontAwesomeIcon icon={faEnvelope} /> Mailout
            </button>
            <button
                disabled={disabled}
                className="secondaryButton"
                onClick={() => handleModal(<InteractionForm type='Note' strategies={config.strategies} fullCohort={false} />)}
            >
                <FontAwesomeIcon icon={faStickyNote} /> Note
            </button>
        </div>
    );
}

export default InteractionBar;