import React, { useContext, useRef, useState } from 'react';
import { ModalContext } from '../../context/ModalContext';
import _, { kebabCase as slugify } from "lodash";
import { config } from '../../utils/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import useCustomParams from '../../hooks/useCustomParams';
import axios from 'axios';
import useRouteParams from '../../hooks/useRouteParams';
import { Editor } from '@tinymce/tinymce-react';
import { INTERACTION_MODES, TINYMCE_INIT_SETTINGS } from '../../utils/constants';
import InteractionValidationErrorList from './InteractionValidationErrorList';
import InteractionPreviewList from './InteractionPreviewList';
import { filterByProperty, flattenEnrollment } from '../../utils/utils';
import { HeaderContext } from "../../context/HeaderContext";
import SelectedEnrollmentsContext from '../../context/SelectedEnrollmentsContext';

/**
 * Count the number of interactions that will be emailed per specified mode.
 *
 * @param {string} mode - The interaction mode. Should be one of INTERACTION_MODES, fallback to INTERACTION_MODES.EMAIL_PER_UNIT by default.
 * @param {Map} selectedEnrollments - A Map containing enrollment data.
 * @returns {number} - The total count of emails sent out based on the specified mode.
 */
function countEmailSendOutPerMode(mode, selectedEnrollments) {
    let groupByKey;
    switch (mode) {
        case INTERACTION_MODES.EMAIL_PER_COURSE:
            groupByKey = 'course_id';
            break;
        case INTERACTION_MODES.EMAIL_PER_SCHOOL:
            groupByKey = 'school_code';
            break;
        default:
            groupByKey = 'unit_id';
            break;
    }

    // Group the selected enrollments by the specified group key and then by studentId.
    const enrollmentsGroupedByModeGroupedByStudent = _.chain(Array.from(selectedEnrollments.values()))
        .groupBy(groupByKey)
        .map(group => _.groupBy(group, 'student_id'))
        .value();

    let emailCount = 0;

    // Group the selected enrollments by the specified group key and then by studentId.
    enrollmentsGroupedByModeGroupedByStudent.forEach(obj => {
        // There will be only one email per mode per student.
        const studentCount = Object.keys(obj).length;
        emailCount += studentCount;
    });

    return emailCount;
}

const InteractionForm = ({ type, fullCohort, strategies }) => {
    const { selectedEnrollments } = useContext(SelectedEnrollmentsContext);
    const [subject, setSubject] = useState('');
    const [cc, setCC] = useState('');
    const [mode, setMode] = useState(INTERACTION_MODES.EMAIL_PER_UNIT); // Defaults to sending emails per unit.
    const [message, setMessage] = useState('');
    const [strategy, setStrategy] = useState('');
    const { handleModal } = useContext(ModalContext);
    const [sendingPreview, setSendingPreview] = useState(false);
    const [previews, setPreviews] = useState([]);
    const [errors, setErrors] = useState(null);
    const params = useCustomParams();
    const [submitted, setSubmitted] = useState(false);
    const { year, term } = useRouteParams();
    const editorRef = useRef(null);
    const isVet = !['HT1', 'HT2', 'HT3'].includes(term);
    const filterStrategiesByParam = isVet ? 'is_vet' : 'is_he';
    const filteredStrategies = !strategies ? [] : strategies.filter((element) => filterByProperty(filterStrategiesByParam, [true.toString()], element));
    const [from, setFrom] = useState('');
    const { user } = useContext(HeaderContext);
    const interactionFieldNames = isVet ? config.interactions.fieldNames.VET : config.interactions.fieldNames.HE;

    const handleTextInsert = (textToInsert) => {
        editorRef.current.insertContent(textToInsert);
    }

    const handleSubmit = (e) => {
        const url = `${config.system.baseApiUrl}/interaction`;
        const data = {
            type,
            enrollments: Array.from(selectedEnrollments.values()).map((enr) => flattenEnrollment(enr)),
            term: { year, term },
            subject,
            mode,
            from,
            cc,
            body_text: message,
            strategy: filteredStrategies.length === 0 || fullCohort ? 'General broadcast' : strategy,
            createdAtFilters: params,
        }

        axios.post(url, data)
            .then(res => {
                setErrors(null);
                setSubmitted(true)
            }).catch(error => {
                setErrors(error.response.data ?? error);
            }).finally(() => {
                setPreviews([]);
                setSendingPreview(false);
            });

    }

    const handleSubmitPreview = (e) => {
        e.preventDefault();
        const url = `${config.system.baseApiUrl}/interaction-preview`;
        const data = {
            type,
            enrollments: Array.from(selectedEnrollments.values()).map((enr) => flattenEnrollment(enr)),
            term: { year, term },
            subject,
            mode,
            from,
            cc,
            body_text: message,
            strategy: !filteredStrategies || filteredStrategies.length === 0 || fullCohort ? 'General broadcast' : strategy,
            createdAtFilters: params,
        }

        setSendingPreview(true);
        axios.post(url, data)
            .then(res => {
                setErrors(null);
                setPreviews(res.data.data.previews);
            }).catch(error => {
                setErrors(error.response.data ?? error);
            }).finally(() => setSendingPreview(false))
    }

    const handleGoBackButton = () => {
        setPreviews([]);
    }

    if (submitted) {
        return (
            <>
                <h3>{type} message</h3>
                <p>
                    Successfully submitted the interactions. You may now close this window.
                </p>
                <button type="button" className="primaryButton" onClick={() => handleModal()}>
                    Close
                </button>
            </>
        );
    }

    if (previews.length > 0) {
        return (
            <>
                <h3>Enter {type} message</h3>
                <InteractionPreviewList previews={previews} handleGoBackButton={handleGoBackButton} handleSubmit={handleSubmit} />
            </>
        );
    }

    const distinctUnits = Array.from(selectedEnrollments.values()).reduce( (acc, {unit_id}) => acc.add(unit_id), new Set() );
    const distinctStudents = Array.from(selectedEnrollments.values()).reduce( (acc, {student_id}) => acc.add(student_id), new Set() );

    return (
        <>
            <h3>Enter {type} message for {distinctStudents.size} distinct student{distinctStudents.size > 1 && 's'} in {distinctUnits.size} distinct unit{distinctUnits.size > 1 && 's'}</h3>
            <form id='interactionForm' onSubmit={handleSubmitPreview}>
                <table>
                    <tbody>
                        <tr>
                            <td align='right'>
                                <b>Subject: *</b>
                                {errors?.errors?.subject && <InteractionValidationErrorList errors={errors.errors.subject} />}
                            </td>
                            <td>
                                <input type="text" autoFocus name="subject" onChange={(e) => setSubject(e.target.value)} value={subject} />
                            </td>
                        </tr>
                        {(type === 'Mailout' || type === 'Email') &&
                            <>
                                <tr>
                                    <td align='right'>
                                        <b>From:</b>
                                        {errors?.errors?.from && <InteractionValidationErrorList errors={errors.errors.from} />}
                                    </td>
                                    <td>
                                        <input type="text" autoFocus name="from" onChange={(e) => setFrom(e.target.value)} value={from} placeholder={user.email} />
                                    </td>
                                </tr>
                                <tr>
                                    <td align='right'>
                                        <b>Email CSV record:</b>
                                        {errors?.errors?.cc && <InteractionValidationErrorList errors={errors.errors.cc} />}
                                    </td>
                                    <td>
                                        <input type="text" autoFocus name="cc" onChange={(e) => setCC(e.target.value)} value={cc} />
                                    </td>
                                </tr>
                                <tr>
                                    <td align='right'>
                                        <b>Send email per: </b>
                                        {errors?.errors?.mode && <InteractionValidationErrorList errors={errors.errors.mode} />}
                                    </td>
                                    <td>
                                        <label>
                                            <input type="radio" name="mode" value={INTERACTION_MODES.EMAIL_PER_UNIT} checked={mode === INTERACTION_MODES.EMAIL_PER_UNIT} onChange={() => setMode(INTERACTION_MODES.EMAIL_PER_UNIT)} />
                                            Unit ({countEmailSendOutPerMode(INTERACTION_MODES.EMAIL_PER_UNIT, selectedEnrollments)} emails in total)
                                        </label>
                                        <br />
                                        <label>
                                            <input type="radio" name="mode" value={INTERACTION_MODES.EMAIL_PER_COURSE} checked={mode === INTERACTION_MODES.EMAIL_PER_COURSE} onChange={() => setMode(INTERACTION_MODES.EMAIL_PER_COURSE)} />
                                            Course ({countEmailSendOutPerMode(INTERACTION_MODES.EMAIL_PER_COURSE, selectedEnrollments)} emails in total)
                                        </label>
                                        <br />
                                        <label>
                                            <input type="radio" name="mode" value={INTERACTION_MODES.EMAIL_PER_SCHOOL} checked={mode === INTERACTION_MODES.EMAIL_PER_SCHOOL} onChange={() => setMode(INTERACTION_MODES.EMAIL_PER_SCHOOL)} />
                                            School ({countEmailSendOutPerMode(INTERACTION_MODES.EMAIL_PER_SCHOOL, selectedEnrollments)} emails in total)
                                        </label>
                                        <br />
                                        <label>
                                            <input type="radio" name="mode" value={INTERACTION_MODES.EMAIL_PER_ENROLLMENT} checked={mode === INTERACTION_MODES.EMAIL_PER_ENROLLMENT} onChange={() => setMode(INTERACTION_MODES.EMAIL_PER_ENROLLMENT)} />
                                            Enrolment ({selectedEnrollments.size} emails in total)
                                        </label>
                                    </td>
                                </tr>
                            </>
                        }
                        <tr>
                            <td align='right'>
                                <b>Message: *</b>
                                {errors?.errors?.body_text && <InteractionValidationErrorList errors={errors.errors.body_text} />}
                                <div className="vertical-space">
                                    {interactionFieldNames.map((name, index) => (
                                        <div key={index} className="textInsert" onClick={() => handleTextInsert('{' + name + '}')}>
                                            {name} <FontAwesomeIcon icon={faAngleDoubleRight} />
                                        </div>
                                    ))}
                                </div>
                            </td>
                            <td>
                                <Editor tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                                    onInit={(evt, editor) => editorRef.current = editor}
                                    onEditorChange={(value,) => setMessage(value)}
                                    value={message}
                                    init={TINYMCE_INIT_SETTINGS}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td align='right'>
                                <b>Strategy: *</b>
                                {errors?.errors?.strategy && <InteractionValidationErrorList errors={errors.errors.strategy} />}
                            </td>
                            <td>{fullCohort ? (
                                <div>
                                    <b className="faded">All users selected so 'General broadcast' strategy used</b>
                                </div>
                            ) : filteredStrategies.length === 0 ? (
                                <div>
                                    <b className="faded">No options available so 'General broadcast' strategy used</b>
                                </div>
                            ) : (
                                filteredStrategies.map((strat, index) => (
                                    <div key={index}>
                                        <input id={`radio-strategy-${slugify(strat.name)}`} type="radio" name="strategy" value={strat.name} checked={strat.name === strategy} onChange={() => setStrategy(strat.name)} />
                                        <label htmlFor={`radio-strategy-${slugify(strat.name)}`}>{strat.name}</label>
                                    </div>
                                )))}
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2} align={'right'}>
                                {errors?.message && <InteractionValidationErrorList errors={[errors.message]} />}
                                <button type="submit" className="primaryButton" disabled={sendingPreview}>
                                    {sendingPreview ? 'Previewing ... ' : 'Preview'}
                                </button>
                                <button type="button" className="secondaryButton" onClick={() => handleModal()}>
                                    Cancel
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </>
    );
};

export default InteractionForm;
