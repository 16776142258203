import React, { useEffect, useState } from 'react'
import ColorChangingTableCell from '../ColorChangingTableCell/ColorChangingTableCell';

const ResourceDiagnostics = ({ year, term, method, header }) => {
    const [isLoading, setLoading] = useState(true);
    const [isEnabled, setEnabled] = useState(false);
    const [resources, setResources] = useState([]);
    const hasParameters = year && term && method;
    const isReady = isEnabled && !isLoading && hasParameters;

    useEffect(() => {
        setLoading(true);
        if (!isEnabled || !hasParameters) {
            setLoading(false)
            return;
        }

        setLoading(true)
        method(year, term, true).then(resources => {
            setResources(resources);
            setLoading(false);
        }).catch(error => console.log(error));

    }, [isEnabled, year, term]);

    const handleClick = () => {
        setResources([]);
        setEnabled(!isEnabled);
    }

    if (!isEnabled) {
        return (
            <table className='oddEvenRows'>
                <thead>
                    <tr>
                        <th width={1}><button onClick={handleClick}>Enable</button></th>
                        <th width={99} colSpan={3}>{header}</th>
                    </tr>
                </thead>
            </table>
        );
    }

    return (
        <table className='oddEvenRows'>
            <thead>
                <tr>
                    <th width={1}><button onClick={handleClick}>Disable</button></th>
                    <th width={99} colSpan={7}>{header}</th>
                </tr>
                <tr>
                    <th>#</th>
                    <th>Code</th>
                    <th>Name</th>
                    <th align={'right'}>ENR</th>
                    <th align={'right'}>WD</th>
                    <th align={'right'}>PASS</th>
                    <th align={'right'}>FAIL</th>
                    <th align={'right'}>ALL</th>
                </tr>
            </thead>
            <tbody>
                {!hasParameters && <tr><td colSpan={8}>Select a term.</td></tr>}
                {isLoading && <tr><td colSpan={8}>Loading ...</td></tr>}
                {isReady && resources.map((resource, index) =>
                    <ResourceDiagnosticsRow index={index} key={index} resource={resource} />
                )}
            </tbody>
        </table>
    );
}

const ResourceDiagnosticsRow = ({ resource, index }) => (
    <tr data-resource-id={resource.id}>
        <td align='center'>{index + 1}</td>
        <td>{resource.code}</td>
        <td>{resource.name}</td>
        <ColorChangingTableCell align={'right'} text={resource.enrollments_enr_count} />
        <ColorChangingTableCell align={'right'} text={resource.enrollments_wd_count} />
        <ColorChangingTableCell align={'right'} text={resource.enrollments_pass_count} />
        <ColorChangingTableCell align={'right'} text={resource.enrollments_fail_count} />
        <ColorChangingTableCell align={'right'} text={resource.enrollments_count} />
    </tr>
);

export default ResourceDiagnostics;
