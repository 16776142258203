import React, { useContext } from 'react';
import { HeaderContext } from '../../../../context/HeaderContext';
import { getDueInTermDesc, getValueByProperty, setValueByProperty, submitUserPreferences } from '../../../../utils/utils';
import { createPreferenceKey } from '../../utils/preference';
import useRouteParams from '../../../../hooks/useRouteParams';

/**
 * This component renders the dashboard preferences section.
 *
 * @param {Object} props - The props object.
 * @param {Object} props.assessments - The assessments object.
 * @returns {JSX.Element}
 */
const DashboardPreferences = ({ assessments }) => {
    const { user, setUser } = useContext(HeaderContext);
    const { year, term, unit: unitCode } = useRouteParams()

    /**
     * Updates the user's preference and submits it to the server.
     *
     * @param {Object} newPreferences - The new preference object.
     */
    const submitPreferences = (newPreferences) => {
        const newUser = { ...user, preference: newPreferences };
        submitUserPreferences(newUser);
        setUser(newUser);
    };

    /**
     * Returns whether the preference with the given key is checked or not. If the preference key cannot be found in user.preference
     * default return is true.
     *
     * @param {string} key - The preference key.
     * @param {Object} preference - The preferences object to check.
     * @param {boolean} fallback - The fallback value if the key does not exist - Defaults to true.
     * @returns {boolean} - Whether the preference is checked or not, if the key does not exist we return value of fallback.
     */
    const isPreferenceChecked = (key, preferences, fallback = true) => {
        const preferenceKey = createPreferenceKey(key, { year, term }, unitCode);
        const value = getValueByProperty(preferenceKey, preferences);
        return value ?? fallback;
    };

    /**
     * Handles the change event of the checkbox inputs.
     *
     * @param {Object} event - The change event object.
     */
    const handleChange = ({ target: { id, checked } }) => {
        const preferenceKey = createPreferenceKey(id, { year, term }, unitCode);
        const newPreferences = { ...user.preference };
        setValueByProperty(preferenceKey, checked, newPreferences);
        submitPreferences(newPreferences);
    };

    const assessmentTypes = [...assessments.reduce((acc, assessment) => acc.add(assessment.type), new Set())].map((type, key) => (
        <React.Fragment key={key}>
            <input
                id={`assessment.type.${type}`}
                type="checkbox"
                checked={isPreferenceChecked(`assessment.type.${type}`, user.preference)}
                onChange={handleChange}
            />
            <label htmlFor={`assessment.type.${type}`}> {type} </label>
            <br />
        </React.Fragment>
    ));

    const assessmentDueDates = [...assessments.reduce((acc, assessment) => acc.add(assessment.due_in_term), new Set())].map((dueInValue, key) => (
        <React.Fragment key={key}>
            <input
                id={`assessment.due_date.${dueInValue}`}
                type="checkbox"
                checked={isPreferenceChecked(`assessment.due_date.${dueInValue}`, user.preference)}
                onChange={handleChange}
            />
            <label htmlFor={`assessment.due_date.${dueInValue}`}> {getDueInTermDesc(dueInValue)} </label>
            <br />
        </React.Fragment>
    ));

    return <section className="filterBox">
        <fieldset>
            <legend>Assessment Visibility Status</legend>
            <input
                id="assessment.hidden.no"
                type="checkbox"
                checked={isPreferenceChecked('assessment.hidden.no', user.preference)}
                onChange={handleChange}
            />
            <label htmlFor="assessment.hidden.no"> Available </label>
            <br />
            <input
                id="assessment.hidden.yes"
                type="checkbox"
                checked={isPreferenceChecked('assessment.hidden.yes', user.preference)}
                onChange={handleChange}
            />
            <label htmlFor="assessment.hidden.yes"> Hidden in the Gradebook </label>
        </fieldset>
        <fieldset>
            <legend>Assessment Due Date</legend>
            {assessmentDueDates}
        </fieldset>
        <fieldset>
            <legend>Assessment Type</legend>
            {assessmentTypes}
        </fieldset>
    </section>;
}

export default DashboardPreferences;