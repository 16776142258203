import React, { useContext } from 'react';
import SelectedEnrollmentsContext from '../../../context/SelectedEnrollmentsContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faStickyNote } from '@fortawesome/free-solid-svg-icons';
import { pluralize } from '../../../utils/utils';
import { ModalContext } from '../../../context/ModalContext';
import { InteractionForm } from '../../../components';

/**
 *  Function component which is only visible when enrollments have been selected. It's the box which allows a user to open
 *  an interaction form.
 */
const SelectedFrom = ({ loaded, strategies }) => {
    const { selectedEnrollments } = useContext(SelectedEnrollmentsContext);
    const { handleModal } = useContext(ModalContext);
    const buttonClass = selectedEnrollments.size > 1 ? "primaryButton" : "secondaryButton";

    if (selectedEnrollments.size === 0) {
        return <></>;
    }

    return (
        <section className="filterBarConsole">
            <h4 style={{ backgroundColor: '#eebcff', color: 'black' }}>{pluralize(selectedEnrollments.size, 'Row')}</h4>
            {selectedEnrollments.size === 1 && (
                <p style={{ textAlign: 'center' }}>
                    <button
                        className="primaryButton"
                        style={{ width: '7rem' }}
                        onClick={() => handleModal(
                            <InteractionForm type='Email' enrollments={loaded} fullCohort={false} strategies={strategies} selectedEnrollments={selectedEnrollments} />
                        )}
                    >
                        <FontAwesomeIcon icon={faEnvelope} /> Email
                    </button>
                </p>
            )}
            <p style={{ textAlign: 'center' }}>
                <button
                    className={buttonClass}
                    style={{ width: '7rem' }}
                    onClick={() => handleModal(
                        <InteractionForm type='Mailout' enrollments={loaded} fullCohort={selectedEnrollments.size === loaded.length} strategies={strategies} selectedEnrollments={selectedEnrollments}/>
                    )}
                >
                    <FontAwesomeIcon icon={faEnvelope} /> Mailout
                </button>
            </p>
            <p style={{ textAlign: 'center' }}>
                <button
                    className="secondaryButton"
                    style={{ width: '7rem' }}
                    onClick={() => handleModal(
                        <InteractionForm type='Note' enrollments={loaded} fullCohort={selectedEnrollments.size === loaded.length} strategies={strategies} selectedEnrollments={selectedEnrollments}/>
                    )}
                >
                    <FontAwesomeIcon icon={faStickyNote} /> Note
                </button>
            </p>
        </section>
    );
}

export default SelectedFrom;