import React, { useContext, useState } from 'react';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faSquare as faSquareOutline } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sortBy } from 'lodash';
import SelectedEnrollmentsContext from '../../../../context/SelectedEnrollmentsContext';

/**
 * Allows the user to select enrollments.
 *
 * @param {Object} props
 * @param {Array} props.enrollments Hold all the enrollments objects available
 * @param {Object} props.defaultEnrollment Is the default enrollment object
 * @param {null|Array} props.value Holds an array of integers which are the id's of the selected enrollments
 * @param {Function} props.onChange The method to call with the new array of enrollment id's
 * @returns {JSX.Element} - The rendered component.
 */
const EnrollmentSelection = ({ enrollments, defaultEnrollment }) => {
    const {addMultipleSelectedEnrollments, clearAndAddMultipleSelectedEnrollments } = useContext(SelectedEnrollmentsContext);
    const [allSelected, setAllSelected] = useState(false);

    const handleSelectAll = () => {
        if (allSelected) {
            clearAndAddMultipleSelectedEnrollments([defaultEnrollment]);
            setAllSelected(false);
        } else {
            addMultipleSelectedEnrollments(enrollments);
            setAllSelected(true);
        }
    };

    return (
        <div className="tabBody">
            <div className="unitButtons">
                {sortBy(enrollments, 'unit.code').map((enrollment) => (
                    <EnrollmentSelectionButton
                        key={enrollment.id}
                        enrollment={enrollment}
                        primary={enrollment === defaultEnrollment}
                    />
                ))}
                {enrollments.length > 0 && <span className="horizontal-padding">
                    <label>
                        <input type="checkbox" name="compareAll" checked={allSelected} onChange={handleSelectAll}/>
                        Show all units
                    </label>
                </span>}
            </div>
        </div>
    );
};

/**
 * Renders a button to select an enrollment.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.enrollment - The enrollment object to display.
 * @param {boolean} [props.primary=false] - Whether the button is a primary button.
 * @param {boolean} [props.selected=false] - Whether the button is currently selected.
 * @param {Function} props.onClick - The click handler for the button.
 * @returns {JSX.Element} - The rendered component.
 */
const EnrollmentSelectionButton = ({ enrollment, primary}) => {
    const { selectedEnrollments, removeSelectedEnrollment, addSelectedEnrollment } = useContext(SelectedEnrollmentsContext);
    const selected = selectedEnrollments.has(enrollment.id);
    const buttonClassName = primary ? 'primaryButton' : selected ? 'secondaryButtonActive' : '';

    const handleOnClick = (enrollment) => {
        if (selectedEnrollments.has(enrollment.id)) {
            removeSelectedEnrollment(enrollment.id);
        } else {
            addSelectedEnrollment(enrollment);
        }
    };

    return (
        <button className={buttonClassName} onClick={() => handleOnClick(enrollment)}>
            <FontAwesomeIcon className="buttonIcon fa-fw" icon={selected ? faCheckCircle : faSquareOutline} /> {enrollment.unit.code}
        </button>
    );
};

export default EnrollmentSelection;
