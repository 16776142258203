import React from "react";
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';
import axios from "axios";
import * as yup from "yup";
import { config } from "../../../../utils/config";

const strategiesSchema = yup.object().shape({
    strategies: yup.array().of(
        yup.object().shape({
            name: yup.string().required().min(10).max(50),
            is_vet: yup.boolean().required(),
            is_he: yup.boolean().required(),
        })
    )
        .required(),
});

const defaultValue = {
    name: '',
    is_he: true,
    is_vet: true
};

const StrategiesForm = (props) => {

    return (
        <Formik
            validationSchema={strategiesSchema}
            initialValues={{
                strategies: props.initialValues ? props.initialValues : [],
            }}
            onSubmit={(values, { setSubmitting }) => {
                axios
                    .post(config.system.baseApiUrl + "/config/strategies", values)
                    .then(() => setSubmitting(false));
            }}
        >
            {({ values, isSubmitting }) => (
                <Form className="siteConfigForm">
                    <table className="oddEvenRows">
                        <FieldArray name="strategies">
                            {({ insert, remove, push }) => (
                                <tbody>
                                    {values.strategies.length > 0 ?
                                        values.strategies.map((strategy, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <Field name={`strategies.${index}.name`} type="text" placeholder="Enter strategy name ..." className="config config-text"/>
                                                    <ErrorMessage name={`strategies.${index}.name`} component="div" className="errorMessage"/>
                                                </td>
                                                <td>
                                                    <label>
                                                        HE <Field name={`strategies.${index}.is_he`} type="checkbox" checked={strategy.is_he} />
                                                    </label>
                                                    <ErrorMessage name={`strategies.${index}.is_he`} component="div" className="errorMessage"/>
                                                </td>
                                                <td>
                                                    <label>
                                                        VET <Field name={`strategies.${index}.is_vet`} type="checkbox" checked={strategy.is_vet} />
                                                    </label>
                                                    <ErrorMessage name={`strategies.${index}.is_vet`} component="div" className="errorMessage"/>
                                                </td>
                                                <td>
                                                    <button type="button" onClick={() => insert(index + 1, defaultValue)}>+</button>
                                                    <button type="button" onClick={() => remove(index)}>-</button>
                                                </td>
                                            </tr>
                                        ))
                                        :
                                        <tr>
                                            <td colSpan={4}>
                                                <button type="button" onClick={() => push(defaultValue)}>
                                                    Add a strategy
                                                </button>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            )}
                        </FieldArray>
                        <tfoot>
                            <tr>
                                <td colSpan={4} align="right">
                                    <button type="submit" disabled={isSubmitting}>{isSubmitting ? 'Submitting ...' : 'Submit'}</button>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </Form>
            )}
        </Formik>
    );
}

export { StrategiesForm }
