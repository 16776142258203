export default class QueryString {
    constructor(url) {
        this.pathname = '';
        this.query = {};

        if (url) {
            this.decode(url);
        }
    }

    encode() {
        // Construct the encoded URL string
        let encodedUrl = `${this.pathname}?`;
        for (const [key, value] of Object.entries(this.query)) {
            const encodedKey = encodeURIComponent(key);
            const encodedValue = Array.isArray(value) ? value.map(v => encodeURIComponent(v)).join(',') : encodeURIComponent(value);
            encodedUrl += `${encodedKey}=${encodedValue}&`;
        }
        encodedUrl = encodedUrl.slice(0, -1); // Remove trailing "&" or "?"

        return encodedUrl;
    }

    decode(url) {
        // Split the URL into pathname and query parts
        const [pathname, queryStr] = url.split('?');
        this.pathname = pathname;

        // Parse the query parameters
        const query = {};
        if (queryStr) {
            for (const param of queryStr.split('&')) {
                const [key, value] = param.split('=');
                const decodedKey = decodeURIComponent(key);
                const decodedValue = decodeURIComponent(value);
                query[decodedKey] = decodedValue.includes(',') ? decodedValue.split(',') : decodedValue;
            }
        }
        this.query = query;
    }
}
