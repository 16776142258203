import React from 'react';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import QueryString from '../../utils/queryString';

const FlagDashboardFilter = ({ enrollments, value, parameter }) => {
    const history = useHistory();
    const flags = enrollments.reduce((acc, obj) => {
        obj.flags.forEach(flag => acc.add(flag));
        return acc;
    }, new Set());

    const options = Array.from(flags).map( group => {
        return { value: group, label: group };
    });

    const onChange = (data) => {
        const qs = new QueryString(window.location.pathname + window.location.search);

        if (!data) {
            delete qs.query[parameter];
            history.replace(qs.encode());
            return;
        }

        qs.query[parameter] = data.map( data => data.value).join();
        history.replace(qs.encode());
    }

    return (
        <Select
            isMulti
            options={options}
            value={options.filter( obj => value.includes(obj.value))}
            placeholder="Student Flag ..."
            onChange={onChange}
            classNamePrefix="react-select"
        />
    );
}

export default FlagDashboardFilter;