import React from 'react';
import { RadialChart } from "react-vis";
import { config } from "../../../../utils/config";
import { withWidget } from "../../../../utils/graph";
import CourseGrades from '../CourseGrades/CourseGrades';

const ProgressInCourseGraph = ({ courseEnrollment }) => {

    const course = courseEnrollment.course,
          completed = courseEnrollment.credit_points_completed,
          required = courseEnrollment.credit_points_required,
          enrolled = courseEnrollment.credit_points_enrolled;

    var graph = [];
    if (completed > 0) {
        graph.push({
            angle: completed,
            color: config.colors.cquCharcoal,
            label: 'Completed',
        });
    }
    if (enrolled > 0) {
        graph.push({
            angle: enrolled,
            color: '#669fe3',
            label: 'Enrolled',
        });
    }
    graph.push({
        angle: (required - completed - enrolled),
        color: config.colors.cquLightCharcoal,
        label: 'Remaining',
    });

    return (
        <div style={{ float: 'left' }}>
            <div
                className="shadowBox numberBox"
                style={{
                    maxWidth: '250px',
                }}
            >
                <div style={{
                    height: '210px',
                    width: '200px',
                    margin: 'auto',
                }}>
                    <div style={{
                        position: 'relative',
                        zIndex: 10,
                        top: '80px',
                        left: '0px',
                    }}>{course.code}</div>
                    <div style={{
                        position: 'relative',
                        zIndex: 10,
                        top: '-40px',
                        left: '0px',
                    }}>
                        <RadialChart
                            radius={90}
                            innerRadius={60}
                            colorType={'literal'}
                            data={graph}
                            width={200}
                            height={200}
                        />
                    </div>
                </div>
                <div><b>{course.name}</b></div>
                <div style={{ padding: '0.5rem' }}>
                    {completed} of {required} CP completed<br />
                    {enrolled} CP enrolled
                </div>
                <CourseGrades courseEnrollment={courseEnrollment} withHeader={false}/>
            </div>
        </div>
    );
}

export default withWidget(ProgressInCourseGraph);