import React from 'react';

const OffsiteLink = ({link, children}) => (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={link}
      onClick={event => event.stopPropagation()}
    >
      {children}
    </a>
);

export default OffsiteLink;