import React, { useContext } from 'react';
import ModalBackground from '../ModalBackground/ModalBackground';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import { AssetsContext } from '../../context/AssetsContext';
import { getPreferenceFromUser, isCurrent as getIsCurrent } from '../../utils/utils';
import { config } from '../../utils/config';
import useRouteParams from '../../hooks/useRouteParams';
import { CURRENT_VET_YEAR_VALUE } from '../../utils/constants';

function getSelectedColumnsAndFilters() {
    // Get a list of checked columns
    const columns = [];
    config.columnOptions.forEach((label) => {
        const checkbox = document.getElementById('preferences_columns_' + label);
        if (checkbox.checked) {
            columns.push(label);
        }
    });

    // Get a list of checked filters
    const filters = [];
    config.filterOptions.forEach((label) => {
        const checkbox = document.getElementById('preferences_filters_' + label);
        if (checkbox.checked) {
            filters.push(label);
        }
    });

    return {columns, filters};
}

const PreferenceForm = ({ user, saving, handleClose, onChange, onSave }) => {
    const { year, term } = useRouteParams();
    const assets = useContext(AssetsContext);

    if (!assets || !assets.terms || assets.isLoading) {
        return <></>;
    }

    const thisTerm = assets.terms.find(elem => elem.year === parseInt(year) && elem.term === (term));

    const isVet = term === 'VET';
    const isCurrent = (isVet && year === CURRENT_VET_YEAR_VALUE) || ( !isVet && thisTerm && getIsCurrent(thisTerm));

    const category = isCurrent ? 'current' : 'historic';
    const subcategory = isVet ? 'VET' : 'HE';

    const unavailableColumns = config.defaultPrefs[category][subcategory].unavailable.columns;
    const unavailableFilters = config.defaultPrefs[category][subcategory].unavailable.filters;

    const defaultColumns = config.defaultPrefs[category][subcategory].default.columns;
    const defaultFilters = config.defaultPrefs[category][subcategory].default.filters;

    const { columns: userColumns, filters: userFilters } = getPreferenceFromUser(user, isCurrent, isVet); // Will be default when [].

    const handleRestoreDefaults = () => {
        // Restore to defaults using dom manipulation.
        const preferencesElement = document.getElementById('Preferences');
        const checkboxes = preferencesElement.querySelectorAll('input[type="checkbox"]');
        checkboxes.forEach((checkbox) => {
            const label = checkbox.getAttribute('name');
            const isFilter = checkbox.getAttribute('id').startsWith('preferences_filters');
            const isDisabled = isFilter ? unavailableFilters.includes(label) : unavailableColumns.includes(label);
            const isDefaultChecked = isFilter ? defaultFilters.includes(label) : defaultColumns.includes(label);
            if (!isDisabled) {
                checkbox.checked = isDefaultChecked;
            }
        });
        handleUpdatePreferences();
    };

    const handleUpdatePreferences = () => {
        if (!user.preference[category]) {
            user.preference[category] = {};
        }
        if (!user.preference[category][subcategory]) {
            user.preference[category][subcategory] = {};
        }

        user.preference[category][subcategory] = getSelectedColumnsAndFilters();

        onChange(user.preference);
    };

    return (
        <React.Fragment>
            <ModalBackground handleClose={handleClose} />
            <aside id="Preferences" className="topLeft">
                <div>
                    <div className="closeButton">
                        <FontAwesomeIcon icon={faTimesCircle} onClick={handleClose} />
                    </div>
                </div>
                <center><i>Preferences for {isCurrent ? 'Current' : 'Historic'} {isVet ? 'VET' : 'HE'}</i></center>
                <div style={{ display: 'flex', justifyContent: 'space-between' }} className='vertical-space-above'>
                    <div>
                        <h4>Filters</h4>
                        {config.filterOptions.map((label, index) => (
                            <div key={index}>
                                <input
                                    id={'preferences_filters_' + label}
                                    name={label}
                                    type="checkbox"
                                    disabled={unavailableFilters.includes(label)}
                                    defaultChecked={userFilters.includes(label)}
                                    onChange={handleUpdatePreferences}
                                /> <label
                                    htmlFor={'preferences_filters_' + label}
                                    title={unavailableFilters.includes(label) ? 'The selected cohort does not support this filter' : undefined}
                                >
                                    {unavailableFilters.includes(label) ? <s style={{ color: 'var(--cquCharcoal)' }}>{label}</s> : label}
                                </label>
                            </div>
                        ))}
                    </div>
                    <div>
                        <h4>Columns</h4>
                        {config.columnOptions.map((label, index) => (
                            <div key={index}>
                                <input
                                    id={'preferences_columns_' + label}
                                    name={label}
                                    type="checkbox"
                                    disabled={unavailableColumns.includes(label)}
                                    defaultChecked={userColumns.includes(label)}
                                    onChange={handleUpdatePreferences}
                                /> <label
                                    htmlFor={'preferences_columns_' + label}
                                    title={unavailableColumns.includes(label) ? 'The selected cohort does not support this column' : undefined}
                                >
                                    {unavailableColumns.includes(label) ? <s style={{ color: 'var(--cquCharcoal)' }}>{label}</s> : label}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='vertical-space'>
                    <button type="button" onClick={handleRestoreDefaults}>Restore Defaults</button>
                    <button type="button" className="primaryButton" disabled={saving} onClick={onSave}>{saving ? 'Saving Preferences ...' : 'Save Preferences'}</button>
                </div>
            </aside>
        </React.Fragment>
    )
}

export default PreferenceForm;