import React from 'react';

/**
 * Return a table cell element with the a formatted GPA value.
 */
const GPACell = ({ enabled = true, value }) => {
    if (!enabled) {
        return <></>;
    }

    return <td align='center'>{value < 0 ? '-' : Number(value).toFixed(1)}</td>
};

export default GPACell;