import React, { useContext } from 'react';
import { Hint, HorizontalGridLines, VerticalBarSeries, XAxis, XYPlot, YAxis } from 'react-vis';
import { AssetsContext } from '../../../../context/AssetsContext';
import { mapBeginDateToShortDates, mapBeginDateToWeeks } from '../../../../utils/calendar';
import { config } from '../../../../utils/config';
import { filterIntegers, renderYLabel, withWidget } from '../../../../utils/graph';
import { color_scheme } from '../../../../utils/utils';
import useRouteParams from '../../../../hooks/useRouteParams';

const WeeklyActivityGraph = ({ enrollments, hover, handleMouseOver, handleMouseOut }) => {
    const { year, term } = useRouteParams();
    const { termInfo } = useContext(AssetsContext);
    const thisTerm = termInfo.displayedTerm;
    const isVet = term === 'VET';
    const scheme = color_scheme(enrollments.flatMap(enrollments => enrollments.unit.code), [config.colors.cquBlue, config.colors.cquBlue50]);

    // Map by week, and index by unit code.
    const result = enrollments.reduce((acc, enrollment) => {
        const unitCode = enrollment.unit.code;

        if (!acc[unitCode]) {
            acc[unitCode] = [];
        }

        thisTerm.weeks.data.forEach((week) => {
            const { start } = week;
            const activity = enrollment.activities.find((activity) => activity.begin_date === start);

            const code = `${week.long_name}: ${unitCode}`
            acc[unitCode].push({
                x: start,
                y: activity?.activity || 0,
                code,
                description: activity?.activity || 0,
                color: hover.code === code ? config.colors.cquGreen : scheme[unitCode],
            });
        });

        return acc;
    }, {});

    return (
        <div className={isVet ? 'UnitWidget UnitWidgetWide VetUnitWidgetWide' : 'UnitWidget UnitWidgetWide'}>
            <h4>Activity by Week of Term</h4>
            <XYPlot
                width={isVet ? 1050 : 480}
                height={isVet ? 400 : 280}
                stackBy="y"
                xType="ordinal"
                margin={{
                    left: isVet ? 80 : 60,
                    bottom: isVet ? 50 : 30,
                }}
            >
                {hover && (
                    <Hint value={hover} className='plotTooltip' >
                        <div>
                            <h3>{hover.code}</h3>
                            <p>{hover.description}</p>
                        </div>
                    </Hint>
                )}
                {isVet ? <XAxis height={50} tickFormat={val => mapBeginDateToShortDates(val, thisTerm.weeks.data)} tickLabelAngle={-90} />
                       : <XAxis height={50} tickFormat={val => mapBeginDateToWeeks(val, thisTerm.weeks.data)} />}
                <YAxis width={isVet ? 80 : 60} tickFormat={filterIntegers} />
                <HorizontalGridLines />
                {Object.entries(result).map(([key, value]) =>
                    <VerticalBarSeries
                        key={key}
                        data={value}
                        colorType={"literal"}
                        onValueMouseOver={handleMouseOver}
                        onValueMouseOut={handleMouseOut}
                    />
                )}
                {renderYLabel('Moodle Clicks')}
            </XYPlot>

        </div>
    );


};

export default withWidget(WeeklyActivityGraph);