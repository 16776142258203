import React, { useState } from "react";
import { sortBy } from "lodash";
import InteractionTableSortableHeaderCell from "./InteractionTableSortableHeaderCell";
import InteractionTableRow from "./InteractionTableRow";
import InteractionTableTitle from "./InteractionTableTitle";

const SORT_DATE = 'interaction.time_created';
const SORT_AUTHOR = 'interaction.username';
const SORT_TYPE = 'interaction.interaction_type';
const SORT_ROLE = 'interaction.user_role';
const SORT_STRATEGY = 'interaction.strategy_name';

/**
 * A table that displays a list of interactions sent.
 * @param {Object[]} enrollments - An array of enrollments, each with an `interactionsSent` property.
 */
const InteractionTable = ({ enrollments, interactionCases, totalInteractions, strategies }) => {
    const [sort, setSort] = useState(SORT_DATE);
    const [sortDirection, setSortDirection] = useState(false);

    const sortList = (sortBy) => {
        if (sortBy === sort) {
            setSortDirection(!sortDirection);
            return;
        }

        setSort(sortBy);
        setSortDirection(true);
    };

    /**
     * Combine the two different type of interactions, namely interactionsSent and interactionCases.
     * Each interactionCase will include a new object property "interaction".
     * This mimics interactionSent and has been done to save us some if else statements.
     */
    const localInteractions = enrollments.flatMap(enr => enr.interactionsSent).concat(interactionCases.map((interCase) => {
        interCase.interaction = {
            username: interCase.creation_user,
            user_role: interCase.assigned_team,
            interaction_type: "Crm",
            subject: interCase.case_title,
            strategy_name: "",
            time_created: interCase.creation_date,
            num_messages: 0,
        }
        return interCase;
    }));

    const sorted = sortDirection ? sortBy(localInteractions, [sort]) : sortBy(localInteractions, [sort]).reverse();

    return (
        <div className="flexRow">
            <div className="shadowBox" style={{ minWidth: '60rem', width: '100%' }}>
                <InteractionTableTitle text={`Interactions (showing ${localInteractions.length} of ${totalInteractions})`} strategies={strategies}/>
                <table className="interactionTable" style={{ width: '100%' }}>
                    <thead>
                        <tr className="secondarySorting">
                            <InteractionTableSortableHeaderCell text='Type' sortConst={SORT_TYPE}  sort={sort} direction={sortDirection} handleClick={sortList}/>
                            <InteractionTableSortableHeaderCell text='Date' sortConst={SORT_DATE}  sort={sort} direction={sortDirection} handleClick={sortList}/>
                            <InteractionTableSortableHeaderCell text='Author' sortConst={SORT_AUTHOR}  sort={sort} direction={sortDirection} handleClick={sortList}/>
                            <InteractionTableSortableHeaderCell text='Role' sortConst={SORT_ROLE}  sort={sort} direction={sortDirection} handleClick={sortList}/>
                            <td width="45%">Message</td>
                            <InteractionTableSortableHeaderCell text='Strategy' sortConst={SORT_STRATEGY}  sort={sort} direction={sortDirection} handleClick={sortList}/>
                        </tr>
                    </thead>
                    <tbody>
                        {sorted.map((interaction, index) => <InteractionTableRow key={index} interactionSent={interaction} />)}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default InteractionTable;