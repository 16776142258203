import React, { useContext } from 'react';
import { renderLegendBar, withWidget } from '../../../../utils/graph';
import { config } from '../../../../utils/config';
import { sendLog } from '../../../../utils/utils';
import { RadialChart } from 'react-vis';
import { ModalContext } from '../../../../context/ModalContext';
import { LOG } from '../../../../utils/constants';
import useRouteParams from '../../../../hooks/useRouteParams';
import SelectedEnrollmentsContext from '../../../../context/SelectedEnrollmentsContext';
import { UnitWidgetModal } from '..';

const ActivityThisWeekGraph = ({ enrollments, hover, handleMouseOver, handleMouseOut }) => {
    const { handleModal } = useContext(ModalContext);
    const { year, term, unit } = useRouteParams();
    const { clearAndAddMultipleSelectedEnrollments } = useContext(SelectedEnrollmentsContext);

    const result = enrollments.reduce((acc, enrollment) => {
        const activityBand = enrollment.activityThisWeekBand;
        acc[activityBand] = acc[activityBand] || { angle: 0 };
        acc[activityBand].angle++;
        return acc;
    }, {});

    const radialChartData = Object.keys(result).map(key => ({
        angle: result[key].angle,
        label: result[key].angle.toString(),
        code: key,
        color: config.colors[key.toLowerCase()]
    }));

    // Create a legend based on the radialChartData
    const legend = radialChartData.map((val) => ([val.code, val.color, val]));

    if (radialChartData.length === 0) {
        return (
            <div className="UnitWidget">
                <h4>Activity This Week</h4>
                <div style={{ marginTop: '40%', textAlign: 'center' }}>
                    No data
                </div>
            </div>
        );
    }

    /**
     * Open the Modal displaying the cohort of the clicked course.
     */
    const handleClick = (data) => {
        const aggregated = enrollments.filter(({ activityThisWeekBand }) => data.code === activityThisWeekBand);

        clearAndAddMultipleSelectedEnrollments(aggregated);
        handleModal(<UnitWidgetModal title={`Activity This Week - ${data.code}`} handleModal={handleModal} cohortFilter={`activityBands=${data.code}`} />);
        sendLog("App\\Events\\Widget\\Aggregated", 'r', LOG.ACTION.AGGREGATED, LOG.TARGET.ENROLLMENTS, LOG.DASHBOARD.UNIT, {
            term: { year, term },
            code: unit,
            aggregation: 'activityThisWeek',
            value: data.code,
            aggregated: aggregated.length,
        })
    };

    return (
        <div className="UnitWidget">
            <h4>Activity This Week</h4>
            <div style={{ padding: '1rem 0', width: '200px', margin: '0.5rem auto' }}>
                <RadialChart
                    radius={100}
                    innerRadius={60}
                    data={radialChartData}
                    colorType={"literal"}
                    width={200}
                    height={200}
                    showLabels={true}
                    className={"clickable"}
                    labelsStyle={{ fontWeight: 'bold', fill: '#fff' }}
                    labelsRadiusMultiplier={0.90}
                    onValueClick={handleClick}
                    onValueMouseOver={handleMouseOver}
                    onMouseLeave={handleMouseOut}
                />
            </div>
            {renderLegendBar(legend, { onClick: handleClick, onMouseOver: handleMouseOver, onMouseOut: handleMouseOut })}
        </div>
    );
}

export default withWidget(ActivityThisWeekGraph);