import { useState} from 'react';

/**
 * This custom hook returns the Modal Component statuses.
 *
 * @returns {Object}
 */
export default function useModal() {
  let [modal, setModal] = useState(false);
  let [modalContent, setModalContent] = useState("I'm the Modal Content");

  let handleModal = (content = false) => {
    setModal(!modal);
    if (content) {
      setModalContent(content);
    }
  };

  return { modal, handleModal, modalContent };
};
