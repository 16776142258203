import React from 'react';

const CourseGrades = ({ courseEnrollment, withHeader = true }) => {
    const titles = [
        { title: 'Credit Transfers', total: courseEnrollment.credit_points_credited },
        { title: 'Credited Units', total: courseEnrollment.units_credited },
        { title: 'Enrolled Units', total: courseEnrollment.units_enrolled },
        { title: 'Exempt Units', total: courseEnrollment.units_exempt },
        { title: 'Failed Units', total: courseEnrollment.units_failed },
        { title: 'Passed Units', total: courseEnrollment.units_passed },
        { title: 'Withdrawn Units', total: courseEnrollment.units_withdrawn },
    ];

    return (
        <table className="dataTable" style={{ minWidth: '10rem', width: '100%', color: '#000' }}>
            <tbody>
                <tr>
                    <th colSpan={2}>
                        <b>{withHeader && courseEnrollment.course.code}</b>
                    </th>
                </tr>
                {titles.map((title, index) => (
                    <React.Fragment key={index}>
                        {title.total > 0 && (
                            <tr>
                                <td align='left'><b>{title.title}</b></td>
                                <td><b>{title.total}</b></td>
                            </tr>
                        )}
                    </React.Fragment>
                ))}
            </tbody>
        </table>
    );
}

export default CourseGrades;