import React from "react";
import {
    Hint,
    HorizontalGridLines,
    VerticalBarSeries,
    XYPlot,
    XAxis,
    YAxis,
} from "react-vis";
import { config } from "../../utils/config";
import { getWeekFromDate, mapBeginDateToWeeks, mapBeginDateToShortDates } from "../../utils/calendar";
import { filterIntegers, renderNumberBar, renderYLabel, getWeeklyTotals, withWidget } from "../../utils/graph";

const WeeklyLoginsGraph = (props) => {
    const {
        data,
        hover,
        //handleClick,
        handleMouseOver,
        handleMouseOut,
        //isClickable,
        calendar,
        disabled,
        axisDates, // display dates instead of week numbers
    } = props;
    const title = props.title ? props.title : "Weekly Staff Logins to Success";

    const getLoginGraph = (loginsByWeek) => {
        const points = Object.entries(loginsByWeek).map((item, index) => {
            const [begin_date, total] = item;
            const week = getWeekFromDate(begin_date, calendar);
            const selected = hover;
            return {
                x: begin_date,
                y: total,
                color: selected.x === begin_date ? config.colors.cquBlue50 : config.colors.cquGreen,
                description: week && week.long_name,
            }
        });
        return points;
    }

    const getLoginWeek = (loginsByWeek, thisWeek) => {
        if (thisWeek === null) return null;
        for (const week of loginsByWeek) {
            if (week.begin_date === thisWeek.begin_date) {
                return week;
            }
        }
        return null;
    }
    const hasData = (data.length > 0);
    const thisWeek = calendar.this_week;
    const loginGraph = getLoginGraph(getWeeklyTotals(data, 'total', calendar));
    // obj, or null if no data for this week
    const loginThisWeek = getLoginWeek(data, thisWeek);
    const loginThisWeekTotal = loginThisWeek ? loginThisWeek.total : 0;
    const disabledClass = disabled ? "faded" : "";

    const plot = {
        width: 480,
        height: 240,
        left: 60,
        bottom: 50,
    };

    return (
        <div className={`UnitWidget UnitWidgetWide ${disabledClass}`}>
            <h4>{title}</h4>
            <div style={{
            }}>
                {hasData ? renderNumberBar(
                    loginThisWeekTotal,
                    loginThisWeekTotal === 1
                        ? 'login'
                        : 'logins',
                    'this week'
                ) : renderNumberBar()}
            </div>
            <XYPlot
                height={plot.height}
                width={plot.width}
                xType="ordinal"
                //  xDomain={[0, termWeekTotal]}
                stackBy='y'
                className={hasData ? "" : "empty"}
                margin={{
                    left: plot.left,
                    bottom: plot.bottom,
                }}
            >
                {hover && (
                    <Hint
                        value={hover}
                        className='plotTooltip'
                    >
                        <div>
                            <h3>{hover.description}</h3>
                            <p>{hover.y}</p>
                        </div>
                    </Hint>
                )}
                {axisDates ? (
                    <XAxis
                        height={plot.bottom}
                        tickFormat={begin_date => mapBeginDateToShortDates(begin_date, calendar)}
                        tickLabelAngle={-90}
                    />
                ) : (
                    <XAxis
                        height={plot.bottom}
                        tickFormat={begin_date => mapBeginDateToWeeks(begin_date, calendar)}
                    />
                )}
                {hasData && (
                    <YAxis
                        width={plot.left}
                        tickFormat={filterIntegers}
                    //  tickTotal={3}
                    />
                )}
                {/** renderXLabel("Week of Term", plot) **/}
                {renderYLabel("Logins", plot)}
                <HorizontalGridLines />
                <VerticalBarSeries
                    colorType={"literal"}
                    //fill={config.colors.high}
                    stroke="#ffffff"
                    data={loginGraph}
                    onValueMouseOver={handleMouseOver}
                    onValueMouseOut={handleMouseOut}
                />
            </XYPlot>
            {!hasData && (
                <div style={{
                    position: 'relative',
                    bottom: '160px',
                }}>
                    No data
                </div>
            )}
        </div>
    );
}

export default withWidget(WeeklyLoginsGraph);