import React, { useState } from 'react';
import axios from "axios";
import { config as siteConfig } from "../../../../utils/config";

function ClearCacheForm() {
    const [disabled, setDisabled] = useState(false);

    const handleClick = () => {
        setDisabled(true);
        const url = siteConfig.system.baseApiUrl + '/config/clear-cache';

        axios.post(url)
        .then(res => {
            if (res.data.error) {
                console.log(res.data.error);
            }
            setDisabled(false);
        });
    };

    return (
        <table style={{width: '18rem'}} className="oddEvenRows">
            <tbody>
                <tr>
                    <td>This is typically only necessary if a task becomes stuck due to an unexpected server problem.</td>
                </tr>
                <tr>
                    <td><button onClick={handleClick} disabled={disabled}>Clear</button></td>
                </tr>
            </tbody>
        </table>
    );
}

export default ClearCacheForm;
