import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import axios from "axios";
import { config } from '../../../utils/config';
import { convertAssetsToOptions } from '../../../utils/utils';

const CampusSelect = ({ enabled, year, term, value, onChange }) => {
    const [isLoading, setLoading] = useState(true);
    const [options, setOptions] = useState([]);

    useEffect(() => {
        setLoading(true)
        axios.get(config.system.baseApiUrl + `/terms/${year}/${term}/campuses`)
            .then(res => {
                const options = convertAssetsToOptions(res.data.data);
                setOptions(options);
                setLoading(false);
            });
    }, [year, term]); // Only re-run the effect if year or term changes.

    return (
        <div className='leftThirdPadded'>
            <Select
                isMulti
                isDisabled={!enabled || isLoading || options.length === 0}
                isLoading={isLoading}
                options={options}
                value={value}
                placeholder="Campuses ..."
                onChange={onChange}
                classNamePrefix="react-select"
            />
        </div>
    );
}

export default CampusSelect;
