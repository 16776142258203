import React from 'react'
import AsyncSelect from 'react-select/async';
import axios from "axios";
import { config } from '../../../utils/config';

const StudentSelect = ({ enabled, incognito, value, onChange }) => {
    /**
     * Method to load the option asynchronously.
     *
     * @param {string} input
     * @param {callback} callback
     * @returns
     */
    const loadOptions = (input, callback) => {
        // Must be longer then 3.
        if (input.length < 3) {
            return callback([]);
        }

        axios
            .get(config.system.baseApiUrl + `/students?limit=100&search=${input.toUpperCase()}`)
            .then(res => {
                const results = res.data.data.map(item => {
                    if (incognito) {
                        return {
                            value: item.id,
                            label: 'XXXXX: XXXXX XXXXX'
                        };
                    }

                    return {
                        value: item.id,
                        label: `${item.code}: ${item.first_name} ${item.last_name}`
                    };
                });
                return callback(results);
            })
            .catch(error => {
                console.log(error);
                return callback([]);
            })
    }

    return (
        <div className='leftThirdPadded'>
            <AsyncSelect
                isMulti
                isDisabled={!enabled}
                value={value}
                placeholder="Students ..."
                noOptionsMessage={inputValue => "Enter student names or IDs"}
                loadOptions={loadOptions}
                onChange={onChange}
                classNamePrefix="react-select"
            />
        </div>
    );
}

export default StudentSelect;
