import { faArrowCircleUp, faArrowCircleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import useQuery from '../../../hooks/useQuery';
import { LOG } from '../../../utils/constants';
import { sendLog } from '../../../utils/utils';
import QueryString from '../../../utils/queryString';
import useRouteParams from '../../../hooks/useRouteParams';

const OrderButton = ({ text, sort, vertical = false }) => {
    const [isActive, setActive] = useState(false);
    const [isPrimary, setPrimary] = useState(true);
    const [direction, setDirection] = useState(false); // Where true is ASC and false DESC.
    const location = useLocation();
    const query = useQuery();
    const history = useHistory();
    const { year, term } = useRouteParams();

    useEffect(() => {
        if (!query.has('order')) {
            setActive(false);
            setDirection(true);
            return; // Noting left to do.
        }

        const [primary, secondary] = query.get('order')?.split(',') || [];

        if (primary === sort || primary === `-${sort}`) {
            setActive(true);
            setPrimary(true);
            setDirection(primary === sort);
        } else if (secondary === sort || secondary === `-${sort}`) {
            setActive(true);
            setPrimary(false);
            setDirection(secondary === sort);
        } else {
            setActive(false);
        }
    }, [query, sort]);

    const handleClick = (event) => {
        const [primary, secondary] = query.get('order')?.split(',') || [];
        const newSearch = new QueryString(location.pathname + location.search);
        const newValue = (!direction ? '' : '-') + sort;
        const newOrder = event.shiftKey ? [primary, newValue] : [newValue, secondary];

        // Set the new order.
        // filter(Boolean) will remove any falsy values from the array.
        newSearch.query.order = newOrder.filter(Boolean).join(',');

        history.push(newSearch.encode());
        sendLog("App\\Events\\Cohort\\Sorted", 'r', LOG.ACTION.SORTED, LOG.TARGET.ENROLLMENTS, LOG.DASHBOARD.COHORT, {
            term: {year, term},
            path: newSearch.query.path.split(','),
            order: newSearch.query.order.split(','),
        });
    }

    if (sort === undefined) {
        return <th>{text}</th>
    }

    return (
        <div
            className={`orderButton ${isActive ? (isPrimary ? 'orderButtonPrimary' : 'orderButtonSecondary') : 'orderButtonInactive'}`}
            onClick={handleClick}
        >
            <FontAwesomeIcon icon={isActive ? (direction ? faArrowCircleDown : faArrowCircleUp) : faArrowCircleDown} /> {vertical ? <div>{text}</div> : <>{text}</>}
        </div>
    );
}

export default OrderButton;