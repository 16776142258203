import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { AssessmentContext } from '../../context/AssessmentContext';
import useRouteParams from '../../../../hooks/useRouteParams';

const AssessmentsSubmitted = ({enrollments}) => {
    const { student } = useRouteParams();
    const { isLoading, isError, assessments } = useContext(AssessmentContext);

    if (!enrollments || enrollments.length === 0 || isError ) {
        return (
            <div className="shadowBox numberBox faded" style={{ width: '250px' }}>
                <div>—</div>
                <div>Submitted Assessments</div>
            </div>
        );
    }

    if (isLoading) {
        return (
            <div className="shadowBox numberBox faded" style={{ width: '250px' }}>
                <div>Loading ... </div>
                <div>Submitted Assessments</div>
            </div>
        );
    }

    const unitIds = enrollments.map( enr => enr.unit_id );

    const assessmentWithSubmissions = assessments
        // Filter all assessments so they they are in the targeted enrollments.
        .filter( ass => unitIds.includes(ass.unit_id) )
        // Filter all assessments where the student has made a attempt
        .filter( ass => !!ass.attempts.find(attempt => attempt.student_id === parseInt(student)) );

        return (
            <div className="shadowBox numberBox" style={{ width: '250px' }}>
                <div>{assessmentWithSubmissions.length}</div>
                <div>Submitted Assessments</div>
            </div>
        );
}

export default AssessmentsSubmitted;