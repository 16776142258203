import React from 'react';
import { days_ago, pluralize } from '../../../utils/utils';

/**
 * Return a table cell element with the a formatted days ago value.
 */
const DaysAgoCell = ({enabled = true, value, align = 'center', small = false}) => {
    const days = days_ago(value);

    if (days === null) {
      return <td align={align}>Never</td>;
    }

    if (small) {
        return <td align={align}><small>{pluralize(days, 'day')}</small></td>

    }

    return <td align={align}>{pluralize(days, 'day')}</td>
}

export default DaysAgoCell;