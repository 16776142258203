import React, { useState } from 'react';
import { config } from '../../../../utils/config';

const COLORS = [undefined, config.colors.cquGreen, config.colors.cquOrange, config.colors.cquRed];

const ColorChangingTableCell = ({ text, align }) => {
    const [backgroundColorIndex, setBackgroundColorIndex] = useState(0);

    const handleBackgroundColorChange = () => {
        // Cycle through the background colors when the cell is clicked
        setBackgroundColorIndex((prevIndex) => (prevIndex + 1) % COLORS.length);
    };

    return <td align={align}
        style={{
            backgroundColor: COLORS[backgroundColorIndex],
            cursor: 'pointer',
        }}
        onClick={handleBackgroundColorChange}
        title={'Click to change colour'}
    > {text?.toLocaleString()}</td>
}

export default ColorChangingTableCell;