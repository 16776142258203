import  React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { faPollH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AssetsContext } from '../../../../context/AssetsContext';
import { isCurrent } from '../../../../utils/utils';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

/**
 * Returns a link to the Cohort dashboard from a unit dashboard.
 *
 * @param {String} param0.filter The string value of a filter to be applied to the link.
 */
const CohortLink = ({ filter }) => {
    const { year: parYear, term: parTerm, unit } = useParams();
    const assets = useContext(AssetsContext);
    if (assets.isLoading) {
        // We should never get here, because this component is not created until after assets is finished loading.
        return <></>;
    }

    const term = assets.isSuccess ? assets.termInfo.displayedTerm : null;
    const ENRfilter = isCurrent(term) && term.term !== 'VET' ? '&unitStatus=ENR' : '';
    const url = `/cohort/${parYear}/${parTerm}?path=:${unit}${ENRfilter}&${filter}`;

   return (
        <h4>
            <Link to={url}>
                <FontAwesomeIcon icon={faPollH} /> Cohort Dashboard
            </Link>
        </h4>
    );
}

export default CohortLink;