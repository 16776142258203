import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { HorizontalGridLines, VerticalBarSeries, VerticalGridLines, XAxis, XYPlot, YAxis } from 'react-vis';
import { config } from '../../../../utils/config';
import { filterIntegers } from '../../../../utils/graph';
import { color_scheme, getTypeIcon } from '../../../../utils/utils';

const COLORS = color_scheme(config.notes, config.scheme.interactions);

const InteractionGraph = ({ term, enrollments, interactionCases }) => {

    const interactionsPerType = config.notes.reduce((acc, type) => {
        if (!acc[type]) {
            acc[type] = [];
        }

        acc[type] = enrollments.flatMap(enrol => enrol.interactionsSent.filter(interSent => interSent.interaction.interaction_type.toLowerCase() === type.toLowerCase()));
        return acc;
    }, []);

    // Add Crm cases and add properties for easier filtering.
    interactionsPerType['Crm'] = interactionCases.map(interCase => ({ ...interCase, interaction: { interaction_type: 'Crm', time_created: interCase.creation_date } }));

    // Combine the interactionsPerType array with term weeks into a new one
    const dataSet = Object.keys(interactionsPerType).map(type => ({
        type,
        data: term.weeks.data.map(week => {
            const filteredInteractions = interactionsPerType[type].filter(interaction => {
                const timeCreated = interaction.interaction.time_created;
                return timeCreated >= week.start && timeCreated < week.end;
            });
            return {
                x: week.short_name,
                y: filteredInteractions.length
            };
        })
    }));

    return (
        <div className="flexRow">
            <div className="shadowBox">
                <div className="text-right">
                    <p>
                        {Object.keys(COLORS).map((label) => (
                            <span key={label} className="horizontal-padding">
                                <FontAwesomeIcon icon={getTypeIcon(label)} style={{ color: COLORS[label] }} /> {label}
                            </span>
                        ))}
                    </p>
                </div>
                <XYPlot height={200} width={780} xType="ordinal" stackBy="y">
                    <HorizontalGridLines />
                    <VerticalGridLines />
                    <XAxis />
                    <YAxis tickFormat={filterIntegers} />
                    {dataSet.map(({ type, data }) =>
                        <VerticalBarSeries key={type} data={data} style={{ fill: COLORS[type], stroke: 'white' }} />
                    )}
                </XYPlot>
            </div>
        </div>
    );
}

export default InteractionGraph;