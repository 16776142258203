import React from 'react';
import { MyExperienceListItem } from './index';

const MyExperienceList = ({ items }) => (
    <div className="feedbackList">
        <h4>Feedback ({items.length})</h4>
        {items.map((item, index) => <MyExperienceListItem item={item} key={index} />)}
    </div>
)

export default MyExperienceList;