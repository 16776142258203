import React, { Component } from "react";
import moment from "moment-timezone";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faCheckCircle,
  faExclamationCircle,
  faQuestionCircle
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faCheckCircle,
  faExclamationCircle,
  faQuestionCircle
);
moment.tz.setDefault('Australia/Brisbane');

class ImportLogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imports: props.import.imports,
      steps: props.import.steps,
    };
  }

  getColumns()
  {
    return ['import', 'materialisation', 'created_at', 'finished_at', 'succeeded_at'].concat(this.state.steps);
  }

  renderColumnTitle(col) {
    if (col === 'created_at') {
      return 'Started';
    }
    if (col === 'finished_at') {
      return 'Finish';
    }
    if (col === 'succeeded_at') {
      return 'Succeed';
    }
    if (col === 'materialisation') {
      return 'DSMART';
    }

    return col[0].toUpperCase() + col.substring(1).replace('_', ' ');
  }

  renderColumn(obj, col) {
    if ((!!obj[col]) && (obj[col].constructor !== Object)) {
      if (col === 'id') {
        return null;
      } else if (col === 'import' && obj[col] === 'new-term' && obj.year != null) {
        return obj.year +' '+ obj.term;
      } else if (col === 'materialisation') {
        return moment(obj.materialisation, "YYYY-MM-DD hh:mm:ss").format('D MMM H:mm') ;
      } else if ((col === 'created_at' || col === 'finished_at' || col === 'succeeded_at')) {
        return (
          moment.unix(obj[col]).format('D MMM H:mm')
        );
      } else {
        return (
          obj[col]
        );
      }
    }

    if ((!!obj[col]) && (obj[col].constructor === Object)) {
      return (
        this.renderStatus(obj[col])
      )
    }
  }

  renderStatus(obj) {
    if (obj.error) {
      /**
       *
      return (
        <pre>
        {this.renderObj(obj.error)}
        </pre>
      );
       *
       */
      console.log(obj.error);
      return (
        <div title={obj.error.message}>
        <FontAwesomeIcon icon={faExclamationCircle} style={{color: 'red'}} />
        </div>
      )
    }
    if (obj.finished_at) {
      return (
        <div title={'Finished ' + moment.unix(obj.finished_at).format('D MMM, H:mm')}>
        <FontAwesomeIcon icon={faCheckCircle} style={{color: 'green'}} />
        </div>
      )
    }
    if (obj.created_at) {
      return (
        <div title={'Started ' + moment.unix(obj.created_at).format('D MMM, H:mm')}>
        <FontAwesomeIcon icon={faQuestionCircle} style={{color: 'orange'}} />
        </div>
      )
    }
  }

  render() {
    const columns = this.getColumns();
    const imports = this.state.imports;
    return (
      <table className="oddEvenRows">
        <thead>
          <tr>
            { columns.map((col, index) => (
              this.renderColumnTitle(col) && (
                <td>{this.renderColumnTitle(col)}</td>
              )
            ))}
          </tr>
        </thead>
        <tbody>
          { imports.map((imp, index) => (
            <tr>
              { columns.map((col, ii) => (
                this.renderColumnTitle(col) && (
                  <td>
                    { this.renderColumn(imp, col)}
                  </td>
                )
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  renderObj(obj) {
    return Object.keys(obj).map((key, i) => {
      return (
        <div>
          {obj[key]}
        </div>
      )
    })
  }
}

export { ImportLogs }
