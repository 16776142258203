import React, { useContext } from 'react';
import { getPercentageGraph, renderLegendBar, renderPercentageBar, withWidget } from '../../../../utils/graph';
import { days_ago, sendLog } from '../../../../utils/utils';
import { config } from '../../../../utils/config';
import { ModalContext } from '../../../../context/ModalContext';
import { LABEL_LOGIN, LABEL_NO_LOGIN, LOG } from '../../../../utils/constants';
import moment from 'moment-timezone';
import useRouteParams from '../../../../hooks/useRouteParams';
import SelectedEnrollmentsContext from '../../../../context/SelectedEnrollmentsContext';
import UnitWidgetModal from '../UnitWidgetModal/UnitWidgetModal';
moment.tz.setDefault('Australia/Brisbane');

const RecentLoginsGraph = ({ week, enrollments, hover, handleMouseOver, handleMouseOut }) => {
    const { handleModal } = useContext(ModalContext);
    const { year, term, unit } = useRouteParams();
    const { clearAndAddMultipleSelectedEnrollments } = useContext(SelectedEnrollmentsContext);
    const total = enrollments.length;

    if (total === 0) {
        return (
            <div className="UnitWidget">
                <div style={{ float: 'right' }}>{days_ago(week.start)} days</div>
                <h4>Recent Logins</h4>
                <div style={{ marginTop: '40%', textAlign: 'center', }}>No data</div>
            </div>
        );
    }

    const logins = enrollments.reduce((acc, enrollment) => {
        if ( enrollment.lastLoginBand === 'This week') {
            acc++;
        }
        return acc;
    }, 0);

    const percent = Math.round(100 * (logins / total));

    const data = [
        {
            label: LABEL_LOGIN,
            total: percent,
            description: LABEL_LOGIN,
            code: LABEL_LOGIN,
        },
        {
            label: LABEL_NO_LOGIN,
            total: 100 - percent,
            description: LABEL_NO_LOGIN,
            code: LABEL_NO_LOGIN,
        }
    ];

    const graph = getPercentageGraph(
        data,
        [config.colors.cquGreen, config.colors.cquBlue],
        config.colors.cquBlue50,
        hover.code
    );

    const legend = graph.map((_) => {
        const [, color, obj] = _;
        return [
            obj.label,
            color,
            obj,
        ]
    });

    /**
     * Open the Modal on click.
     */
    const onClick = (data) => {
        const aggregated = enrollments.reduce((acc, enrollment) => {
            const loggedIn = enrollment.lastLoginBand === 'This week';

            if (!loggedIn && data.label === LABEL_LOGIN) {
                return acc;
            }

            if (loggedIn && data.label === LABEL_NO_LOGIN) {
                return acc;
            }

            acc.push(enrollment);
            return acc;
        }, []);
        const cohortFilter = `lastLogin=${data.label === LABEL_LOGIN ? 'This week' : 'Never,Not this week'}`;

        clearAndAddMultipleSelectedEnrollments(aggregated);
        handleModal(<UnitWidgetModal title={`Recent Logins - ${data.label}`} handleModal={handleModal} cohortFilter={cohortFilter} />);
        sendLog("App\\Events\\Widget\\Aggregated", 'r', LOG.ACTION.AGGREGATED, LOG.TARGET.ENROLLMENTS, LOG.DASHBOARD.UNIT, {
            term: {year,term},
            code: unit,
            aggregation: 'loginBands',
            value: data.label,
            aggregated: aggregated.length,
        });
    }

    return (
        <div className="UnitWidget">
            <div style={{ float: 'right' }}>{days_ago(week.start)} days</div>
            <h4>Recent Logins</h4>
            <h1>{percent}%</h1>
            {renderPercentageBar(graph, { onClick, onMouseOver: handleMouseOver, onMouseOut: handleMouseOut, })}
            {renderLegendBar(legend, { onClick, onMouseOver: handleMouseOver, onMouseOut: handleMouseOut, })}
            <p><small>{logins} of {total} students have<br /><b>logged in</b> this week.</small></p>
        </div>
    )
}

export default withWidget(RecentLoginsGraph);