import React, { Component } from "react";
import { config } from "../../../../utils/config"
import axios from "axios";
import moment from 'moment-timezone';
moment.tz.setDefault('Australia/Brisbane');

class ImportJobsForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      jobs: props.initialValues.jobs,
      queue: props.initialValues.queue,
      isSubmitting: {},
      isCancelling: {},
    };
  }

  handleImport(name) {
    return event => {

      event.stopPropagation();

      let isSubmitting = this.state.isSubmitting;
      isSubmitting[name] = true;

      this.setState({
        isSubmitting,
      });

      const url = [
        config.system.baseApiUrl,
        'config',
        'import-job',
        name,
      ].join('/');

      axios.post(url)
        .then(res => {
          if (res.data.error) {
            console.log(res.data.error); //TODO error
          }
          let isSubmitting = this.state.isSubmitting;
          isSubmitting[name] = false;
          this.setState({
            isSubmitting,
            queue: res.data.result.queue,
          });
        })
        .catch(err => {
          let isSubmitting = this.state.isSubmitting;
          isSubmitting[name] = false;
          this.setState({
            isSubmitting,
          });
          console.log(err); //TODO error
        });

    }
  }

  handleCancel(id) {
    return event => {

      event.stopPropagation();

      let isCancelling = this.state.isCancelling;
      isCancelling[id] = true;

      this.setState({
        isCancelling,
      });

      const url = [
        config.system.baseApiUrl,
        'config',
        'import-job-cancel',
        id,
      ].join('/');

      axios.post(url)
        .then(res => {
          if (res.data.error) {
            console.log(res.data.error); //TODO error
          }
          let isCancelling = this.state.isCancelling;
          delete isCancelling[id];
          this.setState({
            isCancelling,
            queue: res.data.result.queue,
          });
        })
        .catch(err => {
          let isCancelling = this.state.isCancelling;
          delete isCancelling[id];
          this.setState({
            isCancelling,
          });
          console.log(err); //TODO error
        });

    }
  }

  render() {

    const { jobs, queue, isSubmitting, isCancelling } = this.state;

    return (
            <table className="oddEvenRows">
              <tbody>
                { jobs && jobs.map((job, _) => {
                  return (
                  <tr>
                    <td>
                        {job.title}:
                    </td>
                    <td>
                      <button onClick={this.handleImport(job.name)} disabled={isSubmitting[job.name]}>
                        { isSubmitting[job.name] ? "Queueing..." : "Run now" }
                      </button>
                    </td>
                  </tr>
                  );
                })}
                <tr>
                  <th colspan="2">
                    Queued jobs
                  </th>
                </tr>
                { queue && queue.length > 0 ? queue.map((job, _) => {
                  return (
                    <tr>
                      <td>
                        {job.title} - {job.status.name} @ {moment(job.status.time).format('D MMM, H:mm')}
                      </td>
                      <td>
                        <button onClick={this.handleCancel(job.id)} disabled={isCancelling[job.id] || job.status.name !== "Queued"}>
                          { isCancelling[job.id] ? "Cancel..." : "Cancel" }
                        </button>
                      </td>
                    </tr>
                  );
                }) : (
                  <tr>
                    <td colspan="2">
                      No queued jobs.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
  );
  }
}

export { ImportJobsForm }
