import React, { useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import { AimsStudentLink, IncognitoText, StudentDashboardLink } from '../../../../components';
import moment from 'moment-timezone';
import { HeaderContext } from '../../../../context/HeaderContext';
import useRouteParams from '../../../../hooks/useRouteParams';
moment.tz.setDefault('Australia/Brisbane');

const SORT_DATE = 'recorded_date';
const SORT_RESPONSE = 'response';
const SORT_NAME = 'student_name';

const MyExperienceModal = ({ weekName, data }) => {
    const [sort, setSort] = useState(SORT_DATE);
    const [sortDirection, setSortDirection] = useState(true);
    const { year, term, unit } = useRouteParams();
    const { incognito } = useContext(HeaderContext);

    const title = `My Experience - ${weekName}`;

    const sortMyExperienceList = (sortBy) => {
        if (sortBy === sort) {
            setSortDirection(!sortDirection);
            return;
        }

        setSort(sortBy);
        setSortDirection(true);
    };

    const onDateSortClick = () => sortMyExperienceList(SORT_DATE);
    const onRespSortClick = () => sortMyExperienceList(SORT_RESPONSE);
    const onNameSortClick = () => sortMyExperienceList(SORT_NAME);

    const responses = sortDirection ? _.sortBy(data, [sort]) : _.sortBy(data, [sort]).reverse();

    return (
        <>
            <div style={{ textAlign: "center" }}>
                <h3>{title}</h3>
            </div>
            <table className="dataTable" style={{ margin: "0px auto" }}>
                <tbody>
                    <tr>
                        <th onClick={onDateSortClick}>
                            <FontAwesomeIcon icon={sort === SORT_DATE ? sortDirection ? faSortDown : faSortUp : faSort} />
                            Response Date
                        </th>
                        <th >Student ID</th>
                        <th onClick={onNameSortClick}>
                            <FontAwesomeIcon icon={sort === SORT_NAME ? sortDirection ? faSortDown : faSortUp : faSort} />
                            Student Name
                        </th>
                        <th>Satisfaction score</th>
                        <th onClick={onRespSortClick}>
                            <FontAwesomeIcon icon={sort === SORT_RESPONSE ? sortDirection ? faSortDown : faSortUp : faSort} />
                            Text Response
                        </th>
                    </tr>
                    {responses.map((elem, key) =>
                        <tr key={key}>
                            <td>{moment(elem.recorded_date).format('YY-MM-DD')}</td>
                            <td>
                                <AimsStudentLink studentCode={elem.student_id.toString()}>
                                    <IncognitoText text={elem.student_id} incognito={incognito} />
                                </AimsStudentLink>
                            </td>
                            <td>
                                <StudentDashboardLink studentId={elem.student_id} term={{year, term}} unitCode={unit}>
                                    <IncognitoText text={elem.student_name} incognito={incognito} />
                                </StudentDashboardLink>
                            </td>
                            <td>{elem.rating}</td>
                            <td>{elem.response}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </>
    );
}

export default MyExperienceModal;