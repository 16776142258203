import React, { useEffect, useState } from 'react'
import axios from "axios";
import { config } from '../../../../utils/config';
import ColorChangingTableCell from '../ColorChangingTableCell/ColorChangingTableCell';

const TermDiagnostics = ({ enabled, year, term, value, onChange }) => {
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const isReady = !isLoading && year && term && enabled;

    useEffect(() => {
        setLoading(true)
        if (!enabled) {
            setLoading(false)
            return;
        }

        axios.get(config.system.baseApiUrl + `/diagnostics/${year}/${term}`)
            .then(res => {
                setData(res.data.data);
                setLoading(false);
            });
    }, [year, term]);


    if (!enabled) {
        return (
            <table className='oddEvenRows'>
                <thead>
                    <tr>
                        <th>Term statistics</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Select a term.</td>

                    </tr>
                </tbody>
            </table>);
    }

    return (
        <table className='oddEvenRows'>
            <thead>
                <tr>
                    <th colSpan={25}>Term statistics</th>
                </tr>
                <tr>
                    <th rowSpan={2}>DSMART</th>
                    <th colSpan={6}>Enrollments</th>
                    <th colSpan={6}>Assets</th>
                    <th colSpan={10}>Miscellaneous</th>
                    <th colSpan={2}>Coordinators</th>
                </tr>
                <tr>
                    <th>ENR</th>
                    <th>WD</th>
                    <th>PASS</th>
                    <th>FAIL</th>
                    <th>ALL</th>
                    <th>Course</th>
                    <th>Campuses</th>
                    <th>Colleges</th>
                    <th>Courses</th>
                    <th>Schools</th>
                    <th>Students</th>
                    <th>Units</th>
                    <th>Groups</th>
                    <th>Group Students</th>
                    <th>Assessments</th>
                    <th>Attempts</th>
                    <th>Extensions</th>
                    <th>VET Participations</th>
                    <th>My Experiences</th>
                    <th>Transfers</th>
                    <th>Activity</th>
                    <th>Last Login</th>
                    <th>Course</th>
                    <th>Unit</th>
                </tr>
            </thead>
            <tbody>
                {isLoading && <tr><td colSpan={25}>Loading ...</td></tr>}
                {isReady &&
                    <tr>
                        <td>{data?.materialisation}</td>
                        <ColorChangingTableCell text={data?.enrollments.enr} />
                        <ColorChangingTableCell text={data?.enrollments.wd} />
                        <ColorChangingTableCell text={data?.enrollments.pass} />
                        <ColorChangingTableCell text={data?.enrollments.fail} />
                        <ColorChangingTableCell text={data?.enrollments.all} />
                        <ColorChangingTableCell text={data?.course_enrollments_count} />
                        <ColorChangingTableCell text={data?.campuses_count} />
                        <ColorChangingTableCell text={data?.colleges_count} />
                        <ColorChangingTableCell text={data?.courses_count} />
                        <ColorChangingTableCell text={data?.schools_count} />
                        <ColorChangingTableCell text={data?.students_count} />
                        <ColorChangingTableCell text={data?.units_count} />
                        <ColorChangingTableCell text={data?.groups_count} />
                        <ColorChangingTableCell text={data?.group_students_count} />
                        <ColorChangingTableCell text={data?.assessments_count} />
                        <ColorChangingTableCell text={data?.attempts_count} />
                        <ColorChangingTableCell text={data?.extensions_count} />
                        <ColorChangingTableCell text={data?.vet_participations_count} />
                        <ColorChangingTableCell text={data?.my_experiences_count} />
                        <ColorChangingTableCell text={data?.course_transfers_count} />
                        <ColorChangingTableCell text={data?.activities_sum} />
                        <ColorChangingTableCell text={data?.last_login} />
                        <ColorChangingTableCell text={data?.course_coordinators_count} />
                        <ColorChangingTableCell text={data?.unit_coordinators_count} />
                    </tr>
                }
            </tbody>
        </table>
    );
}

export default TermDiagnostics;
