import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

const PreferenceButton = () => (
    <section className="filterBox iconBox tableCenter">
        <p
            style={{ fontSize: '4rem', marginTop: '5rem', color: 'white', cursor: 'pointer' }}
            onClick={() => {
                if (window.cquSuccessHeader !== undefined) {
                    const nonEvent = new Event('click_it');
                    window.cquSuccessHeader.handleShowPreferences(nonEvent);
                }
            }}
        >
            <FontAwesomeIcon icon={faPlusCircle} />
        </p>
    </section>
);

export default PreferenceButton;