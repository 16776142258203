import { useState, useEffect } from 'react';
import { fetchTerms } from '../utils/fetch';

/**
 * Custom hook that fetches data.
 *
 * @returns {Object} An object with the following properties:
 *   @property {boolean} isLoading True when the data is being pulled
 *   @property {boolean} isError True when during the fetching of data an error occurred
 *   @property {boolean} isSuccess True when the fetching of data was successful
 *   @property {*} data The data that has been fetched
 *   @property {Object} error Any error that might have occurred
 */
export default function useTerms() {
    const [data, setData] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        setLoading(true);
        fetchTerms()
            .then(setData)
            .catch( error => {
                console.error(error);
                setError(error);
            }).finally( () => {
                setLoading(false);
            });
    }, []);

    return {
        isLoading,
        isError: error !== null,
        isSuccess: error === null && !isLoading,
        data,
        error
    };
}
