import { useState, useEffect } from 'react';
import { fetchAssessments } from '../utils/fetch';

/**
 * Custom hook that fetches enrollment data given a term and unit code.
 *
 * @param {integer} unitId  Target the year of term
 * @returns {Object} An object with the following properties:
 *   @property {boolean} isLoading True when the data is being pulled
 *   @property {boolean} isError True when during the fetching of data an error occurred
 *   @property {boolean} isSuccess True when the fetching of data was successful
 *   @property {*} data The data that has been fetched
 *   @property {Object} error Any error that might have occurred
 */
export default function useUnitAssessments(unitId) {
    const [data, setData] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        setLoading(true);

        if (!unitId) {
            return;
        }

        let unitIds = unitId;
        if ( !Array.isArray(unitId) ) {
            unitIds = [unitId];
        }

        if (!unitIds || unitIds.length === 0) {
            setLoading(false);
            return;
        }

        const fetchData = async () => {
            const promises = unitIds.map(async (unitId) => {
                try {
                    const unitAssessments = await fetchAssessments(unitId);
                    return unitAssessments;
                } catch (err) {
                    console.error(err);
                    setError(err);
                    return [];
                }
            });

            const results = await Promise.all(promises);
            setData(results.flat());
        };

        fetchData().finally(() => {
            setLoading(false);
        });
    }, [JSON.stringify(unitId)]);

    return {
        isLoading,
        isError: error !== null,
        isSuccess: error === null && !isLoading,
        data,
        error
    };
}