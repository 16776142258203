import React from "react";
import {
    Hint,
    HorizontalGridLines,
    VerticalBarSeries,
    XYPlot,
    XAxis,
    YAxis,
} from "react-vis";
import { config } from "../../utils/config";
import { filterIntegers, renderXLabel, renderYLabel, withWidget } from "../../utils/graph";

const CourseCreditOrgsGraph = (props) => {
    const {
        //data,
        hover,
        handleClick,
        handleMouseOver,
        handleMouseOut,
        isClickable,
    } = props;
    const title = props.title ? props.title : "Transfer Credits";
    const cohort = props.cohort ? props.cohort : "students";
    // sort by total, descending, and take top 5
    const data = Object.values(props.data).sort((a, b) => {
        if (a.total < b.total) return 1;
        if (a.total > b.total) return -1;
        return 0;
    }).slice(0, 5);
    const graph = Object.entries(data).map((parts) => {
        const [, item] = parts;
        const key = item.code;
        const value = item.total;
        const color = (hover && hover.code === key) ? config.colors.cquBlue50 : config.colors.cquGreen;
        return {
            x: key,
            y: value,
            color,
            code: key,
            description: key + ": " + item.name,
        };
    });
    const hasData = graph.filter((_) => {
        return (_.y > 0);
    }).length > 0;

    if (graph.length > 0) {
        // pad columns with empty values
        while (graph.length < 5) {
            graph.push({ x: -graph.length, y: 0 });
        }
    }

    const plot = {
        width: 300,
        height: 280,
        left: 60,
        bottom: 60,
    };

    return (
        <div className="UnitWidget">
            <div style={{ float: 'right' }}>Top 5 Codes</div>
            <h4>{title}</h4>
            <XYPlot
                height={plot.height}
                width={plot.width}
                xType="ordinal"
                className={hasData ? "" : "empty"}
                margin={{
                    left: plot.left,
                    bottom: plot.bottom,
                }}
            >
                {hover && (
                    <Hint
                        value={hover}
                        className='plotTooltip'
                    >
                        <div>
                            <h3>{hover.description}</h3>
                            <p>{hover.y}</p>
                        </div>

                    </Hint>
                )}
                <XAxis
                    height={plot.bottom}
                    tickFormat={val => (val < 0 ? '' : val)}
                />
                {hasData && (
                    <YAxis
                        width={plot.left}
                        tickFormat={filterIntegers}
                    />
                )}
                {renderXLabel("Org Code", plot)}
                {renderYLabel("Count of " + cohort, plot)}
                <HorizontalGridLines />
                <VerticalBarSeries
                    colorType={"literal"}
                    //color={config.colors.cquGreen}
                    className={isClickable ? "clickable" : ""}
                    data={graph}
                    onValueClick={handleClick}
                    onValueMouseOver={handleMouseOver}
                    onValueMouseOut={handleMouseOut}
                />
            </XYPlot>
            {!hasData && (
                <div style={{
                    position: 'relative',
                    bottom: '160px',
                }}>
                    No data
                </div>
            )}
        </div>
    );
}

export default withWidget(CourseCreditOrgsGraph);