import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { COUNT_SHOW_DEFAULT, COUNT_SHOW_ERROR, COUNT_SHOW_LOADING, CURRENT_VET_YEAR_VALUE, CURRENT_VET_YEAR_VALUE_STRING } from '../../../utils/constants';
import { Link } from 'react-router-dom';
import {isCurrent}  from '../../../utils/utils';

const MatchesButton = ({ count, loading, selTerm, params }) => {
    if (count === undefined || count === COUNT_SHOW_DEFAULT) {
        return (
            <button id="showMatchesButton" disabled={true} tabIndex={-1} className="primaryButton">
                Show Matches <FontAwesomeIcon icon={faChevronRight} />
            </button>
        );
    }

    if (loading || count === COUNT_SHOW_LOADING) {
        return (
            <button id="showMatchesButton" disabled={true} tabIndex={-1} className="primaryButton">
                <FontAwesomeIcon icon={faSpinner} spin />
            </button>
        );
    }

    if (count === COUNT_SHOW_ERROR) {
        return (
            <button id="showMatchesButton" disabled={true} tabIndex={-1} className="primaryButton">
                Failed please amend your filters
            </button>
        );
    }

    if (count === 0) {
        return (
            <button id="showMatchesButton" disabled={true} tabIndex={-1} className="primaryButton">
                No Matches
            </button>

        );
    }

    const plural = count > 1 ? 'es' : '';
    const ENRfilter = isCurrent(selTerm) && selTerm.term !== 'VET' ? '&unitStatus=ENR' : '' ;
    return (
        <Link to={`cohort/${selTerm.year === CURRENT_VET_YEAR_VALUE ? CURRENT_VET_YEAR_VALUE_STRING : selTerm.year}/${selTerm.term}?order=name${params}${ENRfilter}`}>
            <button id="showMatchesButton" className="primaryButton">
                Show {count} Match{plural} {count === null ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                    <FontAwesomeIcon icon={faChevronRight} />
                )}
            </button>
        </Link>
    );

}

export default MatchesButton;