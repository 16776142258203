import React from 'react';
import { hide_string } from '../../../../utils/utils';

const StudentInfo = ({ incognito, student, course }) => {

    /** Renders the string depending on incognito mode. */
    const renderIncognito = (str, len) => {
        return incognito ? hide_string(str, len) : str;
    }

    return (
        <section>
            <h1>{renderIncognito(`${student.last_name}, ${student.first_name}`, 8)}</h1>
            <p>{renderIncognito(student.id)} {student.code !== student.id.toString() && (
                <>
                    ({renderIncognito(student.code)})
                </>
            )} | {course.level} | {course.code} {course.name}</p>
        </section>
    );
}

export default StudentInfo;