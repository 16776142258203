import { useState, useEffect } from 'react';
import { fetchTermEnrollments } from '../utils/fetch';

/**
 * Custom hook that fetches enrollment data given a term and student code.
 *
 * @param {integer} year  Target the year of term
 * @param {string} term  Target the term of term
 * @param {URLSearchParams} query  Target path to fetch
 * @returns {Object} An object with the following properties:
 *   @property {boolean} isLoading True when the data is being pulled
 *   @property {boolean} isError True when during the fetching of data an error occurred
 *   @property {boolean} isSuccess True when the fetching of data was successful
 *   @property {*} data The data that has been fetched
 *   @property {Object} error Any error that might have occurred
 */
export default function useEnrollments(year, term, query) {
    const [data, setData] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const pathString = query?.path?.toString();


    useEffect(() => {
        setLoading(true);
        fetchTermEnrollments(year, term, query)
            .then(setData)
            .catch( error => {
                console.error(error);
                setError(error);
            }).finally( () => {
                setLoading(false);
            });
    }, [year, term, pathString]);

    return {
        isLoading,
        isError: error !== null,
        isSuccess: error === null && !isLoading,
        data,
        error
    };
}
