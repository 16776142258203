import React, { useContext } from "react";
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import { config } from "../../utils/config";
import { truncate } from "../../utils/utils";
import { AssetsContext } from "../../context/AssetsContext";
moment.tz.setDefault('Australia/Brisbane');

const DashboardInfo = (props) => {
    const { user, term } = props;

    return (
        <section className="infoBox">

            {user.courses.length > 0 && <Courses courses={user.courses} />}
            {user.units.length > 0 && <Units units={user.units} /> }
            <KeyDates term={term} />

            <div className="header">Useful Links</div>
            <div className="content">
                <div><a href={config.links.aimsUrl} target="_blank" rel="noopener noreferrer">AIMS</a></div>
                <div><a href={config.links.handbookUrl} target="_blank" rel="noopener noreferrer">Handbook</a></div>
                <div><a href={config.links.myTasksUrl} target="_blank" rel="noopener noreferrer">My Tasks</a></div>
                <div><a href={config.links.moodleUrl} target="_blank" rel="noopener noreferrer">Moodle</a></div>
            </div>
        </section>
    );
};

const KeyDates = ({ term }) => (
        <>
            <div className="header">Key Dates</div>
            <div className="content">
                {term?.dates?.data?.filter(date => date.short_name !== "").map((date, index) =>
                    <TermDate key={index} date={date} />
                )}
            </div>
        </>
);

const TermDate = ({ date }) => {
    const beginDate = moment(date.start);
    const endDate = moment(date.end);

    const singleDate = beginDate.isSame(endDate, 'day');
    const beginFormat = beginDate.isSame(endDate, 'month') ? 'D' : 'D MMM';

    return (
        <div className="flexRow" title={`${date.short_name}: ${date.full_name}`}>
            <div>{truncate(date.full_name, { len: 20 })}</div>
            <div className="text-right" style={{ flexGrow: '1' }}>
                {singleDate ? beginDate.format('D MMM, YYYY') : `${beginDate.format(beginFormat)} - ${endDate.format('D MMM, YYYY')}`}
            </div>
        </div>
    );
}

const Courses = ({ courses }) => {
    const { terms } = useContext(AssetsContext);

    const data = courses.map(course => {
        course.term = terms.find(({ id }) => id === course.term_id);
        return course;
    });

    return (
        <>
            <div className="header">My Courses</div>
            <div className="content">
                {data.map((course, index) => (
                    <div key={index}>
                        <Link to={`/cohort/${course.term.year}/${course.term.term}?path=!${course.code}`}>
                            {course.code}
                        </Link>
                    </div>
                ))}
            </div>
        </>
    );
};

const Units = ({ units }) => {
    const { terms } = useContext(AssetsContext);

    const data = units.map(unit => {
        unit.term = terms.find(({ id }) => id === unit.term_id);
        return unit;
    });

    return (
        <>
            <div className="header">My Units</div>
            <div className="content">
                {data.map((unit, index) => (
                    <div key={index}>
                        <Link to={`/cohort/${unit.term.year}/${unit.term.term}?path=:${unit.code}`}>
                            {unit.code}
                        </Link>
                    </div>
                ))}
            </div>
        </>
    );
};

export default DashboardInfo;